import { KpiExportFileRequest } from '@cvfm-front/tefps-types/build/observatory/KpiExportFileRequest';
import { SupersetDashboardExportRequest } from '@cvfm-front/tefps-types/build/observatory/SupersetDashboardExportRequest';

import { apiDelete, apiGet, apiGetRaw, apiPost } from '../helpers';

import { DownloadDTO, MailDTO, SmsDTO } from './types';

const BASE_DOWNLOADS_URL =
  '/api/proxy/exporter/api/v0/cities/{cityId}/downloads';

const BASE_MAIL_TEMPLATE_URL = 'api/prv/v0/cities/{cityId}/mail-template';

const BASE_SUPERSET_URL = '/api/proxy/exporter/api/v0/cities/{cityId}/superset';

export async function downloadExport(resourceId: string): Promise<any> {
  return apiGetRaw(`${BASE_DOWNLOADS_URL}/${resourceId}`);
}

export async function listCurrentDownloads(): Promise<Array<DownloadDTO>> {
  return apiGet<Array<DownloadDTO>>(BASE_DOWNLOADS_URL);
}

export async function deleteDocument(resourceId: string): Promise<any> {
  return apiDelete<unknown>(`${BASE_DOWNLOADS_URL}/${resourceId}`);
}

export async function deleteDocuments(
  resourceIds: Array<string>
): Promise<any> {
  return apiPost<unknown>(`${BASE_DOWNLOADS_URL}/delete`, resourceIds);
}

export async function sendTestEmail(mailDTO: MailDTO): Promise<void> {
  return apiPost<void>(`${BASE_MAIL_TEMPLATE_URL}/test-mail`, mailDTO);
}

export async function sendTestSms(smsDTO: SmsDTO): Promise<void> {
  return apiPost<void>(`${BASE_MAIL_TEMPLATE_URL}/test-sms`, smsDTO);
}

export async function exportIndicator1D(
  KpiExportFileRequest: KpiExportFileRequest
): Promise<void> {
  return apiPost<void>(`${BASE_SUPERSET_URL}/export1D`, KpiExportFileRequest);
}

export async function exportDashboard(
  SupersetDashboardExportRequest: SupersetDashboardExportRequest
): Promise<void> {
  return apiPost<void>(
    `${BASE_SUPERSET_URL}/exportDashboard`,
    SupersetDashboardExportRequest
  );
}
