import React, { CSSProperties, useState } from 'react';
import Drawer from 'material-ui/Drawer';

import BoButton from 'facade/BoButton';
import { STYLE_LOGIN_BAR, STYLE_MENU_BAR } from 'theme';

import HintList from './HintList';

const { _t } = window.loadTranslations(__filename);

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 20,
  height: 36,
};

const STYLE_DESCRIPTION: CSSProperties = {
  fontSize: 14,
  display: 'flex',
  justifyContent: 'center',
};

const headerHeight: number =
  (STYLE_LOGIN_BAR.height as number) + (STYLE_MENU_BAR.height as number);

const STYLE_DRAWER: CSSProperties = {
  top: headerHeight,
  height: `calc(100% - ${headerHeight}px)`,
};

type Props = {
  description: string;
  hintList: Array<{
    field: string;
    legend: string | null | undefined;
    required?: boolean;
  }>;
  persistentHints?: boolean;
};

const HintListDrawer = ({
  description,
  hintList,
  persistentHints,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div style={STYLE_ROW}>
        <span style={STYLE_DESCRIPTION}> {description} </span>
        {!persistentHints && !open && (
          <BoButton
            label={_t('element.displayTags.label')}
            onClick={handleToggle}
          />
        )}
      </div>
      <Drawer
        open={open}
        openSecondary
        width={300}
        containerStyle={STYLE_DRAWER}
      >
        <HintList
          hintList={hintList.map(hint => ({
            field: hint.field,
            legend: hint.legend,
          }))}
          closeList={persistentHints ? undefined : handleToggle}
        />
      </Drawer>
    </>
  );
};

export default HintListDrawer;
