import React, { useCallback, useContext } from 'react';
import FileDownload from 'material-ui/svg-icons/file/file-download';
import ErrorIcon from 'material-ui/svg-icons/alert/error';
import { CircularProgress, IconButton } from 'material-ui';

import { Flex } from '@cvfm-front/commons-ui';
import { formatDatetime } from 'commons/Date';
import { PdfExport, PdfExportStatus } from 'tefps/PdfExport/types';
import { BKG_CYAN, ICON_RED } from 'theme';
import { PdfExportContext } from 'tefps/PdfExport/PdfExportContext';

type Props = {
  pdfExport: PdfExport;
};

const { _tg } = window.loadTranslations();

const StatusToText = (status: PdfExportStatus) => {
  switch (status) {
    case 'DONE':
      return _tg('Topbar.PdfDownload.PdfDownloadElement.done');
    case 'ERROR':
      return _tg('Topbar.PdfDownload.PdfDownloadElement.error');
    case 'WAITING_TO_ARCHIVE':
      return _tg('Topbar.PdfDownload.PdfDownloadElement.waitingArchive');
    case 'CREATING_ARCHIVE':
      return _tg('Topbar.PdfDownload.PdfDownloadElement.creatingArchive');
    case 'DOWNLOADING_FILES':
      return _tg('Topbar.PdfDownload.PdfDownloadElement.downloadingFiles');
    case 'PENDING':
    default:
      return _tg('Topbar.PdfDownload.PdfDownloadElement.pending');
  }
};

const PdfDownloadElement = ({ pdfExport }: Props) => {
  const service = useContext(PdfExportContext);
  const downloadArchive = useCallback(
    () => service.getFromApiPdfExportArchive(pdfExport.id),
    [pdfExport]
  );

  return (
    <Flex
      width100
      alignItems="center"
      justifyContent="space-between"
      key={pdfExport.id}
    >
      <span>{formatDatetime({ datetime: pdfExport.creationDatetime })}</span>
      <span>{pdfExport.id}</span>
      <span>{StatusToText(pdfExport.status)}</span>
      {pdfExport.status === 'DOWNLOADING_FILES' && (
        <span>
          {(
            (pdfExport.totalDocument > 0
              ? pdfExport.downloadedDocumentCounter / pdfExport.totalDocument
              : 0) * 100
          ).toFixed(2)}
          %
        </span>
      )}
      {pdfExport.status !== 'DONE' && pdfExport.status !== 'ERROR' && (
        // Hard to render a percentage for archive creation
        // Render a loader instead
        <CircularProgress size={22} />
      )}
      {pdfExport.errors.length > 0 && (
        <IconButton>
          <ErrorIcon color={ICON_RED} />
        </IconButton>
      )}
      {pdfExport.status === 'DONE' && (
        <IconButton
          onClick={downloadArchive}
          style={{ padding: 0 }}
          tooltip={_tg('Topbar.PdfDownload.PdfDownloadElement.tooltip')}
          iconStyle={{ height: 22, width: 40 }}
        >
          <FileDownload color={BKG_CYAN} />
        </IconButton>
      )}
    </Flex>
  );
};

export default PdfDownloadElement;
