import * as React from 'react';
import SocialMoodBad from 'material-ui/svg-icons/social/mood-bad';

import { ApiError } from 'api/ApiError';

type Level = 'WARN' | 'ERROR';

type Props = {
  level?: Level;
  message?: string;
  error?: ApiError | Error | { message: string } | null;
};

function isApiError(error: ApiError | any) {
  return (error as ApiError).status !== undefined;
}

const findColor = (level?: Level) => {
  if (level === 'WARN') return '#000';
  return '#c71b1b';
};

const formatErrorMessage = (
  error: ApiError | Error | { message: string }
): string => {
  if (isApiError(error)) {
    return `${(error as ApiError).name}: ${error.message}`;
  }
  return error.message;
};

const ErrorBlock = ({ message, level, error }: Props): JSX.Element => (
  <table style={{ color: findColor(level) }}>
    <tbody>
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
          <SocialMoodBad
            style={{
              width: 60,
              height: 60,
              color: findColor(level),
              marginRight: 10,
            }}
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: 5 }}>
          <p style={{ fontWeight: 'bold' }}>{message}</p>
          {error && <p style={{ marginTop: 5 }}>{formatErrorMessage(error)}</p>}
          {error && isApiError(error) && (error as ApiError).json && (
            <pre
              style={{
                fontSize: 12,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                padding: 5,
              }}
            >
              {JSON.stringify((error as ApiError).json, null, 2)}
            </pre>
          )}
        </td>
      </tr>
    </tbody>
  </table>
);

export default ErrorBlock;
