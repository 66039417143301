import React from 'react';
import {
  Chart,
  registerables,
  ChartData,
  ChartOptions,
  ChartType,
} from 'chart.js';

// TODO: This initialisation should probably not be here, encapsulate in a module
if (Array.isArray(registerables)) {
  Chart.register(...registerables);
}

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

type PropsType = {
  width?: number;
  height?: number;
  type: ChartType;
  data: ChartData;
  options?: ChartOptions;
  update?: boolean;
  isLastDatasetLighter?: boolean;
};

export default class ChartJSWrapper extends React.Component<PropsType> {
  chart: Chart | undefined = undefined;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props: PropsType): void {
    if (this.chart && props.update) {
      this.chart.data.labels = props.data.labels;
      this.chart.data.datasets = props.data.datasets;
      if (props.isLastDatasetLighter) {
        this.changeLastColorBar();
      }
      this.chart.update();
    }
  }

  changeLastColorBar = (): void => {
    if (!this.chart || !this.chart.data.datasets) return;

    this.chart.data.datasets.forEach(dataset => {
      const currentColor = dataset.backgroundColor as string;
      if (!dataset.data) return;
      dataset.backgroundColor = Array(dataset.data.length).fill(currentColor);
      /* const rgbColor = hexToRgb(currentColor);
      if (rgbColor) {
        const backgroundColorLight = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},0.2)`;
        // dataset.backgroundColor[dataset.data.length - 1] = backgroundColorLight;
      } */
    });
  };

  exportGraph = (): string | undefined => this.chart?.toBase64Image();

  initCharContainerRef = (instance: HTMLCanvasElement): void => {
    const canvas = instance;
    const { data, options, isLastDatasetLighter } = this.props;
    let { type } = this.props;

    if (!canvas) {
      return;
    }

    // We still want to display a pie chart with one element and don't want this overridden
    if (
      type !== 'pie' &&
      data.datasets?.length === 1 &&
      data.datasets[0].data?.length === 1
    ) {
      type = 'bar';
    }
    this.instantiateChart(canvas, type, data, options, isLastDatasetLighter);
  };

  instantiateChart(
    instance: HTMLCanvasElement,
    type: ChartType,
    data: ChartData,
    options?: ChartOptions,
    isLastDatasetLighter?: boolean
  ): void {
    this.chart = new Chart(instance, {
      type,
      data,
      options,
    });
    if (isLastDatasetLighter) {
      this.changeLastColorBar();
      this.chart.update();
    }
  }

  render({ width, height } = this.props): React.ReactNode {
    return (
      <canvas ref={this.initCharContainerRef} width={width} height={height} />
    );
  }
}
