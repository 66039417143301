import querystring from 'querystring';
import moment from 'moment';

import { apiGet, apiPatch, apiPost } from 'api/helpers';
import { MifFilters } from 'tepv/Mif/List/types';

import {
  MapSearchRequest,
  MatchingMifSearch,
  MifDetail,
  MifMapSearchResultDTO,
  MifSearchDTO,
} from './types';

const BASE_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/mifs';

export async function searchMifs(
  filters: Record<string, any>
): Promise<MifSearchDTO> {
  return apiPost<MifSearchDTO>(`${BASE_URL}`, filters);
}

export async function fetchMifDetails(mifId: string): Promise<MifDetail> {
  return apiGet<MifDetail>(`${BASE_URL}/${mifId}`);
}

export async function fetchMatchingMifs(
  addressFilter: string | null | undefined
): Promise<MatchingMifSearch> {
  return apiGet<MatchingMifSearch>(
    `${BASE_URL}/_search-address?${querystring.stringify({
      addressFilter,
    })}`
  );
}

function dateIntoISOString(date: Date | null | undefined): string | null {
  return date ? moment(date).toISOString() : null;
}

export async function getMifExport(
  filterParam: MifFilters,
  columns: Record<string, any>
): Promise<any> {
  return apiPost<unknown>(`${BASE_URL}/_start_export`, {
    ...columns,
    filters: {
      ...filterParam,
      vehicleRemovalStatus: Array.from(filterParam.vehicleRemovalStatus),
      saisineStatus: Array.from(filterParam.saisineStatus),
      photosStatus: Array.from(filterParam.photosStatus),
      antaiStatus: Array.from(filterParam.antaiStatus),
      zoneIds: Array.from(filterParam.zoneIds),
      infractionDateStart: dateIntoISOString(filterParam.infractionDate.from),
      infractionDateEnd: dateIntoISOString(filterParam.infractionDate.to),
      infractionTimeStart: filterParam.infractionTime.from,
      infractionTimeEnd: filterParam.infractionTime.to,
    },
  });
}

export async function searchMifForMap(
  filters: MapSearchRequest
): Promise<MifMapSearchResultDTO> {
  const filter = {
    ...filters,
    boundingBoxNorthEastLatitude: filters.boundingBoxNorthEast.latitude,
    boundingBoxNorthEastLongitude: filters.boundingBoxNorthEast.longitude,
    boundingSouthWestLatitude: filters.boundingSouthWest.latitude,
    boundingSouthWestLongitude: filters.boundingSouthWest.longitude,
    zoom: filters.zoom,
  };
  return apiPost<MifMapSearchResultDTO>(`${BASE_URL}/_map`, filter);
}

export async function patchComments(
  mifId: string,
  comments: string
): Promise<void> {
  const patchObject = {
    path: '/comments',
    op: 'add',
    value: comments,
  };
  return apiPatch<void>(
    `/api/proxy/pv/api/v1/customers/{cityId}/mifs/${mifId}`,
    [patchObject]
  );
}

export function getMifPdfUrl(mifId: string): string {
  return `${BASE_URL}/${mifId}/pdf`;
}
