import moment from 'moment';
import { Dispatch } from 'redux';

import { LOGOUT } from 'api/duck';
import { listCurrentDownloads } from 'api/backoffice';
import { DownloadDTO } from 'api/backoffice/types';

export const FETCH_DOWNLOADS = 'topbar/FETCH_DOWNLOADS';
export const HIGHLIGHT_DOWNLOADS_ICON = 'topbar/HIGHLIGHT_DOWNLOADS_ICON';

export type TopbarData = {
  downloads: Array<DownloadDTO>;
  highlightDownloadsIcon: number; // 0 = none, 1 = highlight icon, 2 = green for just finished download
};

type Action = {
  type: string;
  topbar: TopbarData;
};

export const hasPendingDownload = (downloads: Array<DownloadDTO>) => {
  const thresholdDate = moment().subtract(30, 'minutes');
  return downloads.some(
    d =>
      d.progress >= 0 &&
      d.progress < 100 &&
      thresholdDate.isBefore(moment(d.downloadStart))
  );
};

const countPendingDownload = (downloads: Array<DownloadDTO>) => {
  const thresholdDate = moment().subtract(30, 'minutes');
  return downloads.filter(
    d =>
      d.progress >= 0 &&
      d.progress < 100 &&
      thresholdDate.isBefore(moment(d.downloadStart))
  ).length;
};

const countSuccessfullyGeneratedDocuments = (downloads: Array<DownloadDTO>) =>
  downloads.filter(d => d.progress === 100).length;

const initialState = (): TopbarData => ({
  downloads: [],
  highlightDownloadsIcon: -1,
});

export default function downloadsReducer(
  state: TopbarData = initialState(),
  action: Action
): TopbarData {
  let { highlightDownloadsIcon } = state;
  // Si un téléchargement vient de se terminer, on fait clignoter l'icone de téléchargement quelques secondes en vert
  if (
    action.topbar &&
    action.topbar.downloads &&
    highlightDownloadsIcon !== -1 &&
    countPendingDownload(state.downloads) >=
      countPendingDownload(action.topbar.downloads) &&
    countSuccessfullyGeneratedDocuments(state.downloads) <
      countSuccessfullyGeneratedDocuments(action.topbar.downloads)
  ) {
    highlightDownloadsIcon = 2;
  }
  switch (action.type) {
    case FETCH_DOWNLOADS:
      return action.topbar.downloads
        ? { highlightDownloadsIcon, downloads: action.topbar.downloads }
        : {
            ...state,
            highlightDownloadsIcon,
          };
    case HIGHLIGHT_DOWNLOADS_ICON:
      return action.topbar.highlightDownloadsIcon != null
        ? {
            ...state,
            highlightDownloadsIcon: action.topbar.highlightDownloadsIcon,
          }
        : { ...state, highlightDownloadsIcon };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getTopbarState(state: any): TopbarData {
  return state.topbar;
}

export function updateDownloadsList(): any {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const unsortedDownloads = await listCurrentDownloads();
    const downloads = unsortedDownloads.sort((d, t) =>
      moment(t.downloadStart).diff(moment(d.downloadStart))
    );
    dispatch({
      type: FETCH_DOWNLOADS,
      topbar: { downloads },
    });
  };
}

export function highlightDownloads(highlightDownloadsIcon: number): any {
  return (dispatch: Dispatch<any>): void => {
    dispatch({
      type: HIGHLIGHT_DOWNLOADS_ICON,
      topbar: { highlightDownloadsIcon },
    });
  };
}
