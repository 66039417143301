import querystring from 'querystring';

import {
  AgentAccountFullDTO,
  AgentAccountLightDTO,
  AgentUpsertDTO,
  MatchingAgentSearchDTO,
  MeanOfAuthenticationTypeDTO,
  AgentRight,
} from '@cvfm-front/tefps-types';

import { ImportResultDTO } from '../commonTypes';
import {
  apiDelete,
  apiGet,
  apiGetRaw,
  apiPost,
  apiPostCSV,
  apiPostUnauthenticated,
  apiPut,
} from '../helpers';

type EnumTypeUniverse = 'fps' | 'pv' | 'tao' | 'all';

const BASE_URL = '/api/proxy/directory/api/cities/{cityId}/agents/v1';
const BASE_BO_URL = '/api/prv/v0/city/{cityId}/agents';

export async function fetchAgents(
  type: EnumTypeUniverse = 'all'
): Promise<Array<AgentAccountLightDTO>> {
  return apiGet<Array<AgentAccountLightDTO>>(`${BASE_BO_URL}/?type=${type}`);
}

export async function fetchAvailableAgentsByOperationalType(
  operationalType: string
): Promise<Array<AgentAccountLightDTO>> {
  return apiGet<Array<AgentAccountLightDTO>>(
    `${BASE_BO_URL}/available/${operationalType}`
  );
}

export async function fetchAgentsByRight(
  right: AgentRight,
  type: EnumTypeUniverse = 'fps'
): Promise<Array<AgentAccountLightDTO>> {
  return apiGet<Array<AgentAccountLightDTO>>(
    `${BASE_BO_URL}/right/${right}?type=${type}`
  );
}

export async function getAgentDetail(
  agentId: string
): Promise<AgentAccountFullDTO> {
  return apiGet<AgentAccountFullDTO>(`${BASE_BO_URL}/${agentId}`);
}

export async function upsertAgent(agent: AgentUpsertDTO): Promise<void> {
  return apiPut<void>(`${BASE_BO_URL}/${agent.agentId}`, agent);
}

export async function upsertAgentAuthenticationMeans(
  agentId: string,
  type: MeanOfAuthenticationTypeDTO,
  login: string,
  challenge: string,
  traced: boolean
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${agentId}/authentication-means/${login}`, {
    type,
    challenge,
    traced,
  });
}

export async function updatePassword(password: string): Promise<void> {
  const agentResetPassword = { password };
  return apiPost(
    `/api/prv/reset-agent-password/v1/cities/{cityId}/update`,
    agentResetPassword
  );
}

export async function requestPasswordReset(
  cityId: string,
  login: string,
  deviceId: string | null | undefined,
  deviceSecret: string | null | undefined
): Promise<void> {
  const requestPasswordReset = {
    login,
    deviceId,
    deviceSecret,
  };
  return apiPostUnauthenticated(
    `/api/prv/reset-agent-password/v1/cities/${cityId}/request-password-reset`,
    requestPasswordReset
  );
}

export async function createAgentWithAuthenticationMeans(
  agent: AgentUpsertDTO,
  type: MeanOfAuthenticationTypeDTO,
  login: string,
  challenge: string,
  traced: boolean
): Promise<void> {
  return apiPost<void>(`${BASE_URL}/${agent.agentId}`, {
    agent,
    login,
    meanOfAuthentication: {
      type,
      challenge,
      traced,
    },
  });
}

export async function deleteAgentAuthenticationMeans(
  agentId: string,
  login: string
): Promise<void> {
  return apiDelete<void>(
    `${BASE_URL}/${agentId}/authentication-means/${login}`
  );
}

export async function fetchLowestShortId(
  organizationId: string
): Promise<string> {
  const response = await apiGetRaw(
    `${BASE_URL}/_lowest-short-id?organizationId=${organizationId}`
  );
  return response.text();
}

export async function createAgentsFromCSV(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(
    '/api/prv/v0/city/{cityId}/accounts/import',
    file
  );
}

export async function fetchMatchingAgents(
  agentFilter: string | null | undefined,
  type?: string
): Promise<MatchingAgentSearchDTO> {
  return apiGet<MatchingAgentSearchDTO>(
    `${BASE_BO_URL}/match/filter?${querystring.stringify({
      agentFilter,
      type,
    })}`
  );
}

export function sortedAgentsKeysSubset(
  keys: string[],
  agents: { [key: string]: AgentAccountLightDTO }
): string[] {
  return keys.sort((key1: string, key2: string) => {
    // Protect against invalid keys
    if (!agents[key1] || !agents[key2]) {
      return key1.localeCompare(key2);
    }
    // Sort by lastname/firstname/id
    const s1 = `${agents[key1].lastName}${agents[key1].firstName}${key1}`;
    const s2 = `${agents[key2].lastName}${agents[key2].firstName}${key2}`;
    return s1.localeCompare(s2);
  });
}

export function sortedAgentsKeys(agents: {
  [key: string]: AgentAccountLightDTO;
}): string[] {
  return sortedAgentsKeysSubset(Object.keys(agents), agents);
}
