import { SentryConfigProperties } from '@cvfm-front/tefps-types';

import { apiGetUnauthenticated } from '../helpers';

import { LogoDTO } from './types';

export async function listLogos(): Promise<Array<LogoDTO>> {
  return apiGetUnauthenticated('/api/prv/v0/rootconfig/logos');
}

export async function getSentryDsn(): Promise<SentryConfigProperties> {
  return apiGetUnauthenticated('/api/prv/v0/rootconfig/sentry-dsn');
}
