import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ApiState, getApiState, logoutAction } from 'api/duck';
import { clearAuthLocalStore, getAuthLocalStore } from 'Login/auth-storage';
import { signOut } from 'api/auth';

import UserPicture from './UserPicture';

const { _tg } = window.loadTranslations(__filename);

const STYLE_LOGIN_ACTION: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  paddingRight: 30,
};

const STYLE_LOGOUT_ICON: CSSProperties = {
  cursor: 'pointer',
  marginLeft: 10,
};

type UserLogoutProps = {
  api: ApiState;
  dispatch: (arg0: any) => any;
} & RouteComponentProps;

class UserLogout extends React.Component<UserLogoutProps> {
  logOut = () => {
    const { dispatch, history } = this.props;
    // Do the following in postDisconnect().finally()
    signOut();
    clearAuthLocalStore();
    dispatch(logoutAction());
    history.push('/');
  };

  render() {
    const { api } = this.props;
    if (!api.userInfo) return null;

    return (
      <div style={STYLE_LOGIN_ACTION}>
        <span>{api.userInfo.name}</span>
        <UserPicture
          userInfo={api.userInfo}
          size={40}
          style={STYLE_LOGOUT_ICON}
          title={_tg('action.logout')}
          onClick={this.logOut}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    const api = getApiState(state);
    return {
      api,
    };
  })(UserLogout)
);
