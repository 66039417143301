import * as React from 'react';

import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';

import Section from './Section';

export type SectionListProps = {
  canWrite: boolean;
  sections: SectionDTO[];
  documents: SectionDocumentDTO[];
};

const SectionList = ({
  canWrite,
  sections,
  documents,
}: SectionListProps): JSX.Element => {
  return (
    <div>
      {sections.map(section => {
        return (
          <Section
            canWrite={canWrite}
            documents={documents.filter(e => e.sectionId === section.id)}
            key={section.id}
            section={section}
          />
        );
      })}
    </div>
  );
};

export default SectionList;
