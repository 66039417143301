import { PvMediaDTO, Coordinates, VehicleClass } from 'api/commonTypes';

export type GpvMapSearchRequest = {
  boundingBoxNorthEast: Coordinates;
  boundingSouthWest: Coordinates;
  zoom: number;
  agentId: string | null | undefined;
  periods:
    | Array<{
        type: string;
        startDatetime: string | undefined;
        endDatetime: string | undefined;
      }>
    | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  plate: string | null | undefined;
  signatureDaysOfWeek: Array<string> | null | undefined;
  motifsCategories: Array<string>;
  presetNotes: Array<string>;
  infractionLocations: Array<string>;
  organizationIds: Array<string>;
};

export type GpvOverviewDTO = EsSearchResult & {
  id: string;
  signatureDatetime: string;
  agentName: string;
  agentMatricule: string;
  streetFullName: string;
  postalCode: string;
  city: string;
  presetNote: string;
  infractionLocation: string;
  status: GpvStatus;
  natinfCode: string;
};

export type GpvStatus = 'PENDING' | 'TO_SEND' | 'DONE';
export type GpvSignature = 'AGENT' | 'OFFENDER';
export type GpvCivilityFilter = 'PERSON' | 'COMPANY';

export type GpvDetailAgent = {
  profileName: string;
  organizationName: string;
  matricule: string;
  name: string;
  serviceCode: string;
  signatureDate: number | null;
};

export type GpvSaisine = {
  comment: string;
  date: string;
  motif: string;
};
export type GpvOffenderEvidence = {
  nature: string;
  deliveryDate: number;
  deliveryPlace: string;
};

export type GpvOffenderDetail = {
  civility: string;
  lastName: string | null;
  firstName: string | null;
  parent1Name: string | null;
  parent2Name: string | null;
  birthAddress: string | null;
  birthDate: number | null;
  socialReason: string | null;
  siret: string | null;
  evidences: [GpvOffenderEvidence];
  offenderAddress: string;
  signatureDate?: string;
  offenderSignature: PvMediaDTO;
};

export type GpvVehicleDetail = {
  brand: string;
  model: string;
  vehicleClass: VehicleClass;
  plate: string | null | undefined;
  plateCountry: string | null | undefined;
  mandatoryLicenceExchange: boolean;
};

export interface GpvDetailMotif {
  id: string;
  qualification: string;
  category: string;
}

export interface GpvDetailDecree {
  reference: string;
  applicationDate: string;
  decreeText: string | null;
  comment: string;
}

export type GpvDetailMetadata = {
  agent: GpvDetailAgent;
  offender?: GpvOffenderDetail;
  responsibleParty?: GpvOffenderDetail;
  comments: string[];
  motif: GpvDetailMotif;
  decree: GpvDetailDecree;
  controlAddress: string;
  presetNote?: string;
  infractionCase: string;
  infractionLocation?: string;
  infractionDate: number;
  natinfCode: string;
  saisine?: GpvSaisine;
  vehicle?: GpvVehicleDetail;
  photos: [PvMediaDTO];
  vehicleRemoval: boolean;
  vehicleImmobilization: boolean;
};

export type GpvDetail = {
  gpvId: string;
  metadata: GpvDetailMetadata;
  status: GpvStatus;
  zoneId: string | null | undefined;
};

export type MatchingGpvSearch = {
  matchingGpvs: Array<string>;
};

export const INFRACTION_CASES_OPTIONS = [
  'A',
  '1',
  '2',
  '2B',
  '3',
  '3B',
  '4',
  '4B',
  '5',
  '6',
  '6B',
].map(cas => ({
  key: `${cas}`,
  label: `${cas}`,
}));
