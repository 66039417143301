import {
  FnmsParkingDetailRequestV1DTO,
  FnmsParkingDetailResultV1DTO,
  PricingDTO,
  SpecialDaysRange,
  VehicleCategory,
  ZoningDTO,
} from '@cvfm-front/tefps-types';

import { apiGet, apiPost, apiPut, apiDelete } from '../helpers';
import { ZoningFetchConfig } from '../../commons/ZoningComponents/types';
import { Plate, Vehicle, ItemIdName } from '../commonTypes';

import {
  CreationPricingResponseDTO,
  FnmsCityFpsPriceDTO,
  FnmsFpsPriceRequest,
  ParkingTicketPriceDTO,
  TrigramIdDTO,
  FnmsSignificantRightDTO,
} from './types';

const BASE_URL_BO_PRICING = '/api/prv/v0/city/{cityId}/pricing';
const BASE_URL_ZONING = '/api/proxy/pricing/api/v0/cities/{cityId}/zonings';
const BASE_URL_PRICING = '/api/proxy/pricing/api/v0/cities/{cityId}/pricings';
const BASE_URL_FPS_COMPUTING =
  '/api/proxy/pricing/api/fnms/cities/{cityId}/fine-values/v1/withPricingId';
const BASE_URL_FPS_COMPUTING_AUTO =
  'api/proxy/pricing/api/fnms/cities/{cityId}/fine-values/v1';

const BASE_URL_PARKING_DETAIL =
  'api/proxy/pricing/api/fnms/cities/{cityId}/parking-detail/v2';

const buildFnmsFpsRequest = (
  zoneId: string,
  profile: string,
  vehicleCategory: string | undefined,
  statementDatetime: string
): FnmsFpsPriceRequest => ({
  authId: 'bko',
  licensePlate: {
    plate: 'PI-314-NB',
    plateCountry: 'FR',
    pricingCategory: profile,
  },
  vehicle: {
    vehicleCategory,
    brand: undefined,
    model: undefined,
    pollutionType: undefined,
    surfaceArea: undefined,
  },
  zoneId,
  parkId: null,
  statementDatetime,
  tickets: [],
});

// Zoning

export async function fetchZoning(
  fetchConfig: ZoningFetchConfig
): Promise<ZoningDTO> {
  return apiGet<ZoningDTO>(
    `${BASE_URL_ZONING}/${
      fetchConfig.zoningType
    }?light=${fetchConfig.light.toString()}&withVirtual=${fetchConfig.withVirtual.toString()}`
  );
}

export async function generateTrigramId(
  zoneName: string
): Promise<TrigramIdDTO> {
  return apiGet<TrigramIdDTO>(
    `${BASE_URL_ZONING}/_generateTrigramId?zoneName=${zoneName}`
  );
}

export async function saveZoning(modifiedZoning: ZoningDTO): Promise<boolean> {
  return apiPost<boolean>(BASE_URL_ZONING, modifiedZoning);
}

// Pricing

export async function fetchPricing(pricingId: string): Promise<PricingDTO> {
  return apiGet<PricingDTO>(`${BASE_URL_PRICING}/${pricingId}`);
}
export async function fetchPricingByZoneId(
  zoneId: string
): Promise<Array<PricingDTO>> {
  return apiGet<Array<PricingDTO>>(`${BASE_URL_ZONING}/zone/${zoneId}`);
}

export async function fetchActivePricing(): Promise<PricingDTO> {
  return apiGet<PricingDTO>(`${BASE_URL_PRICING}/active`);
}

export async function fetchPricings(): Promise<{
  [key: string]: string;
}> {
  return apiGet<{ [key: string]: string }>(BASE_URL_PRICING);
}

export async function savePricing(
  modifiedPricing: PricingDTO | null | undefined
): Promise<void> {
  const id = modifiedPricing ? modifiedPricing.id : 'active';
  return apiPut<void>(`${BASE_URL_PRICING}/${id}`, modifiedPricing);
}

export function saveSpecialDays(
  pricingId: string,
  specialDays: SpecialDaysRange
): Promise<void> {
  return apiPut<void>(
    `${BASE_URL_PRICING}/${pricingId}/specialDays`,
    specialDays
  );
}

export async function createPricing(
  pricingName: string,
  copiedPricingId: string
): Promise<CreationPricingResponseDTO> {
  return apiPost<CreationPricingResponseDTO>(BASE_URL_PRICING, {
    pricingName,
    copiedPricingId,
  });
}

export async function deletePricing(pricingId: string) {
  return apiDelete<unknown>(`${BASE_URL_PRICING}/${pricingId}`);
}

export async function computeTicket(
  pricingId = 'active',
  zoneId: string,
  profile: string,
  vehicleCategory: string | null | undefined,
  duration: number,
  askedStart: string
): Promise<ParkingTicketPriceDTO> {
  const vehicleParam = vehicleCategory
    ? `&vehicleCategory=${vehicleCategory}`
    : '';
  return apiGet<ParkingTicketPriceDTO>(
    `${BASE_URL_PRICING}_compute?pricingId=${pricingId}&zoneId=${zoneId}&profile=${profile}&duration=${duration}&askedStart=${askedStart}${vehicleParam}`
  );
}

export async function computeFps(
  pricingId = 'active',
  zoneId: string,
  profile: string,
  vehicleCategory: string | null | undefined,
  statementDatetime: string
): Promise<FnmsCityFpsPriceDTO> {
  const request = buildFnmsFpsRequest(
    zoneId,
    profile,
    vehicleCategory || undefined,
    statementDatetime
  );
  return apiPost<FnmsCityFpsPriceDTO>(
    `${BASE_URL_FPS_COMPUTING}?pricingId=${pricingId}`,
    request
  );
}

// Change active pricing
export async function setPricingActive(id: string): Promise<void> {
  return apiPut<void>(`${BASE_URL_PRICING}/activePricingId/${id}`, null);
}

export async function getFpsPrice(
  zoneId: string,
  statementDatetime: string,
  plate: Plate,
  vehicle: Vehicle,
  tickets: Array<FnmsSignificantRightDTO>
): Promise<FnmsCityFpsPriceDTO> {
  const request: FnmsFpsPriceRequest = {
    authId: null,
    licensePlate: plate,
    vehicle,
    zoneId,
    parkId: null,
    statementDatetime,
    tickets,
  };
  return apiPost<FnmsCityFpsPriceDTO>(
    `${BASE_URL_FPS_COMPUTING_AUTO}`,
    request
  );
}

// Product
export async function fetchProducts(): Promise<Array<ItemIdName>> {
  return apiGet<Array<ItemIdName>>(`${BASE_URL_BO_PRICING}/product`);
}

export async function fetchParkingDetail(
  productId: string,
  plate: string,
  startDatetime: string,
  zoneId: string
): Promise<FnmsParkingDetailResultV1DTO> {
  const plateObject: Plate = {
    plate,
    plateCountry: 'FR',
    pricingCategory: productId,
  };
  return apiPost<FnmsParkingDetailResultV1DTO>(BASE_URL_PARKING_DETAIL, {
    licensePlate: plateObject,
    requestStartDatetime: startDatetime,
    zoneId,
    vehicleCategory: 'CAR' as VehicleCategory,
  } as FnmsParkingDetailRequestV1DTO);
}
