import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import {
  AvailableModuleConfiguration,
  getConfigState,
  ModulesConfiguration,
} from 'config/duck';
import { Universe } from 'UniverseHandler/duck';
import { getActiveUniverse } from 'UniverseHandler/helpers';
import { AgentRight } from '@cvfm-front/tefps-types';
import { shouldNotHappen } from 'helpers';

type Props = {
  userInfo: InternalAgent | null | undefined;
  modulesConfiguration: ModulesConfiguration;
  activeUniverse: Universe;
};

const ORDERED_PAGES_FPS: Array<{
  right: AgentRight;
  module?: AvailableModuleConfiguration;
  url: string;
}> = [
  { right: 'BACKOFFICE_FPS', module: 'fps', url: '/fps' },
  {
    right: 'BACKOFFICE_RAPO',
    module: 'rapo',
    url: '/recourses',
  },
  { right: 'BACKOFFICE_CCSP', module: 'ccsp', url: '/tsp' },
  {
    right: 'BACKOFFICE_SUBSCRIBERS',
    module: 'subscribers',
    url: '/subscription',
  },
  { right: 'BACKOFFICE_PRICING', url: '/pricing' },
  {
    right: 'BACKOFFICE_TV',
    module: 'tv',
    url: '/tickets',
  },
  { right: 'BACKOFFICE_DASHBOARD_REFUND', url: '/dashboard/refund' },
  {
    right: 'BACKOFFICE_ADMIN',
    url: '/administration',
  },
  { right: 'BACKOFFICE_CONTROL', url: '/controls' },
  { right: 'BACKOFFICE_PLANNER', url: '/planner' },
  { right: 'LAPI_REVIEW_QUALITY_CONTROL', url: '/qualityControl' },
  { right: 'LAPI_REVIEW_READ', url: '/review' },
];

const ORDERED_PAGES_PV: Array<{
  right: AgentRight;
  url: string;
}> = [
  {
    url: '/mif',
    right: 'BACKOFFICE_MIFS',
  },
  {
    url: '/dashboard',
    right: 'BACKOFFICE_PV_DASHBOARD',
  },
  {
    url: '/natinf',
    right: 'BACKOFFICE_NATINF',
  },
  {
    url: '/motif',
    right: 'BACKOFFICE_MOTIFS',
  },
  {
    url: '/decree',
    right: 'BACKOFFICE_DECREES',
  },
  {
    url: '/devices',
    right: 'BACKOFFICE_DEVICES',
  },
  {
    url: '/presets',
    right: 'BACKOFFICE_PRESET_NOTES',
  },
  {
    url: '/locations',
    right: 'BACKOFFICE_INFRACTION_LOCATIONS',
  },
];

const findDefaultPageFps = (
  rights: Array<AgentRight>,
  modulesConfiguration: ModulesConfiguration
): string => {
  const page = ORDERED_PAGES_FPS.find(
    p =>
      rights.includes(p.right) &&
      (!p.module || modulesConfiguration[p.module].enabled)
  );
  return page ? page.url : '/fps';
};

const findDefaultPagePv = (rights: Array<AgentRight>): string => {
  const page = ORDERED_PAGES_PV.find(p => rights.includes(p.right));
  return page ? page.url : '/dashboard';
};

const findDefaultPageTao = (): string => {
  return '/marches';
};

const findDefaultPage = (
  rights: Array<AgentRight>,
  modulesConfiguration: ModulesConfiguration,
  activeUniverse: Universe
): string => {
  switch (activeUniverse) {
    case 'fps':
      return findDefaultPageFps(rights, modulesConfiguration);
    case 'pv':
      return findDefaultPagePv(rights);
    case 'tao':
      return findDefaultPageTao();
    default:
      return shouldNotHappen(activeUniverse);
  }
};

const DefaultPage = ({
  userInfo,
  modulesConfiguration,
  activeUniverse,
}: Props): JSX.Element | null => {
  if (!userInfo) {
    return null;
  }

  return (
    <Redirect
      to={findDefaultPage(
        userInfo.rights,
        modulesConfiguration,
        activeUniverse
      )}
    />
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { modulesConfiguration } = getConfigState(state);
  const activeUniverse = getActiveUniverse(state);
  return {
    userInfo,
    modulesConfiguration,
    activeUniverse,
  };
})(DefaultPage);
