import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';

import {
  addDocument,
  addSection,
  initState,
  removeDocument,
  removeSection,
} from './actions';
import { Actions, State } from './types';

export type DocumentLibraryReduxDispatchProps = {
  initState: (sections: SectionDTO[], documents: SectionDocumentDTO[]) => void;
  addDocument: (doc: SectionDocumentDTO) => void;
  removeDocument: (documentId: string) => void;
  addSection: (section: SectionDTO) => void;
  removeSection: (sectionId: string) => void;
};

export const mapDocumentLibraryActionToProps = (
  dispatch: (action: Actions) => void
): DocumentLibraryReduxDispatchProps => {
  return {
    initState: (
      sections: SectionDTO[],
      documents: SectionDocumentDTO[]
    ): void => dispatch(initState(sections, documents)),
    addDocument: (doc: SectionDocumentDTO): void => dispatch(addDocument(doc)),
    removeDocument: (documentId: string) =>
      dispatch(removeDocument(documentId)),
    addSection: (section: SectionDTO): void => dispatch(addSection(section)),
    removeSection: (sectionId: string): void =>
      dispatch(removeSection(sectionId)),
  };
};

export type DocumentLibraryReduxStateProps = {
  sections: Array<SectionDTO>;
  documents: Array<SectionDocumentDTO>;
};

export const mapDocumentLibraryStateToProps = (state: {
  documentLibrary: State;
}): DocumentLibraryReduxStateProps => {
  return {
    sections: state.documentLibrary.sections || [],
    documents: state.documentLibrary.documents || [],
  };
};
