import { ImportResultDTO, PatchObject } from '../commonTypes';
import {
  apiDelete,
  apiGet,
  apiGetRaw,
  apiPatch,
  apiPostCSV,
  apiPut,
} from '../helpers';

import { CityOrganizationDTO, CityOrganizationUpsertDTO } from './types';

const BASE_PROXY_URL =
  '/api/proxy/directory/api/cities/{cityId}/organizations/v1';
const BASE_BO_URL = '/api/prv/v0/city/{cityId}/organizations';

export async function fetchOrganizations(
  details = false,
  type: EnumTypeUniverse = 'all'
): Promise<Array<CityOrganizationDTO>> {
  return apiGet<Array<CityOrganizationDTO>>(
    `${BASE_BO_URL}?details=${details.toString()}?type=${type}`
  );
}

export async function fetchOrganizationsFromTeams(): Promise<
  Array<CityOrganizationDTO>
> {
  return apiGet<Array<CityOrganizationDTO>>(`${BASE_BO_URL}/from-teams`);
}

export async function createOrganization(
  id: string,
  org: CityOrganizationUpsertDTO
): Promise<void> {
  return apiPut<void>(`${BASE_BO_URL}/${id}`, org);
}

export async function fetchLowestShortIdAvailable(): Promise<string> {
  return (await apiGetRaw(`${BASE_PROXY_URL}/_lowest-short-id`)).text();
}

export async function updateOrganization(
  organizationId: string,
  body: Array<PatchObject<unknown>>
): Promise<void> {
  return apiPatch<void>(`${BASE_PROXY_URL}/${organizationId}`, body);
}

export async function deleteOrganization(
  organizationId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_PROXY_URL}/${organizationId}`);
}

export async function importOrganizationsFromCSV(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_BO_URL}/import`, file);
}
export async function getOrganization(
  organizationId: string
): Promise<CityOrganizationDTO> {
  return apiGet<CityOrganizationDTO>(`${BASE_PROXY_URL}/${organizationId}`);
}
