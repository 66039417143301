import * as React from 'react';

import { formConnector } from './createStore';
import { attachFieldAction, detachFieldAction } from './duck';
import {
  FieldsMap,
  AttachedField,
  DispatchToProps,
  FormField,
  Props,
  StateToProps,
} from './types';

const { _tg } = window.loadTranslations(__filename);

interface State<T> {
  error: string | null | undefined;
  value: T | null | undefined;
}

function mapStateToProps(state: FieldsMap) {
  return {
    fields: state,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    attachField: (field: AttachedField) => dispatch(attachFieldAction(field)),
    detachField: (field: AttachedField) => dispatch(detachFieldAction(field)),
  };
}

/**
 * Wrap an input field to expose an interface which allows the FormComponent to retrieve the value, name and other info.
 */
function attachToForm<T, ComponentProps extends FormField<T>>(
  FieldComponent: React.ComponentType<ComponentProps>
): React.ComponentClass<Props<T> & Omit<ComponentProps, keyof FormField<T>>> {
  class ConnectedField extends React.Component<
    StateToProps & DispatchToProps & Props<T> & FormField<T>,
    State<T>
  > {
    public state: State<T> = {
      error: null,
      value:
        this.props.defaultValue !== undefined
          ? this.props.defaultValue
          : this.props.value,
    };

    public componentDidMount() {
      // eslint-disable-next-line
      // @ts-ignore
      this.props.attachField(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(
      nextProps: StateToProps & DispatchToProps & Props<T> & FormField<T>
    ) {
      if (nextProps.value !== this.props.value) {
        this.setState({ value: nextProps.value });
      }
      if (nextProps.error !== this.props.error) {
        this.setState({ error: nextProps.error });
      }
    }

    public componentWillUnmount() {
      // eslint-disable-next-line
      // @ts-ignore
      this.props.detachField(this);
    }

    public onValueChange = (value: T) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(value);
      }
      this.setState({ value });
    };

    public getName = () => {
      return this.props.name;
    };

    public getValue = () => {
      return this.state.value;
    };

    public getValueOnSubmit = () => {
      const { onBeforeSubmit } = this.props;
      const value = this.getValue();
      return onBeforeSubmit ? onBeforeSubmit(value) : value;
    };

    public getError = () => {
      return this.state.error;
    };

    public isBlank = (value: T | null | undefined = this.getValue()) => {
      // eslint-disable-next-line
      // @ts-ignore TS doesn't understand that value can be a string
      return value === null || value === undefined || value === ''; // because Number(0) is valid
    };

    public validate = (value: T | null | undefined = this.getValue()) => {
      const { mandatory, fields, validators } = this.props;
      let error;
      if (this.isBlank(value)) {
        if (mandatory) {
          error = _tg('feedback.error.mandatoryField');
        }
      } else if (validators) {
        validators.some(validator => {
          error = validator(value, fields);
          return error; // break if there is a message
        });
      }
      this.setState({ error: error || null });
      return error;
    };

    public render() {
      const { error, value } = this.state;
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        defaultValue,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        attachField,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        detachField,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fields,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        validators,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onBeforeSubmit,
        ...otherProps
      } = this.props;
      return (
        // eslint-disable-next-line
        // @ts-ignore
        <FieldComponent
          {...otherProps}
          error={error}
          value={value}
          validateField={this.validate}
          onValueChange={this.onValueChange}
        />
      );
    }
  }

  // eslint-disable-next-line
  // @ts-ignore
  return formConnector(mapStateToProps, mapDispatchToProps)(ConnectedField);
}

export default attachToForm;
