import querystring from 'querystring';

import { Filters } from 'commons/types/filterbar';
import { TaskStats } from 'tefps/Tasks/types';
import { DirectUploadDTO } from 'api/mediaupload/types';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';
import { MailDTO } from '../mail/type';
import { ColumnConfig } from '../commonTypes';

import {
  FpsContactDTO,
  FpsContactType,
  RefundComment,
  RefundDetailDTO,
  RefundDocuments,
  RefundOverviewDTO,
} from './types';

const BASE_PROXY_FPS_URL = 'api/proxy/fps/api/v0/cities/{cityId}';
const BASE_PROXY_FPS_REFUND_URL = `${BASE_PROXY_FPS_URL}/prv/fps-refund`;
const BASE_BO_REFUND_URL = '/api/prv/v0/city/{cityId}/fps/refunds';

const BASE_BO_URL = '/api/prv/v0/cities/{cityId}';

export async function getRefund(refundId: string): Promise<RefundDetailDTO> {
  return apiGet<RefundDetailDTO>(`${BASE_PROXY_FPS_REFUND_URL}/${refundId}`);
}

export async function fetchRefundFilteredList(
  filterParam: PagedQuery<Filters>
): Promise<EsSearchResponse<Array<RefundOverviewDTO>>> {
  return apiPost<EsSearchResponse<Array<RefundOverviewDTO>>>(
    `${BASE_PROXY_FPS_REFUND_URL}/_search`,
    filterParam
  );
}

export async function updateDocuments(
  fpsId: string,
  documents: RefundDocuments
): Promise<void> {
  return apiPut<void>(
    `${BASE_PROXY_FPS_REFUND_URL}/upload/${fpsId}`,
    documents
  );
}

export async function addComment(
  comments: RefundComment,
  refundId: string
): Promise<void> {
  return apiPost<void>(
    `${BASE_PROXY_FPS_REFUND_URL}/${refundId}/comments`,
    comments
  );
}

export async function sendContact(
  fpsContact: FpsContactDTO | null | undefined,
  refundId: string
): Promise<FpsContactType> {
  return apiPut<FpsContactType>(
    `${BASE_PROXY_FPS_REFUND_URL}/_contact/${refundId}`,
    fpsContact
  );
}

export async function getRefundExport(
  filterParam: Filters,
  exportParam: ColumnConfig
): Promise<void> {
  return apiPost<void>(`${BASE_BO_REFUND_URL}/_export`, {
    ...filterParam,
    ...exportParam,
  });
}

export async function putBankDetailRefund(
  refundId: string,
  bankDetails: {
    iban: string;
    bic: string;
  }
): Promise<void> {
  return apiPut<void>(
    `${BASE_PROXY_FPS_REFUND_URL}/${refundId}/bank-detail`,
    bankDetails
  );
}

export async function fetchRefundTask(): Promise<TaskStats> {
  return apiGet<TaskStats>(`${BASE_BO_URL}/refund/task`);
}

export async function fetchMailMessage(refundId: string): Promise<MailDTO> {
  return apiGet<MailDTO>(`${BASE_PROXY_FPS_REFUND_URL}/${refundId}/mail`);
}

export async function updateRenderedMail(
  refundId: string,
  renderedMail: MailDTO
): Promise<void> {
  return apiPut<void>(`${BASE_PROXY_FPS_REFUND_URL}/${refundId}/mail`, {
    ...renderedMail,
  });
}

export async function updateMainContact(
  refundId: string,
  fpsContact: FpsContactDTO
): Promise<void> {
  return apiPut<void>(`${BASE_PROXY_FPS_REFUND_URL}/${refundId}/mainContact`, {
    ...fpsContact,
  });
}

export function getRefundPdfUrl(refundId: string): string {
  return `${BASE_PROXY_FPS_REFUND_URL}/${refundId}/pdf`;
}

export async function getDirectUploadLinkRefund(
  refundId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_PROXY_FPS_URL}/refund/${refundId}/upload-url?${querystring.stringify(
      {
        mediaType,
      }
    )}`
  );
}
