const { _t } = window.loadTranslations(__filename);

export type DAYS_TYPE =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

const MONDAY: DAYS_TYPE = 'MONDAY';
const TUESDAY: DAYS_TYPE = 'TUESDAY';
const WEDNESDAY: DAYS_TYPE = 'WEDNESDAY';
const THURSDAY: DAYS_TYPE = 'THURSDAY';
const FRIDAY: DAYS_TYPE = 'FRIDAY';
const SATURDAY: DAYS_TYPE = 'SATURDAY';
const SUNDAY: DAYS_TYPE = 'SUNDAY';

export const DAYS: Array<DAYS_TYPE> = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
];

export const translateDayOptions = () => [
  { value: MONDAY, label: _t('days.MONDAY') },
  { value: TUESDAY, label: _t('days.TUESDAY') },
  {
    value: WEDNESDAY,
    label: _t('days.WEDNESDAY'),
  },
  { value: THURSDAY, label: _t('days.THURSDAY') },
  { value: FRIDAY, label: _t('days.FRIDAY') },
  {
    value: SATURDAY,
    label: _t('days.SATURDAY'),
  },
  { value: SUNDAY, label: _t('days.SUNDAY') },
];

export const translateDayMapping = (): { [k in DAYS_TYPE]: string } => {
  return {
    MONDAY: _t('days.MONDAY'),
    TUESDAY: _t('days.TUESDAY'),
    WEDNESDAY: _t('days.WEDNESDAY'),
    THURSDAY: _t('days.THURSDAY'),
    FRIDAY: _t('days.FRIDAY'),
    SATURDAY: _t('days.SATURDAY'),
    SUNDAY: _t('days.SUNDAY'),
  };
};

export const translateDaysOfWeek = () => [
  { id: MONDAY, name: _t('days.MONDAY') },
  { id: TUESDAY, name: _t('days.TUESDAY') },
  {
    id: WEDNESDAY,
    name: _t('days.WEDNESDAY'),
  },
  { id: THURSDAY, name: _t('days.THURSDAY') },
  { id: FRIDAY, name: _t('days.FRIDAY') },
  { id: SATURDAY, name: _t('days.SATURDAY') },
  {
    id: SUNDAY,
    name: _t('days.SUNDAY'),
  },
];
