import { Filters, FilterType } from 'commons/types/filterbar';

/**
 * Take a Filters object and convert it into a Request.
 * Since the type cannot be constructed dynamicly,
 * the filter's id and filter's type provided MUST match the type shape of Request
 */
const convertFiltersToRequest = <Request>(filters: Filters): Request => {
  const filterArray: Array<FilterType> = (Object.values(
    filters
  ) as any) as Array<FilterType>;
  return (filterArray.reduce((acc, filter: any) => {
    switch (filter.type) {
      case 'INPUT':
        return {
          ...acc,
          [filter.id]: filter.inputValue,
        };
      case 'MULTISEARCH':
        return {
          ...acc,
          [filter.id]: Array.from(filter.multiSearchValue || new Set()),
        };
      case 'SELECT':
        return {
          ...acc,
          [filter.id]: filter.selectValue,
        };
      case 'MULTISELECT':
        return {
          ...acc,
          [filter.id]: filter.selectValues,
        };
      case 'CHECKBOX':
        return {
          ...acc,
          [filter.id]: Array.from(filter.checkboxValues || new Set()),
        };
      case 'PRICE_RANGE':
        if (filter.priceRangeValue) {
          return {
            ...acc,
            [filter.id]: {
              from: filter.priceRangeValue.from
                ? filter.priceRangeValue.from * 100
                : null,
              to: filter.priceRangeValue.to
                ? filter.priceRangeValue.to * 100
                : null,
            },
          };
        }

        return { ...acc };
      case 'DATETIME':
        return {
          ...acc,
          [filter.id]: filter.datetimeValue,
        };
      case 'CHECKBOX_TO_RANGE': {
        const checked = filter.checkboxValues && filter.checkboxValues.size > 0;
        const from = 0;
        const to = checked ? 0 : null;
        return {
          ...acc,
          [filter.id]: { from, to },
        };
      }
      case 'CHECKBOX_TO_BOOLEAN': {
        return {
          ...acc,
          [filter.id]: filter.checkboxValue,
        };
      }
      case 'AUTOCOMPLETE': {
        return {
          ...acc,
          [filter.id]: filter.search,
        };
      }
      default:
        return {
          ...acc,
          [filter.id]: filter.value || null,
        };
    }
  }, {}) as any) as Request;
};

export default convertFiltersToRequest;
