import { LogoDTO, LogoTarget } from 'api/root-config/types';

import { apiGet } from '../helpers';

export const fetchLogo = (
  cityId: string,
  target: LogoTarget = 'LOGO'
): Promise<LogoDTO[]> => {
  return apiGet<LogoDTO[]>(`/api/prv/v0/logos/${cityId}?target=${target}`);
};
