import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import BookIcon from 'material-ui/svg-icons/action/book';
import FlatButton from 'material-ui/FlatButton';

import {
  AvailableModuleConfiguration,
  Config,
  getConfigState,
} from 'config/duck';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { InternalAgent } from 'api/auth/types';
import { getApiState, InternalApiState } from 'api/duck';
import {
  getUniverseState,
  initUniverseState,
  switchUniverse,
  Universe,
  UniverseState,
} from 'UniverseHandler/duck';
import { getActiveUniverse, getInitConfig } from 'UniverseHandler/helpers';
import { LicenseTokenAlertDTO } from 'api/devices/types';
import { fetchTokenAlert, getTokenAlertState } from 'tokenAlert/duck';
import {
  BKG_BLUE,
  BKG_BLUE_SELECTED,
  BKG_CYAN_SELECTED,
  LEFTBAR_WIDTH,
  STYLE_LOGIN_BAR,
  STYLE_MENU_BAR,
} from 'theme';
import WarningSymbol from 'commons/WarningSymbol';
import { RIGHT_GROUPS } from 'Administration/Profiles/ProfileModal/helpers';
import { AgentRight } from '@cvfm-front/tefps-types';
import { Flex, StagingHeader } from '@cvfm-front/commons-ui';

import Download from './Download';
import UserLogout from './UserLogout';
import Logo from './Logo';
import PdfDownload from './PdfDownload';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_MENU: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: 0,
  flexGrow: 1,
};

const STYLE_LINK: CSSProperties = {
  color: 'white',
  fontSize: 14,
  fontWeight: 'bold',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  flexGrow: 1,
  padding: '0 10px',
};

const STYLE_ADMIN_LINK: CSSProperties = {
  color: 'white',
  fontSize: 14,
  fontWeight: 'bold',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  flexGrow: 1,
  margin: '0 30px',
};

const STYLE_HELP_LINK: CSSProperties = {
  flex: 1,
};

const STYLE_LINK_ACTIVE: CSSProperties = {
  ...STYLE_LINK,
  backgroundColor: BKG_CYAN_SELECTED,
};

const STYLE_ADMIN_LINK_ACTIVE: CSSProperties = {
  ...STYLE_ADMIN_LINK,
  backgroundColor: BKG_BLUE_SELECTED,
};

const STYLE_UNIVERSES_CONTAINER: CSSProperties = {
  flex: 1,
  cursor: 'pointer',
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'row',
  margin: '0 5px',
};

type MenuItem = {
  label: string;
  to: string;
  requiredRight?: AgentRight;
  oneRequiredRight?: Array<AgentRight>;
  restrictedModule?: AvailableModuleConfiguration;
  isDisplayed?: (config: Config, userInfo: InternalAgent) => boolean;
};

// TODO : Appliquer ce fonctionnement à l'ensemble
const isPlannerOrReviewDisplayed = (
  config: Config,
  userInfo: InternalAgent
) => {
  return (
    (userInfo.rights.includes('BACKOFFICE_PLANNER') &&
      isDisplayed(config.modulesConfiguration.planner)) ||
    (userInfo.rights.includes('LAPI_REVIEW_READ') &&
      isDisplayed(config.modulesConfiguration.lapiReview))
  );
};

const isConfigDisplayed = (config: Config, userInfo: InternalAgent) => {
  return (
    (userInfo.rights.includes('TEAM_READ') &&
      isDisplayed(config.modulesConfiguration.planner)) ||
    (userInfo.rights.includes('RAPO_ANSWER_ADMIN') &&
      isDisplayed(config.modulesConfiguration.rapo)) ||
    (userInfo.rights.includes('BACKOFFICE_SUBSCRIBERS') &&
      isDisplayed(config.modulesConfiguration.subscribers)) ||
    (config.whiteListEnabled &&
      userInfo.rights.includes('FPS_ADMIN_WHITELIST') &&
      isDisplayed(config.modulesConfiguration.fps)) ||
    (userInfo.rights.includes('PARKING_CONFIGURATION_WRITE') &&
      isDisplayed(config.modulesConfiguration.parking)) ||
    (userInfo.rights.includes('KPI_WRITE') &&
      config.observatoryConfigurationDTO.observatoryEnabled)
  );
};

const isPvConfigDisplayed = (config: Config, userInfo: InternalAgent) => {
  return (
    userInfo.rights.includes('ZONING_READ') ||
    userInfo.rights.includes('BACKOFFICE_PRESET_NOTES') ||
    userInfo.rights.includes('BACKOFFICE_INFRACTION_LOCATIONS')
  );
};

const isGpvDisplayed = (config: Config) => {
  return isDisplayed(config.modulesConfiguration.gpv);
};

const isDashBoardDisplayed = (config: Config, userInfo: InternalAgent) => {
  return !!RIGHT_GROUPS(true, false)
    .find(group => group.value === 'BACKOFFICE_DASHBOARD')
    ?.rights?.some(right => userInfo.rights.includes(right));
};

const isSubscriptionV2Displayed = (config: Config, userInfo: InternalAgent) => {
  return (
    userInfo.rights.includes('BACKOFFICE_SUBSCRIBERS') &&
    isDisplayed(config.modulesConfiguration.subscribers)
  );
};

const isObservatoryDisplayed = (config: Config, userInfo: InternalAgent) => {
  return (
    userInfo.rights.includes('BACKOFFICE_OBSERVATORY') &&
    config.observatoryConfigurationDTO.observatoryEnabled
  );
};

const translatePvItems = (): Array<MenuItem> => [
  {
    label: _t('element.navItems.pv.dashboard'),
    to: '/dashboard',
    requiredRight: 'BACKOFFICE_PV_DASHBOARD',
  },
  {
    label: _tg('tepv.mif'),
    to: '/mif',
    requiredRight: 'BACKOFFICE_MIFS',
  },
  {
    label: _t('element.navItems.pv.gpv'),
    to: '/gpv',
    requiredRight: 'BACKOFFICE_GPV',
    isDisplayed: isGpvDisplayed,
  },
  {
    label: _t('element.navItems.pv.natinf'),
    to: '/natinf',
    requiredRight: 'BACKOFFICE_NATINF',
  },
  {
    label: _t('element.navItems.pv.motif'),
    to: '/motif',
    requiredRight: 'BACKOFFICE_MOTIFS',
    isDisplayed: isGpvDisplayed,
  },
  {
    label: _t('element.navItems.pv.decree'),
    to: '/decree',
    requiredRight: 'BACKOFFICE_DECREES',
  },
  {
    label: _t('element.navItems.pv.devices'),
    to: '/devices',
    requiredRight: 'BACKOFFICE_DEVICES',
  },
  {
    label: _t('element.navItems.pv.configuration'),
    to: '/configuration',
    requiredRight: 'BACKOFFICE_ADMIN',
    isDisplayed: isPvConfigDisplayed,
  },
];

const translateFpsItems = (): Array<MenuItem> => [
  {
    label: _t('element.navItems.fps.tasks'),
    to: '/tasks',
    requiredRight: 'BACKOFFICE_TASK',
    restrictedModule: 'tasks',
  },
  {
    label: _t('element.navItems.fps.observatory'),
    to: '/observatory',
    requiredRight: 'BACKOFFICE_OBSERVATORY',
    isDisplayed: isObservatoryDisplayed,
  },
  {
    label: _t('element.navItems.fps.dashboard'),
    to: '/dashboard',
    oneRequiredRight: RIGHT_GROUPS(true, false).find(
      group => group.value === 'BACKOFFICE_DASHBOARD'
    )?.rights,
    isDisplayed: isDashBoardDisplayed,
  },
  {
    label: _t('element.navItems.fps.pricing'),
    to: '/pricing',
    requiredRight: 'BACKOFFICE_PRICING',
  },
  {
    label: _t('element.navItems.fps.beneficiaries'),
    to: '/subscription',
    requiredRight: 'BACKOFFICE_SUBSCRIBERS',
    restrictedModule: 'subscribers',
    isDisplayed: isSubscriptionV2Displayed,
  },
  {
    label: _t('element.navItems.fps.tickets'),
    to: '/tickets',
    requiredRight: 'BACKOFFICE_TV',
    restrictedModule: 'tv',
  },
  {
    label: _t('element.navItems.fps.eligibilities'),
    to: '/eligibilities',
    requiredRight: 'BACKOFFICE_ELIGIBILITY',
    restrictedModule: 'tv',
  },
  {
    label: _t('element.navItems.fps.controls'),
    to: '/controls',
    requiredRight: 'BACKOFFICE_CONTROL',
    restrictedModule: 'control',
  },
  {
    label: _t('element.navItems.fps.fps'),
    to: '/fps',
    requiredRight: 'BACKOFFICE_FPS',
    restrictedModule: 'fps',
  },
  {
    label: _t('element.navItems.fps.bill'),
    to: '/bill',
    requiredRight: 'BACKOFFICE_BILL',
    restrictedModule: 'parking',
  },
  {
    label: _t('element.navItems.fps.recourses'),
    to: '/recourses',
    requiredRight: 'BACKOFFICE_RAPO',
    restrictedModule: 'rapo',
  },
  {
    label: _t('element.navItems.fps.ccsp'),
    to: '/tsp',
    requiredRight: 'BACKOFFICE_CCSP',
    restrictedModule: 'ccsp',
  },
  {
    label: _t('element.navItems.fps.planner'),
    to: '/planner',
    requiredRight: 'BACKOFFICE_PLANNER',
    restrictedModule: 'planner',
    isDisplayed: isPlannerOrReviewDisplayed,
  },
  {
    label: _t('element.navItems.fps.configuration'),
    to: '/configuration',
    requiredRight: 'BACKOFFICE_ADMIN',
    isDisplayed: isConfigDisplayed,
  },
];

const translateTaoItems = (): Array<MenuItem> => [
  {
    label: _t('element.navItems.tao.marches'),
    to: '/marches',
  },
  {
    label: _t('element.navItems.tao.subscribers'),
    to: '/subscribers',
  },
];

type TopbarReduxProps = {
  loadTokenAlertDispatch: (arg0: Array<AgentRight>) => void;
  loadUniversesDispatch: (universeState: UniverseState) => void;
  switchUniverseDispatch: (universe: Universe) => void;
};

type TopbarProps = TopbarReduxProps &
  RouteComponentProps & {
    config: Config;
    userInfo: InternalAgent;
    cityId: string;
    tokenAlert: LicenseTokenAlertDTO | null | undefined;
    activeUniverse: Universe;
    availableUniverses: Array<Universe>;
  };

const shouldLinkBeDisplayed = (
  config: Config,
  userInfo: InternalAgent,
  item: MenuItem
): boolean => {
  if (item.isDisplayed) {
    return item.isDisplayed(config, userInfo);
  }
  // If the item has module restricted access and module is not activated in city config
  if (item.restrictedModule) {
    const moduleConfiguration =
      config.modulesConfiguration[item.restrictedModule];
    if (!moduleConfiguration.enabled && !moduleConfiguration.displayed) {
      return false;
    }
  }
  if (
    item.oneRequiredRight &&
    item.oneRequiredRight.every(right => !userInfo.rights.includes(right))
  ) {
    return false;
  }
  return !item.requiredRight || userInfo.rights.includes(item.requiredRight);
};

const registrerPrevious = () => {
  sessionStorage.setItem('previousLocation', window.location.hash);
};

class Topbar extends React.Component<TopbarProps> {
  constructor(props: TopbarProps) {
    super(props);

    const {
      config: { modulesConfiguration },
      loadUniversesDispatch,
      cityId,
      userInfo,
    } = this.props;
    const initialUniverseConfig = getInitConfig(
      modulesConfiguration,
      cityId,
      userInfo
    );

    loadUniversesDispatch(initialUniverseConfig);
  }

  componentDidMount() {
    this.loadTokenAlert();
  }

  switchUniverse = ({ currentTarget }: React.MouseEvent<any>) => {
    // eslint-disable-next-line
    const { universe } = currentTarget.dataset;
    const { history, switchUniverseDispatch } = this.props;
    history.push('/');
    switchUniverseDispatch(universe as Universe);
  };

  loadTokenAlert() {
    const { tokenAlert, loadTokenAlertDispatch, userInfo } = this.props;
    if (!tokenAlert) {
      loadTokenAlertDispatch(userInfo.rights);
    }
  }

  render() {
    const {
      cityId,
      config,
      userInfo,
      availableUniverses,
      activeUniverse,
      tokenAlert,
    } = this.props;

    const universeItems = {
      pv: translatePvItems,
      tao: translateTaoItems,
      fps: translateFpsItems,
    };

    const items = (universeItems[activeUniverse] || translatePvItems)();

    return (
      <div>
        <StagingHeader />
        <div style={STYLE_LOGIN_BAR}>
          <div style={{ width: LEFTBAR_WIDTH + 20 }} />
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'white',
            }}
          >
            <div style={{ flex: 5 }}>
              {`${_t(`element.universe.${activeUniverse ?? 'pv'}`)} - ${
                config.name
              }`}
            </div>
            <div style={{ flex: 1 }}>
              <Download />
            </div>
            <NavLink
              to="/documents"
              style={STYLE_HELP_LINK}
              title={_t('element.navLinkDocuments.title')}
            >
              <BookIcon style={{ width: 25, height: 30 }} color="white" />
            </NavLink>
            <PdfDownload />
            {availableUniverses.length > 1 &&
              userInfo.hasFpsAccess &&
              userInfo.hasPvAccess && (
                <div style={STYLE_UNIVERSES_CONTAINER}>
                  {availableUniverses.map(
                    (universe: Universe, index: number) => (
                      <FlatButton
                        key={`topbar-btn-${index}`}
                        label={
                          universe === 'tao'
                            ? universe.toUpperCase()
                            : `Te${universe.toUpperCase()}`
                        }
                        onClick={this.switchUniverse}
                        data-universe={universe}
                        backgroundColor={
                          universe === activeUniverse
                            ? BKG_CYAN_SELECTED
                            : BKG_BLUE
                        }
                        labelStyle={{ color: 'white', textTransform: 'none' }}
                        hoverColor={
                          universe === activeUniverse
                            ? BKG_CYAN_SELECTED
                            : BKG_BLUE_SELECTED
                        }
                        style={{
                          width: '75px',
                          borderRadius:
                            index === 0 ? '5px 0 0 5px' : '0 5px 5px 0',
                          border: '1px solid',
                          borderColor: BKG_BLUE_SELECTED,
                        }}
                      />
                    )
                  )}
                </div>
              )}
            {userInfo.rights.includes('BACKOFFICE_ADMIN') && (
              <NavLink
                to="/administration"
                key="/administration"
                style={STYLE_ADMIN_LINK}
                activeStyle={STYLE_ADMIN_LINK_ACTIVE}
                onClick={registrerPrevious}
              >
                <span style={{ margin: '0 auto', textAlign: 'center' }}>
                  {_t('element.navAdmin.title')}
                </span>
                {tokenAlert && tokenAlert.alert && (
                  <WarningSymbol style={{ marginRight: 'auto' }} />
                )}
              </NavLink>
            )}
            <UserLogout />
          </div>
        </div>
        <div style={STYLE_MENU_BAR}>
          <div
            style={{
              maxWidth: 200,
              /* img size + border * 2 */
              flexGrow: 1,
              display: 'flex',
              marginLeft: '10px',
              justifyContent: 'center',
            }}
          >
            <Logo cityId={cityId} universe={activeUniverse} />
          </div>
          <div style={STYLE_MENU}>
            {items
              .filter(item => shouldLinkBeDisplayed(config, userInfo, item))
              .map(item => {
                return (
                  <NavLink
                    to={item.to}
                    key={item.to}
                    style={STYLE_LINK}
                    activeStyle={STYLE_LINK_ACTIVE}
                    onClick={registrerPrevious}
                  >
                    <span style={{ margin: '0 auto', textAlign: 'center' }}>
                      {item.label}
                    </span>
                  </NavLink>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const config = getConfigState(state);
  const { userInfo, cityId } = getApiState(state);
  const { availableUniverses } = getUniverseState(state);
  const activeUniverse = getActiveUniverse(state);
  const tokenAlert = getTokenAlertState(state);

  return {
    config,
    userInfo,
    cityId,
    availableUniverses,
    activeUniverse,
    tokenAlert,
  };
};

const mapDispatchToProps = (dispatch: (arg0: any) => void) => {
  return {
    loadUniversesDispatch: (universeState: UniverseState) => {
      dispatch(initUniverseState(universeState));
    },
    switchUniverseDispatch: (universe: Universe) => {
      dispatch(switchUniverse(universe));
    },
    loadTokenAlertDispatch: (rights: any) => {
      dispatch(fetchTokenAlert(rights));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
