import { apiGet } from '../helpers';

import { FpsSimulationResultDTO } from './types';

const BASE_URL = 'api/proxy/fps/api/v0/cities/{cityId}/admin/stats/simulation';

export async function getFpsSimulationResult(
  price: number,
  reducedPrice: number
): Promise<FpsSimulationResultDTO> {
  return apiGet<FpsSimulationResultDTO>(
    `${BASE_URL}?price=${price}&reducedPrice=${reducedPrice}`
  );
}
