import querystring from 'querystring';

import { openUrlInNewTab } from 'commons/openUrlInNewTab';
import { PatchObject } from 'api/commonTypes';
import { NotificationService } from '@cvfm-front/commons-services';

import {
  apiDelete,
  apiDeleteRaw,
  apiGet,
  apiGetRaw,
  apiPatch,
  apiPost,
} from '../helpers';
import { DirectUploadDTO } from '../mediaupload/types';

import {
  SectionDocumentDTO,
  SectionDTO,
  ParkingMeterConfiguration,
} from './types';

const BASE_URL = '/api/prv/v0/city/{cityId}/sections';
const BASE_UPLOAD_URL = '/api/v0/cities/{cityId}/section_document/';

export async function openPDFManual(manualType: string): Promise<void> {
  const windowHandle = window.open('', `Manuel_Te${manualType}`);
  if (windowHandle !== null) {
    try {
      const data = await apiGetRaw(`${BASE_URL}/download/manual/${manualType}`);
      const file = await data.blob();
      const fileURL = URL.createObjectURL(file);
      windowHandle.location.assign(fileURL);
    } catch (error) {
      windowHandle.document.body.innerHTML = 'Missing access rights';
    }
  }
}

export function fetchSections(): Promise<SectionDTO[]> {
  return apiGet<SectionDTO[]>(`${BASE_URL}`);
}

export function postSection(name: string): Promise<SectionDTO> {
  return apiPost<SectionDTO>(`${BASE_URL}`, { name });
}

export function parkingMeterPriceListPatch(
  id: string | null,
  parkingMeterConfiguration: ParkingMeterConfiguration | null
): PatchObject<ParkingMeterConfiguration> {
  return {
    op: 'replace',
    path: '/parkingMeterConfiguration',
    value: { ...parkingMeterConfiguration, priceListId: id },
  } as PatchObject<ParkingMeterConfiguration>;
}

export function parkingMeterCvguPatch(
  id: string | null,
  parkingMeterConfiguration: ParkingMeterConfiguration | null
): PatchObject<ParkingMeterConfiguration> {
  return {
    op: 'replace',
    path: '/parkingMeterConfiguration',
    value: { ...parkingMeterConfiguration, cvguId: id },
  } as PatchObject<ParkingMeterConfiguration>;
}

export async function upsertParkingMeterPriceList(
  id: string | null,
  parkingMeterConfiguration: ParkingMeterConfiguration
): Promise<void> {
  const patchObject = parkingMeterPriceListPatch(id, parkingMeterConfiguration);

  try {
    await apiPatch<void>(
      `${BASE_URL}/documents/newParkingMeterDocument/`,
      patchObject
    );
    NotificationService.pushNotification({
      id: 'parking-meter-configuration',
      type: 'success',
      message: 'Grille tarifaire modifiée avec succès',
    });
  } catch (e) {
    NotificationService.pushNotification({
      id: 'parking-meter-configuration',
      type: 'error',
      message: e.message,
    });
    throw e;
  }
}

export async function upsertParkingMeterCVGU(
  id: string | null,
  parkingMeterConfiguration: ParkingMeterConfiguration
): Promise<void> {
  const patchObject = parkingMeterCvguPatch(id, parkingMeterConfiguration);

  try {
    await apiPatch<void>(
      `${BASE_URL}/documents/newParkingMeterDocument/`,
      patchObject
    );
    NotificationService.pushNotification({
      id: 'parking-meter-configuration',
      type: 'success',
      message: 'Les CVGU modifiées avec succès',
    });
  } catch (e) {
    NotificationService.pushNotification({
      id: 'parking-meter-configuration',
      type: 'error',
      message: e.message,
    });
    throw e;
  }
}

export function postSectionDocument(
  sectionId: string,
  doc: SectionDocumentDTO
): Promise<SectionDocumentDTO> {
  return apiPost<SectionDocumentDTO>(`${BASE_URL}/${sectionId}/documents`, doc);
}

export function getSectionDocumentsByCityId(): Promise<SectionDocumentDTO[]> {
  return apiGet<SectionDocumentDTO[]>(`${BASE_URL}/documents`);
}

export async function getFileUrl(id: string): Promise<string> {
  const definedLink = await apiGetRaw(`${BASE_URL}/documents/${id}/_download`);
  const url = await definedLink.text();
  return url;
}

export function deleteDocument(id: string): Promise<any> {
  return apiDelete<unknown>(`${BASE_URL}/documents/${id}`);
}

export function deleteSection(id: string): Promise<any> {
  return apiDeleteRaw(`${BASE_URL}/${id}`);
}

export async function openUploadedDocument(documentId: string): Promise<void> {
  const url = await getFileUrl(documentId);
  openUrlInNewTab(url);
}

export async function getDirectUploadLink(
  sectionId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_UPLOAD_URL}/${sectionId}/upload-url?${querystring.stringify({
      mediaType,
    })}`
  );
}
