import React, { CSSProperties } from 'react';

import { BKG_PINK } from 'theme';

const STYLE_CONTAINER: CSSProperties = {
  width: '100%',
  fontFamily: 'Roboto',
  position: 'relative',
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 40,
  backgroundColor: BKG_PINK,
  borderRadius: '5px 5px 0 0',
  color: 'white',
  fontSize: 20,
  padding: '5px 20px',
};

const STYLE_CUSTOM_HEADER: CSSProperties = {
  ...STYLE_HEADER,
  borderRadius: '5px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  height: 30,
  fontSize: 17,
  padding: '0px 50px',
};

const STYLE_CONTENT: CSSProperties = {
  border: '1px solid #CACACA',
  borderTop: '0',
  borderRadius: '0 0 5px 5px',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const STYLE_CUSTOM_CONTENT: CSSProperties = {
  ...STYLE_CONTENT,
  borderTop: '1px solid #CACACA',
  borderRadius: '5px',
  marginTop: 15,
  paddingTop: 40,
};

const STYLE_PARAMETER: CSSProperties = {
  marginRight: 30,
  lineHeight: '32px',
  fontSize: 16,
  verticalAlign: 'middle',
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  textAlign: 'left',
  width: '50%',
  paddingLeft: '50',
};

const STYLE_WARNING: CSSProperties = {
  ...STYLE_CUSTOM_CONTENT,
  border: '1px solid #FF0000',
  borderTop: '1px solid #FF0000',
};

const STYLE_WIDTH_HALF: CSSProperties = { width: '50%' };

const STYLE_WRAPPER: CSSProperties = {
  marginTop: 40,
  justifyContent: 'space-around',
};

type BoxItemProps = {
  children?: React.ReactNode;
  style?: Record<string, any>;
};

type BoxHeaderProps = {
  children?: React.ReactNode;
  color?: string;
  panel?: boolean;
};

type BoxContentProps = {
  children?: React.ReactNode;
  panel?: boolean;
  warning?: boolean;
};

type DataBoxProps = {
  children?: any;
  style?: Record<string, any>;
  panel?: boolean;
};

type DataBoxWithNameProps = {
  name: string;
  node: React.ReactNode;
  containerStyle?: Record<string, any>;
};

export const DataBoxHeader = ({ children, color, panel }: BoxHeaderProps) => {
  const style = panel ? { ...STYLE_HEADER } : { ...STYLE_CUSTOM_HEADER };
  if (color) style.backgroundColor = color;

  return <div style={style}>{children}</div>;
};

export const DataBoxContent = ({
  children,
  panel,
  warning,
}: BoxContentProps) => {
  const style = panel ? STYLE_CONTENT : STYLE_CUSTOM_CONTENT;

  return <div style={warning ? STYLE_WARNING : style}>{children}</div>;
};

export const DataBoxItem = ({ children, style }: BoxItemProps) => {
  return <div style={{ flex: 1, maxWidth: 275, ...style }}>{children}</div>;
};

export const DataBoxItemWrapper = ({ children, style }: BoxItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        width: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const DataBox = ({ children, style, panel }: DataBoxProps) => {
  const childrenWithProps = React.Children.map(children, child => {
    return child ? React.cloneElement(child, { panel }) : child;
  });

  return (
    <div style={{ ...STYLE_CONTAINER, ...style }}>{childrenWithProps}</div>
  );
};

export const DataBoxWithName = ({
  name,
  node,
  containerStyle,
}: DataBoxWithNameProps) => (
  <DataBoxItemWrapper style={{ ...STYLE_WRAPPER, ...containerStyle }}>
    <span style={STYLE_PARAMETER}>{name}</span>
    <div style={STYLE_WIDTH_HALF}>{node}</div>
  </DataBoxItemWrapper>
);
