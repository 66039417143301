import FileDownload from 'material-ui/svg-icons/file/file-download';
import React, { CSSProperties, useCallback, useContext, useState } from 'react';
import { Badge, Dialog, IconButton } from 'material-ui';

import useWatcher from 'commons/hooks/useWatcher';
import BoButton from 'facade/BoButton';
import { Flex } from '@cvfm-front/commons-ui';
import { PdfExportContext } from 'tefps/PdfExport/PdfExportContext';

import PdfDownloadElement from './PdfDownloadElement';

const CYAN_GLOW_START = 'downloadStart 1s ease-in-out infinite';
const GREEN_GLOW_END = 'downloadEnd 1s ease-in-out infinite';

const { _tg } = window.loadTranslations();

function computeGlowing(glowCode: number) {
  if (glowCode === 1) {
    return CYAN_GLOW_START;
  }
  if (glowCode === 2) {
    return GREEN_GLOW_END;
  }
  return undefined;
}

const STYLE_DOWNLOADS_COUNTER: CSSProperties = {
  flex: 1,
  top: 6,
  right: 2,
  width: 20,
  height: 20,
  fontSize: 11,
  borderRadius: 50,
};

const PdfDownload = (): JSX.Element => {
  const service = useContext(PdfExportContext);
  const hasExport = useWatcher(service.watchHasExport, false);
  const numberOfExportDone = useWatcher(service.watchNumberOfExportDone, 0);
  const pdfExportList = useWatcher(service.watchPdfExports, []);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  if (!hasExport) {
    return <></>;
  }

  const actions = [<BoButton secondary label="fermer" onClick={closeModal} />];

  return (
    <>
      <Badge
        style={{ padding: 0 }}
        badgeContent={numberOfExportDone}
        primary
        badgeStyle={{
          ...STYLE_DOWNLOADS_COUNTER,
          animation: computeGlowing(numberOfExportDone),
        }}
      >
        <IconButton
          onClick={openModal}
          style={{ padding: 0 }}
          tooltip={_tg('Topbar.PdfDownload.index.tooltip')}
          iconStyle={{ height: 22, width: 40 }}
        >
          <FileDownload color="white" />
        </IconButton>
      </Badge>
      <Dialog
        open={isOpen}
        onRequestClose={closeModal}
        autoScrollBodyContent
        actions={actions}
      >
        <Flex width100 flexDirection="column" overflow="hidden">
          {pdfExportList.map(e => (
            <PdfDownloadElement pdfExport={e} />
          ))}
        </Flex>
      </Dialog>
    </>
  );
};

export default PdfDownload;
