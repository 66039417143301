import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import Reset from 'material-ui/svg-icons/action/restore';
import DatePicker from 'react-datepicker';

import { getLocale, getDateFormat } from 'commons/Utils/localeUtils';
import 'intl';
import 'react-datepicker/dist/react-datepicker.css';
import 'commons/css/datepicker.css';
import { ICON_STYLE, LABEL_STYLE, WIDTH_STYLE } from 'theme';

import './DateFilter.css';
import { FilterDate } from './SidebarV2/Components/Dates';

const { _t } = window.loadTranslations(__filename);

export enum DatepickerClass {
  sidebar = 'react-datepicker-sidebar',
  filter = 'react-datepicker-filter',
  lapiSidebar = 'react-datepicker-lapiSidebar',
}

const translateQuickValueElements = () => [
  {
    label: _t('quickValueElements.3'),
    value: '3',
  },
  {
    label: _t('quickValueElements.6'),
    value: '6',
  },
  {
    label: _t('quickValueElements.12'),
    value: '12',
  },
  {
    label: _t('quickValueElements.all'),
    value: '-1',
  },
];

export const computeDatesWithPeriod = (period: string | null | undefined) => {
  let dateFrom;
  let dateTo;
  const monthNumber = Number(period);
  if (monthNumber > 0) {
    dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - monthNumber);
    dateFrom.setHours(0, 0, 0, 0);

    dateTo = new Date();
    dateTo.setHours(23, 59, 59, 999);
  }
  return { from: dateFrom, to: dateTo };
};

type DateFilterProps = {
  styleText: any;
  datepickerClass: DatepickerClass;
  onChange: (dates: {
    from?: Date | null | undefined;
    to?: Date | null | undefined;
  }) => void;
  dates: { from: Date | undefined; to: Date | undefined };
  periodSelectionEnabled?: boolean | null | undefined;
  colorResetButton?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  excludedDates?: Date[];
  excludedDatesRange?: FilterDate;
  disabled?: boolean;
  dateDirection?: 'row' | 'column';
};

type DateFilterState = {
  period: string | null | undefined;
};

class DateFilter extends React.Component<DateFilterProps, DateFilterState> {
  state: DateFilterState = { period: '12' };

  componentDidMount(): void {
    this.initFromProps(this.props); // init period (for quicksearch) value
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: DateFilterProps): void {
    const { from, to } = nextProps.dates;
    const { from: quickFrom, to: quickTo } = computeDatesWithPeriod('12');
    if (quickFrom && quickTo) {
      // For global reset of filters, 12 month period is checked
      if (
        from &&
        to &&
        from.toLocaleDateString() === quickFrom.toLocaleDateString() &&
        to.toLocaleDateString() === quickTo.toLocaleDateString()
      ) {
        this.setState({ period: '12' });
      }
    }
  }

  onCheckValue = (event: any) => {
    const period = event.target.dataset.value;
    this.setState({ period });
    this.props.onChange(computeDatesWithPeriod(period));
  };

  setFromDate = (newDate: Date | null) => {
    const { onChange, dates } = this.props;

    if (newDate) {
      newDate.setHours(0, 0, 0, 0);
    }

    onChange({ from: newDate, to: dates.to });
    this.setState({ period: null });
  };

  setToDate = (newDate: Date | null) => {
    const { onChange, dates } = this.props;

    if (newDate) {
      newDate.setHours(23, 59, 59);
    }

    onChange({ from: dates.from, to: newDate });
    this.setState({ period: null });
  };

  resetDate = () => {
    const { onChange, periodSelectionEnabled } = this.props;
    if (periodSelectionEnabled) {
      this.setState({ period: '-1' });
    }
    onChange({ from: undefined, to: undefined });
  };

  initFromProps(props: DateFilterProps) {
    const { period } = this.state;
    const { from: quickFrom, to: quickTo } = computeDatesWithPeriod(
      Number(period) > 0 ? period : '12'
    );
    const {
      dates: { from, to },
    } = props;

    if (quickFrom && quickTo) {
      // We keep quick filter value only if dates are matching the dates of the quick filter
      if (
        (from &&
          to &&
          (from.toLocaleDateString() !== quickFrom.toLocaleDateString() ||
            to.toLocaleDateString() !== quickTo.toLocaleDateString())) ||
        (!to && from) ||
        (!from && to)
      ) {
        this.setState({ period: null });
      } else if (!from && !to) {
        this.setState({ period: '-1' });
      }
    }
  }

  render(): React.ReactNode {
    const {
      styleText,
      datepickerClass,
      dates,
      colorResetButton,
      periodSelectionEnabled,
      minDate,
      maxDate,
      dateDirection = 'row',
      excludedDates = [],
      disabled,
    } = this.props;
    const { period } = this.state;

    return (
      <div className="date-filter">
        {periodSelectionEnabled && (
          <div className="date-filter-periodic-checkboxes">
            {translateQuickValueElements().map(quickValueElement => (
              <Checkbox
                key={quickValueElement.value}
                label={quickValueElement.label}
                iconStyle={ICON_STYLE}
                labelStyle={LABEL_STYLE}
                style={WIDTH_STYLE}
                onCheck={this.onCheckValue}
                checked={period === quickValueElement.value}
                data-value={quickValueElement.value}
              />
            ))}
          </div>
        )}
        <div className={`date-filter-position-${dateDirection}`}>
          <div className="date-filter-column-dates">
            <span className="date-filter-spacer" style={styleText}>
              {_t('element.from')}
            </span>
            <div className="date-filter-spacer" style={{ flex: 1 }}>
              <DatePicker
                className={datepickerClass}
                selected={dates.from}
                onChange={date => this.setFromDate(date as Date)}
                locale={getLocale()}
                dateFormat={getDateFormat('date')}
                todayButton={_t('element.today')}
                portalId="root-portal"
                minDate={minDate}
                maxDate={maxDate}
                excludeDates={excludedDates || []}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="date-filter-column-dates">
            <span className="date-filter-spacer" style={styleText}>
              {_t('element.to')}
            </span>
            <div className="date-filter-spacer" style={{ flex: 1 }}>
              <DatePicker
                className={datepickerClass}
                selected={dates.to}
                onChange={date => this.setToDate(date as Date)}
                locale={getLocale()}
                dateFormat={getDateFormat('date')}
                todayButton={_t('element.today')}
                portalId="root-portal"
                minDate={minDate}
                maxDate={maxDate}
                excludeDates={excludedDates || []}
                disabled={disabled}
              />
            </div>
          </div>
          {colorResetButton && (
            <div className="date-filter-reset">
              <Reset
                className="date-filter-reset-icon"
                style={{
                  color: colorResetButton,
                }}
                onClick={this.resetDate}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DateFilter;
