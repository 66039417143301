import React, { useEffect, useState } from 'react';

import '@cvfm-front/commons-css/notification.css';
import { NotificationService } from '@cvfm-front/commons-services';
import { NotificationList } from '@cvfm-front/commons-ui';

const NotificationWrapper = (): JSX.Element => {
  const [notifications, setNotifications] = useState({});

  useEffect(() => NotificationService.watchNotifications(setNotifications), []);

  return (
    <NotificationList
      notifications={notifications}
      closeNotificationById={NotificationService.deleteNotification}
    />
  );
};

export default NotificationWrapper;
