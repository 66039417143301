import * as React from 'react';

import { Address, SimpleAddress } from 'api/commonTypes';
import { CoreAddress } from '@cvfm-front/tefps-types';

import AddressRenderer from './AddressRenderer';

const simpleAddressRenderer = (address: SimpleAddress | null) => {
  if (!address) {
    return null;
  }

  const fullAddress: Address = {
    streetNumber: address.streetNumber,
    streetNumberBis: address.streetNumberBis,
    streetType: address.streetType,
    streetName: address.streetName,
    specialPlace: address.complement,
    postOfficeBoxNumber: null,
    postalCode: address.postalCode,
    addressSubRegion: null,
    addressRegion: null,
    addressLocality: address.locality,
    addressCountry: null,
  } as any;

  return <AddressRenderer address={fullAddress} />;
};

export const coreAddressRenderer = (
  address: CoreAddress | null | undefined
) => {
  if (!address) {
    return null;
  }

  const fullAddress: Address = {
    streetNumber: address.streetNumber,
    streetNumberBis: address.streetNumberBis,
    streetType: null,
    streetName: address.streetName,
    specialPlace: address.complement,
    postOfficeBoxNumber: null,
    postalCode: address.postalCode,
    addressSubRegion: null,
    addressRegion: null,
    addressLocality: address.locality,
    addressCountry: null,
  } as any;

  return <AddressRenderer address={fullAddress} />;
};

export default simpleAddressRenderer;
