import * as React from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import { CSSProperties } from 'react';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import { BKG_CYAN_SELECTED, STYLE_ERROR_WRAPPER } from 'theme';
import { highlightDownloads, updateDownloadsList } from 'Topbar/Download/duck';

const { _t, _tg } = window.loadTranslations(__filename);

const DisplayInfo = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        marginBottom: 24,
        color: BKG_CYAN_SELECTED,
        textAlign: 'center',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>
        {_t('feedback.info.exportInProgress')}
      </span>
      <div style={{ fontSize: '0.8em', marginTop: 6 }}>
        {_t('feedback.info.exportDuration')}
      </div>
      <div style={{ fontSize: '0.8em', marginTop: 6 }}>
        {_t('feedback.info.exportEnd')}
      </div>
    </div>
  </div>
);

export type Column = {
  key: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
};

type ExporterProps = {
  modalTitle?: string;
  description?: string;
  filters?: Record<string, any>;
  disabled: boolean;
  columns: Array<Column>;
  type: string;
  label?: string;
  style?: CSSProperties;
  fileExport: (
    filters: Record<string, any> | undefined,
    columnsParam: { columns: Array<string> }
  ) => any;
  refreshDownloadsList: () => void;
  highlightDownloadsIcon: () => void;
};

type ExporterState = {
  displayInfo: boolean;
  error: string | null | undefined;
  open: boolean;
  selectedColumns: Set<string>;
};

const initSelected = (columns: Array<Column>) => {
  // Les champs exportés par défaut sont ajoutés à la liste des colonnes sélectionnées :
  const result = columns.filter(x => x.checked).map(x => x.key);
  return new Set(result);
};

const initialState = (columns: Array<Column>) => ({
  displayInfo: false,
  error: null,
  open: false,
  selectedColumns: initSelected(columns),
});

class Exporter extends React.Component<ExporterProps, ExporterState> {
  state: ExporterState = initialState(this.props.columns);

  onCheck = (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    const { key } = currentTarget.dataset;
    const selectedColumns = new Set(this.state.selectedColumns);
    if (isInputChecked) {
      selectedColumns.add(key as string);
    } else {
      selectedColumns.delete(key as string);
    }
    this.setState({ selectedColumns });
  };

  onClickExport = () => {
    this.setState({ error: null });
    try {
      const {
        filters,
        fileExport,
        highlightDownloadsIcon,
        refreshDownloadsList,
      } = this.props;
      const { selectedColumns } = this.state;
      const columnsParam = { columns: Array.from(selectedColumns) };

      fileExport(filters, columnsParam);
      highlightDownloadsIcon();
      setTimeout(refreshDownloadsList, 2000);
      setTimeout(this.handleClose, 3500);
    } catch (error) {
      this.setState({
        error: error.json?.requestId
          ? _t('feedback.error.requestError_requestId', {
              requestId: error.json.requestId,
            })
          : _t('feedback.error.requestError'),
      });
    } finally {
      this.setState({ displayInfo: true });
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState(initialState(this.props.columns));
  };

  selectAll = () => {
    const { columns } = this.props;
    const { selectedColumns } = this.state;

    let result;
    if (columns.length === selectedColumns.size) {
      result = initSelected(columns);
    } else {
      result = new Set(columns.map(x => x.key));
    }
    this.setState({ selectedColumns: result });
  };

  render() {
    const { selectedColumns, displayInfo, error } = this.state;
    const { modalTitle, description } = this.props;

    const actions = [
      <BoButton
        key="discard"
        style={{ marginRight: 10 }}
        label={_tg('action.cancel')}
        onClick={this.handleClose}
      />,
      <BoButton
        key="export"
        style={{ marginRight: 10 }}
        label={error ? _t('element.retry') : _t('element.export')}
        primary
        onClick={this.onClickExport}
      />,
    ];

    const { type, columns, label, style } = this.props;

    const checkboxes = columns.map((column, i) => (
      <Checkbox
        label={column.label}
        onCheck={this.onCheck}
        checked={selectedColumns.has(column.key)}
        key={i}
        data-key={column.key}
        disabled={column.disabled}
      />
    ));

    const title = (
      <div>
        {modalTitle || _t('element.currentPageExport.title', { type })}
        {!displayInfo && (
          <div style={{ fontSize: '70%' }}>
            {description ||
              _t('element.currentPageExport.description', { type })}
          </div>
        )}
      </div>
    );

    const content = displayInfo ? (
      <DisplayInfo />
    ) : (
      <div style={{ marginTop: 24 }}>
        {columns.find(x => !x.checked) && (
          <Checkbox
            label={_tg('action.select_all')}
            onCheck={this.selectAll}
            labelStyle={{ textDecoration: 'underline' }}
            checked={columns.length === selectedColumns.size}
            style={{ marginBottom: 10 }}
          />
        )}
        <div style={{ marginLeft: 10 }}>{checkboxes}</div>
      </div>
    );

    return (
      <div style={style}>
        <BoButton
          label={label || _t('element.excelExport')}
          onClick={this.handleOpen}
          disabled={this.props.disabled}
          primary
        />
        <Dialog
          title={title}
          actions={displayInfo && !error ? [] : actions}
          modal={!displayInfo}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={!displayInfo && !error}
          open={this.state.open}
        >
          {error ? (
            <div style={STYLE_ERROR_WRAPPER}>
              <ErrorBlock error={{ message: error }} />
            </div>
          ) : (
            <div>{content}</div>
          )}
        </Dialog>
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  refreshDownloadsList: () => dispatch(updateDownloadsList()),
  highlightDownloadsIcon: () => dispatch(highlightDownloads(1)),
}))(Exporter);
