import * as React from 'react';
import { connect } from 'react-redux';
import UploadIcon from 'material-ui/svg-icons/file/cloud-upload';
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever';

import SectionTitle from 'commons/SectionTitle';
import { openUrlInNewTab } from 'commons/openUrlInNewTab';
import { openUploadedDocument } from 'api/documentLibrary';
import { SectionDocumentDTO } from 'api/documentLibrary/types';

import {
  mapModalsActionToProps,
  ModalsReduxDispatchProps,
} from './Modals/ducks/mapper';
import { STYLE } from './style';
import { DisplayFile } from './SectionList/DisplayFile';

type ReduxDispatchProps = ModalsReduxDispatchProps;

type OwnProps = {
  canWrite: boolean;
  sectionId: string;
  sectionName: string;
  document: SectionDocumentDTO | null | undefined;
};

type Props = OwnProps & ReduxDispatchProps;

class OneDocumentSection extends React.Component<Props, any> {
  onOpenUploadModal = (ev: React.MouseEvent<HTMLInputElement>) => {
    const { openUploadModal, sectionId } = this.props;
    openUploadModal(sectionId);
  };

  onOpenDeleteModal = (ev: React.MouseEvent<HTMLInputElement>) => {
    const { openDeleteModal, sectionId } = this.props;
    openDeleteModal(sectionId);
  };

  openDocument = async (ev: React.MouseEvent<HTMLInputElement>) => {
    const { documentId } = ev.currentTarget.dataset;
    const { document } = this.props;
    if (!documentId || !document) return;

    if (document.ressourceType === 'FILE') {
      await openUploadedDocument(documentId);
    } else {
      openUrlInNewTab(document.definedLink || '');
    }
  };

  isThereAlreadyDocument = () => {
    const { document } = this.props;
    // if using in section check also section id
    if (document) {
      return true;
    }
    return false;
  };

  render() {
    const { canWrite, document, sectionId, sectionName } = this.props;

    return (
      <div>
        <SectionTitle title={sectionName} />

        {/* Section actions: upload, delete */}
        {canWrite && (
          <div>
            {!this.isThereAlreadyDocument() && (
              <UploadIcon
                data-section-id={sectionId}
                onClick={this.onOpenUploadModal}
                style={STYLE.BTN_ICON}
              />
            )}
            {this.isThereAlreadyDocument() && (
              <DeleteIcon
                data-section-id={sectionId}
                onClick={this.onOpenDeleteModal}
                style={STYLE.BTN_ICON}
              />
            )}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {document && (
            <DisplayFile
              key={document.id}
              filename={document.name}
              filetype={document.type}
              onClick={this.openDocument}
              data-document-id={document.id}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect<OwnProps, ReduxDispatchProps>(
  null,
  mapModalsActionToProps
)(OneDocumentSection);
