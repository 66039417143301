import React, { CSSProperties } from 'react';

type Props = {
  fpsId: string;
};

/**
 * Explode fps id
 */
const splitFpsId = (fpsId: string) => {
  let idx = 0;
  if (fpsId.length === 26) {
    return [14, 2, 1, 3, 3, 3].map(chars => {
      const token = fpsId.substring(idx, idx + chars);
      idx += chars;
      return token;
    });
  }
  return [fpsId];
};

const STYLE_FPS_PART: CSSProperties = {
  marginLeft: 10,
};

const FpsNumber = ({ fpsId }: Props) => {
  return (
    <span>
      {splitFpsId(fpsId).map((fpsPart, i) => (
        <span key={i} style={i > 0 ? STYLE_FPS_PART : undefined}>
          {fpsPart}
        </span>
      ))}
    </span>
  );
};

export default FpsNumber;
