import React, { CSSProperties } from 'react';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import ClearRoundedIcon from 'material-ui/svg-icons/content/clear';

import { TEXT_COLOR_LIGHT, TXT_BLACK } from 'theme';
import { formatNumber } from 'commons/Utils/numberUtil';

import { formatDate } from '../Utils/dateUtil';

import {
  STYLE_CONTAINER,
  STYLE_FILE_NAME,
  STYLE_NAME_CONTAINER,
} from './types';

const { _tg } = window.loadTranslations();

type Props = {
  rows: [string, string, string];
  contentUrl?: string;
  purgedFile?: boolean;
  onClick?: React.MouseEventHandler;
};

const STYLE_ICON: CSSProperties = {
  width: 40,
  height: 50,
};

export function buildRowsFromFile(
  title: string,
  filename: string,
  size: number,
  dateRemoved?: string
): [string, string, string] {
  const thirdField = dateRemoved
    ? `Purge: ${formatDate(new Date(dateRemoved))}`
    : `${filename.split('.').slice(-1)[0]} | ${formatNumber(
        size,
        'filesize'
      )}`.toUpperCase();
  return [title, filename, thirdField];
}

function FileBlock({ rows, contentUrl, purgedFile, onClick }: Props) {
  const content = (
    <>
      {!purgedFile ? (
        <FileIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
      ) : (
        <ClearRoundedIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
      )}
      <div
        style={{ paddingTop: 5, marginLeft: 10, lineHeight: '12px', flex: 1 }}
      >
        <div style={{ color: TXT_BLACK }}>{rows[0]}</div>
        <div style={STYLE_NAME_CONTAINER}>
          <div style={STYLE_FILE_NAME}>{rows[1]}</div>
          <div>{rows[2]}</div>
        </div>
      </div>
    </>
  );

  return (
    <div style={STYLE_CONTAINER}>
      {onClick && (
        <a
          onClick={onClick}
          rel="noopener noreferrer"
          style={{ display: 'flex', width: '100%' }}
          title={_tg('action.downloadAttachment')}
        >
          {content}
        </a>
      )}
      {!onClick && (
        <a
          href={contentUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', width: '100%' }}
          title={_tg('action.downloadAttachment')}
        >
          {content}
        </a>
      )}
    </div>
  );
}

export default FileBlock;
