import querystring from 'querystring';

import { apiGet, apiPostCSV, apiPut } from 'api/helpers';
import { ColumnConfig, ImportResultDTO } from 'api/commonTypes';

import { MotifDTO, MatchingMotifSearch } from './types';

const BASE_PROXY_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/motifs';
const BASE_PRV_URL = '/api/prv/v0/city/{cityId}/motif';

export async function fetchMotifs(): Promise<MotifDTO[]> {
  return apiGet<MotifDTO[]>(BASE_PROXY_URL);
}

export async function fetchMotifDetails(motifId: string): Promise<MotifDTO> {
  return apiGet<MotifDTO>(`${BASE_PROXY_URL}/${motifId}`);
}

export async function fetchMatchingMotifs(
  motifFilter?: string
): Promise<MatchingMotifSearch> {
  return apiGet<MatchingMotifSearch>(
    `${BASE_PROXY_URL}/match/filter?${querystring.stringify({
      motifFilter,
    })}`
  );
}

export async function createMotifsFromCsv(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_PRV_URL}/import`, file);
}

export function getExport(
  filterParam: Record<string, any>,
  exportParam: ColumnConfig
): Promise<void> {
  return apiGet<void>(
    `/api/prv/v0/city/{cityId}/motif/export?${querystring.stringify(
      exportParam
    )}`
  );
}

export function updateMotifEnabled(
  motifId: string,
  enabled: boolean
): Promise<void> {
  return apiPut<void>(BASE_PROXY_URL, { motifId, enabled });
}
