import { PatchObject } from 'api/commonTypes';

import { apiPatch } from '../helpers';

const BASE_PRV_URL = '/api/prv/v0/cities/fps_configuration/{cityId}';

export async function updateRefundConfiguration(
  updateConfig: PatchObject<unknown>
): Promise<void> {
  return apiPatch<void>(`${BASE_PRV_URL}/refund_configuration`, updateConfig);
}

export async function updateCommentsConfiguration(
  updateConfig: PatchObject<unknown>
): Promise<void> {
  return apiPatch<void>(`${BASE_PRV_URL}/comments_configuration`, updateConfig);
}
