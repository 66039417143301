import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import InputFile from 'commons/InputFile';
import { BKG_GREEN, BKG_GREY, BKG_LIGHT_BLUE, BKG_PINK } from 'theme';
import { patchFps } from 'api/fps';
import { uploadFileAndReturnUrn } from 'api/mediaupload';
import { FnmsMediaDTO, FpsVersionDTO } from 'api/fps/types';
import { getApiState } from 'api/duck';

import Picture from './Picture';

const { _t } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  fontSize: 12,
  color: BKG_LIGHT_BLUE,
  cursor: 'pointer',
};

type FpsPicturesProps = {
  version: FpsVersionDTO;
  mediaUpload: string | null | undefined;
  hasFpsCreationRight: boolean;
};

type FpsPicturesState = {
  open: boolean;
  uploading: boolean;
  tmpFile: (File & { preview: string }) | null | undefined;
  medias: Array<FnmsMediaDTO>;
};

class FpsPictures extends React.Component<FpsPicturesProps, FpsPicturesState> {
  state: FpsPicturesState = {
    open: false,
    uploading: false,
    tmpFile: null,
    medias: this.props.version.medias,
  };

  onDrop = (files: Array<File>) => {
    const { mediaUpload } = this.props;
    if (!mediaUpload) return;

    this.setState({ tmpFile: files[0] as File & { preview: string } });
  };

  onEndTmpUpload = () => this.setState({ tmpFile: null });

  onStartUpload = async () => {
    const { tmpFile } = this.state;
    const { version, mediaUpload } = this.props;
    if (!tmpFile || !mediaUpload) return;

    this.setState({ uploading: true });

    try {
      const urn = await uploadFileAndReturnUrn(mediaUpload, tmpFile);
      const mediaUpdate = {
        op: 'add',
        path: '/medias',
        value: {
          url: urn,
          name: tmpFile.name,
        },
      };
      const newFps = await patchFps(version.id, [mediaUpdate]);
      this.setState({ medias: newFps.medias });
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ uploading: false, tmpFile: null });
    }
  };

  openModal = () => this.setState({ open: true });
  closeModal = () =>
    this.setState({ open: false, uploading: false, tmpFile: null });

  render() {
    const { mediaUpload, version, hasFpsCreationRight } = this.props;
    const { uploading, tmpFile, medias } = this.state;

    if (!mediaUpload && !version.medias.length) return <div />;

    const btnText =
      hasFpsCreationRight && mediaUpload
        ? _t('element.addDisplayPictures')
        : _t('element.displayPictures');

    return (
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <span style={STYLE_LINK} onClick={this.openModal}>
          {btnText} ({(medias || []).length})
        </span>
        <Dialog
          open={this.state.open}
          onRequestClose={this.closeModal}
          contentStyle={{ width: 800 }}
          autoScrollBodyContent
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>{_t('element.fpsPictures')}</span>
              {mediaUpload && hasFpsCreationRight && (
                <InputFile
                  accept="image/jpeg"
                  onDrop={this.onDrop}
                  filename={tmpFile?.name}
                  loading={uploading}
                />
              )}
            </div>
          }
        >
          <div style={{ height: 500 }}>
            {tmpFile && (
              <div
                style={{
                  marginTop: 25,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={tmpFile.preview}
                  style={{ maxWidth: 75, maxHeight: 75, marginRight: 30 }}
                />
                <div>{_t('feedback.warning.addPicture')}</div>
                <CheckIcon
                  color={uploading ? BKG_GREY : BKG_GREEN}
                  style={{
                    margin: '0 10px',
                    cursor: uploading ? 'not-allowed' : 'pointer',
                  }}
                  onClick={!uploading ? this.onStartUpload : undefined}
                />
                <CloseIcon
                  color={uploading ? BKG_GREY : BKG_PINK}
                  style={{ cursor: uploading ? 'not-allowed' : 'pointer' }}
                  onClick={!uploading ? this.onEndTmpUpload : undefined}
                />
              </div>
            )}
            {medias.map((media, i) => (
              <Picture index={i} media={media} />
            ))}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    hasFpsCreationRight: userInfo && userInfo.rights.includes('FPS_CREATE'),
  };
})(FpsPictures);
