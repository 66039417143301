import * as React from 'react';

import attachToForm from 'commons/FormComponent/attachToForm';
import { FormField } from 'commons/FormComponent/types';

type Props = FormField<string>;

const HiddenFieldCustom = ({ value, ...otherProps }: Props) => (
  <input {...otherProps} value={value || undefined} type="hidden" />
);

const AttachedForm = attachToForm((p: any) => <HiddenFieldCustom {...p} />);
export default AttachedForm;
