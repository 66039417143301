import { vehicleCategories } from 'tepv/Mif/List/utils';

import { GpvCivilityFilter, GpvSignature, GpvStatus } from './types';

const { _t } = window.loadTranslations(__filename);

export const gpvStatusesName: { [key in GpvStatus]: string } = {
  PENDING: 'À traiter',
  TO_SEND: 'À envoyer',
  DONE: 'Envoyé',
};

export const gpvSignaturesName: { [key in GpvSignature]: string } = {
  AGENT: "Signature de l'agent",
  OFFENDER: 'Signature du contrevenant',
};

export const gpvCivilityFilters: { [key in GpvCivilityFilter]: string } = {
  PERSON: _t('filters.civility.person'),
  COMPANY: _t('filters.civility.company'),
};

export const STATUSES_FILTER_OPTIONS = Object.entries(gpvStatusesName).map(
  entry => {
    return { value: entry[0], label: entry[1] };
  }
) as { value: GpvStatus; label: string }[];

export const VEHICLE_CATEGORIES_OPTIONS = vehicleCategories.map(cat => {
  return {
    key: `${cat}`,
    label: `${cat}`,
  };
});

export const RESPONSIBLE_PARTY_CIVILITY_OPTIONS = Object.entries(
  gpvCivilityFilters
).map(entry => {
  return { value: entry[0], label: entry[1] };
}) as { value: GpvCivilityFilter; label: string }[];

export const GPV_COLUMNS_EXPORT = [
  { key: 'natinfCode', label: 'Code NATINF', checked: true },
  {
    key: 'infractionCase',
    label: 'Cas Infraction',
    checked: true,
  },
  { key: 'gpvId', label: "Numéro d'infraction", checked: true },
  { key: 'motifCategory', label: 'Catégorie du motif' },
  { key: 'motifQualification', label: 'Qualification', checked: true },
  {
    key: 'signatureDate',
    label: 'Date de signature',
    checked: true,
  },
  {
    key: 'signatureTime',
    label: 'Heure de signature',
    checked: true,
  },
  {
    key: 'infractionDate',
    label: "Date d'infraction",
    checked: true,
  },
  {
    key: 'infractionTime',
    label: "Heure d'infraction",
    checked: true,
  },
  { key: 'agentName', label: "Nom de l'agent", checked: true },
  {
    key: 'agentMatricule',
    label: "Matricule de l'agent",
    checked: true,
  },
  { key: 'agentOrganizationName', label: 'Organisation' },
  { key: 'address', label: 'Voie', checked: true },
  { key: 'city', label: 'Ville', checked: true },
  { key: 'presetNote', label: 'Renseignement prédéfini' },
  { key: 'infractionLocation', label: 'Lieu de verbalisation' },
  { key: 'status', label: 'Status' },
  {
    key: 'vehicleCategory',
    label: 'Categorie du véhicule',
  },
  {
    key: 'vehicleBrand',
    label: 'Marque du véhicule',
  },
  {
    key: 'vehicleModel',
    label: 'Modèle du véhicule',
  },
  {
    key: 'vehiclePlate',
    label: 'Immatriculation',
  },
  { key: 'plateCountry', label: "Pays d'immatriculation" },
  { key: 'freeComments', label: 'Renseignement libre' },
  { key: 'photoStatus', label: 'Photo (oui/non)' },
  { key: 'vehicleRemoval', label: 'Enlèvement du véhicule' },
  { key: 'decreeReference', label: "Référence de l'arrêté" },
  { key: 'infractionCoordinates', label: "Coordonnées GPS de l'infraction" },
  { key: 'zoneId', label: 'Zone' },
  { key: 'signatureCoordinates', label: 'Coordonnées GPS de signature' },
  { key: 'responsiblePartyCivility', label: 'Type du contrevenant' },
  { key: 'vehicleImmobilization', label: 'Immobilisation du véhicule' },
  { key: 'mandatoryLicenceExchange', label: 'Échange du permis de conduire' },
];

export const GPV_COLUMNS_EXPORT_DBF = GPV_COLUMNS_EXPORT.map(
  ({ key, label }) => ({ key, label, checked: true, disabled: true })
);
