import React, { CSSProperties } from 'react';
import ActionAccountCircle from 'material-ui/svg-icons/action/account-circle';

import { InternalAgent } from 'api/auth/types';
import { AgentAccountLightDTO } from '@cvfm-front/tefps-types';

const STYLE: CSSProperties = {
  width: '200',
  height: '200',
  borderRadius: '50%',
  backgroundColor: 'white',
};

const STYLE_LOGIN_ICON: CSSProperties = {
  height: 45,
  width: 45,
  marginLeft: 10,
};

export type UserPictureProps = {
  userInfo?: InternalAgent;
  account?: AgentAccountLightDTO;
  size: number;
} & React.HTMLProps<HTMLSpanElement>;

const UserPicture = ({
  userInfo,
  account,
  size,
  ...otherProps
}: UserPictureProps): JSX.Element | null => {
  if (!account && !userInfo) return null;

  let name = '';
  let picture = null;
  if (userInfo) {
    ({ name, picture } = userInfo);
  } else if (account) {
    ({ picture } = account);
    name = `${account.firstName} ${account.lastName}`;
  }

  return (
    <span title={name} {...otherProps}>
      {picture ? (
        <img src={picture} style={{ ...STYLE, width: size, height: size }} />
      ) : (
        <ActionAccountCircle color="white" style={STYLE_LOGIN_ICON} />
      )}
    </span>
  );
};

export default UserPicture;
