import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';

import attachToForm from 'commons/FormComponent/attachToForm';
import { clearOtherProperties } from 'commons/FormComponent/utils';
import { TextFieldInputStyle, TextFieldStyle } from 'styles/TextFieldStyle';
import { BKG_PINK } from 'theme';

import { FormField } from '../types';

interface Props extends FormField<string> {
  name: string;
  hint?: string;
  trimValue?: boolean;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  mandatory?: boolean;
}

class TextFieldCustom extends React.Component<Props> {
  getDefaultProps() {
    return {
      trimValue: true,
    };
  }

  onChange = (_e: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    const { onValueChange } = this.props;
    onValueChange(newValue);
  };

  onBlur = () => {
    const { validateField, onValueChange, trimValue } = this.props;
    let { value } = this.props;
    if (trimValue && typeof value === 'string') {
      value = value.trim();
      onValueChange(value);
    }
    validateField(value || null);
  };

  render() {
    const {
      name,
      hint,
      error,
      value, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validateField, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onValueChange, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      trimValue,
      style,
      inputStyle,
      mandatory = false,
      ...otherProps
    } = this.props;
    const mandatoryStar = mandatory && (
      <span style={{ color: BKG_PINK }}>*</span>
    );
    clearOtherProperties(otherProps);
    return (
      <TextField
        {...otherProps}
        key={name}
        value={value !== undefined && value !== null ? value : ''}
        onChange={this.onChange}
        onBlur={this.onBlur}
        floatingLabelText={
          <span>
            {hint} {mandatoryStar}
          </span>
        }
        errorText={error}
        style={{ ...TextFieldStyle, ...style }}
        inputStyle={{ ...TextFieldInputStyle, ...inputStyle }}
        required={mandatory}
      />
    );
  }
}

export default attachToForm((p: any) => <TextFieldCustom {...p} />);
