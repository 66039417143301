import * as React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { RessourceType } from 'api/documentLibrary/types';

const { _tg } = window.loadTranslations();

export type UploadModalInputSelectorProps = {
  ressourceType: RessourceType;
  onTypeChange: (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number,
    value: RessourceType
  ) => any;
};

/*
 ** InputSelector:
 ** Render component for the selector of ressource: file or link
 */
export const UploadModalInputSelector = ({
  ressourceType,
  onTypeChange,
}: UploadModalInputSelectorProps): JSX.Element => {
  return (
    <SelectField value={ressourceType} onChange={onTypeChange}>
      <MenuItem value="FILE" primaryText={_tg('field.evidence.file')} />
      <MenuItem
        value="LINK"
        primaryText={_tg('tefps.documentLibrary.upload.link')}
      />
    </SelectField>
  );
};
