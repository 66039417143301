import { apiGet, apiPut } from '../helpers';

import { NotificationType } from './types';

const BASE_BO_URL = '/api/prv/v0/city/{cityId}/notifications';

export async function fetchAgentNotifications(): Promise<
  Array<NotificationType>
> {
  return apiGet<Array<NotificationType>>(BASE_BO_URL);
}

export async function upsertAgentNotifications(
  notifications: Array<NotificationType>
): Promise<void> {
  return apiPut<void>(BASE_BO_URL, notifications);
}
