import * as React from 'react';
import { connect } from 'react-redux';

import {
  mapSnackbarActionToProps,
  SnackbarReduxDispatchProps,
} from 'commons/Snackbar/ducks/mapper';
import {
  getDirectUploadLink,
  upsertParkingMeterPriceList,
  postSectionDocument,
  upsertParkingMeterCVGU,
} from 'api/documentLibrary';
import { uploadFileV2 } from 'api/mediaupload';
import {
  ParkingMeterConfiguration,
  RessourceType,
} from 'api/documentLibrary/types';

import {
  mapModalsActionToProps,
  mapModalsStateToProps,
  ModalsReduxDispatchProps,
  ModalsReduxStateProps,
} from '../ducks/mapper';
import {
  DocumentLibraryReduxDispatchProps,
  mapDocumentLibraryActionToProps,
} from '../../ducks/mapper';
import { getConfigState } from '../../../config/duck';

import { UploadModalContainer } from './UploadModalContainer';

const { _tg } = window.loadTranslations();

type ReduxStateProps = ModalsReduxStateProps & {
  parkingMeterConfigurationDTO: ParkingMeterConfiguration;
};

type ReduxDispatchProps = ModalsReduxDispatchProps &
  DocumentLibraryReduxDispatchProps &
  SnackbarReduxDispatchProps;

type Props = ReduxDispatchProps & ReduxStateProps;

type State = {
  file: File | null;
  isLoading: boolean;
  linkName: string;
  linkUrl: string;
  ressourceType: RessourceType;
};

const INITIAL_STATE: State = {
  file: null,
  isLoading: false,
  linkName: '',
  linkUrl: '',
  ressourceType: 'FILE',
};

/*
 ** UploadModal:
 ** Main component holding states, redux mapping and logic
 */
class UploadModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClose = () => {
    const { closeModals } = this.props;
    this.setState(INITIAL_STATE);
    closeModals();
  };

  onDrop = (e: File[]) => {
    const { snackbarSendMessage } = this.props;
    if (e[0]) {
      this.setState({ file: e[0] });
    } else {
      snackbarSendMessage(_tg('tefps.documentLibrary.upload.incorrectFile'));
    }
  };

  onTypeChange = (
    _ev: React.ChangeEvent<HTMLInputElement>,
    _index: number,
    value: RessourceType
  ) => {
    this.setState({ ressourceType: value });
  };

  onSubmit = async () => {
    const {
      addDocument,
      closeModals,
      snackbarSendMessage,
      sectionId,
      parkingMeterConfigurationDTO,
    } = this.props;
    const { ressourceType, file, linkName, linkUrl } = this.state;

    if (file === null && (linkName === '' || linkUrl === '')) {
      snackbarSendMessage(
        _tg('tefps.documentLibrary.upload.fieldsShouldBeFilled')
      );
      return;
    }
    if (!this.isLinkCorrect(linkUrl)) {
      snackbarSendMessage("Le lien fourni n'est pas conforme");
      return;
    }

    try {
      this.setState({ isLoading: true });
      let doc;
      if (ressourceType === 'FILE' && file !== null) {
        doc = await this.onFileSubmit(file);
      } else {
        doc = await this.onLinkSubmit(linkName, linkUrl);
      }
      // update parking meter config in city config
      if (sectionId === 'PRICELIST') {
        await upsertParkingMeterPriceList(doc.id, parkingMeterConfigurationDTO);
        // Modifying the object locally for front
        parkingMeterConfigurationDTO.priceListId = doc.id;
      } else if (sectionId === 'CVGU') {
        await upsertParkingMeterCVGU(doc.id, parkingMeterConfigurationDTO);
        // Modifying the object locally for front
        parkingMeterConfigurationDTO.cvguId = doc.id;
      }
      addDocument(doc);
      closeModals();
      this.setState({ linkUrl: '', linkName: '', file: null });
    } catch (e) {
      snackbarSendMessage((e as Error).message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onFileSubmit = async (file: File) => {
    const { sectionId } = this.props;

    const directUploadDTO = await getDirectUploadLink(sectionId, file.type);

    await uploadFileV2(directUploadDTO.signedUploadUrl, file, null); // no watermark because it's by and for the agents

    const doc = await postSectionDocument(sectionId, {
      id: '',
      downloadUrn: directUploadDTO.fileUrn,
      name: file.name,
      type: file.type,
      filesize: file.size,
      ressourceType: 'FILE',
    });

    return doc;
  };

  isLinkCorrect = (url: string) => {
    return !url || url.indexOf('http') === 0 || url.indexOf('https') === 0;
  };

  onLinkSubmit = async (name: string, url: string) => {
    const { sectionId } = this.props;
    const doc = await postSectionDocument(sectionId, {
      id: '',
      definedLink: url,
      filesize: 0,
      name,
      ressourceType: 'LINK',
      type: '',
    });
    return doc;
  };

  onLinkNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ linkName: e.target.value });
  };

  onLinkUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ linkUrl: e.target.value });
  };

  render() {
    const { isUploadModalOpen } = this.props;
    const { ressourceType, file, isLoading } = this.state;

    return (
      <UploadModalContainer
        closeModals={this.onClose}
        file={file}
        isLoading={isLoading}
        isUploadModalOpen={isUploadModalOpen}
        onDrop={this.onDrop}
        onLinkNameChange={this.onLinkNameChange}
        onLinkUrlChange={this.onLinkUrlChange}
        onSubmit={this.onSubmit}
        onTypeChange={this.onTypeChange}
        ressourceType={ressourceType}
      />
    );
  }
}

const mapState = (state: any) => ({
  ...mapModalsStateToProps(state),
  ...getConfigState(state),
});

const mapAction = (dispatch: any) => {
  return {
    ...mapDocumentLibraryActionToProps(dispatch),
    ...mapModalsActionToProps(dispatch),
    ...mapSnackbarActionToProps(dispatch),
  };
};

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapState,
  mapAction
)(UploadModal);
