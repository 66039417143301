import * as React from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';

import {
  mapModalsActionToProps,
  ModalsReduxDispatchProps,
} from './Modals/ducks/mapper';
import { COLOR_STYLE } from './style';

const { _tg } = window.loadTranslations();

type OwnProps = {
  canWrite: boolean;
};

type SectionCreationProps = OwnProps & ModalsReduxDispatchProps;

const SectionCreation = ({
  canWrite,
  openCreateSectionModal,
}: SectionCreationProps) => {
  if (canWrite) {
    return (
      <div style={{ textAlign: 'right' }}>
        <BoButton
          labelColor="#fff"
          backgroundColor={COLOR_STYLE.BKG_CYAN}
          label={_tg('tefps.documentLibrary.sections.addSection')}
          onClick={openCreateSectionModal}
        />
      </div>
    );
  }
  return <span />;
};

export default connect<OwnProps, ModalsReduxDispatchProps>(
  null,
  mapModalsActionToProps
)(SectionCreation);
