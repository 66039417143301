import { PatchObject } from 'api/commonTypes';

import { apiPatch } from '../helpers';

const ROOT_API = '/api/prv/v0/cities/parking_configuration';
const CITY_API = '/{cityId}';

export const patchCity = (dto: PatchObject<unknown>) => {
  return apiPatch(ROOT_API + CITY_API, dto);
};
