import { connect, createProvider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import formStorage from './duck';
import { FieldsMap, DispatchToProps, StateToProps } from './types';

type TMapStateToProps = (state: FieldsMap) => StateToProps;
type TMapDispatchToProps = (dispatch: any) => DispatchToProps;

const STORE_CONTEXT = '@@form';
export const FormStorage = createProvider(STORE_CONTEXT);

export function createFormStore() {
  // eslint-disable-next-line
  // @ts-ignore
  return createStore(formStorage, compose(applyMiddleware(thunk)));
}

// override default connector from redux
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function formConnector<T>(
  mapStateToProps: TMapStateToProps,
  mapDispatchToProps: TMapDispatchToProps
) {
  return connect(mapStateToProps, mapDispatchToProps, null, {
    storeKey: STORE_CONTEXT,
  });
}
