import React, { CSSProperties } from 'react';
import CopyIcon from 'material-ui/svg-icons/content/content-copy';

import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations();

const STYLE_COPY: CSSProperties = {
  cursor: 'pointer',
  textDecoration: 'underline',
  marginLeft: 20,
  lineHeight: '14px',
  fontSize: 12,
  color: BKG_CYAN,
};

type CopyValueButtonProps = {
  value?: string;
  spanStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
};

function CopyValueButton({
  value,
  spanStyle,
  iconStyle,
}: CopyValueButtonProps): JSX.Element {
  const setMessage = useSnackbar();

  function handleCopy(): void {
    const textField = document.createElement('textarea');
    textField.innerText = value || '';

    if (document && document.body) {
      document.body.appendChild(textField);
    }

    textField.select();
    document.execCommand('copy');
    textField.remove();
    setMessage(_tg('feedback.success.copyClipboard'));
  }

  if (value === undefined || value === null) {
    return <></>;
  }

  return (
    <span
      style={{ ...STYLE_COPY, ...spanStyle }}
      title={_tg('action.copy')}
      onClick={handleCopy}
    >
      <CopyIcon style={iconStyle} />
    </span>
  );
}

export default CopyValueButton;
