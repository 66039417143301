import React, { CSSProperties } from 'react';

const STYLE: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  justifyContent: 'center',
  alignItems: 'center',
};

type Props = {
  children?: React.ReactElement<any>; // Waiting for https://github.com/facebook/flow/issues/1964
};

const FlexCenter = ({ children }: Props) => <div style={STYLE}>{children}</div>;
export default FlexCenter;
