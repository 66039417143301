import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';

import { Actions, State } from './types';

const INITIAL_STATE = {
  sections: [],
  documents: [],
} as State;

export const documentLibraryReducer = (
  state: State = INITIAL_STATE,
  action: Actions
): State => {
  switch (action.type) {
    case 'INIT_DOCUMENT_STATE':
      return action.payload || state;
    case 'ADD_DOCUMENT':
      return {
        ...state,
        documents: [...state.documents, action.payload],
      };
    case 'REMOVE_DOCUMENT':
      return {
        ...state,
        documents: state.documents.filter(
          (e => e.id !== action.payload) as (
            arg0: SectionDocumentDTO
          ) => boolean
        ),
      };
    case 'ADD_SECTION':
      return {
        ...state,
        sections: [...state.sections, action.payload],
      };
    case 'REMOVE_SECTION':
      return {
        ...state,
        sections: state.sections.filter(
          (e => e.id !== action.payload) as (arg0: SectionDTO) => boolean
        ),
      };
    default:
      return state;
  }
};
