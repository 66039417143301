import React from 'react';
import Dropzone from 'react-dropzone';

import BoButton from 'facade/BoButton';

type Props = {
  buttonLabel: string;
  buttonStyle?: Record<string, any>;
  onDrop: (files: File[]) => void;
  accept?: string;
};

function CsvImportDropZone({
  buttonStyle,
  buttonLabel,
  onDrop,
  accept,
}: Props) {
  return (
    <Dropzone
      style={{ width: 'auto', height: 'auto', border: 'none' }}
      accept={
        accept ||
        'text/csv, application/csv, application/vnd.ms-excel, text/plain, .csv'
      }
      /** Note: CSV files are reported as text/plain under macOS, application/vnd.ms-excel under windows.
       In some cases there might not be a mime type set at all. */
      multiple={false}
      onDrop={onDrop}
    >
      <BoButton label={buttonLabel} style={buttonStyle} />
    </Dropzone>
  );
}

export default CsvImportDropZone;
