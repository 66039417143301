import * as React from 'react';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

import attachToForm from 'commons/FormComponent/attachToForm';
import { clearOtherProperties } from 'commons/FormComponent/utils';
import { FormField } from 'commons/FormComponent/types';

interface Props extends FormField<string> {
  name: string;
  validateField: (value: (string | Array<string>) | null) => string | null;
  onValueChange: (newValue: (string | Array<string>) | null) => void;
  items: Array<{ id: string; name: string }>;
  hint?: string;
  multiple?: boolean;
}

class SelectFieldCustom extends React.Component<Props> {
  onChange = (event: any, index: number, newValue: string | Array<string>) => {
    const { onValueChange, validateField } = this.props;
    onValueChange(newValue);
    validateField(newValue);
  };

  render() {
    const {
      name,
      hint,
      error,
      value,
      items,
      multiple,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onValueChange, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validateField, // remove from otherProps
      ...otherProps
    } = this.props;
    clearOtherProperties(otherProps);
    return (
      <SelectField
        {...otherProps}
        key={name}
        value={value}
        onChange={this.onChange}
        floatingLabelText={hint}
        multiple={multiple}
        errorText={error}
      >
        {items &&
          items.map(item => (
            <MenuItem
              key={item.id}
              value={item.id}
              insetChildren={multiple}
              checked={
                multiple && Array.isArray(value) && value.includes(item.id)
              }
              primaryText={item.name ? item.name : item.id}
              style={{
                whiteSpace: 'normal',
                lineHeight: '1.2em',
                padding: '0.6em 0',
              }}
            />
          ))}
      </SelectField>
    );
  }
}

export default attachToForm((p: any) => <SelectFieldCustom {...p} />);
