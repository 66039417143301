import React, { CSSProperties } from 'react';
import moment from 'moment';
import { deepPurple300 } from 'material-ui/styles/colors';
import LinearProgress from 'material-ui/LinearProgress';
import CircularProgress from 'material-ui/CircularProgress';
import IconButton from 'material-ui/IconButton';
import DownloadFileIcon from 'material-ui/svg-icons/file/file-download';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import ErrorIcon from 'material-ui/svg-icons/action/report-problem';

import { BKG_CYAN_SELECTED, BKG_DARK } from 'theme';
import { DownloadDTO } from 'api/backoffice/types';
import { formatNumber } from 'commons/Utils/numberUtil';

import { Resource } from './DownloadList';
import { getExtensionFromDownloadType, LABEL_TO_DOWNLOAD_TAG } from './helpers';

const { _tg } = window.loadTranslations(__filename);

const FRENCH_DATE_TIME_PATTERN = 'DD-MM-YYYY HH:mm';

const STYLE_DOWNLOAD_ITEM: CSSProperties = {
  padding: 6,
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
};

const STYLE_FILENAME: CSSProperties = {
  fontWeight: 'bold',
  width: 380,
  overflowWrap: 'break-word',
};

const STYLE_CENTERED_FIXED_WIDTH = (width: number): CSSProperties => ({
  width,
  textAlign: 'center',
});

type DownloadItemProps = {
  download: DownloadDTO;
  documentsToDelete: Array<Resource>;
  updateDeletionList: (resources: Array<Resource>) => void;
  downloadFile: (res: Resource) => Promise<any>;
};

class DownloadItem extends React.Component<DownloadItemProps> {
  onClickDownload = (): void => {
    const {
      downloadFile,
      download: { resourceId, fileName, downloadType },
    } = this.props;
    void downloadFile({ resourceId, fileName, downloadType });
  };

  handleClickOnDeleteIcon = (): void => {
    const {
      documentsToDelete,
      updateDeletionList,
      download: { resourceId, fileName, downloadType },
    } = this.props;
    if (documentsToDelete.find(d => d.resourceId === resourceId)) {
      updateDeletionList(
        documentsToDelete.filter(d => d.resourceId !== resourceId)
      );
    } else {
      updateDeletionList([
        ...documentsToDelete,
        { resourceId, fileName, downloadType },
      ]);
    }
  };

  render(): React.ReactNode {
    const { download, documentsToDelete } = this.props;
    const {
      progress,
      downloadType,
      fileName,
      size,
      downloadStart,
      downloadDate,
      resourceId,
    } = download;

    const deleteColor = documentsToDelete.find(r => r.resourceId === resourceId)
      ? BKG_CYAN_SELECTED
      : BKG_DARK;
    return (
      <span>
        <div style={STYLE_DOWNLOAD_ITEM}>
          {progress >= 0 && progress < 100 ? (
            <CircularProgress style={{ padding: '0 16px' }} size={20} />
          ) : (
            <IconButton
              title={_tg('Topbar.download.downloadItem.addToDeletionList')}
              style={{ padding: 0 }}
              iconStyle={{ height: 20, width: 20 }}
              data-id={resourceId}
              data-filename={fileName}
              onClick={this.handleClickOnDeleteIcon}
            >
              <DeleteIcon color={deleteColor} />
            </IconButton>
          )}
          <span
            style={STYLE_CENTERED_FIXED_WIDTH(200)}
            title={_tg('Topbar.download.downloadItem.startFileGeneration')}
          >
            {moment(downloadStart).format(FRENCH_DATE_TIME_PATTERN)}
          </span>
          <span style={STYLE_CENTERED_FIXED_WIDTH(200)}>
            {LABEL_TO_DOWNLOAD_TAG[downloadType]}
          </span>
          <span
            title={_tg('Topbar.download.downloadItem.fileName')}
            style={STYLE_FILENAME}
          >
            {fileName}.{getExtensionFromDownloadType(downloadType)}
          </span>
          <span
            style={STYLE_CENTERED_FIXED_WIDTH(100)}
            title={_tg('Topbar.download.downloadItem.fileSize')}
          >
            {progress === 100 && formatNumber(size, 'filesize')}
            {progress < 0 && _tg('Topbar.download.downloadItem.errorProduced')}
          </span>
          {progress === 100 && (
            <span style={STYLE_CENTERED_FIXED_WIDTH(140)}>
              <IconButton
                style={{ padding: 0 }}
                tooltip={
                  downloadDate ? (
                    <div style={{ lineHeight: '14px', padding: '4px 0' }}>
                      {_tg('Topbar.download.downloadItem.download')}
                      <br />
                      {_tg('Topbar.download.downloadItem.alreadyDownloaded', {
                        date: moment(downloadDate).format(
                          FRENCH_DATE_TIME_PATTERN
                        ),
                      })}
                    </div>
                  ) : (
                    _tg('Topbar.download.downloadItem.download')
                  )
                }
                iconStyle={{ height: 32, width: 32 }}
                data-id={resourceId}
                data-filename={fileName}
                onClick={this.onClickDownload}
              >
                <DownloadFileIcon
                  color={downloadDate ? deepPurple300 : BKG_CYAN_SELECTED}
                />
              </IconButton>
            </span>
          )}
          {progress >= 0 && progress < 100 && (
            <span style={STYLE_CENTERED_FIXED_WIDTH(140)} title="avancement">
              {progress} %
            </span>
          )}
          {progress < 0 && (
            <span style={STYLE_CENTERED_FIXED_WIDTH(140)}>
              <ErrorIcon style={{ height: 20, width: 20 }} color="orange" />
            </span>
          )}
        </div>
        {progress >= 0 && progress < 100 && (
          <LinearProgress
            /* title={`${progress} %`} */ style={{ marginTop: 12 }}
            mode="determinate"
            value={progress}
          />
        )}
        <hr />
      </span>
    );
  }
}

export default DownloadItem;
