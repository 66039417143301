import React, { CSSProperties } from 'react';
import InfoIcon from 'material-ui/svg-icons/action/info';
import IconButton from 'material-ui/IconButton';

import BoButton from 'facade/BoButton';
import { BKG_DARK_BLUE, TXT_BLACK } from 'theme';

const { _t } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  height: '100%',
  flexFlow: 'column',
};

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 20,
  marginRight: 20,
  marginTop: 10,
  marginBottom: 10,
};

const STYLE_FIELD: CSSProperties = {
  fontSize: 12,
  flexBasis: 0,
  marginRight: 20,
  color: BKG_DARK_BLUE,
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 20,
  marginRight: 20,
  marginTop: 30,
  marginBottom: 30,
  fontSize: 15,
  color: TXT_BLACK,
};

const STYLE_BUTTON: CSSProperties = {
  marginTop: 30,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
};

const STYLE_ICON: CSSProperties = {
  height: 20,
  width: 20,
};

type Props = {
  hintList: Array<{
    field: string;
    legend: string | null | undefined;
  }>;
  closeList?: () => void;
};

const HintList = ({ hintList, closeList }: Props): JSX.Element => {
  return (
    hintList && (
      <div style={STYLE_CONTAINER}>
        {closeList && (
          <div style={STYLE_BUTTON}>
            <BoButton
              label={_t('element.hideTags.label')}
              onClick={closeList}
            />
          </div>
        )}

        <div style={STYLE_HEADER}>
          <span>{_t('element.tag')}</span>
        </div>
        {hintList.map(item => (
          <div style={STYLE_ROW} key={item.field}>
            <span style={STYLE_FIELD}>{`{${item.field}}`}</span>
            <IconButton
              tooltip={item.legend || ''}
              tooltipPosition="bottom-left"
              iconStyle={STYLE_ICON}
            >
              <InfoIcon color={BKG_DARK_BLUE} />
            </IconButton>
          </div>
        ))}
      </div>
    )
  );
};

export default HintList;
