import { UserNotificationDTO } from '@cvfm-front/tefps-types';

import { apiGet } from '../helpers';

const BASE_PROXY_URL =
  '/api/proxy/notification/api/cities/{cityId}/notifications/v0/subscribers';

export async function fetchUserNotification(
  notificationId: string
): Promise<UserNotificationDTO> {
  return apiGet<UserNotificationDTO>(
    `${BASE_PROXY_URL}?notificationId=${notificationId}`
  );
}
