import querystring from 'querystring';

import { apiDelete, apiGet, apiPut } from 'api/helpers';

import {
  MatchingNatinfSearch,
  NatinfDetail,
  NatinfGroup,
  NatinfListDTO,
} from './types';

const BASE_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/natinfs';

export async function fetchNatinfs(): Promise<NatinfListDTO> {
  return apiGet<NatinfListDTO>(BASE_URL);
}

export async function fetchNatinfDetails(
  natinfId: string
): Promise<NatinfDetail> {
  return apiGet<NatinfDetail>(`${BASE_URL}/${natinfId}`);
}

export async function fetchNatinfGroups(): Promise<Array<NatinfGroup>> {
  return apiGet<Array<NatinfGroup>>(`${BASE_URL}/groups`);
}

export async function createOrUpdateGroup(group: NatinfGroup): Promise<void> {
  return apiPut<void>(`${BASE_URL}/groups/${group.identifier}`, group);
}

export async function removeNatinfsFromGroup(
  identifier: string,
  natinfIds: Array<string>
): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/groups/${identifier}/members`, natinfIds);
}

export async function fetchMatchingNatinfs(
  natinfFilter: string | null | undefined
): Promise<MatchingNatinfSearch> {
  return apiGet<MatchingNatinfSearch>(
    `/api/prv/v0/city/{cityId}/natinfs/match/filter?${querystring.stringify({
      natinfFilter,
    })}`
  );
}
