import React, { CSSProperties } from 'react';
import moment from 'moment';

import { JsonDatetime } from 'api/commonTypes';
import DateComponent from 'commons/Date';

import { getCloseFormatDate } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  datetime: JsonDatetime | null | undefined;
  withYear?: boolean;
  withAlternativeSeparator?: boolean;
  withThe?: boolean;
  style?: CSSProperties;
};

function DateClose({
  datetime,
  withYear,
  withAlternativeSeparator,
  withThe,
  style,
}: Props) {
  const today = moment(Date.now());
  const controlDay = moment(moment(datetime || undefined).format('YYYY-MM-DD'));
  const daysFromNow = today.diff(controlDay, 'days');
  const daysFromNowWord = getCloseFormatDate(daysFromNow);

  if (daysFromNowWord) {
    return (
      <span style={style}>
        {_t('element.daysFromNowWord.content', {
          daysFromNowWord,
          datetime: moment(datetime || undefined).format(
            _t('element.daysFromNowWord.timeFormat')
          ),
        })}
      </span>
    );
  }
  return (
    <>
      {withThe && <>{_tg('field.date.the')} </>}
      <DateComponent
        datetime={datetime}
        withYear={withYear}
        withAlternativeSeparator={withAlternativeSeparator}
        style={style}
      />
    </>
  );
}

export default DateClose;
