import React from 'react';
import Dropzone from 'react-dropzone';
import AddIcon from 'material-ui/svg-icons/content/add';
import CircularProgress from 'material-ui/CircularProgress';

import { BRD_GREY } from 'theme';

type Props = {
  onDrop: (files: File[]) => any;
  accept: string;
  filename: string | null | undefined;
  loading: boolean;
};

const STYLE_DROPZONE = {
  border: `2px dashed ${BRD_GREY}`,
  padding: '10px 50px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: 5,
  margin: '10 2em auto',
};

/*
 ** InputFile:
 ** Render an area to select a file
 */
const InputFile = (props: Props) => {
  const { onDrop, accept, filename, loading } = props;
  const title = filename ? <div>{filename}</div> : <AddIcon />;

  return (
    <Dropzone
      accept={accept}
      multiple={false}
      onDrop={onDrop}
      style={STYLE_DROPZONE}
    >
      {loading ? <CircularProgress size={30} /> : title}
    </Dropzone>
  );
};

export default InputFile;
