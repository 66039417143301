import * as React from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import { AgentRight } from '@cvfm-front/tefps-types';
import { RIGHT_GROUPS } from 'Administration/Profiles/ProfileModal/helpers';
import { getApiState } from 'api/duck';
import MissNotFound from 'commons/MissNotFound';
import TaoSubscriberTablePage from 'tao/Subscriber/table/TaoSubscriberTablePage';
import { Universe } from 'UniverseHandler/duck';
import { getActiveUniverse } from 'UniverseHandler/helpers';
import { MarcheRouter } from 'tao/Marches/MarcheRouter';

import Administration from './Administration';
import Configuration from './Configuration';
import Dashboard from './Dashboard';
import DefaultPage from './DefaultPage';
import DocumentLibrary from './DocumentLibrary';
import TaoSubscriberDetailPage from './tao/Subscriber/detail/TaoSubscriberDetailPage';
import CcspRecourses from './tefps/CcspRecourses';
import Control from './tefps/Control';
import Eligibilities from './tefps/Eligibility';
import Fps from './tefps/Fps';
import Observatory from './tefps/Observatory';
import BillDetail from './tefps/Parking/BillDetail';
import BillRouter from './tefps/Parking/BillTablePage/BillRouter';
import Planner from './tefps/Planner';
import Pricing from './tefps/Pricing';
import RecoursesV2 from './tefps/RecoursesV2';
import SubscriptionRouter from './tefps/Subscription/SubscriptionRouter';
import Tasks from './tefps/Tasks';
import Tickets from './tefps/Tickets';
import PVConfiguration from './tepv/Configuration';
import Decree from './tepv/Decree';
import Devices from './tepv/Devices';
import Gpv from './tepv/Gpv';
import Mif from './tepv/Mif';
import Motif from './tepv/Motif';
import Natinf from './tepv/Natinf';

type Props = {
  activeUniverse: Universe;
  userRights: Array<AgentRight>;
} & RouteComponentProps;

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const Routes = ({ activeUniverse, userRights }: Props): JSX.Element => {
  switch (activeUniverse) {
    case 'fps':
      return (
        <Switch>
          <Route exact path="/" component={DefaultPage} />
          {userRights.includes('BACKOFFICE_TASK') && (
            <Route path="/tasks" component={Tasks} />
          )}
          {userRights.includes('BACKOFFICE_OBSERVATORY') && (
            <Route path="/observatory" component={Observatory} />
          )}
          {RIGHT_GROUPS(true, false)
            .find(group => group.value === 'BACKOFFICE_DASHBOARD')
            ?.rights?.some(right => userRights.includes(right)) && (
            <Route path="/dashboard" component={Dashboard} />
          )}
          {userRights.includes('BACKOFFICE_FPS') && (
            <Route path="/fps" component={Fps} />
          )}
          {userRights.includes('BACKOFFICE_RAPO') && (
            <Route path="/recourses" component={RecoursesV2} />
          )}
          {userRights.includes('BACKOFFICE_CCSP') && (
            <Route path="/tsp" component={CcspRecourses} />
          )}
          {userRights.includes('BACKOFFICE_CONTROL') && (
            <Route path="/controls" component={Control} />
          )}
          {userRights.includes('BACKOFFICE_PLANNER') && (
            <Route path="/planner" render={() => <Planner />} />
          )}
          {userRights.includes('BACKOFFICE_ADMIN') && (
            <Route path="/administration" component={Administration} />
          )}
          <Route path="/configuration" component={Configuration} />
          {userRights.includes('BACKOFFICE_TV') && (
            <Route path="/tickets" component={Tickets} />
          )}
          {userRights.includes('BACKOFFICE_ELIGIBILITY') && (
            <Route path="/eligibilities" component={Eligibilities} />
          )}
          {userRights.includes('BACKOFFICE_SUBSCRIBERS') && (
            <Route path="/subscription" component={SubscriptionRouter} />
          )}
          {userRights.includes('BACKOFFICE_PRICING') && (
            <Route path="/pricing" component={Pricing} />
          )}
          {userRights.includes('BACKOFFICE_BILL') && (
            <Route path="/bill" component={BillRouter} />
          )}
          <Route path="/documents" component={DocumentLibrary} />
          <Route path="/parking/bill/:billId" component={BillDetail} />
          <MissNotFound />
        </Switch>
      );
    case 'pv':
      return (
        <Switch>
          <Route exact path="/" component={DefaultPage} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/mif" component={Mif} />
          <Route path="/gpv" component={Gpv} />
          <Route path="/natinf" component={Natinf} />
          <Route path="/motif" component={Motif} />
          <Route path="/decree" component={Decree} />
          <Route path="/devices" component={Devices} />
          <Route path="/administration" component={Administration} />
          <Route path="/documents" component={DocumentLibrary} />
          <Route path="/configuration" component={PVConfiguration} />
          <MissNotFound />
        </Switch>
      );
    case 'tao':
      return (
        <Switch>
          <Route exact path="/" component={DefaultPage} />
          <Route path="/marches" component={MarcheRouter} />
          <Route path="/administration" component={Administration} />
          <Route path="/documents" component={DocumentLibrary} />
          <Route
            path="/subscribers/:subscriberId"
            component={TaoSubscriberDetailPage}
          />
          <Route path="/subscribers" component={TaoSubscriberTablePage} />
          <MissNotFound />
        </Switch>
      );
    default:
      return <MissNotFound />;
  }
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

export default withRouter(
  connect(state => {
    const activeUniverse = getActiveUniverse(state);
    const userRights = getApiState(state).userInfo?.rights;
    return {
      activeUniverse,
      userRights,
    };
  })(Routes)
);
