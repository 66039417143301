import React, { CSSProperties } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars';

const STYLE_CONTENT: CSSProperties = {
  maxWidth: 1200,
  height: '100%',
  backgroundColor: 'white',
  margin: '0 auto',
  display: 'flex',
  padding: 16,
};

type Props = {
  children?: React.ReactNode;
  style?: Record<string, any>;
};

const Content = ({ children, style }: Props) => {
  return (
    <div style={{ ...STYLE_CONTENT, ...style }}>
      <AutoSizer>
        {({ height, width }) => (
          <Scrollbars style={{ width, height }}>{children}</Scrollbars>
        )}
      </AutoSizer>
    </div>
  );
};

export default Content;
