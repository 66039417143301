/* Small hack because material-ui Select Field do not handle or allow to override value comparison with complex objects
Take a list of object, use their id as comparison value and display their name

 */
import React, { CSSProperties, useRef, useState } from 'react';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import { TextField } from 'material-ui';

type Props = {
  list: any;
  onItemClick: (arg0: any) => void;
  valueName: string | null | undefined;
  floatingLabel: string;
  hintText?: string;
  renderer?: (arg0: any) => string;
  disabled?: boolean;
};

const MENU_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'baseline ',
  cursor: 'pointer',
  width: 'auto',
};

const POPOVER_STYLE: CSSProperties = {
  display: 'inline-block',
};

const DropDownList = ({
  list,
  onItemClick,
  valueName,
  floatingLabel,
  hintText,
  renderer,
  disabled = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const inputEl = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (disabled) {
      return;
    }

    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleItemClick = (elt: any) => {
    handleRequestClose();
    onItemClick(elt);
  };

  const renderMenuItems = () => {
    return list.map((elt: any, index: number) => (
      <MenuItem
        value={elt}
        key={index}
        primaryText={renderer ? renderer(elt) : elt}
        onClick={() => handleItemClick(elt)}
      />
    ));
  };

  return (
    <>
      <div ref={inputEl} onClick={handleClick} style={MENU_STYLE}>
        <TextField
          floatingLabelFixed
          floatingLabelText={floatingLabel}
          hintText={hintText}
          value={valueName || ''}
          style={{ cursor: 'pointer' }}
          disabled={disabled}
          fullWidth
        />
        <MenuIcon />
      </div>
      {inputEl.current && (
        <Popover
          style={POPOVER_STYLE}
          open={open}
          anchorEl={inputEl.current}
          onRequestClose={handleRequestClose}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <Menu>{renderMenuItems()}</Menu>
        </Popover>
      )}
    </>
  );
};

export default DropDownList;
