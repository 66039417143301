import React, { CSSProperties } from 'react';

import { BKG_PINK } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_COMMENT: CSSProperties = {
  border: '1px solid #CACACA',
  borderTop: '0',
  width: '85%',
  color: 'grey',
  fontFamily: 'Roboto',
  borderRadius: '0 0 5px 5px',
  padding: 8,
};

const STYLE_BUTTON: CSSProperties = {
  fontSize: 10,
  textAlign: 'center',
  cursor: 'pointer',
};

const STYLE_BUTTON_HOVER: CSSProperties = {
  ...STYLE_BUTTON,
  color: BKG_PINK,
  fontWeight: 'bold',
};

type Props = {
  children?: React.ReactNode;
  style?: Record<string, any>;
  title?: string;
};

type State = {
  isCollapsed: boolean;
  buttonStyle: Record<string, any>;
};

export default class DataBoxComment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: false,
      buttonStyle: STYLE_BUTTON,
    };
  }

  onClick = () => {
    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed });
  };

  handleMouseOver = () => this.setState({ buttonStyle: STYLE_BUTTON_HOVER });
  handleMouseOut = () => this.setState({ buttonStyle: STYLE_BUTTON });

  render() {
    const { children, style, title } = this.props;
    const { isCollapsed, buttonStyle } = this.state;
    const baseTitle = title || _tg('field.comment');
    const fullTitle = baseTitle + (isCollapsed ? ' ▽' : ' △');
    const showComment = children && !isCollapsed;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', ...style }}>
        <div style={{ ...STYLE_COMMENT }}>
          {showComment && (
            <div style={{ fontSize: 13, marginBottom: 10 }}>{children}</div>
          )}
          <div
            style={buttonStyle}
            onClick={this.onClick}
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}
          >
            {fullTitle}
          </div>
        </div>
      </div>
    );
  }
}
