import React from 'react';
import moment from 'moment';
import DatePicker from 'material-ui/DatePicker';
import TextField from 'material-ui/TextField';

import { DAYS_TYPE } from 'commons/Days';
import attachToForm from 'commons/FormComponent/attachToForm';
import { BKG_PINK } from 'theme';

import { FormField } from '../types';

const { _tg } = window.loadTranslations();

interface DatetimePickerCustomProps extends FormField<Date> {
  id?: string;
  hideTime?: boolean;
  hintDate?: string;
  hintHour?: string;
  styleContainer?: Record<string, any>;
  styleField?: Record<string, any>;
  allowedWeekDays?: DAYS_TYPE[];
  disabled?: boolean;
  mandatory?: boolean;
}

type DatetimePickerCustomState = {
  hours: number;
  minutes: number;
  time: string;
  disabledDays: number[];
};

class DatetimePickerCustom extends React.Component<
  DatetimePickerCustomProps,
  DatetimePickerCustomState
> {
  constructor(props: DatetimePickerCustomProps) {
    super(props);
    const { value, allowedWeekDays } = props;
    const mom = value ? moment(value) : moment();
    const state = this.decomposeTime(mom.format('HH:mm'));
    // Si des jours de stationnements spécifiques ont étés données,
    // on en déduit les jours de la semaine ou le stationnement est interdit
    if (allowedWeekDays) {
      state.disabledDays = this.disableDaysNotIn(allowedWeekDays);
    }
    this.state = state;
  }

  onChangeDate = (e: any, newValue: Date) => {
    const { validateField, onValueChange } = this.props;
    const { hours, minutes } = this.state;
    newValue.setHours(hours);
    newValue.setMinutes(minutes);
    newValue.setSeconds(0);
    onValueChange(newValue);
    validateField(newValue);
  };

  onChangeTime = (e: any, newValue: string | null | undefined) => {
    const { validateField, onValueChange, value } = this.props;
    const newState = this.decomposeTime(newValue);
    this.setState(newState);

    const newDate = value ? new Date(value) : new Date();
    newDate.setHours(newState.hours);
    newDate.setMinutes(newState.minutes);
    newDate.setSeconds(0);
    onValueChange(newDate);
    validateField(newDate);
  };

  disableDaysNotIn = (allowedWeekDays: Array<DAYS_TYPE>): number[] => {
    // Si aucun jour spécifique n'a été donné,
    // on revoie un tableau vide qui indique que tout les jours sont autorisés.
    if (
      allowedWeekDays &&
      Array.isArray(allowedWeekDays) &&
      allowedWeekDays.length
    ) {
      const daysLiteral = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
      ];
      // Le composant 'datepicker' permet de désactiver les jours selon leur index
      // (0 : Dimanche, 6 Samedi). "allowedWeekDays" étant donné sous forme de string
      // (ie : "SUNDAY", "MONDAY"...), on convertit ces valeurs en leur index
      const daysIndex = [0, 1, 2, 3, 4, 5, 6];

      const allowedWeekDaysIndexes = allowedWeekDays.map(day =>
        daysLiteral.indexOf(day)
      );
      const disabledDaysIndexes = daysIndex.filter(
        index => !allowedWeekDaysIndexes.includes(index)
      );

      return disabledDaysIndexes;
    }
    return [];
  };

  // Retourne false si la date en question doit être désactivée
  // (cf disableDaysNotIn)
  shouldDisableDate = (date: Date): boolean => {
    return this.state.disabledDays.includes(date.getDay());
  };

  decomposeTime = (
    value: string | null | undefined
  ): DatetimePickerCustomState => {
    const [hours, minutes] = (value || '00:00').split(':');
    return {
      hours: Number(hours),
      minutes: Number(minutes),
      time: `${hours}:${minutes}`,
      disabledDays:
        this.state && this.state.disabledDays ? this.state.disabledDays : [],
    };
  };

  render() {
    const {
      id,
      value,
      hintDate,
      hintHour,
      error,
      styleContainer,
      styleField,
      hideTime,
      disabled,
      mandatory = false,
    } = this.props;
    const { time } = this.state;
    const mandatoryStar = mandatory && (
      <span style={{ color: BKG_PINK }}>*</span>
    );

    return (
      <div style={styleContainer}>
        <DatePicker
          id={id || 'datepicker'}
          okLabel={_tg('action.ok')}
          cancelLabel={_tg('action.cancel')}
          style={styleField}
          value={value || undefined}
          onChange={this.onChangeDate}
          floatingLabelText={
            <span>
              {hintDate} {mandatoryStar}
            </span>
          }
          textFieldStyle={{ width: '100%' }}
          errorText={error}
          shouldDisableDate={this.shouldDisableDate}
          disabled={disabled}
        />
        {!hideTime && (
          <TextField
            id="timepicker"
            type="time"
            style={styleField}
            value={time}
            errorText={!!error}
            floatingLabelText={hintHour}
            onChange={this.onChangeTime}
          />
        )}
      </div>
    );
  }
}

const AttachedForm = attachToForm((p: any) => <DatetimePickerCustom {...p} />);
export default AttachedForm;
