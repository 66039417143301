import * as React from 'react';
import { connect } from 'react-redux';

import {
  mapSnackbarActionToProps,
  SnackbarReduxDispatchProps,
} from 'commons/Snackbar/ducks/mapper';
import { postSection } from 'api/documentLibrary';

import {
  mapModalsActionToProps,
  mapModalsStateToProps,
  ModalsReduxDispatchProps,
  ModalsReduxStateProps,
} from '../ducks/mapper';
import {
  DocumentLibraryReduxDispatchProps,
  mapDocumentLibraryActionToProps,
} from '../../ducks/mapper';

import { CreateSectionModalContainer } from './CreateSectionModalContainer';

type ReduxStateProps = ModalsReduxStateProps;

type ReduxDispatchProps = ModalsReduxDispatchProps &
  DocumentLibraryReduxDispatchProps &
  SnackbarReduxDispatchProps;

type CreateSectionModalProps = ReduxDispatchProps & ReduxStateProps;

type State = {
  sectionName: string;
};

class CreateSectionModal extends React.Component<
  CreateSectionModalProps,
  State
> {
  constructor(props: CreateSectionModalProps) {
    super(props);
    this.state = { sectionName: '' };
  }

  onSectionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sectionName: e.target.value });
  };

  onCreateSection = async () => {
    const { addSection, closeModals, snackbarSendMessage } = this.props;
    const { sectionName } = this.state;
    try {
      const section = await postSection(sectionName);
      addSection(section);
      closeModals();
      this.setState({ sectionName: '' });
    } catch (e) {
      snackbarSendMessage((e as Error).message);
    }
  };

  render() {
    const { isCreateSectionModalOpen, closeModals } = this.props;
    const { sectionName } = this.state;

    return (
      <CreateSectionModalContainer
        closeModals={closeModals}
        isCreateSectionModalOpen={isCreateSectionModalOpen}
        onCreateSection={this.onCreateSection}
        onSectionNameChange={this.onSectionNameChange}
        sectionName={sectionName}
      />
    );
  }
}

const mapAction = (dispatch: any) => {
  return {
    ...mapDocumentLibraryActionToProps(dispatch),
    ...mapModalsActionToProps(dispatch),
    ...mapSnackbarActionToProps(dispatch),
  };
};

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapModalsStateToProps,
  mapAction
)(CreateSectionModal);
