import querystring from 'querystring';
import FileSaver from 'file-saver';

import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPostRaw,
  apiPut,
  openNewPostAuthentifiedTab,
} from '../helpers';
import { DirectUploadDTO } from '../mediaupload/types';
import { PatchObject } from '../commonTypes';

import {
  ComplementRequestCreationDTO,
  NewCommentDTO,
  RapoDetailDTO,
  RapoTaskWrapper,
  RecourseCreationDTO,
  RecourseDecisionRequestDTO,
  RecourseDTO,
  RecourseFileDTO,
  RecourseInformationDTO,
  RecourseInitCreationDTO,
  RecourseProposalRequestDTO,
  RecourseSearchResultDTO,
  RecourseUser,
} from './types';

const BASE_PROXY_RAPO_URL = '/api/proxy/rapo/api/cities/{cityId}/rapo/v0';
const BASE_URL_BO = '/api/prv/v0/cities/{cityId}/rapo';
const BASE_PROXY_UPLOAD_URL = 'api/proxy/rapo/api/v0/cities/{cityId}/rapo/';

export async function patchRecourse(
  recourseId: string,
  body: Array<
    PatchObject<
      | NewCommentDTO
      | RecourseUser
      | RecourseProposalRequestDTO
      | RecourseDecisionRequestDTO
      | RecourseInformationDTO
      | Array<RecourseFileDTO>
    >
  >
): Promise<RecourseDTO> {
  return apiPatch<RecourseDTO>(`${BASE_PROXY_RAPO_URL}/${recourseId}`, body);
}

export async function fetchFpsRecourses(
  rootFpsId: string
): Promise<RecourseSearchResultDTO> {
  return apiGet<RecourseSearchResultDTO>(
    `${BASE_URL_BO}?rootFpsId=${rootFpsId}`
  );
}

export async function fetchRecourse(rapoId: string): Promise<RapoDetailDTO> {
  return apiGet<RapoDetailDTO>(`${BASE_PROXY_RAPO_URL}/detail/${rapoId}`);
}

export function getRecoursePdfUrl(rapoId: string): string {
  return `${BASE_PROXY_RAPO_URL}/${rapoId}/pdf/recourse`;
}

export async function fetchRecourseFilteredList(
  filterParam: any
): Promise<RecourseSearchResultDTO> {
  return apiGet<RecourseSearchResultDTO>(
    `${BASE_URL_BO}?${querystring.stringify(filterParam)}`
  );
}

export async function fetchRecoursesTasks(): Promise<RapoTaskWrapper> {
  return apiGet<RapoTaskWrapper>(`${BASE_URL_BO}/tasks`);
}

export function getResponseDownloadUrl(
  rapoId: string,
  proposalId: number | null | undefined
): string {
  let url = `/${rapoId}/pdf/answer`;
  if (proposalId != null) {
    url += `?proposalId=${proposalId}`;
  }
  return BASE_PROXY_RAPO_URL + url;
}

export function previewResponseUrl(
  reason: string,
  selectedOrganizationId: string | null | undefined
): string {
  const url = `/${reason}/${selectedOrganizationId}/pdf/answer/preview`;
  return BASE_PROXY_RAPO_URL + url;
}

export function previewRapoAnswerWipUrl(
  rapoId: string,
  reason: string,
  organizationId: string,
  agentComment: string
): string {
  const url = `/${rapoId}/${organizationId}/${reason}/${agentComment}/pdf/answer/preview/wip`;
  return BASE_PROXY_RAPO_URL + url;
}

export function getExport(
  filterParam: Record<string, any>,
  exportParam: any
): Promise<any> {
  return apiGet<any>(
    `${BASE_URL_BO}/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export async function initRecourseCreation(
  rapoId: string | null | undefined = null
): Promise<RecourseInitCreationDTO> {
  const url = rapoId ? `${BASE_URL_BO}?rapoId=${rapoId}` : BASE_URL_BO;
  return apiPost<RecourseInitCreationDTO>(url, undefined);
}

export async function createRecourse(
  rapoId: string,
  body: RecourseCreationDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL_BO}/${rapoId}`, body);
}

export async function getDirectUploadLink(
  rapoId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_PROXY_UPLOAD_URL}/${rapoId}/upload-url?${querystring.stringify({
      mediaType,
    })}`
  );
}

export async function createComplementRequest(
  rapoId: string,
  request: ComplementRequestCreationDTO
): Promise<void> {
  return apiPost<void>(`${BASE_PROXY_RAPO_URL}/${rapoId}/complementRequest`, {
    ...request,
  });
}

export function previewComplementRequest(
  rapoId: string,
  request: ComplementRequestCreationDTO
): void {
  openNewPostAuthentifiedTab(
    `${BASE_PROXY_RAPO_URL}/${rapoId}/pdf/complementRequest/preview/wip`,
    request
  );
}

export function getComplementRequestPdfUrl(rapoId: string): string {
  return `${BASE_PROXY_RAPO_URL}/${rapoId}/pdf/complementRequest`;
}

export async function deleteDocument(
  rapoId: string,
  fileUrn: string
): Promise<void> {
  return apiDelete<void>(
    `${BASE_PROXY_RAPO_URL}/${rapoId}/document/${fileUrn}`
  );
}
