import { useState } from 'react';

import { MAX_RECORDS } from 'commons/const';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { Filters } from 'commons/types/filterbar';

import convertFiltersToRequest from './convertFiltersToRequest';

export type PageFetcher<Request, Response> = {
  totalHits: number;
  list: Array<Response>;
  facetings: CheckboxFaceting;
  fetchWithNewFilter: (filters: Filters) => void;
  fetchNextPage: (
    newState: State<Request, Response> | null | undefined
  ) => Promise<void>;
  convertFiltersToRequest: (filter: Filters) => Request;
  initFilters: (query: Request) => State<Request, Response>;
  updateSortParameters: (sortField: string, sortOrder: SortOrder) => void;
};

type State<Request, Response> = {
  totalHits: number;
  list: Array<Response>;
  pagedQuery: PagedQuery<Request>;
  loading: boolean;
  facetings: CheckboxFaceting;
};

const GenericPageFetcherHookFactory = <Request, Response>(
  fetchPage: (
    request: PagedQuery<Request>
  ) => Promise<EsSearchResponse<Array<Response>>>,
  defaultFilters: Filters,
  defaultSort?: RequestSortParameters
): (() => PageFetcher<Request, Response>) => (): PageFetcher<
  Request,
  Response
> => {
  const setMessage = useSnackbar();

  const [state, setState] = useState<State<Request, Response>>({
    loading: true,
    list: [],
    totalHits: 0,
    pagedQuery: {
      page: 0,
      maxRecords: MAX_RECORDS,
      query: convertFiltersToRequest<Request>(defaultFilters),
      sort: defaultSort,
    },
    facetings: {},
  });

  const { list, totalHits, facetings } = state;

  const save = (newState: State<Request, Response>) => {
    setState(newState);
  };

  // Fetch the next page according to data in state
  // You can pass a state to override the current state
  const fetchNextPage = async (
    overridedState: State<Request, Response> | null | undefined
  ) => {
    const actualState = overridedState || state;
    const { pagedQuery, list: actualList } = actualState;

    try {
      const esSearchResponse = await fetchPage(pagedQuery);

      const nextState = {
        ...actualState,
        list: [...actualList, ...esSearchResponse.response],
        totalHits: esSearchResponse.totalHits,
        pagedQuery: {
          query: pagedQuery.query,
          page: pagedQuery.page + 1,
          maxRecords: MAX_RECORDS,
          sort: pagedQuery.sort,
        },
        facetings: esSearchResponse.checkboxFaceting,
        loading: false,
      };

      save(nextState);
    } catch (e) {
      setMessage(e.message);
    }
  };

  const initFilters = (
    query: Request,
    sort?: RequestSortParameters | null
  ): State<Request, Response> => {
    return {
      ...state,
      pagedQuery: {
        query,
        page: 0,
        maxRecords: MAX_RECORDS,
        sort,
      },
      list: [],
    };
  };

  const fetchWithNewFilter = (filter: Filters) => {
    const newState = initFilters(
      convertFiltersToRequest<Request>(filter),
      state.pagedQuery.sort
    );
    fetchNextPage(newState);
  };

  function updateSortParameters(sortField: string, sortOrder: SortOrder) {
    const newSort: RequestSortParameters = {
      sortOrder,
      sortField,
    };
    fetchNextPage({
      ...state,
      list: [],
      pagedQuery: {
        query: state.pagedQuery.query,
        maxRecords: MAX_RECORDS,
        page: 0,
        sort: newSort,
      },
    });
  }

  return {
    list,
    totalHits,
    fetchWithNewFilter,
    fetchNextPage,
    facetings,
    convertFiltersToRequest,
    initFilters,
    updateSortParameters,
  };
};

export default GenericPageFetcherHookFactory;
