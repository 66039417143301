import React from 'react';
import IconButton from 'material-ui/IconButton';
import AssignmentIcon from 'material-ui/svg-icons/action/assignment';

import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

const { _t } = window.loadTranslations(__filename);

type Props = {
  textToClip: string;
  subject: string;
  plural?: boolean;
  feminine?: boolean;
};

export default function ClipBoardButton(props: Props) {
  const setMessage = useSnackbar();

  function copyToClipBoard() {
    try {
      navigator.clipboard.writeText(props.textToClip);
      const message = _t('element.copied', {
        context: props.feminine ? 'female' : 'male',
        count: props.plural ? 2 : 1,
      });
      setMessage(`${props.subject} ${message}`);
    } catch (error) {
      const errMessage = _t('element.error');
      setMessage(`${errMessage} ${props.textToClip}`);
    }
  }

  return (
    <IconButton
      onClick={copyToClipBoard}
      tooltip={_t('element.tooltip')}
      tooltipPosition="top-center"
      touch
    >
      <AssignmentIcon />
    </IconButton>
  );
}
