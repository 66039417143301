import React, { CSSProperties } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { BKG_GREY_LIGHT, TXT_BLACK } from 'theme';

import CopyValueButton from './CopyValueButton';

const STYLE_INPUT_BLOCK: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0',
};

const STYLE_INPUT_LABEL: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  color: TXT_BLACK,
  flex: 1,
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  color: TXT_BLACK,
  fontFamily: 'Roboto',
  marginLeft: 5,
  padding: 5,
  backgroundColor: BKG_GREY_LIGHT,
};

const STYLE_FAKE_INPUT: CSSProperties = {
  ...STYLE_TEXT,
  lineHeight: '16px',
  width: '50%',
  minHeight: 16,
};

const STYLE_P: CSSProperties = {
  paddingBottom: '0.5em',
  paddingTop: '0.5em',
};

type Props = {
  title: string | React.ReactElement<any>;
  value?: (string | null | undefined) | React.ReactElement<any>;
  containerStyle?: any;
  inputStyle?: any;
  inputScrollbar?: boolean;
  labelStyle?: any;
  copyEnabled?: boolean;
};

const FakeInputBlock = ({
  title,
  value,
  containerStyle,
  inputStyle,
  inputScrollbar,
  labelStyle,
  copyEnabled,
}: Props) => {
  const cStyle = containerStyle
    ? { ...STYLE_INPUT_BLOCK, ...containerStyle }
    : STYLE_INPUT_BLOCK;
  const lStyle = labelStyle
    ? { ...STYLE_INPUT_LABEL, ...labelStyle }
    : STYLE_INPUT_LABEL;
  const iStyle = inputStyle
    ? { ...STYLE_FAKE_INPUT, ...inputStyle }
    : STYLE_FAKE_INPUT;

  if (value === undefined) {
    return (
      <div style={cStyle}>
        <span style={lStyle}>{title}</span>
      </div>
    );
  }

  const element: JSX.Element = copyEnabled ? (
    <p style={{ display: 'inline-flex' }}>
      {value}
      <CopyValueButton
        value={value as string}
        spanStyle={{ marginLeft: 10 }}
        iconStyle={{ height: 15 }}
      />
    </p>
  ) : (
    <p style={STYLE_P}>{value}</p>
  );

  return (
    <div style={cStyle}>
      <span style={lStyle}>{title}</span>
      {inputScrollbar && inputStyle ? (
        <Scrollbars
          style={{
            width: inputStyle.width,
            height: inputStyle.height,
            backgroundColor: BKG_GREY_LIGHT,
          }}
        >
          <div
            style={{ ...iStyle, display: 'flex', height: '85%', width: '95%' }}
          >
            {element}
          </div>
        </Scrollbars>
      ) : (
        <div style={{ ...iStyle }}>{element}</div>
      )}
    </div>
  );
};

export default FakeInputBlock;
