import * as React from 'react';

import { RecourseUser } from 'api/recourse/types';
import { Address } from 'api/commonTypes';

import AddressRenderer from './AddressRenderer';

const RapoAddressRenderer = ({ user }: { user: RecourseUser }) => {
  const address: Address = {
    streetNumber: user.addressStreetNumber,
    streetNumberBis: user.addressStreetNumberBis,
    streetType: user.addressStreetType,
    streetName: user.addressStreetName,
    specialPlace: user.addressComplement,
    postOfficeBoxNumber: null,
    postalCode: user.addressPostalCode,
    addressSubRegion: null,
    addressRegion: null,
    addressLocality: user.addressLocality,
    addressCountry: user.addressCountry,
  } as any;

  return <AddressRenderer address={address} />;
};

export default RapoAddressRenderer;
