import {
  ChartStatisticsResult,
  FpsChartFiltersParam,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

const API_STATS = '/api/proxy/fps/api/v0/cities/{cityId}/admin/stats';

export function searchFpsChartStatistics(
  filters: FpsChartFiltersParam
): Promise<ChartStatisticsResult> {
  return apiPost<ChartStatisticsResult>(`${API_STATS}/charts`, filters);
}
