import querystring from 'querystring';
import { Moment } from 'moment';

import { PatrolZoneDTO } from '@cvfm-front/tefps-types';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';

import {
  AgentPingHistoryDTO,
  AlertCommentAdditionDTO,
  AlertFiltersParam,
  AlertOverviewDTO,
  AlertSearchResultDTO,
  PatrolInstanceDTO,
  PatrolInstanceUpsertDTO,
  PingDetailsDTO,
  PingMapSearchRequest,
  PingPointDTO,
  PlanningConfigurationDTO,
  RouteInstanceDTO,
  RouteInstanceUpdateDTO,
  RoutesGroupDTO,
  RouteTemplateDTO,
} from './types';

const BASE_URL = '/api/proxy/planner/api/prv/cities/{cityId}';
const BASE_URL_V1 = '/api/proxy/planner/api/v1/prv/cities/{cityId}';
const BASE_URL_ALERTS = `${BASE_URL}/alerts/v1`;
const BASE_URL_PATROL_ZONE = `${BASE_URL_V1}/patrolZone`;
const BASE_URL_PATROL_INSTANCE = `${BASE_URL_V1}/patrolInstance`;
const BASE_BO_PING_URL = '/api/prv/v0/city/{cityId}/ping';

export async function upsertRoutesGroup(
  routesGroupId: string,
  body: RoutesGroupDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/routesgroups/v1/${routesGroupId}`, body);
}

export async function fetchRoutesGroups(): Promise<Array<RoutesGroupDTO>> {
  return apiGet<Array<RoutesGroupDTO>>(`${BASE_URL}/routesgroups/v1`);
}

export async function deleteRoutesGroup(id: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/routesgroups/v1/${id}`);
}

export async function fetchPlanningConfiguration(): Promise<
  PlanningConfigurationDTO
> {
  return apiGet<PlanningConfigurationDTO>(`${BASE_URL}/configuration/v1`);
}

export async function upsertPlanningConfiguration(
  configuration: PlanningConfigurationDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/configuration/v1`, configuration);
}

export async function upsertRouteTemplate(
  routeTemplateId: string,
  body: RouteTemplateDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/routetemplates/v1/${routeTemplateId}`, body);
}

export async function fetchRouteTemplates(
  activatedOnly: boolean
): Promise<Array<RouteTemplateDTO>> {
  return apiGet<Array<RouteTemplateDTO>>(
    `${BASE_URL}/routetemplates/v1?${querystring.stringify({ activatedOnly })}`
  );
}

export async function deleteRouteTemplate(id: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/routetemplates/v1/${id}`);
}

export async function fetchTeamRouteInstances(
  teamId: string,
  fromDate: Moment,
  toDate: Moment
): Promise<Array<RouteInstanceDTO>> {
  return apiGet<Array<RouteInstanceDTO>>(
    `${BASE_URL}/routes/v1?${querystring.stringify({
      teamId,
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
    })}`
  );
}

export async function upsertRouteInstance(
  routeInstanceId: string,
  request: RouteInstanceUpdateDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/routes/v1/${routeInstanceId}`, request);
}

export async function fetchCurrentPingMap(
  filter: PingMapSearchRequest
): Promise<Array<PingPointDTO>> {
  return apiPost<Array<PingPointDTO>>(
    `${BASE_BO_PING_URL}/map/current`,
    filter
  );
}

export async function fetchHistoryPingMap(
  filter: PingMapSearchRequest
): Promise<Array<AgentPingHistoryDTO>> {
  return apiPost<Array<AgentPingHistoryDTO>>(
    `${BASE_BO_PING_URL}/map/history`,
    filter
  );
}

export async function fetchPingDetails(
  pingId: string,
  teamId: string | null
): Promise<PingDetailsDTO> {
  return apiGet<PingDetailsDTO>(
    `${BASE_BO_PING_URL}/details/${pingId}?${querystring.stringify({
      teamId,
    })}`
  );
}

export async function searchAlerts(
  query: PagedQuery<AlertFiltersParam>
): Promise<AlertSearchResultDTO> {
  return apiPost<AlertSearchResultDTO>(`${BASE_URL_ALERTS}/_search`, query);
}

export async function addNewComment(
  alertId: string,
  commentContent: AlertCommentAdditionDTO
): Promise<AlertOverviewDTO> {
  return apiPost<AlertOverviewDTO>(
    `${BASE_URL_ALERTS}/${alertId}/comments`,
    commentContent
  );
}

export async function askAlertsExport(
  filters: Record<string, any>,
  columns: { columns: unknown }
): Promise<unknown> {
  return apiPost<unknown>(`${BASE_URL_ALERTS}/_export`, {
    columns: columns.columns,
    filters,
  });
}

// PATROL ZONE
export async function fetchPatrolZones(): Promise<Array<PatrolZoneDTO>> {
  return apiGet<Array<PatrolZoneDTO>>(`${BASE_URL_PATROL_ZONE}`);
}

export async function upsertPatrolZone(
  patrolZoneId: string,
  patrolZoneDTO: PatrolZoneDTO
): Promise<Array<PatrolZoneDTO>> {
  return apiPut<Array<PatrolZoneDTO>>(
    `${BASE_URL_PATROL_ZONE}/${patrolZoneId}`,
    patrolZoneDTO
  );
}

export async function deletePatrolZone(
  patrolZoneId: string
): Promise<Array<PatrolZoneDTO>> {
  return apiDelete<Array<PatrolZoneDTO>>(
    `${BASE_URL_PATROL_ZONE}/${patrolZoneId}`
  );
}

// PATROL INSTANCE
export async function fetchPatrolInstances(
  teamId: string,
  fromDate: Moment,
  toDate: Moment
): Promise<Array<PatrolInstanceDTO>> {
  return apiGet<Array<PatrolInstanceDTO>>(
    `${BASE_URL_PATROL_INSTANCE}?${querystring.stringify({
      teamId,
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
    })}`
  );
}

export async function upsertPatrolInstance(
  patrolInstanceId: string,
  request: PatrolInstanceUpsertDTO
): Promise<void> {
  return apiPut<void>(
    `${BASE_URL_PATROL_INSTANCE}/${patrolInstanceId}`,
    request
  );
}

export async function deletePatrolInstance(
  patrolInstanceId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_URL_PATROL_INSTANCE}/${patrolInstanceId}`);
}
