import { PatchObject } from 'api/commonTypes';
import {
  CoreAddress,
  SubscriberAccountType,
  SubscriberCreationOrUpdateDTO,
  SubscriberDTO,
  SubscriberFilters,
  SubscriberMediaDTO,
  SubscribersSearchResultDTO,
} from '@cvfm-front/tefps-types';
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from 'api/helpers';
import { DirectUploadDTO } from 'api/mediaupload/types';

const BASE_URL = '/api/proxy/subscription/api/prv/cities/{cityId}';
const BASE_MEDIA_URL = '/api/proxy/subscription/api/v0/cities/{cityId}';

export async function getSubscriber(
  subscriberId: string,
  full = false
): Promise<SubscriberDTO> {
  return apiGet<SubscriberDTO>(
    `${BASE_URL}/subscribers/${subscriberId}?full=${full.toString()}`
  );
}

function isAddressEmpty(address: CoreAddress | null): boolean {
  return (
    !!address &&
    (JSON.stringify(address) === JSON.stringify({}) ||
      (!address.complement &&
        !address.locality &&
        !address.postalCode &&
        !address.streetName &&
        !address.streetNumber &&
        !address.streetNumberBis))
  );
}

export async function upsertSubscriber(
  subscriberId: string,
  subscriber: SubscriberCreationOrUpdateDTO
): Promise<any> {
  if (isAddressEmpty(subscriber.professionalAddress)) {
    subscriber.professionalAddress = null;
  }
  if (isAddressEmpty(subscriber.address)) {
    subscriber.address = null;
  }
  return apiPut<unknown>(`${BASE_URL}/subscribers/${subscriberId}`, subscriber);
}

export async function sendSignEmail(subscriberId: string): Promise<void> {
  return apiPut<void>(
    `${BASE_URL}/subscribers/email/registration?subscriberId=${subscriberId}`,
    undefined
  );
}

export async function deleteSubscribers(
  subscriberIds: string[]
): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/subscribers`, subscriberIds);
}

export async function dissociateOpenId(
  subscriberId: string,
  openId: string
): Promise<void> {
  return apiDelete<void>(
    `${BASE_URL}/subscribers/${subscriberId}/openId/${openId}`
  );
}

export async function forgotPassword(email: string): Promise<void> {
  return apiGet<void>(`${BASE_URL}/subscribers/recover/${email}`);
}

export async function searchSubscribers(
  filters: SubscriberFilters
): Promise<SubscribersSearchResultDTO> {
  return apiPost<SubscribersSearchResultDTO>(
    `${BASE_URL}/search/subscribers`,
    filters
  );
}

export async function getEvidenceDirectUploadLink(
  subscriberId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_MEDIA_URL}/subscription/${subscriberId}/upload-url?mediaType=${mediaType}`
  );
}

export async function addSubscriberMedia(
  subscriberId: string,
  subscriberMedia: SubscriberMediaDTO
): Promise<SubscriberDTO> {
  return apiPut<SubscriberDTO>(
    `${BASE_URL}/subscribers/${subscriberId}/evidence`,
    subscriberMedia
  );
}

export async function getFranceConnectReconciliationLink(
  subscriberId: string
): Promise<{ link: string }> {
  return apiGet<{ link: string }>(
    `${BASE_URL}/subscribers/${subscriberId}/reconciliation-link-france-connect`
  );
}

export async function patchSubscriber(
  subscriberId: string,
  body: Array<PatchObject<unknown>>
): Promise<SubscriberDTO> {
  return apiPatch<SubscriberDTO>(
    `${BASE_URL}/subscribers/${subscriberId}`,
    body
  );
}

export function getVehiclesUsed(
  subscriberId: string
): Promise<Map<string, boolean>> {
  return apiGet<Map<string, boolean>>(
    `${BASE_URL}/subscribers/${subscriberId}/vehicles-used`
  );
}

export function deleteProfile(
  subscriberId: string,
  subscriberAccountType: SubscriberAccountType
): Promise<SubscriberDTO> {
  return apiDelete<SubscriberDTO>(
    `${BASE_URL}/subscribers/${subscriberId}/deleteProfile?subscriberAccountType=${subscriberAccountType}`
  );
}
