import { PublicLightSubscriptionPlanDTO } from '@cvfm-front/tefps-types';
import { apiGet } from 'api/helpers';

const PUBLIC_URL = 'api/proxy/subscription/api/cities/{cityId}/subscription';

export async function findSubscriptionPlansDTO(): Promise<
  Array<PublicLightSubscriptionPlanDTO>
> {
  return apiGet<Array<PublicLightSubscriptionPlanDTO>>(`${PUBLIC_URL}/plans`);
}
