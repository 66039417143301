import querystring from 'querystring';

import {
  ControlResponse,
  SubscriptionApplicationDTO,
  ControlRequest,
} from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';
import { AbusiveParkingDTO } from 'tefps/Planner/AbusiveParkingPage/types';

import { apiGet, apiPatch, apiPost, apiPut } from '../helpers';
import { DirectUploadDTO } from '../mediaupload/types';

import {
  ControlDTO,
  ControlMapSearchRequest,
  ControlMapSearchResultDTO,
  ControlMediaDTO,
  ControlSearchResultDTO,
} from './types';

const BASE_BO_URL = '/api/prv/v0/city/{cityId}/control';
const BASE_PROXY_PUBLIC_URL =
  '/api/proxy/control/api/v0/cities/{cityId}/controls';
const BASE_PROXY_ADMIN_URL = `${BASE_PROXY_PUBLIC_URL}/admin`;

export async function fetchControlFilteredList(
  filterParam: any
): Promise<ControlSearchResultDTO> {
  return apiGet<ControlSearchResultDTO>(
    `${BASE_BO_URL}?${querystring.stringify(filterParam)}`
  );
}

export async function fetchControl(controlId: string): Promise<ControlDTO> {
  return apiGet<ControlDTO>(`${BASE_PROXY_ADMIN_URL}/${controlId}`);
}

export function getExport(filterParam: any, exportParam: any): Promise<any> {
  return apiGet<any>(
    `${BASE_BO_URL}/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export function getCombinedExport(
  filterParam: any,
  exportParam: any
): Promise<any> {
  return apiGet<any>(
    `${BASE_BO_URL}/combined/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export async function fetchControlForMap(
  filterParam: ControlMapSearchRequest
): Promise<ControlMapSearchResultDTO> {
  const filter: any = {
    ...filterParam,
    boundingBoxNorthEastLatitude: filterParam.boundingBoxNorthEast.latitude,
    boundingBoxNorthEastLongitude: filterParam.boundingBoxNorthEast.longitude,
    boundingSouthWestLatitude: filterParam.boundingSouthWest.latitude,
    boundingSouthWestLongitude: filterParam.boundingSouthWest.longitude,
  };
  delete filter.boundingBoxNorthEast;
  delete filter.boundingSouthWest;
  return apiGet<ControlMapSearchResultDTO>(
    `${BASE_BO_URL}/map?${querystring.stringify(filter)}`
  );
}

export async function patchControl(
  controlId: string,
  patchList: Array<PatchObject<unknown>>
): Promise<ControlResponse> {
  return apiPatch<ControlResponse>(
    `${BASE_PROXY_PUBLIC_URL}/${controlId}`,
    patchList
  );
}

export async function reprojectControl(controlId: string): Promise<boolean> {
  return apiPost<boolean>(`${BASE_PROXY_ADMIN_URL}/${controlId}/reproject`, {});
}

export async function createControl(
  control: ControlRequest
): Promise<ControlResponse> {
  return apiPost(BASE_PROXY_PUBLIC_URL, control);
}

export async function getDirectUploadLinkControl(
  controlID: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `/api/v0/cities/{cityId}/control/${controlID}/upload-url?${querystring.stringify(
      {
        mediaType,
      }
    )}`
  );
}

export async function pathControlMedia(
  controlID: string,
  patches: Array<PatchObject<ControlMediaDTO>>
) {
  return apiPatch(`${BASE_PROXY_PUBLIC_URL}/${controlID}/`, patches);
}

export async function getAbusiveParking(
  statementStartDatetime: string,
  statementEndDatetime: string,
  minNumberOfControlsKO: number
): Promise<AbusiveParkingDTO[]> {
  return apiGet(
    `${BASE_PROXY_PUBLIC_URL}/abusiveParking?statementStartDatetime=${encodeURIComponent(
      statementStartDatetime
    )}&statementEndDatetime=${encodeURIComponent(
      statementEndDatetime
    )}&minNumberOfControlsKO=${minNumberOfControlsKO}`
  );
}
