import React from 'react';

import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';
import Content from 'commons/Content';

import DefaultSection from './DefaultSection';
import Modals from './Modals';
import SectionCreation from './SectionCreation';
import SectionList from './SectionList/SectionList';
import OneDocumentSection from './OneDocumentSection';

const { _tg } = window.loadTranslations();

type Props = {
  sections: SectionDTO[];
  documents: SectionDocumentDTO[];
  canWrite: boolean;
  canParkingMeterDocumentsWrite: boolean;
  enableParkingMeter: boolean;
};

export const DocumentLibraryContainer = ({
  canWrite,
  canParkingMeterDocumentsWrite,
  sections,
  documents,
  enableParkingMeter,
}: Props): JSX.Element => {
  return (
    <Content style={{ padding: 16, boxSizing: 'border-box' }}>
      <SectionCreation canWrite={canWrite} />
      <SectionList
        canWrite={canWrite}
        sections={sections}
        documents={documents}
      />
      {enableParkingMeter && (
        <>
          <OneDocumentSection
            canWrite={canParkingMeterDocumentsWrite}
            sectionId="PRICELIST"
            sectionName={_tg('tefps.documentLibrary.parkingmeters.pricing')}
            document={documents.find(e => e.sectionId === 'PRICELIST')}
          />
          <OneDocumentSection
            canWrite={canParkingMeterDocumentsWrite}
            sectionId="CVGU"
            sectionName={_tg('tefps.documentLibrary.parkingmeters.conditions')}
            document={documents.find(e => e.sectionId === 'CVGU')}
          />
        </>
      )}
      <DefaultSection />
      <Modals />
    </Content>
  );
};
