import { RapoConfigurationDTO } from '@cvfm-front/tefps-types';

import { apiGet, apiPut } from '../helpers';

const BASE_URL = '/api/proxy/rapo/api/prv/cities/{cityId}';

export async function fetchRapoConfiguration(): Promise<RapoConfigurationDTO> {
  return apiGet<RapoConfigurationDTO>(`${BASE_URL}/configurations/rapo/v1`);
}

export async function upsertRapoConfiguration(
  configuration: RapoConfigurationDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/configurations/rapo/v1`, configuration);
}
