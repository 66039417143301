import { apiPost } from '../helpers';

import { WatchingsDTO } from './types';

const BASE_URL = '/api/prv/cities/{cityId}/watchings/v0/';

export async function iAmWatching(
  resourceType: string,
  resourceId: string
): Promise<WatchingsDTO> {
  return apiPost<WatchingsDTO>(
    `${BASE_URL}${resourceType}/${encodeURIComponent(resourceId)}`,
    undefined
  );
}
