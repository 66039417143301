import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

const { _tg } = window.loadTranslations();

type State = {
  open: boolean;
};

type Props = {
  message: string; // ex: Êtes-vous sûr de vouloir supprimer ce polygone ?
  enabled: boolean; // faux si la suppression est désactivée
  styleWrapper?: CSSProperties;
  dialogStyle?: CSSProperties;
  children?: any;
  isOpen?: boolean;
  onClose?: () => void;
  action: () => any; // action de suppression
};

class ConfirmAction extends React.Component<Props, State> {
  state: State = { open: false };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ isOpen }: Props) {
    if (isOpen) this.setState({ open: isOpen });
  }

  handleOpen = () => {
    const { enabled } = this.props;
    if (enabled) {
      this.setState({ open: true });
    }
  };

  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    if (onClose) onClose();
  };

  handleOk = () => {
    this.setState({ open: false });
    this.props.action();
  };

  render() {
    const { dialogStyle, message, styleWrapper, isOpen, children } = this.props;

    const actions = [
      <FlatButton
        label={_tg('action.cancel')}
        primary
        onClick={this.handleClose}
      />,
      <FlatButton
        label={_tg('field.yes')}
        primary
        keyboardFocused
        onClick={this.handleOk}
      />,
    ];

    return (
      <span title={_tg('action.delete')} style={styleWrapper}>
        {isOpen === undefined ? (
          <span onClick={this.handleOpen}>{children}</span>
        ) : (
          children
        )}
        <Dialog
          style={dialogStyle}
          title={_tg('field.confirmation')}
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Dialog>
      </span>
    );
  }
}

export default ConfirmAction;
