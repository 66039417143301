import { apiGet, apiPut } from '../helpers';

const BASE_RAPO_URL = '/api/proxy/rapo/api/cities/{cityId}/rapo-tags';
const BASE_ORDER_URL =
  '/api/proxy/subscription/api/v2/prv/cities/{cityId}/order-tags';

export type TagsType = 'RAPO' | 'ORDER';
export const TagsTypes: {
  [key in string]: TagsType;
} = {
  rapo: 'RAPO',
  order: 'ORDER',
};

const BaseUrlByType: {
  [key in TagsType]: string;
} = {
  RAPO: BASE_RAPO_URL,
  ORDER: BASE_ORDER_URL,
};

export async function fetchTags(
  type: TagsType,
  orgId: string | null | undefined,
  mandatoryTags: boolean | null | undefined
): Promise<Array<string>> {
  return apiGet<Array<string>>(
    `${BaseUrlByType[type]}?organizationId=${orgId ||
      ''}&mandatoryTags=${Boolean(mandatoryTags).toString()}`
  );
}

export async function upsertTags(
  type: TagsType,
  orgId: string | null | undefined,
  tags: Set<string>
): Promise<void> {
  return apiPut<void>(
    `${BaseUrlByType[type]}?organizationId=${orgId || ''}`,
    Array.from(tags)
  );
}
