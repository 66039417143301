import querystring from 'querystring';

import {
  TeamCreationDTO,
  TeamDTO,
  TeamOperationalType,
  TeamSubscriptionConfiguration,
} from '@cvfm-front/tefps-types';
import { apiDelete, apiGet, apiPatch, apiPost } from 'api/helpers';

import { PatchObject } from '../commonTypes';

const BASE_URL = '/api/proxy/directory/api/prv/cities/{cityId}/teams/v1';
const BASE_BO_TEAMS_URL = '/api/prv/v0/city/{cityId}/teams';

export async function fetchTeams(): Promise<Array<TeamDTO>> {
  return apiGet<Array<TeamDTO>>(BASE_BO_TEAMS_URL);
}

export async function fetchTeamSubscriptionConfigurationByOperationTypeByAgent(
  agentId: string,
  operationalType: TeamOperationalType
): Promise<TeamSubscriptionConfiguration> {
  return apiGet<TeamSubscriptionConfiguration>(
    `${BASE_BO_TEAMS_URL}/subscriptionConfiguration?agentId=${agentId}&operationalType=${operationalType}`
  );
}

export async function fetchControlTeams(): Promise<Array<TeamDTO>> {
  return apiGet<Array<TeamDTO>>(`${BASE_URL}//operationalType/CONTROL`);
}

export async function fetchTeamsForOrganizations(
  organizationIds: Set<string>
): Promise<Array<TeamDTO>> {
  return apiGet<Array<TeamDTO>>(
    `${BASE_BO_TEAMS_URL}?${querystring.stringify({
      organizationIds: Array.from(organizationIds),
    })}`
  );
}

export async function getTeam(teamId: string): Promise<TeamDTO> {
  return apiGet<TeamDTO>(`${BASE_BO_TEAMS_URL}/${teamId}`);
}

export async function deleteTeam(teamId: string): Promise<void> {
  return apiDelete<void>(`${BASE_BO_TEAMS_URL}/${teamId}`);
}

export async function createTeam(request: TeamCreationDTO): Promise<void> {
  return apiPost<void>(`${BASE_URL}`, request);
}

export async function patchTeam(
  teamId: string,
  body: Array<PatchObject<unknown>>
): Promise<void> {
  return apiPatch<void>(`${BASE_URL}/${teamId}`, body);
}
