import {
  ChartStatisticsResult,
  ControlChartFilterParam,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

const BASE_PRV_URL =
  '/api/proxy/control/api/prv/cities/{cityId}/controls/statistics';

export async function searchControlStatistics(
  filters: ControlChartFilterParam
): Promise<ChartStatisticsResult> {
  return apiPost<ChartStatisticsResult>(`${BASE_PRV_URL}/charts`, filters);
}
