import querystring from 'querystring';

import { apiGet, apiPatch, apiPost, fetchJson } from 'api/helpers';
import { Filters } from 'commons/types/filterbar';
import { ColumnConfig } from 'api/commonTypes';

import {
  GpvDetail,
  GpvMapSearchRequest,
  GpvOverviewDTO,
  GpvStatus,
  MatchingGpvSearch,
} from './types';

const BASE_PROXY_GPV_URL =
  '/api/proxy/pv/api/v1/customers/{cityId}/private/gpvs';

export async function fetchGpvFilteredList(
  filterParam: PagedQuery<Filters>
): Promise<EsSearchResponse<Array<GpvOverviewDTO>>> {
  return apiPost<EsSearchResponse<Array<GpvOverviewDTO>>>(
    `${BASE_PROXY_GPV_URL}/_search`,
    filterParam
  );
}

export async function patchGpvStatus(
  gpvId: string,
  status: GpvStatus
): Promise<void> {
  return fetchJson<void>(`${BASE_PROXY_GPV_URL}/status`, 'PATCH', {
    gpvId,
    status,
  });
}

export async function fetchGpvDetails(gpvId: string): Promise<GpvDetail> {
  return apiGet<GpvDetail>(`${BASE_PROXY_GPV_URL}/${gpvId}`);
}

export async function getGpvExport(
  filters: Filters,
  columns: ColumnConfig
): Promise<any> {
  return apiPost<unknown>(`/api/prv/v0/city/{cityId}/gpv/_export`, {
    filters,
    ...columns,
  });
}

export async function getGpvExportDBF(
  filters: Filters,
  columns: ColumnConfig
): Promise<any> {
  return apiPost<unknown>(`/api/prv/v0/city/{cityId}/gpv/_export/dbf`, {
    filters,
    ...columns,
  });
}

export async function searchGpvForMap(
  filters: GpvMapSearchRequest
): Promise<any> {
  const filter = {
    ...filters,
    boundingBoxNorthEastLatitude: filters.boundingBoxNorthEast.latitude,
    boundingBoxNorthEastLongitude: filters.boundingBoxNorthEast.longitude,
    boundingSouthWestLatitude: filters.boundingSouthWest.latitude,
    boundingSouthWestLongitude: filters.boundingSouthWest.longitude,
    zoom: filters.zoom,
  };
  return apiPost<unknown>(`${BASE_PROXY_GPV_URL}/_map`, filter);
}

export async function fetchMatchingGpvs(
  addressFilter: string | null | undefined
): Promise<MatchingGpvSearch> {
  return apiGet<MatchingGpvSearch>(
    `${BASE_PROXY_GPV_URL}/_search-address?${querystring.stringify({
      addressFilter,
    })}`
  );
}

export async function patchComments(
  gpvId: string,
  comments: string
): Promise<void> {
  const patchObject = {
    path: '/comments',
    op: 'add',
    value: comments,
  };
  return apiPatch<void>(
    `/api/proxy/pv/api/v1/customers/{cityId}/gpvs/${gpvId}`,
    [patchObject]
  );
}

export function getGpvPdfUrl(gpvId: string): string {
  return `${BASE_PROXY_GPV_URL}/${gpvId}/pdf`;
}
