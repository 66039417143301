import * as React from 'react';

import PeriodPicker from 'commons/PeriodPicker';
import attachToForm from 'commons/FormComponent/attachToForm';

import { FormField } from '../types';

interface Props extends FormField<string> {
  name: string;
  onChange: (newValue: string | null | undefined) => void;
  hint?: string;
}

class PeriodPickerCustom extends React.Component<Props> {
  onChange = (event: React.FormEvent<any>, period: string) => {
    const { onValueChange } = this.props;
    onValueChange(period);
    this.props.onChange(period);
  };

  render() {
    const {
      name,
      error, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validateField, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onValueChange, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      value,
      ...otherProps
    } = this.props;
    return (
      <PeriodPicker
        {...otherProps}
        key={name}
        value={value || undefined}
        errorText={error || undefined}
        onChange={this.onChange}
      />
    );
  }
}

const AttachedForm = attachToForm((p: any) => <PeriodPickerCustom {...p} />);
export default AttachedForm;
