import React, { CSSProperties, useEffect, useState } from 'react';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import { ImageDropZone, ImagePreview } from 'commons/ImagePreviewAndUpload';
import {
  createMailConfiguration,
  deleteMailConfiguration,
  fetchMailConfigurationList,
  updateMailConfiguration,
} from 'api/mail';
import { BRD_GREY } from 'theme';
import ErrorModal from 'commons/ErrorModal/ErrorModal';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import DropDownList from 'commons/DropDownList';
import {
  MailConfigurationDTO,
  MailType,
  MailTypeRAPO,
} from '@cvfm-front/tefps-types';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';

import MailConfigurationCreationModal from './MailConfigurationCreationModal';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_BACKGROUND: CSSProperties = {
  width: '300px',
  height: '425px',
  border: `1px solid ${BRD_GREY}`,
};
const STYLE_HEADER_FOOTER: CSSProperties = {
  width: '90%',
  height: '90%',
};
const STYLE_SIGNATURE: CSSProperties = {
  width: '90%',
  height: '90%',
};

const STYLE_BUTTON_BAR: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: 30,
};

const STYLE_BUTTON: CSSProperties = {
  marginLeft: 20,
};

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const STYLE_CONFIGURATION: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  mailConfiguration: MailConfigurationDTO | null | undefined;
  onChange: (arg0: MailConfigurationDTO | null | undefined) => void;
  onSave?: () => Promise<void>;
  editable: boolean;
  organizationId?: string | null | undefined;
  mailType: MailType;
};

const MailConfiguration = ({
  mailConfiguration,
  onChange,
  onSave,
  editable,
  organizationId,
  mailType,
}: Props): JSX.Element => {
  const [mailConfigurationList, setMailConfigurationList] = useState<
    Array<MailConfigurationDTO>
  >([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [configurationCreation, setConfigurationCreation] = useState<boolean>(
    false
  );
  const [initError, setInitError] = useState<Error | null | undefined>(null);

  const setMessage = useSnackbar();

  const initMailConfigurationList = async () => {
    try {
      const response = await fetchMailConfigurationList(undefined);
      setMailConfigurationList(response);
    } catch (e) {
      setInitError(e);
    }
  };

  useEffect(() => {
    void initMailConfigurationList();
  }, [mailConfiguration]);

  const handleListChange = (value: any) => {
    onChange(value);
  };

  const handleConfigurationChange = (value: any) => {
    if (value === null) {
      onChange(null);
    } else {
      onChange({ ...mailConfiguration, ...value });
    }
  };

  const addNewMailConfiguration = async (name: string) => {
    let newMailConfiguration;
    try {
      newMailConfiguration = await createMailConfiguration(
        name,
        organizationId
      );
      setConfigurationCreation(false);
      setMailConfigurationList([
        ...mailConfigurationList,
        { ...newMailConfiguration },
      ]);
      onChange(newMailConfiguration);
      setMessage(_t('feedback.success.createNewConfig'));
    } catch (e) {
      throw new Error(e.json.message);
    }
  };

  const deleteConfiguration = async () => {
    try {
      if (mailConfiguration) {
        await deleteMailConfiguration(mailConfiguration.id);
        onChange(null);
        setMessage(_t('feedback.success.configDeleted'));
      } else {
        throw new Error();
      }
    } catch (e) {
      setMessage(_t('feedback.error.deleteConfig'));
    }
  };

  const onDrop = (files: Array<File>, field: string) => {
    // Read file content
    const reader = new FileReader();
    reader.onload = () => {
      handleConfigurationChange({
        [field]: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  };

  const onDelete = (field: string) => {
    handleConfigurationChange({
      [field]: null,
    });
  };

  const onDropBackground = (files: Array<File>) =>
    onDrop(files, 'pageBackground');
  const onDropHeader = (files: Array<File>) => onDrop(files, 'header');
  const onDropFooter = (files: Array<File>) => onDrop(files, 'footer');
  const onDropSignature = (files: Array<File>) => onDrop(files, 'signature');

  const onDeleteBackground = () => onDelete('pageBackground');
  const onDeleteHeader = () => onDelete('header');
  const onDeleteFooter = () => onDelete('footer');
  const onDeleteSignature = () => onDelete('signature');

  const onConfigurationNameChange = (
    event: React.ChangeEvent<any>,
    newValue: string
  ) => {
    handleConfigurationChange({
      name: newValue,
    });
  };

  const onConfigurationSave = async () => {
    try {
      if (editable) {
        if (!mailConfiguration) {
          throw new Error();
        }
        const updatedMailConfiguration = await updateMailConfiguration(
          mailConfiguration
        );
        onChange({ ...updatedMailConfiguration });
      } else if (onSave) {
        await onSave();
      }
      setMessage(_t('feedback.success.newConfigSaved'));
    } catch (e) {
      setError(true);
      setErrorMessage(e.message);
    }
  };

  const onErrorClose = () => {
    setError(false);
    setErrorMessage('');
  };

  if (initError) {
    return (
      <FlexCenter>
        <ErrorBlock error={initError} />
      </FlexCenter>
    );
  }

  return (
    <div style={{ position: 'relative', padding: 20, fontFamily: 'Roboto' }}>
      <div style={STYLE_ROW}>
        <DropDownList
          list={mailConfigurationList}
          valueName={mailConfiguration ? mailConfiguration.name : ''}
          onItemClick={handleListChange}
          renderer={(mailConf: MailConfigurationDTO) => mailConf.name}
          floatingLabel={_t('element.mailConfigurationList.floatingLabel')}
        />
        {editable && (
          <div>
            <BoButton
              label={_t('element.setConfigurationCreation.label')}
              primary
              onClick={() => setConfigurationCreation(true)}
            />
            {mailConfiguration && (
              <BoButton
                label={_t('element.deleteConfiguration.label')}
                secondary
                onClick={deleteConfiguration}
                style={STYLE_BUTTON}
              />
            )}
          </div>
        )}
      </div>

      {mailConfiguration && (
        <div style={STYLE_CONFIGURATION}>
          {editable && (
            <TextField
              hintText={_t('element.mailConfigurationName.hintText')}
              floatingLabelText={_t(
                'element.mailConfigurationName.floatingLabelText'
              )}
              value={mailConfiguration.name || ''}
              onChange={onConfigurationNameChange}
            />
          )}
          <ImagePreview
            key="header"
            img={mailConfiguration.header}
            title={_t('element.mailConfigurationHeader.title')}
            style={STYLE_HEADER_FOOTER}
            deleteImage={editable ? onDeleteHeader : undefined}
          />
          <ImagePreview
            key="footer"
            img={mailConfiguration.footer}
            title={_t('element.mailConfigurationFooter.title')}
            style={STYLE_HEADER_FOOTER}
            deleteImage={editable ? onDeleteFooter : undefined}
          />
          <ImagePreview
            key="background"
            img={mailConfiguration.pageBackground}
            title={_t('element.mailConfigurationPageBackground.title')}
            style={STYLE_BACKGROUND}
            deleteImage={editable ? onDeleteBackground : undefined}
          />
          {mailType === MailTypeRAPO.RAPO_COMPLEMENT && (
            <ImagePreview
              key="signature"
              img={mailConfiguration.signature}
              title={_t('element.mailConfigurationSignature.title')}
              style={STYLE_SIGNATURE}
              deleteImage={editable ? onDeleteSignature : undefined}
            />
          )}
          <div style={STYLE_BUTTON_BAR}>
            {editable && (
              <>
                <ImageDropZone
                  onDrop={onDropHeader}
                  legend={_t('element.importHeader.legend')}
                />
                <ImageDropZone
                  onDrop={onDropFooter}
                  legend={_t('element.importFooter.legend')}
                />
                <ImageDropZone
                  onDrop={onDropBackground}
                  legend={_t('element.importBackground.legend')}
                />
                <ImageDropZone
                  onDrop={onDropSignature}
                  legend={_t('element.importSignature.legend')}
                />
              </>
            )}
            <BoButton
              label={_tg('action.save_1')}
              primary
              onClick={onConfigurationSave}
            />
          </div>
        </div>
      )}
      <div style={STYLE_ROW} />
      <MailConfigurationCreationModal
        open={configurationCreation}
        onClose={() => setConfigurationCreation(false)}
        onCreate={addNewMailConfiguration}
      />
      <ErrorModal open={error} onClose={onErrorClose} message={errorMessage} />
    </div>
  );
};

export default MailConfiguration;
