import * as React from 'react';
import { connect } from 'react-redux';

import {
  mapSnackbarActionToProps,
  SnackbarReduxDispatchProps,
} from 'commons/Snackbar/ducks/mapper';
import {
  fetchSections,
  getSectionDocumentsByCityId,
} from 'api/documentLibrary';
import { getApiState } from 'api/duck';
import { AgentRight } from '@cvfm-front/tefps-types';

import { getConfigState } from '../config/duck';

import { DocumentLibraryContainer } from './DocumentLibraryContainer';
import {
  DocumentLibraryReduxDispatchProps,
  DocumentLibraryReduxStateProps,
  mapDocumentLibraryActionToProps,
  mapDocumentLibraryStateToProps,
} from './ducks/mapper';

type DocumentLibraryProps = DocumentLibraryReduxStateProps &
  DocumentLibraryReduxDispatchProps &
  SnackbarReduxDispatchProps & {
    // Redux props from api
    userRights: [AgentRight];
    enableParkingMeter: boolean;
  };

class DocumentLibrary extends React.Component<DocumentLibraryProps> {
  async componentDidMount() {
    const { initState, snackbarSendMessage } = this.props;

    try {
      const sections = await fetchSections();
      const documents = await getSectionDocumentsByCityId();

      initState(sections, documents);
    } catch (e) {
      snackbarSendMessage((e as Error).message);
    }
  }

  render() {
    const { sections, documents, userRights, enableParkingMeter } = this.props;
    const canWrite = userRights.includes('SECTION_EDIT');
    const canParkingMeterDocumentsWrite = userRights.includes(
      'PARKING_METER_DOCUMENTS_WRITE'
    );

    return (
      <DocumentLibraryContainer
        sections={sections}
        documents={documents}
        canWrite={canWrite}
        canParkingMeterDocumentsWrite={canParkingMeterDocumentsWrite}
        enableParkingMeter={enableParkingMeter}
      />
    );
  }
}

const mapUserRightsAndConfigToProps = (state: any) => {
  const { modulesConfiguration, enableParkingMeter } = getConfigState(state);
  return {
    userRights: getApiState(state).userInfo?.rights || [],
    // if both parking meter module and parking meter in config are activated
    enableParkingMeter:
      enableParkingMeter && modulesConfiguration.parkingMeter.enabled,
  };
};

const mapState = (state: any) => ({
  ...mapUserRightsAndConfigToProps(state),
  ...mapDocumentLibraryStateToProps(state),
});

const mapAction = (dispatch: any) => ({
  ...mapDocumentLibraryActionToProps(dispatch),
  ...mapSnackbarActionToProps(dispatch),
});

export default connect(mapState, mapAction)(DocumentLibrary);
