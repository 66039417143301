import React from 'react';
import _debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';

import { Coordinates } from 'api/commonTypes';
import { formatNumber } from 'commons/Utils/numberUtil';
import { MapZoning } from 'api/pricing/types';
import { signGoogleMapUrl, BASE_GOOGLE_MAPS_URL } from 'api/url-signer';

import { drawPolygons } from '../ZoningComponents/helpers';

import { Marker, Position } from './types';

const toCoordinates = (coordinates: google.maps.LatLng) => ({
  latitude: coordinates.lat(),
  longitude: coordinates.lng(),
});

type MapProps = {
  style: Record<string, any>;
  center: Position | null;
  markers: Array<Marker> | null | undefined;
  displayAsClusters: boolean;
  updateFilters?: (
    boundingBoxNorthEast: Coordinates,
    boundingSouthWest: Coordinates,
    zoom: number
  ) => void;
  markerIcons?: Map<string, string>;
  onClick?: (id: string) => any;
  zonings?: MapZoning | null;
  displayZoning?: boolean;
  mapLoadingCallback?: Function;
  zoom?: number;
};

class ClusterMap extends React.Component<MapProps> {
  refMap: HTMLDivElement | undefined = undefined;
  map: google.maps.Map | null | undefined = null;
  markerCluster: MarkerClusterer | null | undefined = null;
  debouncedUpdateBoundingBox: (...args: any[]) => void; // eslint-disable-line react/sort-comp
  polygons: Array<google.maps.Polygon> = [];

  constructor(props: MapProps) {
    super(props);
    this.debouncedUpdateBoundingBox = _debounce(this.updateBoundingBox, 500);
  }

  componentDidMount(): void {
    // Connect the initMap() function within this class to the global window context,
    // so Google Maps can invoke it
    window.initMap = this.initMap;
    // Asynchronously load the Google Maps script, passing in the callback reference
    void this.loadJS(
      `${BASE_GOOGLE_MAPS_URL}/maps/api/js?callback=initMap`,
      this.initMap,
      false
    );
    void this.loadJS('/static/lib/markercluster.js', this.initMap, true);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({
    displayAsClusters,
    center,
    markers,
  }: MapProps): void {
    if (!isEqual(markers, this.props.markers)) {
      this.drawMap({ displayAsClusters, center, markers });
    }
  }

  shouldComponentUpdate(): boolean {
    return false; // render the component only on mount, then let google.Map do the job
  }

  updateBoundingBox = (): void => {
    const { map } = this;
    if (!map) return;
    const bounds = map.getBounds();
    if (!bounds) return;
    const { updateFilters } = this.props;
    if (updateFilters) {
      updateFilters(
        toCoordinates(bounds.getNorthEast()),
        toCoordinates(bounds.getSouthWest()),
        map.getZoom() || 12
      );
    }
  };

  createRefMap = (node: HTMLDivElement): void => {
    this.refMap = node;
  };

  loadJS = async (
    src: string,
    callback: () => void | null | undefined,
    addLoadCallback: boolean
  ): Promise<void> => {
    const script = window.document.createElement('script');
    let signedSrc = '';
    if (addLoadCallback) {
      signedSrc = src;
    } else {
      const signedUrl = await signGoogleMapUrl(src);
      signedSrc = BASE_GOOGLE_MAPS_URL + signedUrl.url;
    }
    script.src = signedSrc;
    script.async = true;

    // avoid adding the same script a second time in the DOM
    const existingScripts = window.document.getElementsByTagName('script');
    for (let i = 0, l = existingScripts.length; i < l; i += 1) {
      if (existingScripts[i].getAttribute('src') === signedSrc) {
        // If the script has already been loaded, we still need to run the callback otherwise nothing loads
        if (callback != null) {
          callback();
        }
        return;
      }
    }

    if (addLoadCallback) {
      script.addEventListener('load', callback);
    }
    window.document.body.appendChild(script);
  };

  togglePolygonVisibility = (visible: boolean): void => {
    this.polygons.forEach(polygon => polygon.setVisible(visible));
  };

  initMap = (): void => {
    const { mapLoadingCallback } = this.props;
    this.drawMap(this.props);
    if (mapLoadingCallback) {
      mapLoadingCallback();
    }
  };

  public updateMapCenter(center: Position) {
    if (this.map && center) {
      this.map.setCenter(center);
    }
  }

  public drawMap({
    displayAsClusters,
    center,
    markers,
  }: {
    center: Position | null;
    markers: Array<Marker> | null | undefined;
    displayAsClusters: boolean;
  }): void {
    if (
      typeof google === 'undefined' ||
      typeof google.maps.Map === 'undefined' ||
      typeof MarkerClusterer === 'undefined'
    ) {
      return;
    }
    if (!this.map && this.refMap) {
      const { zoom } = this.props;
      this.map = new google.maps.Map(this.refMap, {
        zoom: zoom || 12,
        center: center || { lat: 0.0, lng: 0.0 },
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });
      // Lorsque la fenêtre change (bounds_changed) et si la dernière requête date de plus de 500ms,
      // on effectue une nouvelle requête (cela permet d'éviter des requêtes inutiles lors de déplacements courts sucessifs).
      google.maps.event.addListener(
        this.map,
        'bounds_changed',
        this.debouncedUpdateBoundingBox
      );
    }

    const { markerIcons, onClick } = this.props;

    const newMarkers: google.maps.Marker[] = (markers || []).map(marker => {
      const gMarker = new google.maps.Marker({
        position: marker.position,
        title: marker.title,
        icon:
          markerIcons && marker.status
            ? markerIcons.get(marker.status)
            : '/static/img/map/circles/circle_ko.png',
      });
      const { id: markerId } = marker;
      if (onClick && markerId) {
        google.maps.event.addListener(gMarker, 'click', () => {
          onClick(markerId);
        });
      }
      // On passe un paramètre supplémentaire pour chaque marqueur.
      // Cela va permettre d'afficher le nombre de fps contenus dans un cluster.
      // (sinon, un cluster n'ayant qu'une coordonnée, il serait considéré comme un point unique)
      gMarker.count = marker.count ? marker.count : 1;
      return gMarker;
    });

    const style = {
      textColor: 'white',
      textSize: 14,
      backgroundPosition: null,
    };

    const { zonings, displayZoning } = this.props;
    if (zonings && this.polygons.length < 1) {
      drawPolygons(zonings, this.map, this.polygons, !!displayZoning);
    }

    if (!markers) {
      return;
    }

    const { markerCluster } = this;
    if (markerCluster) {
      markerCluster.clearMarkers();
      markerCluster.setMinClusterSize(displayAsClusters ? 1 : 10);
      markerCluster.addMarkers(newMarkers);
    } else if (this.map) {
      this.markerCluster = new MarkerClusterer(this.map, newMarkers, {
        textFormatter: (number: number) => {
          return formatNumber(number);
        },
        styles: [
          {
            url: '/static/img/map/clusters/cluster_1.png',
            width: 40,
            height: 40,
            ...style,
          },
          {
            url: '/static/img/map/clusters/cluster_2.png',
            width: 56,
            height: 56,
            ...style,
          },
          {
            url: '/static/img/map/clusters/cluster_3.png',
            width: 56,
            height: 56,
            ...style,
          },
          {
            url: '/static/img/map/clusters/cluster_4.png',
            width: 70,
            height: 70,
            ...style,
          },
          {
            url: '/static/img/map/clusters/cluster_5.png',
            width: 70,
            height: 70,
            ...style,
          },
        ],
      });
      this.markerCluster.setMinClusterSize(displayAsClusters ? 1 : 10);
    }
  }

  render(): React.ReactElement {
    const { style } = this.props;
    return <div ref={this.createRefMap} style={style} />;
  }
}

export default ClusterMap;
