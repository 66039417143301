import { apiPost } from '../helpers';

import { LocalizedAddressDTO } from './types';

const BASE_PROXY_GEOCODING_URL = 'api/proxy/geocoder/api/v0/geocoding';

export async function findAddress(
  currentResearch: string,
  restrictByDepartment?: boolean
): Promise<Array<LocalizedAddressDTO>> {
  const temp = apiPost<Array<LocalizedAddressDTO>>(
    `${BASE_PROXY_GEOCODING_URL}/address/search?restrictByDepartment=${
      restrictByDepartment ? 'true' : 'false'
    }`,
    currentResearch
  );
  return temp;
}
