import React from 'react';
import { connect } from 'react-redux';

import { updateDownloadsList } from './duck';
import Notification from './Notification';
import DownloadList from './DownloadList';

type State = { open: boolean };

type Props = { dispatchUpdateDownloadsList: () => void };

const INITIAL_STATE = { open: false };

class Download extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  openDownloadList = () => {
    const { dispatchUpdateDownloadsList } = this.props;
    dispatchUpdateDownloadsList();
    this.setState({ open: true });
  };

  closeDownloadList = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    return (
      <div>
        <Notification openDownloadList={this.openDownloadList} />
        <DownloadList open={open} closeDownloadList={this.closeDownloadList} />
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  dispatchUpdateDownloadsList: () => dispatch(updateDownloadsList()),
}))(Download);
