import { Bill } from '@cvfm-front/tefps-types';
import { apiGet, apiPatch } from 'api/helpers';

import { BillVersionPatchDTO } from './type';

const BASE_URL = '/api/proxy/parking/v1/parking/{cityId}/bills';

export async function getBillById(billId: string): Promise<Bill> {
  return apiGet<Bill>(`${BASE_URL}/${billId}`);
}

export async function putBill(
  billId: string,
  patches: BillVersionPatchDTO[]
): Promise<Bill> {
  return apiPatch<Bill>(`${BASE_URL}/${billId}`, patches);
}
