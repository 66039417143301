import urljoin from 'url-join';
import { Store } from 'redux';

import {
  getAuthLocalStore,
  setAuthLocalStore,
  clearAuthLocalStore,
} from 'Login/auth-storage';
import downloadFile from 'commons/DownloadFile';

import { ApiError } from './ApiError';
import { ApiState, logoutAction, refreshed } from './duck';
import { PatchObject } from './commonTypes';
import { BackofficeAuthResponseDTO } from './auth/types';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

// TODO: better type
let store: any = null;

function waitForAccessToken(): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, 325);
  });
}

function getApiState(): ApiState {
  if (!store) {
    throw new Error('ShouldNotHappen');
  }

  return store.getState().api;
}

function fetchOrFail(
  url: string,
  options: Record<string, any>
): Promise<Response> {
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          const apiError = new ApiError();
          apiError.status = response.status;
          apiError.url = url;
          apiError.name = 'ApiError';
          apiError.message = `${response.status} code when requesting ${url}`;
          response
            .json()
            .then((jsonContent: Record<string, unknown>) => {
              apiError.json = jsonContent;
              if ('error' in jsonContent) {
                apiError.name = jsonContent.error as string;
              }
              if ('message' in jsonContent) {
                apiError.message = jsonContent.message as string;
              }
            })
            .catch(() => {
              // Ignore
            })
            .finally(() => {
              reject(apiError);
            });
        }
      })
      .catch((fetchError: Error) => {
        const apiError = new ApiError(fetchError.message);
        reject(apiError);
      });
  });
}

export function getHostname(): string {
  return getApiState().hostname;
}

export function buildUrl(url: string): string {
  return urljoin(getHostname(), url.replace('{cityId}', getApiState().cityId));
}

async function fetchJsonUnauthenticated(
  url: string,
  method: HttpMethod = 'GET',
  body?: any
): Promise<any> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json;charset=UTF-8');
  headers.set('Accept', 'application/json');

  const options = {
    method,
    headers,
    body: body && JSON.stringify(body),
  };

  return fetchOrFail(buildUrl(url), options).then(res => res.json());
}

export async function apiGetUnauthenticated(url: string): Promise<any> {
  return fetchJsonUnauthenticated(url, 'GET');
}

export async function apiPostUnauthenticated(
  url: string,
  body: any
): Promise<any> {
  return fetchJsonUnauthenticated(url, 'POST', body);
}

export const refresh = async () => {
  const authStored = getAuthLocalStore();
  const apiState = getApiState();

  if (authStored.accessToken && apiState) {
    const credentials = { refreshToken: authStored.refreshToken };
    try {
      const refreshResponse: BackofficeAuthResponseDTO = await apiPostUnauthenticated(
        `/api/prv/auth/v1/cities/${apiState.cityId}/token`,
        credentials
      );

      // Update AuthLocalStorage
      const expiration = new Date();
      expiration.setSeconds(
        expiration.getSeconds() + refreshResponse.expiresIn
      );
      const authToStore = {
        accessToken: refreshResponse.accessToken,
        refreshToken: refreshResponse.refreshToken,
        expiresAt: expiration,
      };
      setAuthLocalStore(authToStore);

      store.dispatch(refreshed(refreshResponse.scope));
    } catch (refreshError) {
      // Well, we tried...
      // eslint-disable-next-line no-console
      console.info('Unable to refresh token > logging out');
      clearAuthLocalStore();
      store.dispatch(logoutAction());
    }
  }
};

// There might be a drift between the browser and the server, we have some leeway to take this effect into account
const LEEWAY = 30; // 30 seconds before expiration, we will refresh
const autoRefresh = async () => {
  const { refreshToken, expiresAt } = getAuthLocalStore();

  if (refreshToken && expiresAt) {
    const leewayExpires = new Date(expiresAt);
    leewayExpires.setSeconds(leewayExpires.getSeconds() - LEEWAY);
    if (Date.now() > leewayExpires.getTime()) {
      // eslint-disable-next-line no-console
      console.info('Token almost expired > starting refresh');
      await refresh();
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setTimeout(autoRefresh, 15 * 1000);
};

export const registerStore = (registered: Store<unknown>) => {
  store = registered;
  void autoRefresh();
};

async function fetchAuthenticated(
  url: string,
  options: { method: string; headers: Headers; body: any },
  counter = 0
): Promise<Response> {
  if (counter > 3) {
    throw new Error('User is disconnected');
  }
  if (counter > 0) {
    // eslint-disable-next-line no-console
    console.info(`Retry request #${counter} after invalid_token`);
  }

  let authStored = getAuthLocalStore();
  if (!authStored.accessToken) {
    // Wait a bit in case the token was updating
    // eslint-disable-next-line no-console
    console.info(`Request ${url} waiting for access token`);
    await waitForAccessToken();
    authStored = getAuthLocalStore();
  }

  // Set bearer token header
  options.headers.set('Authorization', `Bearer ${authStored.accessToken}`);

  // Perform request
  try {
    const res = await fetchOrFail(buildUrl(url), options);
    return res;
  } catch (err) {
    if (err.status === 401 || err.status === 403) {
      if (err.json.error === 'invalid_token') {
        return fetchAuthenticated(url, options, counter + 1);
      }
    }
    throw err;
  }
}

async function fetchRaw(
  url: string,
  method: HttpMethod = 'GET',
  body?: any
): Promise<Response> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  const options = {
    method,
    headers,
    body: body && JSON.stringify(body),
  };

  return fetchAuthenticated(url, options);
}

export async function fetchJson<T>(
  url: string,
  method: HttpMethod = 'GET',
  body?: any
): Promise<T> {
  const res = await fetchRaw(url, method, body);
  const contentType = res.headers.get('Content-Type');

  // Handle empty responses
  // Spring doesn't put Content-type when body is empty
  if (res.headers.get('Content-Length') === '0') {
    return {} as T;
  }

  // Non empty reponses with wrong Content-Type
  if (!contentType || contentType.indexOf('application/json') === -1) {
    const apiError = new ApiError(
      `Expected application/json but got ${contentType ?? 'None'}`
    );
    apiError.name = 'Invalid contentType';
    throw apiError;
  }

  // Valid Json response
  return (res.json() as unknown) as T;
}

export function apiGet<T>(url: string): Promise<T> {
  return fetchJson<T>(url, 'GET');
}

export async function apiGetRaw(url: string): Promise<Response> {
  return fetchRaw(url, 'GET');
}

export async function apiPatch<T>(
  url: string,
  body: Array<PatchObject<unknown>> | PatchObject<unknown> | any
): Promise<T> {
  return fetchJson<T>(url, 'PATCH', body);
}

export async function apiPost<T>(url: string, body: unknown): Promise<T> {
  return fetchJson<T>(url, 'POST', body);
}

export async function apiPostRaw(
  url: string,
  body: unknown
): Promise<Response> {
  return fetchRaw(url, 'POST', body);
}

export async function apiPut<T>(url: string, body: any): Promise<T> {
  return fetchJson<T>(url, 'PUT', body);
}

export async function apiDelete<T>(url: string, body?: unknown): Promise<T> {
  return fetchJson<T>(url, 'DELETE', body);
}

export async function apiDeleteRaw(
  url: string,
  body?: unknown
): Promise<Response> {
  return fetchRaw(url, 'DELETE', body);
}

export async function apiPostFile(url: string, file: File): Promise<Response> {
  const headers = new Headers();
  const buffer = await file.arrayBuffer();
  return fetchAuthenticated(url, {
    method: 'POST',
    headers,
    body: buffer,
  });
}

export async function downloadFileFromUrl(
  url: string,
  filename: string
): Promise<void> {
  const headers = new Headers();
  const res = await fetchAuthenticated(url, {
    method: 'GET',
    headers,
    body: undefined,
  });
  const blob = await res.blob();
  const file = new File([blob], filename);
  downloadFile(file);
}

export async function apiPostCSV<T>(url: string, body: unknown): Promise<T> {
  const headers = new Headers();
  headers.set('Content-Type', 'text/csv;charset=UTF-8');
  const res = await fetchAuthenticated(url, {
    method: 'POST',
    headers,
    body,
  });

  return (res.json() as unknown) as T;
}

export function openNewAuthentifiedTab(url: string, filename?: string): void {
  void apiGetRaw(url)
    .then(response => response.blob())
    .then(blob => {
      if (filename) {
        // opens in new tab with forced download and given filename
        const _url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = _url;
        a.download = `${filename}.pdf`;
        a.click();
      } else {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank')?.focus(); // window.open + focus
      }
    });
}

export function openNewPostAuthentifiedTab(
  url: string,
  body: any,
  filename?: string
): void {
  void apiPostRaw(url, body)
    .then(response => response.blob())
    .then(blob => {
      if (filename) {
        // opens in new tab with forced download and given filename
        const _url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = _url;
        a.download = `${filename}.pdf`;
        a.click();
      } else {
        // RETRIEVE THE BLOB AND CREATE LOCAL URL
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank')?.focus(); // window.open + focus
      }
    });
}
