import React from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { connect } from 'react-redux';

import {
  initialFilters as controlInitialFilters,
  strategyFiltersChanged as setControlFilters,
} from '../Dashboard/fps/ControlStrategy/duck';
import { MapFilters as ControlMapFilters } from '../Dashboard/fps/ControlStrategy/types';

import MapLinkButton from './MapLinkButton';
import { computeDatesWithPeriod } from './DateFilter';

const { _t } = window.loadTranslations(__filename);

type ReduxDispatchProps = {
  updateControlFilters: (newFilters: ControlMapFilters) => void;
};

type OwnProps = {
  controlId?: string;
  fineId?: string;
  history?: History; // We need to pass history through props because of material-ui  Dialog rendering. Dialog does not render as a child of the controlList so we can't use hooks inside as intended ...
};

type Props = OwnProps & ReduxDispatchProps;

const ControlMapButton = ({
  controlId,
  fineId,
  updateControlFilters,
  history,
}: Props): JSX.Element => {
  const hist = history || useHistory();

  const setControlFiltersAndRedirect = () => {
    updateControlFilters({
      ...controlInitialFilters(),
      dates: computeDatesWithPeriod('-1'),
      controlId,
      fineId,
    });
    hist.push({
      pathname: '/dashboard/controlStrategy',
    });
  };

  return (
    <MapLinkButton
      label={_t('element.displayOnMap')}
      onClick={setControlFiltersAndRedirect}
    />
  );
};

const mapDispatchToProps = (dispatch: (controlFilters: any) => unknown) => ({
  updateControlFilters: (filters: ControlMapFilters): unknown =>
    dispatch(setControlFilters(filters)),
});

export default connect<null, ReduxDispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(ControlMapButton);
