import { MailConfigurationDTO, MailType } from '@cvfm-front/tefps-types';

export type MailTemplateInfoDTO = {
  id: string;
  name: string;
  organizationId: string;
};

export enum MailGroup {
  REFUND,
  RAPO,
  ORDER,
  SUBSCRIBER,
  SUBSCRIBER_MASS_ACTION,
}

export type MailDTO = {
  mail: string | null | undefined;
  mailConfiguration: MailConfigurationDTO | null | undefined;
  mailType?: MailType;
};

export type MailTemplateQueryDTO = {
  resourceId: string | null | undefined;
  organizationId: string | null | undefined;
  type: MailType;
};
