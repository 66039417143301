import querystring from 'querystring';

import { PatchObject } from 'api/commonTypes';
import { Base64 } from 'commons/helpers/Base64';

import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from '../helpers';
import { DirectUploadDTO } from '../mediaupload/types';

import {
  CcspCreationDTO,
  CcspDetailDTO,
  CcspRecourseDTO,
  CcspSearchResultDTO,
  CcspTaskStats,
  CcspVersionDTO,
} from './types';

const BASE_PROXY_CCSP_URL =
  'api/proxy/ccsp/api/cities/{cityId}/ccsp-recourses/v1';
const BASE_URL_BO = '/api/prv/v0/cities/{cityId}/ccsp';
const BASE_PROXY_UPLOAD_URL = 'api/proxy/ccsp/api/v0/cities/{cityId}/ccsp/';

export async function patchCcspRecourse(
  ccspRecourseId: string,
  body: Array<PatchObject<unknown>>
): Promise<CcspRecourseDTO> {
  return apiPatch<CcspRecourseDTO>(
    `${BASE_PROXY_CCSP_URL}/${ccspRecourseId}`,
    body
  );
}

export async function fetchCcspRecourseByFpsId(
  fpsId: string
): Promise<CcspRecourseDTO> {
  return apiGet<CcspRecourseDTO>(`${BASE_PROXY_CCSP_URL}/fpsId/${fpsId}`);
}

export async function fetchCcspRecourseByNumber(
  ccspNumber: string
): Promise<CcspDetailDTO> {
  return apiGet<CcspDetailDTO>(`${BASE_URL_BO}/${ccspNumber}`);
}

export async function fetchCcspRecourseFilteredList(
  filterParam: any
): Promise<CcspSearchResultDTO> {
  return apiGet<CcspSearchResultDTO>(
    `${BASE_URL_BO}?${querystring.stringify(filterParam)}`
  );
}

export async function fetchCcspTasks(): Promise<CcspTaskStats> {
  return apiGet<CcspTaskStats>(`${BASE_URL_BO}/tasks`);
}

export async function createCcspRecourse(
  ccspNumber: string,
  creationDto: CcspCreationDTO
): Promise<CcspRecourseDTO> {
  return apiPut<CcspRecourseDTO>(
    `${BASE_PROXY_CCSP_URL}/number/${ccspNumber}`,
    creationDto
  );
}

export async function insertCcspVersion(
  ccspId: string,
  version: CcspVersionDTO
): Promise<void> {
  return apiPost<void>(`${BASE_PROXY_CCSP_URL}/${ccspId}`, version);
}

export function getExport(filterParam: any, exportParam: any): Promise<any> {
  return apiGet<any>(
    `${BASE_URL_BO}/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export function getCcspSummaryPdfUrl(ccspId: string): string {
  return `${BASE_PROXY_CCSP_URL}/${ccspId}/pdf/summary`;
}

export async function getDirectUploadLink(
  ccspId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_PROXY_UPLOAD_URL}/${ccspId}/upload-url?${querystring.stringify({
      mediaType,
    })}`
  );
}

export async function deleteCcspFile(
  ccspId: string,
  urn: string
): Promise<void> {
  const b64Urn = Base64.encode(urn);
  await apiDelete<void>(`${BASE_PROXY_CCSP_URL}/${ccspId}/documents/${b64Urn}`);
}

export async function deleteCcspRecourse(
  ccspRecourseId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_PROXY_CCSP_URL}/${ccspRecourseId}`);
}
