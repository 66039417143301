import React, { CSSProperties } from 'react';
import RefreshIcon from 'material-ui/svg-icons/action/cached';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import PauseIcon from 'material-ui/svg-icons/notification/priority-high';

import { BKG_GREEN, BKG_ORANGE, BKG_PINK, TXT_BLACK } from 'theme';
import { FpsVersionDTO } from 'api/fps/types';
import Date from 'commons/Date';

const { _t } = window.loadTranslations(__filename);

const STYLE_STATUS: CSSProperties = {
  position: 'absolute',
  top: 30,
  right: 20,
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  color: TXT_BLACK,
};

const STYLE_SYNCED: CSSProperties = {
  ...STYLE_STATUS,
  color: BKG_GREEN,
};

const STYLE_ERROR: CSSProperties = {
  ...STYLE_STATUS,
  color: BKG_PINK,
};

const STYLE_WARNING: CSSProperties = {
  ...STYLE_STATUS,
  color: BKG_ORANGE,
};

const FpsSyncStatus = ({
  version,
  nextAntaiSyncDatetime,
}: {
  version: FpsVersionDTO;
  nextAntaiSyncDatetime: string | null | undefined;
}) => {
  if (
    version.syncStatus === 'SYNCABLE' ||
    version.syncStatus === 'RETRY_SYNC'
  ) {
    if (!nextAntaiSyncDatetime) {
      return null;
    }

    return (
      <div style={STYLE_STATUS}>
        <RefreshIcon
          color={TXT_BLACK}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
        {_t('feedback.info.waitAntai')}
      </div>
    );
  }
  if (version.syncStatus === 'SYNCED') {
    return (
      <div style={STYLE_SYNCED}>
        <CheckIcon
          color={BKG_GREEN}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
        {_t('feedback.success.antaiTransmission')}
        <Date
          datetime={version.authTransferDatetime || undefined}
          withAlternativeSeparator
          style={{ marginLeft: '3px' }}
        />
      </div>
    );
  }
  if (version.syncStatus === 'REJECTED_WONT_FIX') {
    return (
      <div style={STYLE_SYNCED}>
        <CheckIcon
          color={BKG_GREEN}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
        {_t('feedback.success.antaiWontFix')}
        <Date
          datetime={version.authTransferDatetime || undefined}
          withAlternativeSeparator
        />
      </div>
    );
  }
  if (version.syncStatus === 'ANTAI_DUPLICATE') {
    return (
      <div style={STYLE_WARNING}>
        <PauseIcon
          color={BKG_ORANGE}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />

        {_t('feedback.warning.notTransmited')}
      </div>
    );
  }

  return (
    <div style={STYLE_ERROR} title={version.lastSyncMessage || undefined}>
      <CloseIcon
        color={BKG_PINK}
        style={{ width: 20, height: 20, marginRight: 5 }}
      />
      {_t('feedback.error.antaiTransmission')}
    </div>
  );
};

export default FpsSyncStatus;
