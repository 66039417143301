import * as React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';

import { COLOR_STYLE } from '../../style';

import { UploadModalBody, UploadModalBodyProps } from './UploadModalBody';
import {
  UploadModalInputSelector,
  UploadModalInputSelectorProps,
} from './UploadModalInputSelector';

const { _tg } = window.loadTranslations();

type Props = UploadModalInputSelectorProps &
  UploadModalBodyProps & {
    isUploadModalOpen: boolean;
    isLoading: boolean;
    onSubmit: () => Promise<any>;
    closeModals: () => any;
  };

/*
 ** UploadModalContainer:
 ** Main component to render the upload modal
 */
export const UploadModalContainer = ({
  file,
  onDrop,
  ressourceType,
  isUploadModalOpen,
  onLinkNameChange,
  onLinkUrlChange,
  onTypeChange,
  onSubmit,
  isLoading,
  closeModals,
}: Props): JSX.Element => {
  return (
    <Dialog
      title={_tg('tefps.documentLibrary.upload.uploadDocument')}
      open={isUploadModalOpen}
      actions={[
        <BoButton
          label={_tg('action.back')}
          disabled={isLoading}
          onClick={closeModals}
        />,
        <BoButton
          label={
            isLoading
              ? _tg('tefps.dashboard.refund.sending')
              : _tg('action.send')
          }
          backgroundColor={COLOR_STYLE.BKG_CYAN}
          disabled={isLoading}
          labelColor={COLOR_STYLE.LABEL_COLOR}
          onClick={onSubmit}
          style={{ marginLeft: 8 }}
        />,
      ]}
    >
      <UploadModalInputSelector
        onTypeChange={onTypeChange}
        ressourceType={ressourceType}
      />
      <UploadModalBody
        file={file}
        onDrop={onDrop}
        onLinkNameChange={onLinkNameChange}
        onLinkUrlChange={onLinkUrlChange}
        ressourceType={ressourceType}
        isLoading={isLoading}
      />
    </Dialog>
  );
};
