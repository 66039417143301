import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';

import {
  AddDocumentAction,
  AddSectionAction,
  InitDocumentStateAction,
  RemoveDocumentAction,
  RemoveSectionAction,
} from './types';

export const initState = (
  sections: SectionDTO[],
  documents: SectionDocumentDTO[]
): InitDocumentStateAction => {
  return {
    type: 'INIT_DOCUMENT_STATE',
    payload: {
      sections,
      documents,
    },
  };
};

export const addDocument = (doc: SectionDocumentDTO): AddDocumentAction => {
  return {
    type: 'ADD_DOCUMENT',
    payload: doc,
  };
};

export const removeDocument = (documentId: string): RemoveDocumentAction => {
  return {
    type: 'REMOVE_DOCUMENT',
    payload: documentId,
  };
};

export const addSection = (section: SectionDTO): AddSectionAction => {
  return {
    type: 'ADD_SECTION',
    payload: section,
  };
};

export const removeSection = (sectionId: string): RemoveSectionAction => {
  return {
    type: 'REMOVE_SECTION',
    payload: sectionId,
  };
};
