import {
  ChartStatisticsResult,
  RapoChartFiltersParam,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

const API_STATS = '/api/proxy/rapo/api/cities/{cityId}/rapo/v0/stats';

export function searchRapoChartStatistics(
  filters: RapoChartFiltersParam
): Promise<ChartStatisticsResult> {
  return apiPost<ChartStatisticsResult>(`${API_STATS}/charts`, filters);
}
