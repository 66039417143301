import { apiPost } from '../helpers';

import { UrlDTO } from './types';

const BASE_BO_URL = '/api/prv/v0/global/url/signer';
export const BASE_GOOGLE_MAPS_URL = 'https://maps.googleapis.com';

export async function signGoogleMapUrl(urlToSign: string): Promise<UrlDTO> {
  return apiPost<UrlDTO>(`${BASE_BO_URL}/{cityId}/google/map`, {
    url: urlToSign,
  });
}
