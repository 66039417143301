import React, { CSSProperties } from 'react';
import {
  amber600,
  blueGrey500,
  brown500,
  cyan900,
  deepPurple300,
  fullBlack,
  deepOrange900,
  green500,
  indigo500,
  lightBlue900,
  orange800,
  pinkA100,
  red800,
  teal500,
} from 'material-ui/styles/colors';

import { DownloadType } from 'api/backoffice/types';

const TAG_STYLE = (backgroundColor: string): CSSProperties => ({
  borderRadius: 8,
  padding: 8,
  color: 'white',
  fontWeight: 'bold',
  fontFamily: 'Arial',
  backgroundColor,
});

export const LABEL_TO_DOWNLOAD_TAG = {
  FPS_EXPORT: <span style={TAG_STYLE(red800)}>FPS</span>,
  PAYMENT_EXPORT: <span style={TAG_STYLE(orange800)}>Paiements</span>,
  SUBSCRIBER_PAYMENT_EXPORT: (
    <span style={TAG_STYLE(orange800)}>Paiements Ayants droit</span>
  ),
  RAPO_EXPORT: <span style={TAG_STYLE(pinkA100)}>RAPO</span>,
  CCSP_EXPORT: <span style={TAG_STYLE(deepPurple300)}>TSP</span>,
  CONTROL_EXPORT: <span style={TAG_STYLE(blueGrey500)}>Contrôles</span>,
  COMBINED_CONTROL_EXPORT: (
    <span style={TAG_STYLE(pinkA100)}>Contrôles / FPS</span>
  ),
  TV_EXPORT: <span style={TAG_STYLE(green500)}>Tickets</span>,
  SUBSCRIPTION_EXPORT: <span style={TAG_STYLE(teal500)}>Souscriptions</span>,
  SUBSCRIBER_EXPORT: <span style={TAG_STYLE(indigo500)}>Comptes</span>,
  PRODUCT_EXPORT: <span style={TAG_STYLE(fullBlack)}>Produits</span>,
  COMMUNICATION_EXPORT: (
    <span style={TAG_STYLE(deepOrange900)}>Communications</span>
  ),
  PARKING_RIGHT_REQUEST_EXPORT: (
    <span style={TAG_STYLE(cyan900)}>Tickets longue durée</span>
  ),
  STAT_BY_AGENT_EXPORT: <span style={TAG_STYLE(brown500)}>Stat. agents</span>,
  STAT_SUBSCRIPTIONS_BY_AGENT_EXPORT: (
    <span style={TAG_STYLE(brown500)}>Stat. Souscriptions</span>
  ),
  STAT_BY_ORGANIZATION_EXPORT: (
    <span style={TAG_STYLE(blueGrey500)}>Stat. par organisation</span>
  ),
  ALERT_EXPORT: <span style={TAG_STYLE(amber600)}>ALERT</span>,
  MOTIFS_EXPORT: <span style={TAG_STYLE(lightBlue900)}>MOTIFS</span>,
  BILL_EXPORT: <span style={TAG_STYLE(lightBlue900)}>Factures</span>,
  KPI_EXPORT: <span style={TAG_STYLE(lightBlue900)}>KPI</span>,
};

export const getExtensionFromDownloadType = (
  downloadType: DownloadType
): string => {
  if (downloadType === 'GPV_LIST_DBF_EXPORT') {
    return 'dbf';
  }
  return 'xlsx';
};
