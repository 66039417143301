import React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { BKG_DARK_BLUE } from 'theme';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void> | void;
  message: string;
  title?: string;
};

const ConfirmModal = ({ open, onCancel, onConfirm, message, title }: Props) => {
  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      secondary
      onClick={onCancel}
      style={{ marginRight: 20 }}
    />,
    <BoButton label={_tg('action.confirm')} primary onClick={onConfirm} />,
  ];

  return (
    <Dialog
      title={title || _t('element.dialog.title')}
      titleStyle={{ color: BKG_DARK_BLUE }}
      actions={actions}
      modal
      open={open}
    >
      {message}
    </Dialog>
  );
};

export default ConfirmModal;
