import { ModalsActions, ModalsState } from './types';

const INITIAL_STATE = {
  isUploadModalOpen: false,
  isDeleteModalOpen: false,
  isCreateSectionModalOpen: false,
  sectionId: '',
} as ModalsState;

export const modalsReducer = (
  state: ModalsState = INITIAL_STATE,
  action: ModalsActions
): ModalsState => {
  switch (action.type) {
    case 'OPEN_UPLOAD_MODAL':
      return {
        ...state,
        isUploadModalOpen: true,
        sectionId: action.payload,
      };
    case 'OPEN_DELETE_MODAL':
      return {
        ...state,
        isDeleteModalOpen: true,
        sectionId: action.payload,
      };
    case 'OPEN_CREATE_SECTION_MODAL':
      return {
        ...state,
        isCreateSectionModalOpen: true,
      };
    case 'CLOSE_MODALS':
      return {
        ...state,
        isUploadModalOpen: false,
        isDeleteModalOpen: false,
        isCreateSectionModalOpen: false,
        sectionId: '',
      };
    default:
      return state;
  }
};
