import React, { useState } from 'react';
import AutoComplete from 'material-ui/AutoComplete';
import Reset from 'material-ui/svg-icons/action/restore';
import { connect } from 'react-redux';

import TextFieldCustom from 'commons/FormComponent/Fields/TextFieldCustom';
import CheckboxCustom from 'commons/FormComponent/Fields/CheckboxCustom';
import {
  validatePostalCode,
  validateStreetNumber,
  validateStreetNumberBis,
  validateStreetName,
  validateStreetLocality,
} from 'commons/Validators';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { LocalizedAddressDTO } from 'api/searchingCompletion/types';
import { Address } from 'api/commonTypes';
import { findAddress } from 'api/searchingCompletion';
import {
  BKG_PINK,
  STYLE_BLOCK,
  STYLE_BLOCK_TITLE,
  STYLE_CONTENT_WRAPPER,
  STYLE_RESET,
  STYLE_SELECT_TEXT,
  STYLE_SEPARATOR,
} from 'theme';

import { InternalApiState } from '../api/duck';
import { getConfigState, ICvfmMailingPartnerProperties } from '../config/duck';

import { FieldKeyRecourseUser } from './types/fieldKeyRecourseUser';
import { FieldKeyAddress } from './types/fieldKeyAddress';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  field: string;
  fieldKeys: FieldKeyRecourseUser | FieldKeyAddress;
  mandatoryAddress: boolean;
  styleInputs: Record<string, any>;
  illegibleOption?: boolean;
  countryIn2Char?: boolean;
  isForeignAddressAllow?: boolean;
  mailingPartnerProperties: ICvfmMailingPartnerProperties | null;
  address?: Address | null | undefined;
  onAutoCompleteCallback?: Function;
};

function AddressAutocomplete({
  field,
  fieldKeys,
  mandatoryAddress,
  styleInputs,
  illegibleOption,
  countryIn2Char,
  isForeignAddressAllow,
  mailingPartnerProperties,
  address,
  onAutoCompleteCallback,
}: Props) {
  const [prefixAddress, setPrefixAddress] = useState<string>('');
  const [addressMapping, setAddressMapping] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [foundAddress, setFoundAddress] = useState<Address | null | undefined>(
    null
  );
  const [error, setError] = useState<string>('');
  const [isIllegibleAddress, setIsIllegibleAddress] = useState<boolean>(false);
  const [isForeignAddress, setIsForeignAddress] = useState<boolean>(
    (address?.addressCountry !== 'FR' &&
      address?.addressCountry !== null &&
      address?.addressCountry !== undefined &&
      address?.addressCountry !== '') ||
      false
  );

  const requiredField = mandatoryAddress && !isIllegibleAddress;
  const validateField = !isIllegibleAddress && !isForeignAddress;

  function resetFields() {
    setPrefixAddress('');
    setAddressMapping([]);
    setFoundAddress(null);
    setError('');
    setIsForeignAddress(false);
  }

  function buildMap(addresses: Array<LocalizedAddressDTO>) {
    if (addresses.length === 0) {
      return [{ id: 'noMatch', name: _tg('feedback.error.noMatch') }];
    }
    const addressMap = addresses.map(item => ({
      id: item.concatAddress,
      name: item.concatAddress,
    }));

    return addressMap;
  }

  async function triggerAutoComplete(value: string, isUpdate: boolean) {
    if (value === '') {
      resetFields();
    } else if (value !== null && value !== undefined) {
      setError('');
      setPrefixAddress(value);
      const addresses = await findAddress(value);

      const addressMap = buildMap(addresses);
      setAddressMapping(addressMap);

      if (isUpdate && addresses.length) {
        const found = addresses.pop();
        if (found) {
          if (onAutoCompleteCallback) {
            onAutoCompleteCallback(found);
          }
          setFoundAddress(found.address);
        }
      }
    }
  }

  return (
    <div>
      <div style={STYLE_BLOCK}>
        <div style={{ display: 'flex', height: 70 }}>
          <div style={STYLE_BLOCK_TITLE}>
            {_t('element.addressSearch.title')}
          </div>
          <AutoComplete
            id="Autocomplete field"
            openOnFocus
            fullWidth
            // eslint-disable-next-line @typescript-eslint/unbound-method
            filter={AutoComplete.noFilter}
            inputStyle={STYLE_SELECT_TEXT}
            onUpdateInput={value => {
              triggerAutoComplete(value, false);
            }}
            onNewRequest={element => {
              triggerAutoComplete(element.name, true);
            }}
            searchText={prefixAddress}
            dataSource={addressMapping}
            errorText={error}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            style={{ marginLeft: '-10px', flex: 3 }}
            popoverProps={{ style: { maxWidth: 500 } }}
            errorStyle={{ fontSize: 14, marginTop: 5 }}
          />
          <div title={_tg('action.resetField')}>
            <Reset style={STYLE_RESET} onClick={resetFields} />
          </div>
        </div>
      </div>
      <SeparatorWithTitle
        style={STYLE_SEPARATOR}
        title={_tg('field.address.detailedAddress')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div style={STYLE_CONTENT_WRAPPER}>
        {illegibleOption &&
          !mailingPartnerProperties?.class.includes(
            'CvfmMailingPartnerProperties'
          ) && (
            <CheckboxCustom
              name={`${field}.illegibleAddress`}
              label="Adresse incomplète ou illisible"
              value={isIllegibleAddress}
              style={{ margin: '10px 0' }}
              onChange={setIsIllegibleAddress}
            />
          )}
        {isForeignAddressAllow && (
          <CheckboxCustom
            name="foreignAddress"
            label="Adresse à l'étranger"
            value={isForeignAddress}
            style={{ margin: '10px 0' }}
            onChange={setIsForeignAddress}
          />
        )}
      </div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.streetNumber) || address?.streetNumber
          }
          name={`${field}.${fieldKeys.streetNumber}`}
          hint={_tg('field.address.streetNumberShort')}
          type="number"
          validators={validateField ? [validateStreetNumber] : []}
          style={{ ...styleInputs, width: '15%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.streetNumberBis) ||
            address?.streetNumberBis
          }
          name={`${field}.${fieldKeys.streetNumberBis}`}
          hint={_tg('field.address.streetNumberBisAlternative')}
          validators={validateField ? [validateStreetNumberBis] : []}
          style={{ ...styleInputs, width: '25%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.streetType) || address?.streetType
          }
          name={`${field}.${fieldKeys.streetType}`}
          hint="Type de la voie"
          style={{ ...styleInputs, width: '15%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.streetName) || address?.streetName
          }
          name={`${field}.${fieldKeys.streetName}`}
          hint={_tg('field.address.streetName')}
          validators={!isForeignAddress ? [validateStreetName] : []}
          mandatory={requiredField}
          style={{ ...styleInputs, width: '30%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
      </div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.specialPlace) || address?.specialPlace
          }
          name={`${field}.${fieldKeys.specialPlace}`}
          hint={_tg('field.address.addressComplement')}
          style={{ ...styleInputs, width: '30%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
        <TextFieldCustom
          value={
            (foundAddress && foundAddress.postalCode) || address?.postalCode
          }
          name={`${field}.${fieldKeys.postalCode}`}
          hint={_tg('field.address.postalCode')}
          mandatory={requiredField}
          validators={validateField ? [validatePostalCode] : []}
          style={{ ...styleInputs, width: '20%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
        <TextFieldCustom
          value={
            (foundAddress &&
              foundAddress.addressLocality.charAt(0).toUpperCase() +
                foundAddress.addressLocality.slice(1)) ||
            address?.addressLocality
          }
          name={`${field}.${fieldKeys.addressLocality}`}
          hint={_tg('field.address.locality')}
          validators={!isForeignAddress ? [validateStreetLocality] : []}
          mandatory={requiredField}
          style={{ ...styleInputs, width: '40%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
      </div>
      {isForeignAddress && isForeignAddressAllow && (
        <div style={STYLE_CONTENT_WRAPPER}>
          <TextFieldCustom
            value={
              (foundAddress && foundAddress.addressCountry) ||
              address?.addressCountry
            }
            name={`${field}.${fieldKeys.addressCountry}`}
            hint={`Pays ${countryIn2Char ? 'en 2 lettres (ex: FR)' : ''}`}
            mandatory
            style={{ ...styleInputs, width: '40%' }}
            underlineFocusStyle={styleInputs}
            floatingLabelFocusStyle={styleInputs}
            maxLength={countryIn2Char ? 2 : undefined}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: InternalApiState) => {
  const { mailingPartnerProperties } = getConfigState(state);
  return { mailingPartnerProperties };
};
export default connect(mapStateToProps)(AddressAutocomplete);
