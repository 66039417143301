import { PatchObject } from 'api/commonTypes';
import { FnmsLatestIdDTO } from 'api/fps/types';
import { ControlResponse } from '@cvfm-front/tefps-types';

import { apiPatch, apiPost, apiGet } from '../helpers';

import {
  ExemptionDTO,
  FpsCreationResponse,
  LapiComplexDTO,
  LapiReviewDTO,
  LapiReviewHistoryDTO,
  LapiReviewQualityControlStateDTO,
  LapiReviewStatDTO,
  QualityControlFilterDTO,
  VehicleBrandDTO,
} from './types';

const BASE_PROXY_LAPI_REVIEW_URL =
  'api/proxy/lapi-review/api/cities/{cityId}/lapi-reviews';
const BASE_PROXY_CONFIG_URL = 'api/proxy/config/api/config/dataset';

export async function fetchControl(): Promise<LapiReviewDTO> {
  return apiPost<LapiReviewDTO>(`${BASE_PROXY_LAPI_REVIEW_URL}/_search`, {});
}

export async function createFps(
  lapiReviewDTO: LapiReviewDTO
): Promise<FpsCreationResponse> {
  return apiPost<FpsCreationResponse>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/create-fps`,
    lapiReviewDTO
  );
}

export async function findNextFpsId(
  statementDatetime: string
): Promise<FnmsLatestIdDTO> {
  return apiPost<FnmsLatestIdDTO>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/next-fps-id`,
    statementDatetime
  );
}

export async function exemptLapiReview(
  exemptionDTO: ExemptionDTO
): Promise<ControlResponse> {
  return apiPost<ControlResponse>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/exempt-lapi-review`,
    exemptionDTO
  );
}

export async function patchLapiReview(
  controlId: string,
  body: Array<PatchObject<unknown>>
): Promise<LapiReviewDTO> {
  return apiPatch<LapiReviewDTO>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/${controlId}`,
    body
  );
}

export async function fetchBrandAndModelVehiclesList(): Promise<
  Array<VehicleBrandDTO>
> {
  return apiGet<Array<VehicleBrandDTO>>(`${BASE_PROXY_CONFIG_URL}/vehicles`);
}

export async function findControlByIdWithRebuild(
  controlId: string
): Promise<LapiReviewDTO> {
  return apiGet<LapiReviewDTO>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/control/${controlId}`
  );
}

export async function findLapiReviewByControlId(
  controlId: string
): Promise<LapiReviewDTO> {
  return apiGet<LapiReviewDTO>(`${BASE_PROXY_LAPI_REVIEW_URL}/${controlId}`);
}

export async function fetchRecentHistory(): Promise<
  Array<LapiReviewHistoryDTO>
> {
  return apiGet<Array<LapiReviewHistoryDTO>>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/recentHistory`
  );
}

export async function fetchQualityControl(
  qualityControlFilters: QualityControlFilterDTO
): Promise<LapiReviewQualityControlStateDTO> {
  return apiPost<LapiReviewQualityControlStateDTO>(
    `${BASE_PROXY_LAPI_REVIEW_URL}/statistics/quality-control`,
    qualityControlFilters
  );
}

export async function fetchLapiReviewStat(): Promise<LapiReviewStatDTO> {
  return apiGet<LapiReviewStatDTO>(BASE_PROXY_LAPI_REVIEW_URL);
}
