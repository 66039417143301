import {
  closeModals,
  openCreateSectionModal,
  openDeleteModal,
  openUploadModal,
} from './actions';
import { ModalsActions, ModalsState } from './types';

export type ModalsReduxDispatchProps = {
  openDeleteModal: (sectionId: string) => void;
  openUploadModal: (sectionId: string) => void;
  openCreateSectionModal: () => void;
  closeModals: () => void;
};

export const mapModalsActionToProps = (
  dispatch: (action: ModalsActions) => void
): ModalsReduxDispatchProps => {
  return {
    openDeleteModal: (sectionId: string) =>
      dispatch(openDeleteModal(sectionId)),
    openUploadModal: (sectionId: string) =>
      dispatch(openUploadModal(sectionId)),
    openCreateSectionModal: () => dispatch(openCreateSectionModal()),
    closeModals: () => dispatch(closeModals()),
  };
};

export type ModalsReduxStateProps = {
  isCreateSectionModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isUploadModalOpen: boolean;
  sectionId: string;
};

export const mapModalsStateToProps = (state: {
  documentLibraryModals: ModalsState;
}): ModalsReduxStateProps => {
  return {
    isCreateSectionModalOpen:
      state.documentLibraryModals.isCreateSectionModalOpen,
    isDeleteModalOpen: state.documentLibraryModals.isDeleteModalOpen,
    isUploadModalOpen: state.documentLibraryModals.isUploadModalOpen,
    sectionId: state.documentLibraryModals.sectionId,
  };
};
