import React, { CSSProperties } from 'react';

import Date from 'commons/Date';
import { FnmsMediaDTO } from 'api/fps/types';
import { BKG_PINK, BRD_GREY } from 'theme';

const { _t, Trans } = window.loadTranslations(__filename);

const STYLE_IMG: CSSProperties = {
  height: 200,
  width: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const STYLE_MEDIA: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${BRD_GREY}`,
  padding: '20px 0',
};

const STYLE_MEDIA_INFO: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 20,
  fontSize: 14,
};

const STYLE_MEDIA_INFO_TITLE: CSSProperties = { fontWeight: 'bold' };

const STYLE_MEDIA_LINK: CSSProperties = {
  textDecoration: 'underline',
  fontStyle: 'italic',
  marginTop: 20,
};

type Props = {
  index: number;
  media: FnmsMediaDTO;
};

const Picture = ({ index, media }: Props) => {
  return (
    <div key={index} style={STYLE_MEDIA}>
      <div style={STYLE_IMG}>
        {!media.expires ? (
          <img
            style={{ maxWidth: 200, maxHeight: 200 }}
            src={media.contentUrl}
            alt={media.name}
          />
        ) : (
          <div style={{ color: BKG_PINK }}>{_t('element.expired')}</div>
        )}
      </div>
      <div style={STYLE_MEDIA_INFO}>
        {media.name && (
          <div>
            <Trans
              i18nKey="element.name"
              components={[<span style={STYLE_MEDIA_INFO_TITLE} />]}
              tOptions={{ name: media.name }}
            />
          </div>
        )}
        {media.dateCreated.trim() && (
          <div>
            <Trans
              i18nKey="element.date"
              components={[
                <span style={STYLE_MEDIA_INFO_TITLE} />,
                <Date datetime={media.dateCreated} />,
              ]}
            />
          </div>
        )}
        {media.expires ? (
          <div>
            <Trans
              i18nKey="element.expirationDate"
              components={[
                <span style={STYLE_MEDIA_INFO_TITLE} />,
                <Date datetime={media.expires} />,
              ]}
            />
          </div>
        ) : (
          <div style={STYLE_MEDIA_LINK}>
            <a
              href={media.contentUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {_t('element.seePicture')}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Picture;
