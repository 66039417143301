import querystring from 'querystring';

import { PaymentFiltersParam } from 'Dashboard/fps/SubscribersPayment/List/types';
import { TaskStats } from 'tefps/Tasks/types';
import {
  PaymentSearchResultDTO,
  SubscriberIdAndNameDTO,
  SubscriberMediaDTO,
} from '@cvfm-front/tefps-types';

import { apiDelete, apiGet, apiPost, apiPostCSV, apiPut } from '../helpers';
import { ImportResultDTO } from '../commonTypes';
import { DirectUploadDTO } from '../mediaupload/types';

const BASE_URL = '/api/proxy/subscription/api/prv/cities/{cityId}';
const BASE_DIRECTORY_URL = '/api/proxy/directory/api/cities/{cityId}';
const BASE_URL_BO = '/api/prv/v0/cities/{cityId}';
const BASE_PROXY_UPLOAD_URL =
  'api/proxy/subscription/api/v0/cities/{cityId}/subscription/';
const BASE_PROXY_SUBSCRIBER_URL =
  '/api/proxy/subscription/api/prv/cities/{cityId}/search';
const BASE_ORDER_URL =
  '/api/proxy/subscription/api/v2/prv/cities/{cityId}/order';

export async function matchSubscribers(
  subscriberFilter: string
): Promise<Array<SubscriberIdAndNameDTO>> {
  return apiGet<Array<SubscriberIdAndNameDTO>>(
    `${BASE_URL_BO}/subscribers/match/filter?${querystring.stringify({
      subscriberFilter,
    })}`
  );
}

export async function modifyPassword(
  email: string,
  challenge: string
): Promise<void> {
  return apiPut<void>(`${BASE_DIRECTORY_URL}/customer/modifyPassword/back`, {
    email,
    challenge,
  });
}

export async function importSubscribers(file: File): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(
    '/api/prv/v0/cities/{cityId}/subscribers/import',
    file
  );
}

export async function importSubscriptions(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(
    '/api/prv/v0/cities/{cityId}/subscriptions/import',
    file
  );
}

export function askSubscriberExport(filterParam: any, exportParam: any): void {
  void apiPost<unknown>(
    `${BASE_URL_BO}/subscribers/export?${querystring.stringify({
      ...exportParam,
    })}`,
    filterParam
  );
}

export function askSubscriptionExport(
  filterParam: any,
  exportParam: any
): void {
  void apiGet<unknown>(
    `${BASE_URL_BO}/subscriptions/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export async function fetchPaymentFilteredList(
  filterParam: PaymentFiltersParam
): Promise<PaymentSearchResultDTO> {
  return apiPost<PaymentSearchResultDTO>(
    `${BASE_URL}/search/payments`,
    filterParam
  );
}

export function exportPayments(
  filterParam: any,
  exportParam: any
): Promise<any> {
  return apiGet<unknown>(
    `${BASE_URL_BO}/payments/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export async function getDirectUploadLink(
  subscriptionId: string,
  mediaType: string
): Promise<DirectUploadDTO> {
  return apiGet<DirectUploadDTO>(
    `${BASE_PROXY_UPLOAD_URL}/${subscriptionId}/upload-url?${querystring.stringify(
      {
        mediaType,
      }
    )}`
  );
}

export async function deleteEvidence(
  subscriberId: string,
  evidenceId: string
): Promise<void> {
  return apiDelete<void>(
    `${BASE_URL}/subscribers/${subscriberId}/evidence/${evidenceId}`
  );
}

export async function deleteEvidenceLink(
  orderId: string,
  evidenceId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_ORDER_URL}/${orderId}/evidence/${evidenceId}`);
}

export async function getRemovableEvidences(
  subscriberId: string
): Promise<Array<SubscriberMediaDTO>> {
  return apiGet<Array<SubscriberMediaDTO>>(
    `${BASE_URL}/subscribers/${subscriberId}/removable-evidences`
  );
}

export function getCashVoucherUrl(): string {
  return `${BASE_PROXY_SUBSCRIBER_URL}/payments/cash-voucher`;
}
