const { _t } = window.loadTranslations(__filename);

export const getCloseFormatDate = (daysFromNow: number) => {
  const PRETTY_DAY = [
    _t('day.today'),
    _t('day.yesterday'),
    _t('day.dayBeforeYesterday'),
  ];

  if (daysFromNow >= 0 && daysFromNow < PRETTY_DAY.length) {
    return PRETTY_DAY[daysFromNow];
  }
  return null;
};
