import * as React from 'react';
import { connect } from 'react-redux';
import UploadIcon from 'material-ui/svg-icons/file/cloud-upload';
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever';

import SectionTitle from 'commons/SectionTitle';
import { openUrlInNewTab } from 'commons/openUrlInNewTab';
import { openUploadedDocument } from 'api/documentLibrary';
import { SectionDocumentDTO, SectionDTO } from 'api/documentLibrary/types';

import {
  mapModalsActionToProps,
  ModalsReduxDispatchProps,
} from '../Modals/ducks/mapper';
import { STYLE } from '../style';

import { DisplayFile } from './DisplayFile';

type ReduxDispatchProps = ModalsReduxDispatchProps;

type OwnProps = {
  canWrite: boolean;
  documents: SectionDocumentDTO[];
  section: SectionDTO;
};

type Props = OwnProps & ReduxDispatchProps;

class Section extends React.Component<Props, any> {
  onOpenUploadModal = (ev: React.MouseEvent<HTMLInputElement>) => {
    const { sectionId } = ev.currentTarget.dataset;
    const { openUploadModal } = this.props;
    if (!sectionId) return;
    openUploadModal(sectionId);
  };

  onOpenDeleteModal = (ev: React.MouseEvent<HTMLInputElement>) => {
    const { sectionId } = ev.currentTarget.dataset;
    const { openDeleteModal } = this.props;
    if (!sectionId) return;
    openDeleteModal(sectionId);
  };

  openDocument = async (ev: React.MouseEvent<HTMLInputElement>) => {
    const { documentId } = ev.currentTarget.dataset;
    const { documents } = this.props;
    if (!documentId) return;
    const doc = documents.filter(e => e.id === documentId)[0];

    if (doc.ressourceType === 'FILE') {
      await openUploadedDocument(documentId);
    } else {
      openUrlInNewTab(doc.definedLink || '');
    }
  };

  render() {
    const { section, documents, canWrite } = this.props;

    return (
      <div>
        <SectionTitle title={section.name} />

        {/* Section actions: upload, delete */}
        {canWrite && (
          <div>
            <UploadIcon
              data-section-id={section.id}
              onClick={this.onOpenUploadModal}
              style={STYLE.BTN_ICON}
            />
            <DeleteIcon
              data-section-id={section.id}
              onClick={this.onOpenDeleteModal}
              style={STYLE.BTN_ICON}
            />
          </div>
        )}

        {/* Documents */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {documents &&
            documents.map(doc => (
              <DisplayFile
                key={doc.id}
                filename={doc.name}
                filetype={doc.type}
                onClick={this.openDocument}
                data-document-id={doc.id}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default connect<OwnProps, ReduxDispatchProps>(
  null,
  mapModalsActionToProps
)(Section);
