import { PatchObject } from 'api/commonTypes';

import { apiGet, apiPut } from '../helpers';

import { ControlConfiguration } from './types';

const BASE_URL = '/api/prv/v0/cities/control_configuration/{cityId}';

export async function fetchControlConfiguration(): Promise<
  ControlConfiguration
> {
  return apiGet<ControlConfiguration>(`${BASE_URL}`);
}

export function buildControlConfigurationPatch(
  configuration: ControlConfiguration
): PatchObject<ControlConfiguration> {
  return {
    op: 'replace',
    path: '/controlConfiguration',
    value: configuration,
  } as PatchObject<ControlConfiguration>;
}

export async function upsertControlConfiguration(
  configuration: PatchObject<ControlConfiguration>
): Promise<void> {
  return apiPut<void>(`${BASE_URL}`, configuration);
}
