import { apiGet } from '../helpers';

import { ClientAppLightDTO } from './types';

const BASE_URL_FETCH_CLIENT_APP = '/api/prv/v0/city/{cityId}/clientapp';

export async function fetchClientAppTV(): Promise<Array<ClientAppLightDTO>> {
  return apiGet<Array<ClientAppLightDTO>>(
    `${BASE_URL_FETCH_CLIENT_APP}/tv_create`
  );
}

export async function fetchClientAppPayments(): Promise<
  Array<ClientAppLightDTO>
> {
  return apiGet<Array<ClientAppLightDTO>>(
    `${BASE_URL_FETCH_CLIENT_APP}/fps_patch`
  );
}
