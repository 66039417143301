import * as React from 'react';
import { connect } from 'react-redux';

import { mapModalsStateToProps } from './ducks/mapper';
import CreateSectionModal from './CreateSectionModal';
import DeleteModal from './DeleteModal';
import UploadModal from './UploadModal';

const Modals = () => {
  return (
    <div>
      <CreateSectionModal />
      <UploadModal />
      <DeleteModal />
    </div>
  );
};

export default connect(mapModalsStateToProps, null)(Modals);
