import { EligibilityFilterParam } from '@cvfm-front/tefps-types';
import { ImportResultDTO } from 'api/commonTypes';
import { apiGet, apiPost, apiPostCSV } from 'api/helpers';
import { EligibilityRightPrivateDTO } from 'tefps/Eligibility/types';

import { EligibilitySearchResultDTO } from './type';

const BASE_URL_TV_ES = '/api/proxy/tv/api/prv/cities/{cityId}/es/eligibility';

const BASE_URL_TV = '/api/proxy/tv/api/prv/cities/{cityId}/eligibility';

const BASE_BO_URL = '/api/prv/v0/city/{cityId}/eligibility';

export async function searchEligibilities(
  filterParam: EligibilityFilterParam
): Promise<EligibilitySearchResultDTO> {
  return apiPost<EligibilitySearchResultDTO>(
    `${BASE_URL_TV_ES}/paged`,
    filterParam
  );
}

export async function fetchEligibility(
  eligibilityId: string
): Promise<EligibilityRightPrivateDTO> {
  return apiGet<EligibilityRightPrivateDTO>(`${BASE_URL_TV}/${eligibilityId}`);
}

export function getExport(
  filterParam: Record<string, any>,
  exportParam: { columns: Array<string> }
): Promise<void> {
  return apiPost<void>(`${BASE_BO_URL}/export`, {
    ...filterParam,
    columns: exportParam.columns,
  });
}

export async function importEligibilitiesFromCSV(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_BO_URL}/import`, file);
}
