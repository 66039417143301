import { apiDelete, apiGet, apiPost } from '../helpers';

const BASE_URL = '/api/proxy/fps/api/v0/cities/{cityId}/admin/whitelist';

export async function fetchWhiteListedPlates(): Promise<Array<string>> {
  return apiGet<Array<string>>(BASE_URL);
}

export async function addWhiteListedPlate(
  plates: Array<string>
): Promise<void> {
  return apiPost<void>(BASE_URL, plates);
}

export async function deleteWhiteListedPlate(plate: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${encodeURIComponent(plate)}`);
}
