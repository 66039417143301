import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import Snackbar from 'material-ui/Snackbar';
import Select from 'react-select';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import { greenA700 } from 'material-ui/styles/colors';
import { Trans } from 'react-i18next';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Visibility from 'material-ui/svg-icons/action/visibility';
import IconButton from 'material-ui/IconButton';

import { fetchUserInfoAction, loginAction } from 'api/duck';
import { listLogos } from 'api/root-config';
import { doAuthAndRetrieveToken } from 'api/auth';
import { LogoDTO } from 'api/root-config/types';
import { clearUniverses } from 'UniverseHandler/helpers';
import { BKG_BLUE, BKG_GREY } from 'theme';
import BoButton from 'facade/BoButton';
import { getTokenExpiration } from 'api/devices';
import translateError from 'commons/Utils/translateErrorUtil';
import { requestPasswordReset } from 'api/accounts';

import {
  clearCredentials,
  getCredentials,
  setCredentials,
} from './credentials-storage';
import {
  clearAuthLocalStore,
  getAuthLocalStore,
  setAuthLocalStore,
} from './auth-storage';

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: BKG_BLUE,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const STYLE_LOGO_BOX: CSSProperties = {
  backgroundColor: BKG_GREY,
  width: 500,
  height: 150,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const PASSWORD_RESET_SUCCEEDED: CSSProperties = {
  backgroundColor: BKG_GREY,
  width: 500,
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  lineHeight: 1.5,
};

const STYLE_LOGIN_BOX: CSSProperties = {
  backgroundColor: BKG_GREY,
  width: 500,
  height: 325,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const STYLE_RESET_PASSWORD: CSSProperties = {
  backgroundColor: BKG_GREY,
  color: '#3869B8',
  textDecoration: 'underline',
  width: 495,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 5,
  cursor: 'pointer',
};

const REACT_SELECT_STYLE = {
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  container: (styles: any, state: any) => ({
    ...styles,
    borderBottom: state.isFocused
      ? '2px solid rgba(103, 197, 207, 1)'
      : '1px solid rgba(128, 128, 128, .1)',
  }),

  input: (styles: any) => ({
    ...styles,
    marginLeft: '-5px',
  }),

  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    opacity: '0.8',
    textAlign: 'left',
    marginLeft: '-5px',
  }),
  control: (styles: any) => ({
    ...styles,
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }),
};
const { _tg } = window.loadTranslations(__filename);

type LoginFormProps = {
  dispatch: (arg0: any) => any; // eslint-disable-line react/no-unused-prop-types
};

type LoginFormState = {
  logos: Array<LogoDTO> | null | undefined;
  selectedCityId: string | null | undefined;
  login: string;
  password: string;
  deviceId: string | null | undefined;
  deviceSecret: string | null | undefined;
  error: string | undefined;
  busy: boolean;
  isRequestingPasswordReset: boolean;
  isPasswordResetSucceeded: boolean;
  tooManyAttemptsError: boolean;
  passwordExpiredError: boolean;
  hidePassword: boolean;
};

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  fetchingUserInfo = false;
  state: LoginFormState = {
    logos: null,
    selectedCityId: null,
    login: '',
    password: '',
    deviceId: null,
    deviceSecret: null,
    error: undefined,
    busy: false,
    isRequestingPasswordReset: false,
    isPasswordResetSucceeded: false,
    tooManyAttemptsError: false,
    passwordExpiredError: false,
    hidePassword: true,
  };

  // TODO remove when domain opened on every city
  performAttachmentDummyApiCall = (selectedCityId: string): void => {
    try {
      const { protocol, host } = window.location;

      const computeUrl = (hostUrl: string): string => {
        const splittedHost = hostUrl.split('.');
        if (splittedHost.length >= 2) {
          return `${protocol}//attachment.api.${
            splittedHost[splittedHost.length - 2]
          }.${
            splittedHost[splittedHost.length - 1]
          }/api?cityId=${selectedCityId}`;
        }
        return '';
      };

      const attachmentUrl = computeUrl(host);

      if (attachmentUrl) {
        const headers = new Headers();
        headers.set('Content-Type', 'application/json;charset=UTF-8');
        headers.set('Accept', 'application/json');
        headers.set('Origin', window.location.origin);
        headers.set('Host', host);
        void fetch(attachmentUrl, {
          method: 'GET',
          headers,
          mode: 'no-cors',
        });
      }
    } catch (e) {
      // NO OP
    }
  };

  componentDidMount = async (): Promise<any> => {
    await this.fetchConfig();

    const credentials = getCredentials();
    const authStored = getAuthLocalStore();
    if (!credentials) {
      return;
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      selectedCityId: credentials.cityId,
      login: credentials.login || '',
      deviceId: credentials.deviceId,
      deviceSecret: credentials.deviceSecret,
    });

    if (
      authStored.accessToken &&
      authStored.refreshToken &&
      authStored.expiresAt
    ) {
      if (Date.now() <= authStored.expiresAt.getTime()) {
        this.props.dispatch(loginAction(credentials.cityId, []));
        this.tryFetchUserInfo(this.props);
      } else {
        clearAuthLocalStore();
      }
    }

    // perform empty api call to check if the attachment module's url is available to the local network
    this.performAttachmentDummyApiCall(credentials.cityId);
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps = (newProps: LoginFormProps) => {
    this.tryFetchUserInfo(newProps);
  };

  onSelectCity = (selectedCityId: any) =>
    this.setState({ error: undefined, selectedCityId });
  onChangeLogin = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ error: undefined, login: event.target.value });
  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ error: undefined, password: event.target.value });

  onTryConnect = async () => {
    const {
      selectedCityId,
      login,
      password,
      deviceId,
      deviceSecret,
    } = this.state;
    if (!selectedCityId) {
      this.setState({ error: _tg('login.noCitySelected') });
      return;
    }

    this.setState({ busy: true });
    try {
      const authResponse = await doAuthAndRetrieveToken(
        selectedCityId,
        login,
        password,
        deviceId,
        deviceSecret
      );

      // Update AuthLocalStorage
      const expiration = new Date();
      expiration.setSeconds(expiration.getSeconds() + authResponse.expiresIn);
      const authToStore = {
        refreshToken: authResponse.refreshToken,
        accessToken: authResponse.accessToken,
        expiresAt: expiration,
      };
      setAuthLocalStore(authToStore);

      this.props.dispatch(loginAction(selectedCityId, authResponse.scope));
      setCredentials({
        cityId: selectedCityId,
        login,
        deviceId,
        deviceSecret,
      });
      this.tryFetchUserInfo(this.props);

      // perform empty api call to check if the attachment module's url is available to the local network
      this.performAttachmentDummyApiCall(selectedCityId);
      // Show warning message based on deviceId token expiration
      if (deviceId) {
        const tokenExpirationDate = await getTokenExpiration(deviceId);

        // global admin tokens doesn't have expiration date
        if (tokenExpirationDate) {
          if (
            (new Date(tokenExpirationDate).getTime() - new Date().getTime()) /
              (1000 * 3600 * 24) <=
            7
          ) {
            alert(
              'Votre token va bientôt expirer, veuillez demander un nouveau token à votre administrateur.'
            );
          }
        }
      }
    } catch (e) {
      if (e.json.error === 'invalid_second_factor') {
        const promptResponse = prompt(
          'La connexion sur l’application exige un token dont la durée de validité est limitée à un an. Si vous n’avez jamais eu de token ou bien si votre token a expiré, veuillez demander un nouveau token à votre administrateur.'
        );
        if (promptResponse) {
          const [newDeviceId, newDeviceSecret] = promptResponse.split(':');
          this.setState(
            { deviceId: newDeviceId, deviceSecret: newDeviceSecret },
            () => {
              this.onTryConnect();
            }
          );
          return;
        }
        this.setState({
          error: _tg('login.invalidCredentials'),
        });
      } else if (e.json.error === 'TooManyAttempts') {
        this.setState({
          tooManyAttemptsError: true,
        });
      } else if (e.json.error === 'PasswordExpired') {
        this.setState({
          passwordExpiredError: true,
        });
      } else if (!(e instanceof SyntaxError)) {
        const error =
          e.json.error && translateError(e.json.error).trim()
            ? translateError(e.json.error)
            : e.json.message;
        this.setState({
          error,
        });
      }
      this.setState({ busy: false });
    }
  };

  onClickDelete = () => {
    clearAuthLocalStore();
    clearCredentials(false); // Clear everything
    clearUniverses();
    this.setState({ login: '', deviceId: null, deviceSecret: null });
  };

  fetchConfig = async () => {
    const logos = await listLogos();
    this.setState({ logos });
  };

  tryFetchUserInfo = (props: LoginFormProps) => {
    const { refreshToken } = getAuthLocalStore();
    if (refreshToken && !this.fetchingUserInfo) {
      this.fetchingUserInfo = true;
      this.setState({ busy: true });
      try {
        props.dispatch(fetchUserInfoAction());
        this.fetchingUserInfo = false;
      } catch (e) {
        this.fetchingUserInfo = false;
        this.setState({ busy: false });
        console.warn('Unable to get user info', e); // eslint-disable-line no-console
      }
    } else {
      this.setState({ busy: false });
    }
  };

  moveToNextInput = (e: any) => {
    if (e.key === 'Enter') {
      if (e.currentTarget.id === 'city') {
        document.getElementById('username')?.focus();
      }
      if (e.currentTarget.id === 'username')
        document.getElementById('password')?.focus();
      if (e.currentTarget.id === 'password') this.onTryConnect();
    }
  };

  setHidePassword = () => {
    const { hidePassword } = this.state;
    this.setState({
      hidePassword: !hidePassword,
    });
  };

  passwordReset = () => {
    this.setState({
      tooManyAttemptsError: false,
      isPasswordResetSucceeded: false,
      isRequestingPasswordReset: !this.state.isRequestingPasswordReset,
    });
  };

  requestReset = async () => {
    const { selectedCityId, login, deviceId, deviceSecret } = this.state;
    if (!selectedCityId) {
      this.setState({ error: _tg('login.noCitySelected') });
      return;
    }
    await requestPasswordReset(selectedCityId, login, deviceId, deviceSecret)
      .then(() => {
        this.setState({ isPasswordResetSucceeded: true });
      })
      .catch(err => {
        if (err instanceof SyntaxError || err?.name === 'SyntaxError') {
          this.setState({ isPasswordResetSucceeded: true });
        } else if (err.json.error === 'invalid_second_factor') {
          const promptResponse = prompt(
            _tg('dashboard.login.loginForm.message')
          );
          if (promptResponse) {
            const [newDeviceId, newDeviceSecret] = promptResponse.split(':');
            this.setState(
              { deviceId: newDeviceId, deviceSecret: newDeviceSecret },
              () => {
                this.requestReset();
              }
            );
            return;
          }
          this.setState({
            busy: false,
            error: _tg('login.invalidCredentials'),
          });
        } else {
          this.setState({ error: translateError(err.json?.error) });
        }
      });
  };

  render() {
    const {
      logos,
      login,
      password,
      error,
      busy,
      selectedCityId,
      isPasswordResetSucceeded,
      isRequestingPasswordReset,
      tooManyAttemptsError,
      passwordExpiredError,
      hidePassword,
    } = this.state;

    const credentialsStoredOnComputer = !!getCredentials();
    const tepv = window.location.hostname.includes('tepv');

    const cityOptions = logos?.map(logo => ({
      label: logo.name,
      cityId: logo.cityId,
      logo: logo.logo,
    }));

    const IconOption = (option: any) => (
      <div style={{ display: 'flex' }}>
        <img src={option.logo} style={{ height: '30px', width: '30px' }} />
        <span style={{ flex: 1, marginTop: '2px' }}>{option.label}</span>
      </div>
    );

    return (
      <div style={STYLE_CONTAINER}>
        {isPasswordResetSucceeded ? (
          <div style={PASSWORD_RESET_SUCCEEDED}>
            <p style={{ marginInline: 10 }}>
              {_tg('password.successfullyResetPassword')}
            </p>
            <CheckCircle
              style={{ width: 100, height: 100 }}
              color={greenA700}
            />
          </div>
        ) : (
          <>
            <div style={STYLE_LOGO_BOX}>
              <img
                src={
                  tepv ? 'static/tepv_200_180.png' : 'static/tefps_200_180.png'
                }
                style={{ width: 100, height: 90 }}
              />
            </div>
            <div style={STYLE_LOGIN_BOX}>
              {!logos || busy ? (
                <CircularProgress />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Select
                    autoFocus
                    aria-label="Ville"
                    placeholder="Ville"
                    id="city"
                    options={cityOptions}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={selectedOption => {
                      this.onSelectCity(selectedOption?.cityId);
                    }}
                    value={cityOptions?.filter(
                      option => option.cityId === selectedCityId
                    )}
                    formatOptionLabel={IconOption}
                    getOptionValue={selectedOption => selectedOption.cityId}
                    styles={REACT_SELECT_STYLE}
                    onKeyDown={this.moveToNextInput}
                  />
                  <br />
                  <TextField
                    hintText="Nom d'utilisateur"
                    floatingLabelText="Nom d'utilisateur"
                    value={login}
                    onChange={this.onChangeLogin}
                    id="username"
                    fullWidth
                    onKeyDown={this.moveToNextInput}
                  />
                  <br />
                  {!isRequestingPasswordReset && (
                    <div style={{ width: '100%' }}>
                      <TextField
                        hintText="Mot de passe"
                        floatingLabelText="Mot de passe"
                        type={hidePassword ? 'password' : 'text'}
                        value={password}
                        onChange={this.onChangePassword}
                        id="password"
                        onKeyDown={this.moveToNextInput}
                      />
                      <IconButton
                        tooltip={
                          hidePassword
                            ? _tg('dashboard.login.loginForm.showPassword')
                            : _tg('dashboard.login.loginForm.hidePassword')
                        }
                        tooltipPosition="top-center"
                        onClick={() => this.setHidePassword()}
                      >
                        {hidePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  )}
                  <br />
                  {isRequestingPasswordReset ? (
                    <BoButton
                      label="Réinitialisez"
                      primary
                      style={{ marginTop: 30 }}
                      onClick={this.requestReset}
                    />
                  ) : (
                    <BoButton
                      label="Se connecter"
                      primary
                      style={{ marginTop: 30 }}
                      onClick={this.onTryConnect}
                    />
                  )}
                  {(tooManyAttemptsError || passwordExpiredError) && (
                    <>
                      <br />
                      <br />
                      <div style={{ margin: `auto`, color: `red` }}>
                        {tooManyAttemptsError && (
                          <p>
                            <Trans
                              i18nKey={_tg(
                                'dashboard.login.loginForm.tooManyAttemptsError'
                              )}
                              components={[<br />]}
                            />
                          </p>
                        )}
                        {passwordExpiredError && (
                          <p>
                            <Trans
                              i18nKey={_tg(
                                'dashboard.login.loginForm.passwordExpiredError'
                              )}
                              components={[<br />]}
                            />
                          </p>
                        )}
                      </div>
                      <br />
                      <br />
                    </>
                  )}
                </div>
              )}
              {!credentialsStoredOnComputer ? null : (
                <div
                  onClick={this.onClickDelete}
                  style={{
                    position: 'fixed',
                    textAlign: 'right',
                    bottom: 15,
                    right: 15,
                    width: 500,
                    color: '#EDEDED',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <Trans
                    i18nKey="dashboard.login.loginForm.credentialsStoredOnComputer"
                    components={[<br />]}
                  />
                </div>
              )}
            </div>
          </>
        )}

        <div style={STYLE_RESET_PASSWORD}>
          {!error ? null : (
            <Snackbar open message={error} autoHideDuration={4000} />
          )}
          {isRequestingPasswordReset ? (
            <a onClick={this.passwordReset}>
              {_tg('dashboard.login.loginForm.backToLogin')}
            </a>
          ) : (
            <a onClick={this.passwordReset}>
              {_tg('dashboard.login.loginForm.resetPassword')}
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(LoginForm);
