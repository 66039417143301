import React from 'react';
import Checkbox from 'material-ui/Checkbox';

import attachToForm from 'commons/FormComponent/attachToForm';

type Props = {
  name: string;
  value: boolean | null | undefined;
  validateField: (
    value: boolean | null | undefined
  ) => string | null | undefined;
  onValueChange: (newValue: boolean | null | undefined) => void;
  error?: string | null | undefined;
  label?: string;
};

class CheckboxCustom extends React.Component<Props> {
  onCheck = (event: any, isChecked: boolean) => {
    const { onValueChange } = this.props;
    onValueChange(isChecked);
  };

  render() {
    const {
      name,
      value,
      label, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validateField, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onValueChange,
      ...otherProps
    } = this.props;
    return (
      <Checkbox
        {...otherProps}
        key={name}
        label={label}
        checked={!!value}
        onCheck={this.onCheck}
      />
    );
  }
}

const AttachedForm = attachToForm((p: any) => <CheckboxCustom {...p} />);
export default AttachedForm;
