import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Dialog from 'material-ui/Dialog';
import SuccessIcon from 'material-ui/svg-icons/action/check-circle';
import ErrorIcon from 'material-ui/svg-icons/action/highlight-off';
import WaitIcon from 'material-ui/svg-icons/action/schedule';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import { BKG_GREEN, BKG_PINK, TXT_BLACK } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  width: 25,
  height: 25,
  margin: '2px 10px 2px 0',
};

type Props<T> = {
  title: string;
  objects: Map<string, T>;
  singleAction: (arg0: string) => Promise<string | void>;
  onClose: () => void;
  displayActionResponse: boolean;
};

type ActionStatus<T> = {
  object: T;
  status: string | null | undefined;
  response: string | null | undefined;
};

function renderIcon(status: string | null | undefined) {
  const SUCCESS = _tg('commons.success');
  const FAILURE = _tg('commons.failure');
  let icon = <WaitIcon style={STYLE_ICON} color={TXT_BLACK} />;
  if (status === SUCCESS) {
    icon = <SuccessIcon style={STYLE_ICON} color={BKG_GREEN} />;
  } else if (status === FAILURE) {
    icon = <ErrorIcon style={STYLE_ICON} color={BKG_PINK} />;
  }
  return icon;
}

const ProgressionModal = <T extends unknown>({
  title,
  objects,
  singleAction,
  onClose,
  displayActionResponse,
}: Props<T>): JSX.Element => {
  const [statusMap, setStatusMap] = useState<Map<string, ActionStatus<T>>>(
    new Map()
  );

  const SUCCESS = _tg('commons.success');
  const FAILURE = _tg('commons.failure');

  const TABLE_COLS = useMemo(() => {
    const result = [
      { label: '', width: 30 },
      { label: _tg('field.id'), width: 250 },
      {
        label: _tg('field.status'),
        width: 100,
      },
    ];

    if (displayActionResponse) {
      result.push({ label: _tg('field.comment'), width: 300 });
    }
    return result;
  }, []);

  useEffect(() => {
    async function process() {
      const keys: Array<string> = Array.from(objects.keys());
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = objects.get(key);
        if (!value) {
          return;
        }
        let result: ActionStatus<T> | null = null;
        try {
          // Les requêtes doivent être synchrones et exécutées les unes à la suite des autres
          // eslint-disable-next-line no-await-in-loop
          const response = await singleAction(key);
          result = {
            object: value,
            status: SUCCESS,
            response: response || null,
          };
        } catch (err) {
          result = {
            object: value,
            status: FAILURE,
            response: (err as Error).message,
          };
        } finally {
          setStatusMap(oldStatusMap => {
            const newStatusMap = new Map<string, ActionStatus<T>>(oldStatusMap);
            if (result) {
              newStatusMap.set(key, result);
            }
            return newStatusMap;
          });
        }
      }
    }

    if (objects.size) {
      void process();
    }
  }, [objects]);

  const renderRow = useCallback(
    (objectId: string) => {
      const state = statusMap.get(objectId);

      const icon = renderIcon(state?.status || null);
      let status = _tg('status.pending');
      if (state) {
        status =
          state.status === SUCCESS
            ? _tg('commons.success')
            : _tg('commons.failure');
      }

      return displayActionResponse
        ? [icon, objectId, status, state?.response || '']
        : [icon, objectId, status];
    },
    [statusMap, displayActionResponse]
  );

  const close = useCallback(() => {
    setStatusMap(new Map()); // clears in case the user reopens the modal with the same objects
    onClose();
  }, [onClose]);

  const action = (
    <BoButton
      style={{ marginRight: 10 }}
      key="progression-modal-button"
      label={_tg('action.close')}
      onClick={close}
      disabled={objects.size !== statusMap.size}
    />
  );

  return (
    <Dialog title={title} modal autoScrollBodyContent open actions={[action]}>
      <SimpleTable
        maxHeight={300}
        cols={TABLE_COLS}
        rowHeight={50}
        header
        itemsRenderer={renderRow}
        style={{ marginTop: 10 }}
        items={Array.from(objects.keys())}
      />
    </Dialog>
  );
};

export default ProgressionModal;
