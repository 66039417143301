import * as React from 'react';
import ImageIcon from 'material-ui/svg-icons/image/image';
import PdfIcon from 'material-ui/svg-icons/image/picture-as-pdf';
import LinkIcon from 'material-ui/svg-icons/content/link';
import ExcelIcon from 'material-ui/svg-icons/editor/format-list-numbered';

import SvgIconProps = __MaterialUI.SvgIconProps;

type Props =
  | {
      type: string;
    }
  | SvgIconProps;

export const IconFromDocumentMimeType = (props: Props): JSX.Element => {
  const { type } = props;

  if (type && /image\/.*/.test(type)) {
    return <ImageIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
  }

  switch (type) {
    case 'application/pdf':
      return <PdfIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
    case 'application/vnd.ms-excel':
      return <ExcelIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <ExcelIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
    default:
      return <LinkIcon {...props} />; // eslint-disable-line react/jsx-props-no-spreading
  }
};
