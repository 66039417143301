import { apiDelete, apiGet, apiPut } from 'api/helpers';

import { PresetDTO } from './types';

const BASE_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/preset-notes';

export async function getPresetNotes(): Promise<Array<PresetDTO>> {
  return apiGet<Array<PresetDTO>>(BASE_URL);
}

export async function upsertPresetNote(
  noteId: string,
  preset: PresetDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${noteId}`, preset);
}

export async function deletePresetNote(noteId: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${noteId}`);
}
