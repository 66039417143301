import { applyWatermarkToFile } from '@cvfm-front/commons-utils';

/**
 * @return the uploaded file URN
 */
export async function uploadFileAndReturnUrn(
  mediaUpload: string,
  file: File,
  onProgress?: (progress: number) => void
): Promise<string> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = event => {
      if (onProgress) {
        onProgress(event.loaded / event.total);
      }
    };

    xhr.onerror = err => reject(`Error in request ${err.toString()}`);

    xhr.onload = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          try {
            resolve(JSON.parse(xhr.responseText).url);
          } catch (err) {
            reject(
              `Error while parsing media upload response after 200: ${xhr.responseText}`
            );
          }
        } else {
          try {
            reject(JSON.parse(xhr.responseText).message);
          } catch (err) {
            reject(
              `Error while parsing media upload response: ${xhr.responseText}`
            );
          }
        }
      }
    };

    xhr.open('POST', mediaUpload, true);
    xhr.send(formData);
  });
}

export async function uploadFileV2(
  uploadUrl: string,
  file: File,
  watermarkText: string | null,
  onProgress?: (progress: number) => void,
  onError?: (ev: ProgressEvent<EventTarget>) => void
): Promise<void> {
  let fileToUpload = file;
  if (watermarkText) {
    try {
      fileToUpload = await applyWatermarkToFile(file, watermarkText);
    } catch (err) {
      // ignore the error, use non-watermarked file
    }
  }
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = event => {
      if (onProgress) {
        onProgress(event.loaded / event.total);
      }
    };
    xhr.onload = () => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          resolve();
        }
        reject(new Error('Failed to upload file '));
      }
    };
    if (onError) {
      xhr.onerror = onError;
    }
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(fileToUpload);
  });
}
