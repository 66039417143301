import {
  ProductPrivateDTO,
  ProductSearchQueryDTO,
  ProductType,
} from '@cvfm-front/tefps-types/build';
import { PatchObject } from 'api/commonTypes';
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from 'api/helpers';

const BASE_URL = '/api/proxy/subscription/api/v2/prv/cities/{cityId}/product/';
const BASE_URL_BO = '/api/prv/v2/cities/{cityId}/products/';

export async function searchProducts(
  filters: ProductSearchQueryDTO
): Promise<Array<ProductPrivateDTO>> {
  return apiPost<Array<ProductPrivateDTO>>(BASE_URL, filters);
}

export function exportProducts(productType: ProductType): Promise<unknown> {
  return apiGet<unknown>(`${BASE_URL_BO}/export?productType=${productType}`);
}

export async function createProduct(
  create: ProductPrivateDTO
): Promise<ProductPrivateDTO> {
  return apiPut<ProductPrivateDTO>(`${BASE_URL}/${create.productId}`, create);
}

export async function getProductById(
  productId: string
): Promise<ProductPrivateDTO> {
  return apiGet<ProductPrivateDTO>(`${BASE_URL}/${productId}`);
}

export async function patchProductById(
  productId: string,
  patches: Array<PatchObject<unknown>>
): Promise<ProductPrivateDTO> {
  return apiPatch<ProductPrivateDTO>(`${BASE_URL}/${productId}`, patches);
}

export async function deleteProductById(productId: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${productId}`);
}

export async function recomputeReminders(productId: string): Promise<void> {
  return apiPost<void>(
    `${BASE_URL}/${productId}/_recompute_reminders`,
    undefined
  );
}
