import React from 'react';
import moment, { Moment } from 'moment';
import TextField from 'material-ui/TextField';
import IntlPolyfill from 'intl';
import DatePicker from 'material-ui/DatePicker';

import { formatDate, toHours, toMinutes } from 'commons/Utils/dateUtil';

const { _tg } = window.loadTranslations();

type DateAndTimeSelectorProps = {
  date: Moment;
  onUpdate: (arg0: Moment) => void;
  style: Record<string, any>;
};

function DateAndTimeSelector({
  date,
  onUpdate,
  style,
}: DateAndTimeSelectorProps) {
  function onChangeDate(useless: any, newDate: Date) {
    const newDateTime = moment(newDate);
    newDateTime.hours(date.hours());
    newDateTime.minutes(date.minutes());
    onUpdate(newDateTime);
  }

  function onChangeMinutes(
    e: React.ChangeEvent<HTMLInputElement>,
    minutes: string
  ): void {
    onUpdate(date.clone().minutes(toMinutes(minutes)));
  }

  function onChangeHours(
    e: React.ChangeEvent<HTMLInputElement>,
    hours: string
  ): void {
    onUpdate(date.clone().hours(toHours(hours)));
  }

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <DatePicker
        DateTimeFormat={IntlPolyfill.DateTimeFormat}
        locale={window.i18next.language}
        autoOk
        textFieldStyle={{ width: 125, marginRight: 5 }}
        value={date.toDate()}
        onChange={onChangeDate}
        floatingLabelText={_tg('field.date.date')}
        container="inline"
        formatDate={formatDate}
      />
      <TextField
        underlineFocusStyle={style}
        floatingLabelFocusStyle={style}
        min={0}
        max={24}
        step={1}
        floatingLabelText={_tg('field.date.hours')}
        type="number"
        style={{ width: 50, marginRight: 5 }}
        value={date.hours()}
        onChange={onChangeHours}
      />
      :
      <TextField
        underlineFocusStyle={style}
        floatingLabelFocusStyle={style}
        min={0}
        max={60}
        step={1}
        floatingLabelText={_tg('field.date.min_plural')}
        type="number"
        style={{ width: 50, marginLeft: 5 }}
        value={date.minutes()}
        onChange={onChangeMinutes}
      />
    </div>
  );
}

export default DateAndTimeSelector;
