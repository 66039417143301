import React from 'react';

import { STYLE_FAKE_INPUT, STYLE_ITEM, STYLE_ITEM_NAME } from 'theme';

const Item = ({
  name,
  value,
  style,
  styleName,
  ...otherProps
}: {
  name: string;
  value: any;
  style?: any;
  styleName?: any;
}) => {
  if (value === undefined || value === null) return null;
  return (
    <div style={{ ...STYLE_ITEM, ...style }}>
      <div style={{ ...STYLE_ITEM_NAME, ...styleName }}>{name}</div>
      <div style={STYLE_FAKE_INPUT} {...otherProps}>
        {value}
      </div>
    </div>
  );
};

export default Item;
