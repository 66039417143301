import {
  ChartStatisticsResult,
  ParkingRightsChartRequestFilters,
} from '@cvfm-front/tefps-types';
import { apiPost } from 'api/helpers';

const BASE_PRV_URL = '/api/proxy/tv/api/cities/{cityId}/tickets/v1/admin';

export async function searchParkingRightStatistics(
  filters: ParkingRightsChartRequestFilters
): Promise<ChartStatisticsResult> {
  return apiPost<ChartStatisticsResult>(`${BASE_PRV_URL}/statistics`, filters);
}
