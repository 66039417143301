import * as React from 'react';

import { STYLE } from '../style';
import { IconFromDocumentMimeType } from '../IconFromDocumentMimeType';

type Props = {
  filename: string;
  filetype: string;
} & React.HTMLProps<HTMLElement>;

export const DisplayFile = (props: Props): JSX.Element => {
  // We need props to be defined since we pass all props to the parent element
  const { filename, filetype } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span style={STYLE.DISPLAY_FILE} {...props}>
      <IconFromDocumentMimeType
        type={filetype}
        style={STYLE.DISPLAY_FILE_ICON}
      />
      <div style={STYLE.DISPLAY_FILE_FILENAME}>{filename}</div>
    </span>
  );
};
