import * as React from 'react';
import TextField from 'material-ui/TextField';

import { RessourceType } from 'api/documentLibrary/types';
import InputFile from 'commons/InputFile';

const { _tg } = window.loadTranslations();

export type UploadModalBodyProps = {
  file: File | null | undefined;
  onDrop: (files: File[]) => void;
  onLinkNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLinkUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ressourceType: RessourceType;
  isLoading: boolean;
};

/*
 ** UploadModalBody:
 ** Render body for file upload or link upload
 */
export const UploadModalBody = ({
  file,
  onDrop,
  onLinkNameChange,
  onLinkUrlChange,
  ressourceType,
  isLoading,
}: UploadModalBodyProps): JSX.Element => {
  return ressourceType === 'FILE' ? (
    <div style={{ textAlign: 'center' }}>
      <InputFile
        accept="application/pdf, image/*, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onDrop={onDrop}
        filename={file ? file.name : ''}
        loading={isLoading}
      />
    </div>
  ) : (
    <div>
      <TextField
        floatingLabelText={_tg('tefps.documentLibrary.upload.linkName')}
        onChange={onLinkNameChange}
        fullWidth
      />
      <br />
      <TextField
        floatingLabelText={_tg('tefps.documentLibrary.upload.linkUrl')}
        hintText="https://exemple.fr/ressource"
        onChange={onLinkUrlChange}
        fullWidth
      />
    </div>
  );
};
