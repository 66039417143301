import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';
import Snackbar from 'material-ui/Snackbar';

import attachToForm from 'commons/FormComponent/attachToForm';

import { FormField } from '../types';

const STYLE_CHECKBOX: CSSProperties = {
  width: 26,
};

const STYLE_CHECKBOX_ICON: CSSProperties = {
  marginRight: 8,
};

interface Props extends FormField<string> {
  name: string;
  hint?: string;
  checkboxTitle: string | undefined;
  onCheckAction: () => Promise<string>;
}

type State = {
  errorMessage: string;
  shortIdLoading: boolean;
};

/**
 * Si la checkbox est cochée, le champ devient nul.
 */
class ShortIdFieldWithCheckbox extends React.Component<Props, State> {
  state = {
    errorMessage: '',
    shortIdLoading: false,
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    const { onValueChange } = this.props;
    onValueChange(newValue);
  };

  onCheck = async (
    event: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => {
    this.setState({ shortIdLoading: true });
    const { onValueChange, onCheckAction } = this.props;
    if (isInputChecked) {
      try {
        const shortId = await onCheckAction();
        onValueChange(shortId);
      } catch (err) {
        this.setState({ errorMessage: err.json.message });
      }
    } else {
      onValueChange(null);
    }
    this.setState({ shortIdLoading: false });
  };

  onBlur = () => {
    const { value, validateField } = this.props;
    validateField(value || null);
  };

  handleRequestClose = () => this.setState({ errorMessage: '' });

  render() {
    const {
      name,
      hint,
      error,
      value,
      checkboxTitle, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      validateField, // remove from otherProps
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onValueChange,
      ...otherProps
    } = this.props;
    const { errorMessage, shortIdLoading } = this.state;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onCheckAction, ...myProps } = otherProps;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <TextField
          {...myProps}
          key={name}
          value={value || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          floatingLabelText={hint}
          errorText={error}
        />
        {shortIdLoading ? (
          <CircularProgress />
        ) : (
          <Checkbox
            style={STYLE_CHECKBOX}
            iconStyle={STYLE_CHECKBOX_ICON}
            onCheck={this.onCheck}
            checked={!!value}
            title={checkboxTitle}
          />
        )}
        <Snackbar
          open={errorMessage !== ''}
          message={errorMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    );
  }
}

const AttachedForm = attachToForm((p: any) => (
  <ShortIdFieldWithCheckbox {...p} />
));
export default AttachedForm;
