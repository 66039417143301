import React, { CSSProperties } from 'react';
import Dropzone from 'react-dropzone';
import SuccessIcon from 'material-ui/svg-icons/navigation/check';
import ClearIcon from 'material-ui/svg-icons/content/clear';
import AddIcon from 'material-ui/svg-icons/content/add';

import { ANTAI_BLUE, BKG_PINK, TXT_BLACK, BRD_GREY } from 'theme';
import attachToForm from 'commons/FormComponent/attachToForm';
import { ControlMediaType, UploadFile } from 'api/commonTypes';
import { formatNumber } from 'commons/Utils/numberUtil';

import { FormField } from '../types';

const { _tg } = window.loadTranslations(__filename);

const STYLE_DROPZONE = {
  border: `2px dashed ${BRD_GREY}`,
  padding: '10px 50px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: 5,
  margin: '10 2em auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 14,
  color: TXT_BLACK,
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: 30,
  height: 28,
};

const STYLE_ERROR: CSSProperties = {
  color: 'red',
  fontSize: 12,
  height: 30,
  textAlign: 'center',
  marginTop: 5,
};

const STYLE_FILE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0 10px auto',
  height: 52,
  width: '80%',
  position: 'relative',
};

const STYLE_NAME_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: 12,
  color: TXT_BLACK,
  overflow: 'hidden',
};

const STYLE_FILE_NAME: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '75%',
};

type UploadFileCustomProps = FormField<UploadFile> & {
  onValueChange: (value: UploadFile[]) => string | null | undefined;
  allValues?: UploadFile[];
  hint: string;
  mandatory?: boolean;
  accept?: string | null;
  multipleFiles: boolean;
  mediaType?: ControlMediaType;
};

// let currentFiles: UploadFile[] = [];

const UploadFileCustom = ({
  hint,
  mandatory,
  value,
  allValues,
  error,
  accept,
  multipleFiles,
  validateField,
  onValueChange,
  mediaType,
}: UploadFileCustomProps) => {
  const onDrop = (files: Array<File>) => {
    const newUploadFiles: UploadFile[] = files
      .map(file => {
        return {
          file,
          urn: '',
          created: new Date().toISOString(),
          size: file.size,
          mime: file.type,
          name: file.name,
          uploaded: false,
          contentUrl: '',
          mediaType,
        };
      })
      .filter(newFile => !validateField(newFile));
    onValueChange(allValues ? allValues.concat(newUploadFiles) : []);
  };

  const removeFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newFiles = (allValues ?? []).filter(
      f => f.file?.name !== e.currentTarget.id
    );
    onValueChange(newFiles);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '49%',
      }}
    >
      <div style={STYLE_TITLE}>
        {hint}
        {mandatory && <span style={{ color: BKG_PINK }}> *</span>}
      </div>
      {!value && (
        <Dropzone
          multiple={multipleFiles}
          disablePreview
          accept={accept || 'application/pdf, image/*'}
          onDrop={onDrop}
          style={STYLE_DROPZONE}
        >
          <AddIcon />
        </Dropzone>
      )}
      {value && (
        <div style={STYLE_FILE}>
          <div
            onClick={removeFile}
            style={{ cursor: 'pointer' }}
            title={_tg('action.delete')}
            id={value.name}
          >
            <ClearIcon color={TXT_BLACK} />
          </div>
          <SuccessIcon color={ANTAI_BLUE} />
          <div style={STYLE_NAME_CONTAINER}>
            <div style={STYLE_FILE_NAME} title={value.name}>
              {value.contentUrl ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={value.contentUrl}
                  style={{ textDecoration: 'underline' }}
                >
                  {value.name}
                </a>
              ) : (
                value.name
              )}
            </div>
            <div>
              <span style={{ textTransform: 'uppercase' }}>
                {value.name.split('.').pop()}
              </span>
              <span> | </span>
              {formatNumber(value.size, 'filesize')}
            </div>
          </div>
        </div>
      )}
      <div style={STYLE_ERROR}>{error}</div>
    </div>
  );
};

const AttachedForm = attachToForm((p: any) => <UploadFileCustom {...p} />);
export default AttachedForm;
