import { apiDelete, apiGet, apiPut } from 'api/helpers';

import { DecreeDTO } from './types';

const BASE_PRIVATE_URL =
  '/api/proxy/pv/api/v1/customers/{cityId}/private/decrees';
const BASE_PUBLIC_URL =
  '/api/proxy/pv/api/v1/dataset/customers/{cityId}/decrees';

export async function getDecrees(): Promise<Array<DecreeDTO>> {
  return apiGet<Array<DecreeDTO>>(BASE_PUBLIC_URL);
}

export async function createOrUpdateDecree(decree: DecreeDTO): Promise<void> {
  return apiPut<void>(
    `${BASE_PRIVATE_URL}/${encodeURIComponent(decree.reference)}`,
    decree
  );
}

export async function deleteDecree(decreeReference: string): Promise<void> {
  return apiDelete<void>(
    `${BASE_PRIVATE_URL}/${encodeURIComponent(decreeReference)}`
  );
}
