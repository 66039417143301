import React from 'react';

import { BKG_PINK } from 'theme';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { TextFieldCustom } from 'commons/FormComponent/Fields';
import { ControlAddressType } from 'api/commonTypes';
import { validatePostalCode } from 'commons/Validators';
import { isAddressSubRegionValid } from 'tefps/Fps/List/AddFPS/validators';

import 'commons/css/AddressForm.css';

export const FIELD_STREET_NUMBER = 'streetNumber';
export const FIELD_STREET_NUMBER_BIS = 'streetNumberBis';
export const FIELD_STREET_TYPE = 'streetType';
export const FIELD_STREET_NAME = 'streetName';
export const FIELD_SPECIAL_PLACE = 'specialPlace';
export const FIELD_POST_OFFICE_BOX_NUMBER = 'postOfficeBoxNumber';
export const FIELD_POSTAL_CODE = 'postalCode';
export const FIELD_SUB_REGION = 'addressSubRegion';
export const FIELD_REGION = 'addressRegion';
export const FIELD_LOCALITY = 'addressLocality';
export const FIELD_COUNTRY = 'addressCountry';

type Props = {
  title: string;
  preFill?: ControlAddressType;
  readOnlyFields?: string[];
  hideFields?: string[];
};

const AddressForm = ({
  title,
  preFill,
  readOnlyFields,
  hideFields,
}: Props): React.ReactElement<any> => {
  const renderAddressField = (
    name: string,
    hint?: string,
    mandatory?: boolean,
    validators?: any[],
    maxLength?: number
  ) => {
    const id = `address-form-field-${name}`;
    const value: string = preFill ? (preFill[name] as string) : '';
    return (
      <>
        {!hideFields?.includes(name) && (
          <TextFieldCustom
            id={id}
            name={name}
            className="address-form-field"
            hint={hint}
            value={value}
            validators={validators}
            maxLength={maxLength}
            mandatory={mandatory}
            disabled={readOnlyFields?.includes(name)}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <SeparatorWithTitle
        className="address-form-title"
        title={title}
        color={BKG_PINK}
        titleSize={20}
      />
      <div key="address" className="address-form-fields-container">
        {renderAddressField(FIELD_STREET_NUMBER, 'Numéro de rue', false, [], 3)}
        {renderAddressField(
          FIELD_STREET_NUMBER_BIS,
          'Numéro de rue bis',
          false,
          [],
          3
        )}
        {renderAddressField(
          FIELD_STREET_TYPE,
          'type de voie (rue, avenue, impasse...)',
          false,
          [],
          32
        )}
        {renderAddressField(FIELD_STREET_NAME, 'Nom de rue', true, [], 80)}
        {renderAddressField(
          FIELD_POST_OFFICE_BOX_NUMBER,
          'Numéro de boite postale',
          false,
          [],
          32
        )}
        {renderAddressField(FIELD_SPECIAL_PLACE, 'Lieu-dit', false, [], 80)}
        {renderAddressField(
          FIELD_POSTAL_CODE,
          'Code postal',
          true,
          [validatePostalCode],
          8
        )}
        {renderAddressField(
          FIELD_SUB_REGION,
          'Numéro de département',
          false,
          [isAddressSubRegionValid],
          3
        )}
        {renderAddressField(FIELD_REGION, 'Région', false, [], 80)}
        {renderAddressField(FIELD_LOCALITY, 'Ville', true, [], 80)}
      </div>
    </div>
  );
};

export default AddressForm;
