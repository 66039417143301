import * as React from 'react';
import { connect } from 'react-redux';

import { getApiState, InternalApiState } from 'api/duck';

import { getAuthLocalStore } from './auth-storage';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';

type Props = {
  api: InternalApiState;
  children: any;
};

const Login = ({ api, children }: Props) => {
  const authStored = getAuthLocalStore();
  if (api.userInfo?.resettingPassword) {
    return <ResetPasswordForm />;
  }
  if (
    !api.cityId ||
    !api.userInfo ||
    !authStored.expiresAt ||
    authStored.expiresAt.getTime() < Date.now()
  ) {
    return <LoginForm />;
  }

  return children;
};

export default connect(state => {
  const api = getApiState(state);
  return {
    api,
  };
})(Login);
