import { apiPatch } from '../helpers';
import { PatchObject } from '../commonTypes';

const BASE_PRV_URL = '/api/prv/v0/cities/{cityId}/kpi';

export async function upsertPmrPricingCategories(
  kpiPmrPricingCategories: PatchObject<unknown>
): Promise<void> {
  return apiPatch<void>(
    `${BASE_PRV_URL}/kpiPmrPricingCategories`,
    kpiPmrPricingCategories
  );
}

export async function upsertExportEmails(
  exportEmails: PatchObject<unknown>
): Promise<void> {
  return apiPatch<void>(`${BASE_PRV_URL}/exportEmails`, exportEmails);
}

export async function patchKpiConfiguration(
  patch: PatchObject<unknown>
): Promise<void> {
  return apiPatch<void>(`${BASE_PRV_URL}/patchKpiConfiguration`, [patch]);
}
