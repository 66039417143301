import { FieldsMap, AttachedField } from './types';

export const ATTACH_FIELD = 'Form/ATTACH_FIELD';
export const DETACH_FIELD = 'Form/DETACH_FIELD';

// attach a field component to the store
interface AttachFieldAction {
  type: typeof ATTACH_FIELD;
  field: AttachedField;
}

export function attachFieldAction(field: AttachedField): any {
  return (dispatch: any): void => {
    dispatch({
      type: ATTACH_FIELD,
      field,
    });
  };
}

// detach a field component from the store
interface DetachFieldAction {
  type: typeof DETACH_FIELD;
  field: AttachedField;
}

export function detachFieldAction(field: AttachedField): any {
  return (dispatch: any): void => {
    dispatch({
      type: DETACH_FIELD,
      field,
    });
  };
}

type Action = AttachFieldAction | DetachFieldAction;

const initialState = () => new Map();

export default function formStorage(
  state: FieldsMap = initialState(),
  action: Action
): FieldsMap {
  switch (action.type) {
    case ATTACH_FIELD: {
      const { field } = action;
      state.set(field.getName(), field);
      return state;
    }
    case DETACH_FIELD: {
      const { field } = action;
      state.delete(field.getName());
      return state;
    }
    default:
      return state;
  }
}
