import * as React from 'react';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';

import { COLOR_STYLE } from '../../style';

const { _tg } = window.loadTranslations();

type Props = {
  closeModals: () => any;
  isCreateSectionModalOpen: boolean;
  onSectionNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateSection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sectionName: string;
};

export const CreateSectionModalContainer = ({
  closeModals,
  sectionName,
  isCreateSectionModalOpen,
  onCreateSection,
  onSectionNameChange,
}: Props): JSX.Element => {
  return (
    <Dialog
      title={_tg('tefps.documentLibrary.sections.addSection')}
      open={isCreateSectionModalOpen}
      actions={[
        <BoButton label={_tg('action.back')} onClick={closeModals} />,
        <BoButton
          labelColor={COLOR_STYLE.LABEL_COLOR}
          backgroundColor={COLOR_STYLE.BKG_CYAN}
          label={_tg('action.add')}
          style={{ marginLeft: 8 }}
          onClick={onCreateSection}
        />,
      ]}
    >
      <TextField
        floatingLabelText={_tg('tefps.documentLibrary.sections.sectionName')}
        value={sectionName}
        onChange={onSectionNameChange}
      />
    </Dialog>
  );
};
