import { ImportResultDTO } from '../commonTypes';
import { apiDelete, apiGet, apiPost, apiPostCSV, apiPut } from '../helpers';

import {
  LicenseTokenDTO,
  LicenseTokenAlertDTO,
  LicenseTokenSecretDTO,
} from './types';

const BASE_BO_URL = '/api/prv/v0/city/{cityId}/devices';

export async function fetchDevices(): Promise<Array<LicenseTokenDTO>> {
  return apiGet<Array<LicenseTokenDTO>>(BASE_BO_URL);
}

export async function generateOrResetSingleCityToken(
  deviceId: string
): Promise<LicenseTokenSecretDTO> {
  return apiPost<LicenseTokenSecretDTO>(
    `${BASE_BO_URL}/${deviceId}`,
    undefined
  );
}

export async function generateOrResetMultipleCitiesToken(
  deviceId: string,
  cityIds: string[]
): Promise<LicenseTokenSecretDTO> {
  return apiPost<LicenseTokenSecretDTO>(
    `${BASE_BO_URL}/multipleCities/${deviceId}`,
    cityIds
  );
}

export async function updateTokenCities(
  deviceId: string,
  cityIds: string[]
): Promise<void> {
  return apiPut<void>(`${BASE_BO_URL}/${deviceId}`, cityIds);
}

export async function deleteDevice(
  deviceId: string
): Promise<LicenseTokenSecretDTO> {
  return apiDelete<LicenseTokenSecretDTO>(`${BASE_BO_URL}/${deviceId}`);
}

export async function addCertificate(certificate: string): Promise<void> {
  return apiPut<void>(`${BASE_BO_URL}`, certificate);
}

export async function detectTokenAlert(): Promise<LicenseTokenAlertDTO> {
  return apiGet<LicenseTokenAlertDTO>(`${BASE_BO_URL}/alert`);
}

export async function importDeviceFromCSV(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV(`${BASE_BO_URL}/import`, file);
}

export async function getTokenExpiration(
  deviceId: string
): Promise<Date | undefined> {
  return apiGet<Date | undefined>(`${BASE_BO_URL}/tokenExpiration/${deviceId}`);
}
