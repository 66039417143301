import * as React from 'react';

import { Address, ControlAddressType } from 'api/commonTypes';

/**
 * Build a str address
 */
export const formatAddress = (
  address: Address | ControlAddressType
): Array<string> => {
  const streetNumber = address.streetNumber ? `${address.streetNumber} ` : '';
  const numberBis = address.streetNumberBis
    ? `(${address.streetNumberBis}) `
    : '';
  const streetType = address.streetType ? address.streetType : '';
  const streetName = address.streetName ? address.streetName : '';
  const postalCode = address.postalCode ? address.postalCode : '';
  const locality = address.addressLocality ? address.addressLocality : '';

  const res = [`${streetNumber}${numberBis}${streetType} ${streetName}`];

  if (address.specialPlace) {
    res.push(address.specialPlace);
  }

  if (address.postOfficeBoxNumber) {
    res.push(`BP ${address.postOfficeBoxNumber}`);
  }

  res.push(`${postalCode} ${locality}`);

  return res;
};

const AddressRenderer = ({
  address,
  style,
  itemStyle,
}: {
  address: Address | ControlAddressType;
  style?: Record<string, any>;
  itemStyle?: Record<string, any>;
}): React.ReactElement<any> => {
  return (
    <div style={style}>
      {formatAddress(address).map(item => (
        <span key={item} style={itemStyle}>
          {item} <br />
        </span>
      ))}
    </div>
  );
};

export default AddressRenderer;
