import {
  CloseModalsAction,
  OpenCreateSectionModalAction,
  OpenDeleteModalAction,
  OpenUploadModalAction,
} from './types';

export const openCreateSectionModal = (): OpenCreateSectionModalAction => {
  return { type: 'OPEN_CREATE_SECTION_MODAL' };
};

export const openDeleteModal = (sectionId: string): OpenDeleteModalAction => {
  return { type: 'OPEN_DELETE_MODAL', payload: sectionId };
};

export const openUploadModal = (sectionId: string): OpenUploadModalAction => {
  return { type: 'OPEN_UPLOAD_MODAL', payload: sectionId };
};

export const closeModals = (): CloseModalsAction => {
  return { type: 'CLOSE_MODALS' };
};
