const AUTH_LOCAL_STORAGE_KEY = 'cvfm.auth';

export type AuthLocalStore = {
  accessToken?: string;
  refreshToken?: string;
  expiresAt?: Date;
};

export function getAuthLocalStore(): AuthLocalStore {
  try {
    const authLocalStorageContent = localStorage.getItem(
      AUTH_LOCAL_STORAGE_KEY
    );
    if (authLocalStorageContent) {
      // JSON.parse return type is not typed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const authLocalStorageJson: Record<string, string> = JSON.parse(
        authLocalStorageContent
      );
      return {
        accessToken: authLocalStorageJson.accessToken,
        refreshToken: authLocalStorageJson.refreshToken,
        expiresAt: new Date(authLocalStorageJson.expiresAt),
      };
    }
  } catch (err) {
    // NOOP
  }
  return {} as AuthLocalStore;
}

export function setAuthLocalStore(auth: AuthLocalStore): void {
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(auth));
}

export function clearAuthLocalStore(): void {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
}
