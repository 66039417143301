import querystring from 'querystring';

import { GpvReportDTO, PvReportDTO } from 'api/tepv/stats/types';
import {
  StatsAgentsSearchFilters,
  StatsAgentsSearchFiltersParam,
} from 'Dashboard/fps/StatsAgents/types';
import { StatsAgentsTepvSearchFiltersParam } from 'Dashboard/pv/Reports/types';

import { apiGet, apiPost, apiPostRaw } from '../helpers';
import { ColumnConfig } from '../commonTypes';

import {
  AgentLapiReviewCounterDTO,
  AgentsDashboardStatisticsDTO,
  AgentsSubsStatisticsDTO,
  ChartDTO,
  FinanceStatisticDTO,
  FpsOrganizationReportDTO,
  OfficialReportRowDTO,
  DashboardSubscriptionStatisticsDTO,
} from './types';

const BASE_BO_URL = '/api/prv/v0/city/{cityId}/dashboard';
const BASE_SUBS_URL =
  '/api/proxy/subscription/api/prv/cities/{cityId}/statistics';
const BASE_PDF_URL = '/api/proxy/documents/api/cities/{cityId}/documents/v0';

export async function getDefaultPage(
  module = 'fps',
  param: any = {}
): Promise<Array<ChartDTO>> {
  return apiGet<Array<ChartDTO>>(
    `${BASE_BO_URL}/report/${module}/?${querystring.stringify(param)}`
  );
}

export async function computeOfficialStats(
  from: string,
  to: string
): Promise<Map<string, OfficialReportRowDTO>> {
  return apiGet<Map<string, OfficialReportRowDTO>>(
    `${BASE_BO_URL}/report/official?from=${from}&to=${to}`
  );
}

export async function computeFinanceStats(
  param: any = {}
): Promise<FinanceStatisticDTO> {
  return apiGet<FinanceStatisticDTO>(
    `${BASE_BO_URL}/finances?${querystring.stringify(param)}`
  );
}

export async function exportFinanceStats(
  graphType: string,
  param: any = {}
): Promise<void> {
  return apiGet<void>(
    `${BASE_BO_URL}/finances/${graphType}/_export?${querystring.stringify(
      param
    )}`
  );
}

export async function computeSubscriptionsStats(): Promise<
  DashboardSubscriptionStatisticsDTO
> {
  return apiGet<DashboardSubscriptionStatisticsDTO>(BASE_SUBS_URL);
}

export async function fetchNumberOfFpsAndControlsByAgent(
  params: StatsAgentsSearchFiltersParam
): Promise<AgentsDashboardStatisticsDTO> {
  return apiPost<AgentsDashboardStatisticsDTO>(
    `${BASE_BO_URL}/_reporting`,
    params
  );
}

export async function fetchLapiReviewByAgent(
  params: any = {}
): Promise<Array<AgentLapiReviewCounterDTO>> {
  return apiGet<Array<AgentLapiReviewCounterDTO>>(
    `${BASE_BO_URL}/controls/reporting?${querystring.stringify(params)}`
  );
}

export async function fetchNbrSubscriptionsByAgent(
  params: any = {}
): Promise<AgentsSubsStatisticsDTO> {
  return apiGet<AgentsSubsStatisticsDTO>(
    `${BASE_BO_URL}/subscriber/reporting?${querystring.stringify(params)}`
  );
}

export async function askNumberOfFpsAndControlsByAgentExport(
  filters: StatsAgentsSearchFilters,
  exportParam: ColumnConfig
): Promise<any> {
  return apiPost<unknown>(`${BASE_BO_URL}/reporting/agents/_export`, {
    filters: {
      ...filters,
      teamIds: Array.from(filters.teamIds),
    },
    ...exportParam,
  });
}

export async function askNumberOfMifByOrgExport(
  filters: StatsAgentsTepvSearchFiltersParam,
  exportParam: ColumnConfig
): Promise<void> {
  return apiPost<void>(`${BASE_BO_URL}/reporting/org/_export/tepv`, {
    filters,
    ...exportParam,
  });
}
export async function askNumberOfMifByAgentExport(
  filters: StatsAgentsTepvSearchFiltersParam,
  exportParam: ColumnConfig
): Promise<void> {
  return apiPost<void>(`${BASE_BO_URL}/reporting/agents/_export/tepv`, {
    filters,
    ...exportParam,
  });
}

export async function askNumberOfGpvByAgentExport(
  filters: StatsAgentsTepvSearchFiltersParam,
  exportParam: ColumnConfig
): Promise<void> {
  return apiPost<void>(`${BASE_BO_URL}/reporting/agents/_export/gpv`, {
    filters,
    ...exportParam,
  });
}

export async function askNumberOfGpvByOrgExport(
  filters: StatsAgentsTepvSearchFiltersParam,
  exportParam: ColumnConfig
): Promise<void> {
  return apiPost<void>(`${BASE_BO_URL}/reporting/org/_export/gpv`, {
    filters,
    ...exportParam,
  });
}

export async function askNumberOfMifByAgentPdfExport(
  body?: PvReportDTO | null
): Promise<Response> {
  return apiPostRaw(`${BASE_PDF_URL}/statsAgent/tepv`, body);
}

export async function askNumberOfGpvByAgentPdfExport(
  body?: GpvReportDTO | null
): Promise<any> {
  return apiPostRaw(`${BASE_PDF_URL}/statsAgent/gpv`, body);
}

export async function askNumberOfGpvByOrgPdfExport(
  body?: GpvReportDTO | null
): Promise<any> {
  return apiPostRaw(`${BASE_PDF_URL}/statsOrg/gpv`, body);
}

export async function askAgentSubscriptionsReportingExport(
  params: any = {}
): Promise<any> {
  return apiGet<any>(
    `${BASE_BO_URL}/subscriber/reporting/agents/export?${querystring.stringify(
      params
    )}`
  );
}

export async function askOrganizationReportingExport(
  params: any = {}
): Promise<any> {
  return apiGet<any>(
    `${BASE_BO_URL}/reporting/organizations/export?${querystring.stringify(
      params
    )}`
  );
}

export function generateDownloadFileUrlForOfficialReport(
  from: string,
  to: string
): string {
  return `${BASE_BO_URL}/report/official/export?from=${from}&to=${to}`;
}

export async function fetchFpsCountByOrganization(): Promise<
  FpsOrganizationReportDTO
> {
  return apiGet<FpsOrganizationReportDTO>(
    `${BASE_BO_URL}/reporting_count_by_organization`
  );
}
