import querystring from 'querystring';

import { OrderRestrictionsCount } from '@cvfm-front/tefps-types/build/order/OrderRestrictionsCount';
import { apiDelete, apiGet, apiPost, apiPostCSV, apiPut } from 'api/helpers';
import {
  CancelOrderDTO,
  OrderCustomField,
  OrderCustomLimitCounter,
  OrderDecision,
  OrderEsSearchQueryPagedDTO,
  OrderEsSearchResultDTO,
  OrderEvidence,
  OrderEvidenceRequest,
  OrderPayment,
  OrderPlateChange,
  OrderPlates,
  OrderPrivateDTO,
  OrderSimpleDTO,
  OrderSubscribers,
  OrderTransfer,
  OrderValidityPeriod,
  OrderZones,
  ChoosablePlatesDTO,
  OrderEsSearchQueryScrollDTO,
  OrderPriceRequestDTO,
  MailTemplateDTO,
  MailTypeOrder,
  OrderProfileAccountDTO,
  OrderTags,
  SubscriptionSource,
  OrderClaimDTO,
} from '@cvfm-front/tefps-types';
import { OrderTaskWrapper } from 'tefps/Tasks/types';
import { ImportResultDTO } from 'api/commonTypes';

import { CustomFieldDTO } from '../pricing/types';

const BASE_URL = '/api/proxy/subscription/api/v2/prv/cities/{cityId}/order';
const BASE_ES_URL =
  '/api/proxy/subscription/api/v2/prv/cities/{cityId}/es/order';
const BASE_URL_BO = '/api/prv/v0/cities/{cityId}';

export async function searchEsOrderPaged(
  searchQueryPaged: OrderEsSearchQueryPagedDTO
): Promise<OrderEsSearchResultDTO> {
  return apiPost<OrderEsSearchResultDTO>(
    `${BASE_ES_URL}/paged`,
    searchQueryPaged
  );
}

export async function hasOrdersForProfile(
  pageQuery: OrderEsSearchQueryPagedDTO
): Promise<OrderProfileAccountDTO> {
  return apiPost<OrderProfileAccountDTO>(`${BASE_ES_URL}/profile`, pageQuery);
}

export async function searchEsOrderScroll(
  searchQuery: OrderEsSearchQueryScrollDTO
): Promise<OrderEsSearchResultDTO> {
  return apiPost<OrderEsSearchResultDTO>(`${BASE_ES_URL}/scroll`, searchQuery);
}

export async function getOrderById(orderId: string): Promise<OrderPrivateDTO> {
  return apiGet<OrderPrivateDTO>(`${BASE_URL}/${orderId}`);
}

export async function calculateOrderPrice(
  orderPriceRequestDTO: OrderPriceRequestDTO
): Promise<number> {
  return apiPost<number>(
    `${BASE_URL}/calculate-order-price`,
    orderPriceRequestDTO
  );
}

export async function getOrderWithInstantPrice(
  orderId: string
): Promise<OrderPrivateDTO> {
  return apiGet<OrderPrivateDTO>(`${BASE_URL}/${orderId}/instant-price`);
}

export async function getRestrictionCountByOrderId(
  orderId: string
): Promise<OrderRestrictionsCount> {
  return apiGet<OrderRestrictionsCount>(
    `${BASE_URL}/${orderId}/count-restrictions`
  );
}

export async function addCustomOrderLimitCounter(
  orderId: string,
  orderCustomLimitCounter: OrderCustomLimitCounter
): Promise<void> {
  return apiPut<void>(
    `${BASE_URL}/${orderId}/add-custom-counter`,
    orderCustomLimitCounter
  );
}

export async function createOrderById(
  orderId: string,
  order: OrderPrivateDTO
): Promise<OrderPrivateDTO> {
  // TODO remove v2 when front v2 is deployed
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/v2`, order);
}

export async function deleteOrderById(
  orderId: string,
  cancelOrderMessage: CancelOrderDTO
): Promise<OrderPrivateDTO> {
  return apiDelete<OrderPrivateDTO>(
    `${BASE_URL}/${orderId}`,
    cancelOrderMessage
  );
}

export async function addValidityPeriodUpdate(
  orderId: string,
  update: OrderValidityPeriod
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/period`, update);
}

export async function addPlatesUpdate(
  orderId: string,
  update: OrderPlates
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/plates`, update);
}

export async function applyPlatesModifications(
  orderId: string,
  change: OrderPlateChange
): Promise<OrderPrivateDTO> {
  return apiPost<OrderPrivateDTO>(
    `${BASE_URL}/${orderId}/plates/modify`,
    change
  );
}

export async function addZonesUpdate(
  orderId: string,
  update: OrderZones
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/zones`, update);
}

export async function addTagsUpdate(
  orderId: string,
  update: OrderTags
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/tags`, update);
}

export async function addEvidenceRequestsUpdate(
  orderId: string,
  update: Array<OrderEvidenceRequest>,
  mailTemplate: MailTemplateDTO
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/evidence/requests`, {
    evidenceRequests: update,
    mailTemplate,
  });
}

export async function addEvidenceUpdate(
  orderId: string,
  update: OrderEvidence
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(
    `${BASE_URL}/${orderId}/evidence/provide`,
    update
  );
}

export async function addEvidencesUpdate(
  orderId: string,
  update: Array<OrderEvidence>
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(
    `${BASE_URL}/${orderId}/evidence/provide/list`,
    update
  );
}

export async function addCustomFieldUpdate(
  orderId: string,
  update: OrderCustomField
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/custom-field`, update);
}

export async function addTransferUpdate(
  orderId: string,
  update: OrderTransfer
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/transfer`, update);
}

export type TakeDecisionUpdateParams = {
  orderId: string;
  decision: OrderDecision;
  mailTemplate?: MailTemplateDTO;
  force: boolean;
};

export async function takeDecisionUpdate(
  takeDecisionUpdateParams: TakeDecisionUpdateParams
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(
    `${BASE_URL}/${takeDecisionUpdateParams.orderId}/decision?forceDecision=${takeDecisionUpdateParams.force}`,
    {
      decision: takeDecisionUpdateParams.decision,
      mailTemplate: takeDecisionUpdateParams.mailTemplate,
    }
  );
}

export async function renderDecisionTemplate(
  orderId: string,
  accepted: boolean,
  message: string,
  mailType: MailTypeOrder | null | undefined
): Promise<MailTemplateDTO> {
  return apiPost<MailTemplateDTO>(`${BASE_URL}/${orderId}/decision`, {
    accepted,
    message,
    mailType,
  });
}

export type MakeClaimUpdateParams = {
  orderId: string;
  mailTemplate?: MailTemplateDTO;
  message?: string;
  force: boolean;
};

export async function makeClaimUpdate(
  makeClaimUpdateParams: MakeClaimUpdateParams
): Promise<OrderPrivateDTO> {
  const orderClaimDTO: OrderClaimDTO = {
    mailTemplate: makeClaimUpdateParams.mailTemplate,
    message: makeClaimUpdateParams.message,
    source: SubscriptionSource.BACK,
  };

  return apiPut<OrderPrivateDTO>(
    `${BASE_URL}/${makeClaimUpdateParams.orderId}/claim/make`,
    orderClaimDTO
  );
}

export async function renderClaimMailTemplate(
  orderId: string,
  message: string,
  mailType: MailTypeOrder | null | undefined
): Promise<MailTemplateDTO> {
  return apiPost<MailTemplateDTO>(`${BASE_URL}/${orderId}/claim/mail`, {
    message,
    mailType,
  });
}

export async function sendMassCommunication(
  ordersIds: string[],
  sendOnlyOneEmailPerUser: boolean,
  mailTemplate: MailTemplateDTO
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(
    `${BASE_URL}/mass-communication?sendOnlyOneEmailPerUser=${sendOnlyOneEmailPerUser}`,
    {
      ordersIds,
      mailTemplate,
    }
  );
}

export async function addPaymentUpdate(
  orderId: string,
  update: OrderPayment
): Promise<OrderPrivateDTO> {
  return apiPut<OrderPrivateDTO>(`${BASE_URL}/${orderId}/payment`, update);
}

export async function getOrderExport(
  filterParam: any,
  columns: any
): Promise<any> {
  return apiPost<unknown>(
    `${BASE_URL_BO}/orders/export?${querystring.stringify({
      ...columns,
    })}`,
    filterParam
  );
}

export async function fetchOrderTasks(): Promise<OrderTaskWrapper> {
  return apiGet<OrderTaskWrapper>(`${BASE_URL_BO}/orders/tasks`);
}

export async function fetchChoosablePlates(
  orderId: string
): Promise<ChoosablePlatesDTO> {
  return apiGet<ChoosablePlatesDTO>(`${BASE_URL}/${orderId}/choosablePlates`);
}

export async function fetchInducedOrders(
  orderId: string
): Promise<Array<OrderSimpleDTO>> {
  return apiGet<Array<OrderSimpleDTO>>(`${BASE_URL}/${orderId}/induced-orders`);
}

export async function importOrders(file: File): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_URL_BO}/orders/import`, file);
}

export function getPaymentProofPdfUrl(orderId: string): string {
  return `${BASE_URL}/${orderId}/pdf/proof`;
}

export function getSupportingDocumentPdfUrl(orderId: string): string {
  return `${BASE_URL}/${orderId}/pdf/supportingDocument`;
}

export function getPaymentInvoicePdfUrl(orderId: string): string {
  return `${BASE_URL}/${orderId}/pdf/invoice`;
}

export async function generateShareToken(
  orderId: string
): Promise<{ token: string }> {
  return apiGet(`${BASE_URL}/share/${orderId}/generate`);
}

export async function updateSubscribersFromOrder(
  orderId: string,
  orderSubscribers: OrderSubscribers
): Promise<OrderPrivateDTO> {
  return apiPut(`${BASE_URL}/share/${orderId}`, orderSubscribers);
}

export async function fetchOrderCustomFields(): Promise<Array<CustomFieldDTO>> {
  return apiGet<Array<CustomFieldDTO>>(`${BASE_ES_URL}/customFields`);
}

export function getOrderHistoryPdfUrl(orderId: string): string {
  return `${BASE_URL}/export/${orderId}`;
}

export function getSubscriberOrders(
  subscriberId: string
): Promise<Array<OrderPrivateDTO>> {
  return apiGet(`${BASE_URL}/subscriber/${subscriberId}`);
}

export function hasInvoice(orderId: string): Promise<boolean> {
  return apiGet(`${BASE_URL}/${orderId}/hasInvoice`);
}

export async function getPlatesUsedByOrder(
  productId: string,
  subscriberId: string,
  year: number | undefined,
  plate: string | null
): Promise<{ [key: string]: number }> {
  const encodedProductId = encodeURIComponent(productId);
  const encodedSubscriberId = encodeURIComponent(subscriberId);

  const queryParams = new URLSearchParams();
  if (year) {
    queryParams.append('year', year.toString());
  }
  if (plate) {
    queryParams.append('plate', encodeURIComponent(plate));
  }

  const url = `${BASE_URL}/${encodedProductId}/${encodedSubscriberId}/count-plate-usage${
    queryParams.toString() ? `?${queryParams.toString()}` : ''
  }`;

  return apiGet<{ [key: string]: number }>(url);
}
