import { apiGet, apiPut } from '../helpers';

import { RapoAnswerDTO } from './types';

const BASE_URL = '/api/proxy/rapo/api/cities/{cityId}/rapo-answer';

export async function fetchRapoAnswer(
  organizationId: string | null | undefined
): Promise<RapoAnswerDTO> {
  return apiGet<RapoAnswerDTO>(
    BASE_URL + (organizationId ? `?organizationId=${organizationId}` : '')
  );
}

export async function updateRapoAnswer(
  rapoAnswer: RapoAnswerDTO
): Promise<void> {
  return apiPut<void>(BASE_URL, rapoAnswer);
}
