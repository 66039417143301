import { apiPost } from 'api/helpers';
import {
  BillEsSearchQueryDTO,
  BillEsSearchQueryPagedDTO,
  BillEsSearchResultDTO,
  BillExportFileRequest,
} from '@cvfm-front/tefps-types';

import { ColumnConfig } from '../commonTypes';

const BASE_ES_URL = '/api/proxy/parking/api/v1/prv/cities/{cityId}/es/bill';
const BASE_BO_URL = 'api/prv/city/{cityId}/bills/export';

export async function getExport(
  filterParam: BillEsSearchQueryDTO,
  columnsParam: ColumnConfig
): Promise<unknown> {
  const request: BillExportFileRequest = {
    filters: filterParam,
    columns: columnsParam.columns,
  };
  return apiPost<unknown>(BASE_BO_URL, request);
}

export async function searchEsBillPaged(
  searchQueryPaged: BillEsSearchQueryPagedDTO
): Promise<BillEsSearchResultDTO> {
  return apiPost<BillEsSearchResultDTO>(
    `${BASE_ES_URL}/paged`,
    searchQueryPaged
  );
}
