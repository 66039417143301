import React, { useState } from 'react';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';

import './ResetPasswordForm.css';
import IconButton from 'material-ui/IconButton';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Visibility from 'material-ui/svg-icons/action/visibility';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import { greenA700, grey500 } from 'material-ui/styles/colors';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  PasswordValidationResponse,
  processPasswordValidation,
} from '@cvfm-front/commons-utils';
import { Flex } from '@cvfm-front/commons-ui';
import { logoutAction } from 'api/duck';
import { updatePassword } from 'api/accounts';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import BoButton from '../facade/BoButton';

const { _tg } = window.loadTranslations(__filename);

const PASSWORD_CONDITIONS = [
  'minLength',
  'maxLength',
  'number',
  'lowercaseLetter',
  'uppercaseLetter',
  'specialCharacter',
  'confirmation', // _tg(`password.rules.confirmation`)
];

type Props = {
  dispatch: (arg0: any) => any;
};

const ResetPasswordForm = ({ dispatch }: Props): JSX.Element => {
  const errors = false;
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmationPassword, setHideConfirmationPassword] = useState<
    boolean
  >(true);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [passwordValidity, setPasswordValidity] = useState<
    PasswordValidationResponse
  >();

  const setMessage = useSnackbar();

  const history = useHistory();

  const moveToNextInput = (e: any) => {
    if (e.key === 'Enter') {
      if (e.currentTarget.id === 'login') {
        document.getElementById('password')?.focus();
      }
      if (e.currentTarget.id === 'password')
        document.getElementById('confirmPassword')?.focus();
    }
  };

  const onChangePassword = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(target.value);
    setPasswordValidity(
      processPasswordValidation({ password: target.value, confirmPassword })
    );
  };

  const onChangeConfirmPassword = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassword(target.value);
    setPasswordValidity(
      processPasswordValidation({ password, confirmPassword: target.value })
    );
  };

  const submit = (): void => {
    updatePassword(password)
      .then(res => {
        dispatch(logoutAction());
      })
      .catch(err => {
        if (err.json?.error === 'PasswordMustChange') {
          setMessage(
            'Le mot de passe saisie est identique au précédent mot de passe'
          );
        }
      });
  };

  const cancel = () => {
    dispatch(logoutAction());
    history.push('/');
  };

  return (
    <div className="reset-password-container">
      <Flex className="reset-password-box" gap={10}>
        <Flex className="align-items-baseline">
          <TextField
            floatingLabelText="Mot de passe"
            type={`${hidePassword ? 'password' : 'text'}`}
            value={password}
            onChange={onChangePassword}
            id="password"
            fullWidth
            onKeyDown={moveToNextInput}
          />
          <IconButton
            tooltip={`${hidePassword ? 'show' : 'hide'} password`}
            tooltipPosition="top-center"
            onClick={() => setHidePassword(!hidePassword)}
          >
            {hidePassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Flex>
        <Flex className="align-items-baseline">
          <TextField
            floatingLabelText="Mot de passe"
            type={`${hideConfirmationPassword ? 'password' : 'text'}`}
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            fullWidth
            id="confirmPassword"
            onKeyDown={moveToNextInput}
          />
          <IconButton
            tooltip={`${hideConfirmationPassword ? 'show' : 'hide'} password`}
            tooltipPosition="top-center"
            onClick={() =>
              setHideConfirmationPassword(!hideConfirmationPassword)
            }
          >
            {hideConfirmationPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Flex>
        <Flex gap={10} justifyContent="center">
          <BoButton
            label="Annuler"
            primary
            style={{ marginTop: 30 }}
            onClick={cancel}
          />
          <BoButton
            label="Soumettre"
            disabled={
              !processPasswordValidation({ password, confirmPassword }).status
            }
            primary
            style={{ marginTop: 30 }}
            onClick={submit}
          />
        </Flex>
        <Flex flexDirection="column" gap={5}>
          {PASSWORD_CONDITIONS.map(condition => {
            return (
              <Flex alignItems="center" gap={5}>
                {passwordValidity?.conditions[condition] ? (
                  <CheckCircle color={greenA700} />
                ) : (
                  <CheckCircle color={grey500} />
                )}
                {_tg(`password.rules.${condition}`)}
              </Flex>
            );
          })}
        </Flex>
        {errors && (
          <Snackbar
            open
            message="Une erreur s'est produite lors du renouvellement de mot de passe"
            autoHideDuration={4000}
          />
        )}
      </Flex>
    </div>
  );
};
export default connect()(ResetPasswordForm);
