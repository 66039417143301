import querystring from 'querystring';

import {
  LightSubscriptionPlanDTO,
  MessageToSendToSubscribers,
  SubscriptionPlanDTO,
  SubscriptionPlanStatus,
  SubscriptionPlanWithMemberCountDTO,
} from '@cvfm-front/tefps-types';
import { apiDelete, apiGet, apiPost, apiPut } from 'api/helpers';

const BASE_URL = '/api/proxy/subscription/api/prv/cities/{cityId}/plans';

/**
 * subscriberId restricts to validityAreas containing the subscriber zone id if validityAreasAutoSelection is true
 */
export async function fetchSubscriptionPlans(
  subscriberId?: string
): Promise<Array<LightSubscriptionPlanDTO>> {
  return apiGet<Array<LightSubscriptionPlanDTO>>(
    `${BASE_URL}?${subscriberId ? querystring.stringify({ subscriberId }) : ''}`
  );
}

export async function fetchSubscriptionPlansWithMemberCount(): Promise<
  Array<SubscriptionPlanWithMemberCountDTO>
> {
  return apiGet<Array<SubscriptionPlanWithMemberCountDTO>>(
    `${BASE_URL}/_extended`
  );
}

export async function fetchAllSubscriptionPlans(): Promise<
  Array<LightSubscriptionPlanDTO>
> {
  return apiGet<Array<LightSubscriptionPlanDTO>>(`${BASE_URL}/_all`);
}

export async function createSubscriptionPlan(
  body: SubscriptionPlanDTO
): Promise<void> {
  return apiPost<void>(BASE_URL, body);
}

/**
 * subscriberId restricts to validityAreas containing the subscriber zone id if validityAreasAutoSelection is true
 */
export async function fetchSubscriptionPlan(
  subscriptionPlanId: string,
  subscriberId?: string
): Promise<SubscriptionPlanDTO> {
  return apiGet<SubscriptionPlanDTO>(
    `${BASE_URL}/${subscriptionPlanId}?${
      subscriberId ? querystring.stringify({ subscriberId }) : ''
    }`
  );
}

export async function updateSubscriptionPlan(
  subscriptionPlanId: string,
  body: SubscriptionPlanDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${subscriptionPlanId}`, body);
}

export async function updateSubscriptionPlanStatus(
  subscriptionPlanId: string,
  status: SubscriptionPlanStatus
): Promise<void> {
  return apiPut<void>(
    `${BASE_URL}/${subscriptionPlanId}/status/${status}`,
    undefined
  );
}

export async function updateSubscriptionPlanUserStatus(
  subscriptionPlanId: string,
  status: SubscriptionPlanStatus
): Promise<void> {
  return apiPut<void>(
    `${BASE_URL}/${subscriptionPlanId}/status/user/${status}`,
    undefined
  );
}

export async function deleteSubscriptionPlan(
  subscriptionPlanId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${subscriptionPlanId}`, undefined);
}

export async function sendMessage(
  subscriptionPlanId: string,
  message: MessageToSendToSubscribers
): Promise<void> {
  return apiPost<void>(`${BASE_URL}/${subscriptionPlanId}/message`, message);
}

export async function recomputeRenewalReminders(planId: string): Promise<void> {
  return apiPost<void>(`${BASE_URL}/${planId}/_recompute_reminders`, undefined);
}
