import { ControlViewPrivateDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';
import { apiGet, apiPost, apiDelete, apiPatch } from 'api/helpers';

const BASE_PRV_URL =
  '/api/proxy/control/api/v0/prv/cities/{cityId}/controlView';

export async function fetchAllControlViews(): Promise<
  Array<ControlViewPrivateDTO>
> {
  return apiGet<Array<ControlViewPrivateDTO>>(BASE_PRV_URL);
}

export async function createControlView(
  controlView: ControlViewPrivateDTO
): Promise<ControlViewPrivateDTO> {
  return apiPost<ControlViewPrivateDTO>(BASE_PRV_URL, controlView);
}

export async function fetchControlViewById(
  controlViewId: string
): Promise<ControlViewPrivateDTO> {
  return apiGet<ControlViewPrivateDTO>(`${BASE_PRV_URL}/${controlViewId}`);
}

export async function deleteControlViewById(
  controlViewId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_PRV_URL}/${controlViewId}`);
}

export async function patchControlViewById(
  controlViewId: string,
  patchList: Array<PatchObject<unknown>>
): Promise<ControlViewPrivateDTO> {
  return apiPatch<ControlViewPrivateDTO>(
    `${BASE_PRV_URL}/${controlViewId}`,
    patchList
  );
}
