import * as React from 'react';

import { FpsAddressDTO } from 'api/fps/types';
import { Address } from 'api/commonTypes';

import AddressRenderer from './AddressRenderer';

const FpsAddressRenderer = ({ fpsAddress }: { fpsAddress: FpsAddressDTO }) => {
  const address: Address = {
    streetNumber: fpsAddress.streetNumber,
    streetNumberBis: fpsAddress.streetNumberBis,
    streetType: fpsAddress.streetType,
    streetName: fpsAddress.streetName,
    specialPlace: fpsAddress.specialPlace,
    postOfficeBoxNumber: fpsAddress.postOfficeBoxNumber,
    postalCode: fpsAddress.postalCode,
    addressSubRegion: fpsAddress.subRegion,
    addressRegion: fpsAddress.region,
    addressLocality: fpsAddress.locality,
    addressCountry: fpsAddress.country,
  } as any;

  return <AddressRenderer address={address} />;
};

export default FpsAddressRenderer;
