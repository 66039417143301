import React from 'react';
import { connect } from 'react-redux';
import FileIcon from 'material-ui/svg-icons/content/content-paste';

import SectionTitle from 'commons/SectionTitle';
import { Universe } from 'UniverseHandler/duck';
import { getActiveUniverse } from 'UniverseHandler/helpers';
import { openPDFManual } from 'api/documentLibrary';

import { COLOR_STYLE, STYLE } from './style';

const { _tg } = window.loadTranslations();

const FPS_GUIDE_VERSION = '2.97';
const PV_GUIDE_VERSION = 'v1.0';

interface Props {
  universe: Universe;
}

const DefaultSection = ({ universe }: Props) => {
  const fps = universe === 'fps';
  const openManual = () => {
    if (fps) {
      void openPDFManual('fps');
    } else {
      void openPDFManual('pv');
    }
  };
  return (
    <div>
      <SectionTitle title={_tg('tefps.documentLibrary.defaultSection.help')} />
      <div style={STYLE.CONTAINER}>
        <div
          style={{ display: 'flex', marginTop: 20, cursor: 'pointer' }}
          title={_tg('tefps.documentLibrary.defaultSection.consultManual')}
          onClick={openManual}
        >
          <FileIcon
            style={{ width: 40, height: 50 }}
            color={COLOR_STYLE.TEXT_COLOR_LIGHT}
          />
          <div style={STYLE.FILENAME_CONTAINER}>
            <div style={{ color: COLOR_STYLE.TXT_BLACK }}>
              {fps
                ? _tg('tefps.documentLibrary.defaultSection.tefpsManual')
                : _tg('tefps.documentLibrary.defaultSection.tepvManual')}
            </div>
            <div style={STYLE.NAME_CONTAINER}>
              <div>
                <span style={{ textTransform: 'uppercase' }}>
                  {fps ? FPS_GUIDE_VERSION : PV_GUIDE_VERSION}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapUniverseToProps = (state: any) => {
  return {
    universe: getActiveUniverse(state),
  };
};

export default connect(mapUniverseToProps)(DefaultSection);
