import React, { CSSProperties } from 'react';
import Dropzone from 'react-dropzone';
import DeleteIcon from 'material-ui/svg-icons/content/clear';

import BoButton from 'facade/BoButton';
import { TXT_BLACK } from 'theme';

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '0 auto',
  fontFamily: 'Roboto',
  alignItems: 'center',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 20,
  marginTop: 25,
  marginBottom: 10,
};

type ImagePreviewType = {
  img: string | null | undefined;
  title: string;
  style: CSSProperties;
  deleteImage: ((e: React.MouseEvent<HTMLElement>) => void) | undefined;
};
export const ImagePreview = ({
  img,
  title,
  style,
  deleteImage,
}: ImagePreviewType) => {
  if (!img) {
    return null;
  }
  return (
    <div style={{ marginBottom: 20 }}>
      <div style={STYLE_CONTAINER}>
        {deleteImage && (
          <DeleteIcon
            style={{
              ...STYLE_TITLE,
              position: 'absolute',
              right: 48,
              width: 32,
              height: 32,
              cursor: 'pointer',
            }}
            onClick={deleteImage}
          />
        )}
        <div style={STYLE_TITLE}>{title}</div>
      </div>
      <div style={STYLE_CONTAINER}>
        <img src={img} style={style} />
      </div>
    </div>
  );
};

type ImageDropZoneType = {
  onDrop?<T extends File>(
    acceptedFiles: T[],
    rejectedFiles: T[],
    event: React.DragEvent<HTMLDivElement>
  ): void;
  legend: string;
  disabled?: boolean;
};
export const ImageDropZone = ({
  onDrop,
  legend,
  disabled,
}: ImageDropZoneType) => (
  <div
    style={{ width: 'auto', height: 'auto', border: 'none', marginRight: 20 }}
  >
    <Dropzone
      disabled={disabled}
      accept="image/gif, image/jpeg, image/png"
      multiple={false}
      onDrop={onDrop}
      style={{ width: 'auto', height: 'auto', border: 'none' }}
    >
      <BoButton label={legend} primary />
    </Dropzone>
  </div>
);
