import { RefundDocumentType } from './types';

export const DOCUMENT_ENTITLED: { [key in RefundDocumentType]: string } = {
  ID: "Carte d'identité",
  RIB: 'RIB',
  CG: 'Certificat d’immatriculation',
  PAYMENT_PROOF: 'Justificatif de paiement',
  ADDRESS_PROOF: 'Justificatif de domicile',
  PAYMENT_NOTICE: 'Avis de paiement',
  OTHER: 'Autre',
};
