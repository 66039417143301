import { apiGet, apiPut } from '../helpers';

import { RapoChoiceReasonDTO, RapoChoiceReasonList } from './types';

const BASE_URL = '/api/proxy/rapo/api/cities/{cityId}/rapo-choice-reason';

export async function fetchRapoChoiceReasonList(
  organizationId: string | null | undefined
): Promise<RapoChoiceReasonList> {
  return apiGet<RapoChoiceReasonList>(
    BASE_URL + (organizationId ? `?organizationId=${organizationId}` : '')
  );
}

export async function fetchRapoChoiceReasonListFormatted(
  rapoId: string,
  fpsId: string
): Promise<RapoChoiceReasonList> {
  return apiGet<RapoChoiceReasonList>(
    `${BASE_URL}/formatted?rapoId=${rapoId}&fpsId=${fpsId}`
  );
}

export async function updateRapoChoiceReasonMail(
  reason: RapoChoiceReasonDTO
): Promise<void> {
  return apiPut<void>(BASE_URL, reason);
}
