import React from 'react';

import { ListBody, ListBottom, ListWrapper } from './ListWrappers';

type Props = {
  list: React.ReactNode;
  actions: Array<React.ReactNode> | React.ReactNode;
  loading?: boolean;
};

const ListWithActions = ({ list, actions, loading }: Props) => {
  return (
    <ListWrapper>
      <ListBody loading={!!loading}>{list}</ListBody>
      <ListBottom>{actions}</ListBottom>
    </ListWrapper>
  );
};

export default ListWithActions;
