import React, { CSSProperties } from 'react';
import moment from 'moment';

import { JsonDatetime } from 'api/commonTypes';

const { _t } = window.loadTranslations(__filename);

type Props = {
  datetime: JsonDatetime | null | undefined;
  withDate?: boolean;
  withYear?: boolean;
  withTime?: boolean;
  withSeconds?: boolean;
  withAlternativeSeparator?: boolean;
  style?: CSSProperties;
};

export function formatDatetime({
  datetime,
  withDate = true,
  withYear = true,
  withTime = true,
  withSeconds = false,
  withAlternativeSeparator = false,
}: Props): string {
  let format = '';

  if (!datetime) return '';

  if (withDate && withYear) format += _t('dateWithYearFormat');
  else if (withDate && !withYear) format += _t('dateFormat');
  if (withDate && withTime && !withAlternativeSeparator)
    format += _t('standardSeparator');
  else if (withDate && withTime && withAlternativeSeparator)
    format += _t('alternativeSeparator');
  if (withTime) format += _t('timeFormat');
  if (withTime && withSeconds) format += _t('secondFormat');
  return moment(datetime).format(format);
}

const Date = ({
  datetime,
  withDate = true,
  withYear = true,
  withTime = true,
  withSeconds = false,
  withAlternativeSeparator = false,
  style,
}: Props): JSX.Element | null => {
  if (!datetime) return null;

  return (
    <span style={style}>
      {formatDatetime({
        datetime,
        withDate,
        withYear,
        withTime,
        withSeconds,
        withAlternativeSeparator,
      })}
    </span>
  );
};

export default Date;
