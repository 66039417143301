import React, { ReactNode, useState } from 'react';
import IconButton from 'material-ui/IconButton';
import Visibility from 'material-ui/svg-icons/action/visibility';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';
import Info from 'material-ui/svg-icons/action/info';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import Clear from 'material-ui/svg-icons/content/clear';
import { red800, green800 } from 'material-ui/styles/colors';
import TextField from 'material-ui/TextField';

import '@cvfm-front/commons-css/tooltip.css';
import {
  PasswordValidationResponse,
  processPasswordValidation,
} from '@cvfm-front/commons-utils';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  // password props
  value: string;
  label?: string;
  errorText?: string;
  onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
  // confirm password props
  withConfirm?: boolean;
  confirmValue?: string;
  confirmLabel?: string;
  confirmErrorText?: string;
  onConfirmChange?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
};

type State = {
  // password
  password: string;
  passwordValidation: PasswordValidationResponse;
  // confirm password
  confirmPasswordValue?: string;
};

const PasswordField = ({
  // password props
  value,
  label,
  errorText,
  onChange,
  // confirm password props
  withConfirm,
  confirmValue,
  confirmErrorText,
  confirmLabel,
  onConfirmChange,
}: Props): JSX.Element => {
  // initialize password validation
  const initPasswordValidation = (): PasswordValidationResponse => {
    return processPasswordValidation({ password: value });
  };

  // show or hide password visibility
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  // show or hide confirm password visibility
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState<
    boolean
  >(false);

  // show or hide requirements ( password or confirm password)
  const [requirements, setRequirements] = useState<{
    showRequirements: boolean;
    confirmShowRequirements: boolean;
  }>({
    showRequirements: false,
    confirmShowRequirements: false,
  });

  const [state, setState] = useState<State>({
    // password
    password: value,
    passwordValidation: initPasswordValidation(),
    // confirm password
    confirmPasswordValue: confirmValue,
  });

  // confirmPasswordErrorMessage
  const [
    confirmPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
  ] = useState<string>('');

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChange(event);
    const { confirmPasswordValue } = state;
    const passwordValidation = processPasswordValidation({
      password: event.target.value,
      ...(withConfirm ? { confirmPassword: confirmPasswordValue } : {}),
    });
    setState({
      ...state,
      password: event.target.value,
      passwordValidation,
    });
    setConfirmPasswordErrorMessage(
      withConfirm && passwordValidation.conditions.confirmation
        ? ''
        : _tg('password.confirm.notEqual')
    );
  };

  // show or hide confirm password visibility
  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (onConfirmChange) {
      onConfirmChange(event);
      const { password } = state;
      const passwordValidation = processPasswordValidation({
        password,
        confirmPassword: event.target.value,
      });
      setState({
        ...state,
        confirmPasswordValue: event.target.value,
        passwordValidation,
      });
      setConfirmPasswordErrorMessage(
        withConfirm && passwordValidation.conditions.confirmation
          ? ''
          : _tg('password.confirm.notEqual')
      );
    }
  };

  const getColor = (correct: boolean): string => {
    return correct ? green800 : red800;
  };

  const getConditionDisplay = (attribute: string): ReactNode => {
    let tradNode: ReactNode;
    switch (attribute) {
      case 'minLength':
        tradNode = <span>{_tg('password.rules.minLength')}</span>;
        break;
      case 'maxLength':
        tradNode = <span>{_tg('password.rules.maxLength')}</span>;
        break;
      case 'number':
        tradNode = <span>{_tg('password.rules.number')}</span>;
        break;
      case 'uppercaseLetter':
        tradNode = <span>{_tg('password.rules.uppercaseLetter')}</span>;
        break;
      case 'lowercaseLetter':
        tradNode = <span>{_tg('password.rules.lowercaseLetter')}</span>;
        break;
      case 'specialCharacter':
        tradNode = <span>{_tg('password.rules.specialCharacter')}</span>;
        break;
      default:
        console.error(`Unexpected attribute`);
    }
    return (
      <p>
        {state.passwordValidation.conditions[attribute] ? (
          <CheckCircle color={green800} />
        ) : (
          <Clear color={red800} />
        )}
        {tradNode}
      </p>
    );
  };

  // don't consider confirmation key in password info status
  const getPasswordStatus = (): boolean => {
    const { passwordValidation } = state;
    return Object.keys(passwordValidation.conditions).every(
      key => key === 'confirmation' || passwordValidation.conditions[key]
    );
  };

  return (
    <div>
      <TextField
        floatingLabelText={label || _tg('password.label')}
        type={passwordVisibility ? 'text' : 'password'}
        data-field="password"
        errorText={errorText}
        value={state.password}
        style={{ width: 300 }}
        onChange={handleChangePassword}
      />
      <IconButton
        tooltip={_tg('password.toggle')}
        tooltipPosition="top-center"
        onClick={() => setPasswordVisibility(!passwordVisibility)}
      >
        {passwordVisibility ? <VisibilityOff /> : <Visibility />}
      </IconButton>
      <div className="tooltip-container tooltip-right">
        <IconButton
          tooltip={_tg('password.toggleTooltip')}
          tooltipPosition="top-center"
          onClick={() =>
            setRequirements({
              showRequirements: !requirements.showRequirements,
              confirmShowRequirements: false,
            })
          }
        >
          <Info color={getColor(getPasswordStatus())} />
        </IconButton>
      </div>
      {requirements.showRequirements && (
        <div className="tooltip--password">
          {getConditionDisplay('minLength')}
          {getConditionDisplay('maxLength')}
          {getConditionDisplay('number')}
          {getConditionDisplay('lowercaseLetter')}
          {getConditionDisplay('uppercaseLetter')}
          {getConditionDisplay('specialCharacter')}
        </div>
      )}
      {withConfirm && (
        <div>
          <TextField
            floatingLabelText={confirmLabel || _tg('password.confirm.label')}
            type={confirmPasswordVisibility ? 'text' : 'password'}
            data-field="confirmPassword"
            errorText={confirmErrorText || confirmPasswordErrorMessage}
            value={state.confirmPasswordValue}
            onChange={handleChangeConfirmPassword}
            style={{ width: 300 }}
          />
          <IconButton
            tooltip={_tg('password.toggle')}
            tooltipPosition="top-center"
            onClick={() =>
              setConfirmPasswordVisibility(!confirmPasswordVisibility)
            }
          >
            {confirmPasswordVisibility ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default PasswordField;
