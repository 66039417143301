import { ZoningFetchConfig } from './ZoningComponents/types';

export const FETCH_CONFIG = {
  light: false,
  withVirtual: true,
};

export const FETCH_LIGHT_CONFIG = {
  light: true,
  withVirtual: true,
};

export const FETCH_SECTORS_CONFIG: ZoningFetchConfig = {
  ...FETCH_CONFIG,
  zoningType: 'LAPI',
};

export const FETCH_ZONING_CONFIG: ZoningFetchConfig = {
  ...FETCH_CONFIG,
  zoningType: 'FPS',
};

export const FETCH_LIGHT_ZONING_CONFIG: ZoningFetchConfig = {
  ...FETCH_LIGHT_CONFIG,
  zoningType: 'FPS',
};

export const FETCH_PV_ZONING_CONFIG: ZoningFetchConfig = {
  ...FETCH_CONFIG,
  zoningType: 'PV',
};
