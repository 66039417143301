import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DeleteAllIcon from 'material-ui/svg-icons/action/delete-forever';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import ConfirmAction from 'commons/ConfirmAction';
import { BKG_CYAN_SELECTED, BKG_DARK, STYLE_ERROR_WRAPPER } from 'theme';
import { deleteDocuments, downloadExport } from 'api/backoffice';
import { DownloadDTO, DownloadType } from 'api/backoffice/types';

import { getTopbarState, updateDownloadsList } from './duck';
import DownloadItem from './DownloadItem';
import { getExtensionFromDownloadType } from './helpers';

const { _tg } = window.loadTranslations(__filename);

const STYLE_DELETION_INFO: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  fontSize: 13,
  marginLeft: 24,
};

const STYLE_TITLE: CSSProperties = {
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 20,
  paddingTop: 20,
  marginBottom: 8,
};

function largeWidth() {
  return window.innerWidth > 1200 ? 1200 : window.innerWidth;
}

const DIALOG_TITLE = (
  <div style={STYLE_TITLE}>Téléchargements en cours et disponibles</div>
);

const LoadingContent = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        marginBottom: 24,
        color: BKG_CYAN_SELECTED,
        textAlign: 'center',
      }}
    >
      <span style={{ fontWeight: 'bold' }}>
        {' '}
        {_tg('Topbar.download.downloadList.downloading')}
      </span>
    </div>
    <div>
      <CircularProgress />
    </div>
  </div>
);

export type Resource = {
  resourceId: string;
  fileName: string;
  downloadType: DownloadType;
};

const confirmationMessage = (documentsToDeleteAmount: number) => {
  if (documentsToDeleteAmount === 1) {
    return _tg('Topbar.download.downloadList.confirmSelection');
  }
  return _tg('Topbar.download.downloadList.documentsToDeleteAmount', {
    documentsToDeleteAmount,
  });
};

type State = {
  loading: boolean;
  error: string | null | undefined;
  averageProgress: number | null | undefined;
  documentsToDelete: Array<Resource>;
};

type ReduxStateProps = {
  downloads: Array<DownloadDTO>;
  highlightDownloadsIcon: number;
};

type ReduxDispatchProps = {
  updateDownloadsListDispatch: () => void;
};

type Props = ReduxStateProps &
  ReduxDispatchProps & {
    open: boolean;
    closeDownloadList: () => void;
  };

const INITIAL_STATE = {
  loading: false,
  error: null,
  averageProgress: null,
  documentsToDelete: [],
};

// Modale avec les documents en cours de génération et ceux encore disponibles au téléchargement
class Download extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onClickDelete = async () => {
    const { documentsToDelete } = this.state;
    const { updateDownloadsListDispatch } = this.props;
    if (!documentsToDelete || documentsToDelete.length === 0) {
      return;
    }
    this.setState({ error: null });
    try {
      await deleteDocuments(
        documentsToDelete.map(({ resourceId }) => resourceId)
      );
    } catch (error) {
      this.displayError(error);
    } finally {
      this.closeConfirmDeletion();
      updateDownloadsListDispatch();
    }
  };

  handleClose = () => {
    const { closeDownloadList } = this.props;
    closeDownloadList();
  };

  downloadFile = async (resource: Resource) => {
    const { updateDownloadsListDispatch } = this.props;
    this.setState({ loading: true, error: null });
    try {
      const file = (await downloadExport(resource.resourceId)) as Response;

      const fileName = resource.fileName || 'export';
      const blobFile = await file.blob();
      FileSaver.saveAs(
        blobFile,
        `${fileName}.${getExtensionFromDownloadType(resource.downloadType)}`
      );

      updateDownloadsListDispatch();
    } catch (error) {
      this.displayError(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  displayError = (error: any) => {
    const requestId = error?.json?.requestId as string | undefined;
    const requestIdText = requestId ? `(requestId = ${requestId})` : '';
    this.setState({
      error: `Une erreur est survenue. ${requestIdText}`,
    });
  };

  updateDeletionList = (documentsToDelete: Array<Resource>) => {
    this.setState({ documentsToDelete });
  };
  closeConfirmDeletion = () => this.setState({ documentsToDelete: [] });
  selectAll = () => {
    this.setState(prevState => {
      const { downloads } = this.props;
      return {
        documentsToDelete:
          prevState.documentsToDelete.length === 0
            ? downloads
                .filter(d => d.progress === 100 || d.progress === -1)
                .map(({ resourceId, fileName, downloadType }) => ({
                  resourceId,
                  fileName,
                  downloadType,
                }))
            : [],
      };
    });
  };

  render() {
    const { loading, error, documentsToDelete } = this.state;
    const { downloads, open } = this.props;

    const deleteColor = documentsToDelete.length ? BKG_CYAN_SELECTED : BKG_DARK;
    const deleteAllColor =
      documentsToDelete.length > 0 &&
      documentsToDelete.length ===
        downloads.filter(d => d.progress === 100 || d.progress === -1).length
        ? BKG_CYAN_SELECTED
        : BKG_DARK;

    if (open && loading) {
      return (
        <Dialog
          modal={false}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={false}
          open
          contentStyle={{ width: 400, maxWidth: 'none' }}
        >
          <LoadingContent />
        </Dialog>
      );
    }

    if (open && !loading) {
      const title = [
        DIALOG_TITLE,
        <div key="1" style={STYLE_DELETION_INFO}>
          <IconButton
            style={{ padding: 0, margin: '0 6px' }}
            title="Tout sélectionner"
            iconStyle={{ height: 22, width: 22 }}
            onClick={this.selectAll}
          >
            <DeleteIcon color={deleteAllColor} />
          </IconButton>
          <div>
            {_tg('Topbar.download.downloadList.fileAutoDeletedAfterAWeek')}
          </div>
        </div>,
      ];

      const actions = [
        <ConfirmAction
          enabled={documentsToDelete.length > 0}
          action={this.onClickDelete}
          message={confirmationMessage(documentsToDelete.length)}
          onClose={this.closeConfirmDeletion}
          isOpen={undefined}
        >
          <IconButton
            style={{ padding: '0 0 10px 0', position: 'fixed', left: 30 }}
            title={_tg('Topbar.download.downloadList.deletingSelectedFiles')}
            iconStyle={{ height: 24, width: 24 }}
          >
            <DeleteAllIcon color={deleteColor} />
          </IconButton>
        </ConfirmAction>,
        <BoButton
          key="discard"
          style={{ marginRight: 10 }}
          label="Fermer"
          primary
          onClick={this.handleClose}
        />,
      ];

      return (
        <div>
          <Dialog
            title={title}
            actions={actions}
            modal={false}
            onRequestClose={this.handleClose}
            autoScrollBodyContent={!error}
            open
            contentStyle={{ width: largeWidth(), maxWidth: 'none' }}
          >
            {error ? (
              <div style={STYLE_ERROR_WRAPPER}>
                <ErrorBlock error={{ message: error }} />
              </div>
            ) : (
              <div>
                {downloads.map(d => (
                  <DownloadItem
                    key={d.resourceId}
                    download={d}
                    downloadFile={this.downloadFile}
                    documentsToDelete={documentsToDelete}
                    updateDeletionList={this.updateDeletionList}
                  />
                ))}
              </div>
            )}
          </Dialog>
        </div>
      );
    }

    return <div />;
  }
}

export default connect(
  (state): ReduxStateProps => {
    const { downloads, highlightDownloadsIcon } = getTopbarState(state);
    return {
      downloads,
      highlightDownloadsIcon,
    };
  },
  (dispatch): ReduxDispatchProps => ({
    updateDownloadsListDispatch: () => dispatch(updateDownloadsList()),
  })
)(Download);
