import { CSSProperties } from 'react';

import { TEXT_COLOR_LIGHT } from 'theme';

export const STYLE_CONTAINER: CSSProperties = {
  flex: 1,
  display: 'flex',
  fontSize: 10,
  justifyContent: 'space-between',
};

export const STYLE_NAME_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  color: TEXT_COLOR_LIGHT,
  overflow: 'hidden',
};

export const STYLE_FILE_NAME: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '75%',
};
