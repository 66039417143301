import React, { useEffect, useState, CSSProperties } from 'react';

import { Universe } from 'UniverseHandler/duck';
import { fetchLogo } from 'api/logo';
import { LogoDTO } from 'api/root-config/types';

interface Props {
  cityId: string;
  universe: Universe;
}

const STYLE_CITY_LOGO: CSSProperties = {
  position: 'absolute',
  maxHeight: 100,
  maxWidth: 200,
  top: -28,
  border: '8px solid #cccccc',
  zIndex: 100,
};

interface LogoState {
  [universe: string]: string; // Key: universe; Value: b64 data corresponding to the logo
}

const Logo = ({ cityId, universe }: Props): JSX.Element => {
  const [logos, setLogos] = useState<LogoState>({});

  useEffect(() => {
    const fn = async () => {
      const logoArray = await fetchLogo(cityId, 'LOGO');
      const state = {};
      logoArray.forEach((logo: LogoDTO) => {
        state[logo.type.toLowerCase()] = logo.logo;
      });
      setLogos(state);
    };
    void fn();
  }, [cityId]);

  return (
    <>
      <img
        style={STYLE_CITY_LOGO}
        src={logos[universe] || logos.fps} // Try to fallback on fps logo since pv logo can be undefined
      />
    </>
  );
};

export default Logo;
