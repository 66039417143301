import { Camera } from '@cvfm-front/tefps-types';

import { apiGet, apiPost } from '../helpers';

const ROOT_API = '/api/proxy/parking/v1/parking/{cityId}/configuration';

export async function getCityCamerasConfig(): Promise<Array<Camera>> {
  return apiGet<Array<Camera>>(ROOT_API);
}

export async function updateCityCamerasConfig(
  cityCamerasConfig: Array<Camera>
): Promise<void> {
  return apiPost<void>(ROOT_API, {
    cameras: cityCamerasConfig,
  });
}
