import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import { RadioButton, RadioButtonGroup, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import { BKG_DARK_BLUE } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { sendTestEmail, sendTestSms } from 'api/backoffice';
import { unreachable } from 'commons/Enums';
import { MailTemplateDTO } from '@cvfm-front/tefps-types';
import { validateEmail } from '@cvfm-front/commons-utils';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  close: () => void;
  useSms: boolean;
  hintList: Array<{
    field: string;
    legend: string | null | undefined;
    required?: boolean;
    testValue?: string;
  }>;
  mailTemplateDTO: MailTemplateDTO;
};

const MailEditorTest = ({
  open,
  close,
  useSms,
  hintList,
  mailTemplateDTO,
}: Props): JSX.Element => {
  const [notificationType, setNotificatonType] = useState<'MAIL' | 'SMS'>(
    'MAIL'
  );

  const [destination, setDestination] = useState('');

  const setMessage = useSnackbar();

  const phoneNumberRegEx = /^((\+)33|0)[6-7](\d{2}){4}$/;

  function onChangeNotificationType(
    _e: React.FormEvent<Element>,
    selected: 'MAIL' | 'SMS'
  ) {
    setNotificatonType(selected);
  }

  function onChangeDestination(_e: React.FormEvent<Element>, value: string) {
    setDestination(value);
  }

  function replaceTestValue(content: string): string {
    return hintList
      .filter(h => h.testValue)
      .reduce(
        (acc, hint) => acc.replace(`{${hint.field}}`, hint.testValue || ''),
        content
      );
  }

  async function onConfirm() {
    try {
      switch (notificationType) {
        case 'MAIL':
          await sendTestEmail({
            content: replaceTestValue(mailTemplateDTO.mail || ''),
            subject: replaceTestValue(mailTemplateDTO.subject || ''),
            to: destination,
          });
          break;
        case 'SMS':
          await sendTestSms({
            content: replaceTestValue(mailTemplateDTO.sms || ''),
            to: destination,
          });
          break;
        default:
          unreachable(notificationType);
      }
      setMessage(_t('notification.success'));
    } catch (err) {
      setMessage(err.json.message);
    }
  }
  let error: null | string;
  if (notificationType === 'SMS') {
    error = phoneNumberRegEx.exec(destination) ? null : 'Invalid number';
  } else if (notificationType === 'MAIL') {
    error = validateEmail(destination) ? null : 'Invalid mail';
  } else {
    error = null;
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      secondary
      onClick={close}
      style={{ marginRight: 20 }}
    />,
    <BoButton
      label={_tg('action.confirm')}
      primary
      onClick={onConfirm}
      disabled={!!error}
    />,
  ];

  return (
    <Dialog
      title={_t('dialog.title')}
      titleStyle={{ color: BKG_DARK_BLUE }}
      actions={actions}
      modal
      open={open}
    >
      {useSms && (
        <RadioButtonGroup
          name="group"
          onChange={onChangeNotificationType}
          valueSelected={notificationType}
        >
          <RadioButton name="MAIL" value="MAIL" label="Email" />
          <RadioButton name="SMS" value="SMS" label="SMS" />
        </RadioButtonGroup>
      )}
      <TextField
        value={destination}
        placeholder="Destinataire"
        errorText={error}
        onChange={onChangeDestination}
      />
    </Dialog>
  );
};

export default MailEditorTest;
