import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  onCreate: (arg0: string) => Promise<void>;
  onClose: () => void;
};

const MailConfigurationCreationModal = ({ open, onCreate, onClose }: Props) => {
  const [name, setName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = () => {
    setName('');
    setErrorMessage('');
    onClose();
  };

  const handleCreate = async () => {
    try {
      await onCreate(name);
      handleClose();
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const onNameChange = (
    event: React.FormEvent<HTMLInputElement>,
    newValue: string
  ) => {
    setName(newValue);
  };

  const actions = [
    <BoButton label={_tg('action.cancel')} secondary onClick={handleClose} />,
    <BoButton
      label={_tg('action.validate')}
      style={{ marginLeft: 10 }}
      primary
      onClick={handleCreate}
    />,
  ];

  return (
    <Dialog
      title={_t('element.dialog.title')}
      actions={actions}
      modal
      open={open}
    >
      <TextField
        hintText={_t('element.text.hintText')}
        floatingLabelText={_t('element.text.floatingLabelText')}
        value={name}
        onChange={onNameChange}
        errorText={errorMessage}
      />
    </Dialog>
  );
};

export default MailConfigurationCreationModal;
