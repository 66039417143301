import React, { useEffect, useState } from 'react';
import _cloneDeep from 'lodash.clonedeep';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import { Filters, FilterType } from 'commons/types/filterbar';

import mapFilterToComponent from './mapFilterToComponent';

type Props = {
  initialFilters: Filters;
  totalHits: number;
  onChangeFilters: (arg0: Filters) => void;
  facetings: CheckboxFaceting;
};

const GenericFilterBar = ({
  initialFilters,
  totalHits,
  onChangeFilters,
  facetings,
}: Props): JSX.Element => {
  const [filters, setFilters] = useState<Filters>(_cloneDeep(initialFilters));

  useEffect(() => {
    if (initialFilters) {
      setFilters(_cloneDeep(initialFilters));
      onChangeFilters(initialFilters);
    }
  }, [initialFilters]);

  const filterArray: Array<FilterType> = Object.values(filters);

  const onChange = (id: string, value: any) => {
    let changedValue = false;
    const newFilters: Filters = { ...filters };
    const actualFilter: FilterType = filters[id];
    // eslint-disable-next-line default-case
    switch (actualFilter.type) {
      case 'INPUT':
        changedValue = actualFilter.inputValue !== value;
        actualFilter.inputValue = value;
        break;
      case 'MULTISEARCH':
        changedValue = actualFilter.multiSearchValue !== value;
        actualFilter.multiSearchValue = value;
        break;
      case 'SELECT':
        changedValue = actualFilter.selectValue !== value;
        actualFilter.selectValue = value;
        break;
      case 'CHECKBOX_TO_BOOLEAN':
        changedValue = actualFilter.checkboxValue !== value;
        actualFilter.checkboxValue = value;
        break;
      case 'MULTISELECT':
        changedValue = actualFilter.selectValues !== value;
        actualFilter.selectValues = value;
        break;
      case 'CHECKBOX':
        changedValue = actualFilter.checkboxValues !== value;
        actualFilter.checkboxValues = value;
        break;
      case 'DATETIME':
        changedValue = actualFilter.datetimeValue !== value;
        actualFilter.datetimeValue = value;
        break;
      case 'PRICE_RANGE':
        changedValue = actualFilter.priceRangeValue !== value;
        actualFilter.priceRangeValue = value;
        break;
      case 'CHECKBOX_TO_RANGE':
        changedValue = actualFilter.checkboxValues !== value;
        actualFilter.checkboxValues = value;
        break;
      case 'AUTOCOMPLETE':
        changedValue = actualFilter.search !== value;
        actualFilter.search = value;
        break;
      default:
        break;
    }
    if (changedValue) {
      setFilters(newFilters);
      onChangeFilters(newFilters);
    }
  };

  const resetFilters = () => {
    const defaultFilters: Filters = ((Object.entries(filters) as any) as Array<
      FilterType
      // eslint-disable-next-line
      // @ts-ignore
    >).reduce((acc, [id, filter]) => {
      return {
        ...acc,
        [id]: {
          ...filter,
          value: filter.defaultValue,
          inputValue: filter.inputDefaultValue,
          multiSearchValue: filter.multiSearchDefaultValue,
          selectValue: filter.selectDefaultValue,
          checkboxValues: filter.checkboxDefaultValues,
          datetimeValue: filter.datetimeDefaultValue,
          priceRangeValue: filter.priceRangeDefaultValue,
          checkboxValue: filter.checkboxDefaultValue,
          selectValues: filter.selectDefaultValues,
        },
      };
    }, {});

    setFilters(defaultFilters);
    onChangeFilters(defaultFilters);
  };

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      {filterArray
        .filter(filter => !filter.hide)
        .map(filter => mapFilterToComponent(filter, onChange, facetings))}
    </Sidebar>
  );
};

export default GenericFilterBar;
