import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import {
  AvailableModuleConfiguration,
  getConfigState,
  ModulesConfiguration,
} from 'config/duck';

const { _t, _tg } = window.loadTranslations(__filename);

const translateModulesMessages = (): {
  [key in AvailableModuleConfiguration]: string;
} => {
  return {
    fps: _t('modulesMessages.fps'),
    control: _t('modulesMessages.control'),
    rapo: _t('modulesMessages.rapo'),
    ccsp: _t('modulesMessages.ccsp'),
    tv: _t('modulesMessages.tv'),
    subscribers: _t('modulesMessages.subscribers'),
    tasks: _t('modulesMessages.tasks'),
    planner: _t('modulesMessages.planner'),
    pv: '', // TODO,
    gpv: '', // TODO,
    lapiReview: '', // TODO
    controlView: '',
    parking: '',
    parkingMeter: '',
    eligibility: _t('modulesMessages.eligibility'),
    tao: '',
  };
};

const MODULES_BASE_URL: { [key in AvailableModuleConfiguration]: string } = {
  fps: '#/fps',
  control: '#/controls',
  rapo: '#/recourses',
  ccsp: '#/tsp',
  tv: '#/tickets',
  subscribers: '#/beneficiaries',
  tasks: '#/tasks',
  pv: '', // TODO
  gpv: '', // TODO,
  planner: '', // TODO
  lapiReview: '', // TODO
  controlView: '',
  parking: '',
  parkingMeter: '',
  eligibility: '#/eligibilities',
  tao: '',
};

type Props = {
  module: AvailableModuleConfiguration;
  modulesConfiguration: ModulesConfiguration;
};

const AdvertisingModal = ({ module, modulesConfiguration }: Props) => {
  const configuration = modulesConfiguration[module];

  if (!configuration.enabled && configuration.displayed) {
    const previousLocation = sessionStorage.getItem('previousLocation');
    const url =
      !previousLocation || previousLocation.includes(MODULES_BASE_URL[module])
        ? '#/'
        : previousLocation;

    return (
      <Dialog
        actions={[<FlatButton label={_tg('action.back')} href={url} />]}
        modal={false}
        open
      >
        {translateModulesMessages()[module]}
      </Dialog>
    );
  }

  return null;
};

export default connect(state => {
  const { modulesConfiguration } = getConfigState(state);
  return {
    modulesConfiguration,
  };
})(AdvertisingModal);
