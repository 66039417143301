import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  mapSnackbarActionToProps,
  mapSnackbarStateToProps,
  SnackbarReduxDispatchProps,
  SnackbarReduxStateProps,
} from 'commons/Snackbar/ducks/mapper';
import {
  deleteDocument,
  deleteSection,
  upsertParkingMeterCVGU,
  upsertParkingMeterPriceList,
} from 'api/documentLibrary';
import {
  ParkingMeterConfiguration,
  SectionDocumentDTO,
} from 'api/documentLibrary/types';

import {
  mapModalsActionToProps,
  mapModalsStateToProps,
  ModalsReduxDispatchProps,
  ModalsReduxStateProps,
} from '../ducks/mapper';
import {
  DocumentLibraryReduxDispatchProps,
  DocumentLibraryReduxStateProps,
  mapDocumentLibraryActionToProps,
  mapDocumentLibraryStateToProps,
} from '../../ducks/mapper';
import { getConfigState } from '../../../config/duck';

import { DeleteModalContainer } from './DeleteModalContainer';

type ReduxStateProps = ModalsReduxStateProps &
  SnackbarReduxStateProps &
  DocumentLibraryReduxStateProps & {
    parkingMeterConfigurationDTO: ParkingMeterConfiguration;
  };

type ReduxDispatchProps = ModalsReduxDispatchProps &
  SnackbarReduxDispatchProps &
  DocumentLibraryReduxDispatchProps;

type DeleteModalProps = ReduxDispatchProps & ReduxStateProps;

type DeleteModalState = {
  documentsToRemove: MapInterface<boolean>;
};

class DeleteModal extends React.Component<DeleteModalProps, DeleteModalState> {
  constructor(props: DeleteModalProps) {
    super(props);
    this.initState(props.documents);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: DeleteModalProps): void {
    const { documents } = this.props;
    if (newProps.documents !== documents) {
      this.initState(newProps.documents);
    }
  }

  onToggleDocumentremove = (ev: React.MouseEvent<HTMLInputElement>) => {
    const { documentId } = ev.currentTarget.dataset;
    const { documentsToRemove } = this.state;
    const actualValue = documentsToRemove[documentId as string];
    this.setState({
      documentsToRemove: Object.assign(documentsToRemove, {
        [documentId as string]: !actualValue,
      }),
    });
  };

  onDocumentRemove = async () => {
    const { snackbarSendMessage, removeDocument } = this.props;
    const { documentsToRemove } = this.state;
    const promises: Promise<any>[] = [];

    try {
      Object.keys(documentsToRemove).forEach(documentToRemoveKey => {
        const documentsToRemoveValue = documentsToRemove[documentToRemoveKey];
        if (documentsToRemoveValue) {
          removeDocument(documentToRemoveKey);
          promises.push(deleteDocument(documentToRemoveKey));
        }
      });
      await Promise.all(promises);
    } catch (e) {
      snackbarSendMessage((e as Error).message);
    }
  };

  onSectionRemove = async (): Promise<void> => {
    const {
      sectionId,
      closeModals,
      snackbarSendMessage,
      removeSection,
    } = this.props;
    try {
      removeSection(sectionId);
      await deleteSection(sectionId);
      closeModals();
    } catch (e) {
      snackbarSendMessage((e as Error).message);
    }
  };

  initState = (documents: SectionDocumentDTO[]): void => {
    const documentsToRemove: MapInterface<boolean> = {};

    documents.forEach(document => {
      documentsToRemove[document.id] = false;
    });

    this.setState({ documentsToRemove });
  };

  closeOneDocumentSectionModals = async () => {
    const { closeModals, sectionId, parkingMeterConfigurationDTO } = this.props;
    if (sectionId === 'PRICELIST') {
      await upsertParkingMeterPriceList(null, parkingMeterConfigurationDTO);
      // Modifying the object locally for front
      parkingMeterConfigurationDTO.priceListId = null;
    } else if (sectionId === 'CVGU') {
      await upsertParkingMeterCVGU(null, parkingMeterConfigurationDTO);
      // Modifying the object locally for front
      parkingMeterConfigurationDTO.cvguId = null;
    }
    closeModals();
    await this.onDocumentRemove();
  };

  render(): JSX.Element {
    const { isDeleteModalOpen, sectionId, closeModals, documents } = this.props;
    const { documentsToRemove } = this.state || {};
    const filteredDocuments = documents.filter(e => e.sectionId === sectionId);

    return (
      <DeleteModalContainer
        documents={filteredDocuments}
        documentsToRemove={documentsToRemove}
        isDeleteModalOpen={isDeleteModalOpen}
        onDocumentRemove={this.onDocumentRemove}
        onSectionRemove={this.onSectionRemove}
        onToggleDocumentremove={this.onToggleDocumentremove}
        closeModals={closeModals}
        closeOneDocumentSectionModals={this.closeOneDocumentSectionModals}
        sectionId={sectionId}
      />
    );
  }
}

const mapState = (state: any) => ({
  ...mapModalsStateToProps(state),
  ...mapDocumentLibraryStateToProps(state),
  ...mapSnackbarStateToProps(state),
  ...getConfigState(state),
});

const mapAction = (dispatch: Dispatch<any>): any => {
  return {
    ...mapDocumentLibraryActionToProps(dispatch),
    ...mapModalsActionToProps(dispatch),
    ...mapSnackbarActionToProps(dispatch),
  };
};

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapState,
  mapAction
)(DeleteModal);
