import { apiDelete, apiGet, apiPut } from 'api/helpers';

import { InstrumentDTO } from './types';

const BASE_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/instruments';

export async function getInstruments(): Promise<Array<InstrumentDTO>> {
  return apiGet<Array<InstrumentDTO>>(BASE_URL);
}

export async function createOrUpdateInstrument(
  instrument: InstrumentDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${instrument.identifier}`, instrument);
}

export async function deleteInstrument(
  instrumentIdentifier: string
): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${instrumentIdentifier}`);
}
