import React, { CSSProperties } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import Badge from 'material-ui/Badge';
import IconButton from 'material-ui/IconButton';
import DownloadIcon from 'material-ui/svg-icons/file/cloud-download';

import { DownloadDTO } from 'api/backoffice/types';
import { getApiState } from 'api/duck';

import {
  getTopbarState,
  hasPendingDownload,
  highlightDownloads,
  updateDownloadsList,
} from './duck';
import './keyframe.css';

const PENDING_DOWNLOAD_HEARTBEAT = 1.5 * 1000; // 1.5 seconds
const SLOW_DOWNLOAD_HEARTBEAT = 60 * 1000; // 1 minute

const SHOW_DOWNLOAD_STARTED_DURATION = 10000; // 10s
const SHOW_DOWNLOAD_ENDED_DURATION = 10000; // 8s

const CYAN_GLOW_START = 'downloadStart 1s ease-in-out infinite';
const GREEN_GLOW_END = 'downloadEnd 1s ease-in-out infinite';

const STYLE_DOWNLOADS_COUNTER: CSSProperties = {
  flex: 1,
  top: 6,
  right: 2,
  width: 20,
  height: 20,
  fontSize: 11,
  borderRadius: 50,
};

function computeGlowing(glowCode: number) {
  if (glowCode === 1) {
    return CYAN_GLOW_START;
  }
  if (glowCode === 2) {
    return GREEN_GLOW_END;
  }
  return undefined;
}

type State = {
  averageProgress: number | null | undefined;
};

type Props = {
  canDownload: boolean;
  downloads: Array<DownloadDTO>;
  highlightDownloadsIcon: number;
  updateDownloadsListDispatch: () => void;
  stopBlinkingNotificationDispatch: () => void;
  openDownloadList: () => void;
};

const INITIAL_STATE = {
  averageProgress: null,
};

class Notification extends React.Component<Props, State> {
  timeoutId0: number | null | undefined = null;
  timeoutId1: number | null | undefined = null;
  timeoutId2: number | null | undefined = null;

  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.slowlyRefreshDownloadsList();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      highlightDownloadsIcon,
      stopBlinkingNotificationDispatch,
      canDownload,
      updateDownloadsListDispatch,
    } = this.props;
    const pendingDownloads = hasPendingDownload(nextProps.downloads);
    if (pendingDownloads && canDownload) {
      this.timeoutId1 = window.setTimeout(
        updateDownloadsListDispatch,
        PENDING_DOWNLOAD_HEARTBEAT
      );
    }
    const thresholdDate = moment().subtract(30, 'minutes');
    const recentPendingDownloads =
      nextProps.downloads
        .filter(
          d =>
            d.progress >= 0 &&
            d.progress < 100 &&
            thresholdDate.isBefore(moment(d.downloadStart))
        )
        .map(({ progress }) => progress) || [];
    const averageProgress = recentPendingDownloads.length
      ? recentPendingDownloads.reduce((p1, p2) => p1 + p2, 0) /
        recentPendingDownloads.length
      : null;
    if (highlightDownloadsIcon !== nextProps.highlightDownloadsIcon) {
      if (nextProps.highlightDownloadsIcon === 1) {
        this.timeoutId2 = window.setTimeout(
          stopBlinkingNotificationDispatch,
          SHOW_DOWNLOAD_STARTED_DURATION
        );
      } else if (nextProps.highlightDownloadsIcon === 2) {
        this.timeoutId2 = window.setTimeout(
          stopBlinkingNotificationDispatch,
          SHOW_DOWNLOAD_ENDED_DURATION
        );
      }
    }
    this.setState({ averageProgress });
  }

  componentWillUnmount() {
    const { timeoutId0, timeoutId1, timeoutId2 } = this;
    if (timeoutId0) clearTimeout(timeoutId0);
    if (timeoutId1) clearTimeout(timeoutId1);
    if (timeoutId2) clearTimeout(timeoutId2);
  }

  slowlyRefreshDownloadsList = () => {
    const { canDownload, updateDownloadsListDispatch } = this.props;
    if (canDownload) {
      updateDownloadsListDispatch();
      this.timeoutId0 = window.setTimeout(
        this.slowlyRefreshDownloadsList,
        SLOW_DOWNLOAD_HEARTBEAT
      );
    }
  };

  openModal = () => {
    const { openDownloadList, stopBlinkingNotificationDispatch } = this.props;
    stopBlinkingNotificationDispatch();
    openDownloadList();
  };

  render() {
    const { averageProgress } = this.state;
    const { downloads, highlightDownloadsIcon } = this.props;

    if (!downloads || downloads.length < 1) {
      return null;
    }

    return (
      <div onClick={this.openModal}>
        <Badge
          style={{ padding: 0 }}
          badgeContent={downloads.length}
          primary
          badgeStyle={{
            ...STYLE_DOWNLOADS_COUNTER,
            animation: computeGlowing(highlightDownloadsIcon),
          }}
        >
          {averageProgress != null && (
            <CircularProgress
              mode="determinate"
              value={averageProgress}
              style={{
                position: 'absolute',
                top: 5,
                right: 0,
                zIndex: 1,
              }}
              color="#6FF"
              size={23}
              thickness={3}
            />
          )}
          <IconButton
            style={{ padding: 0 }}
            tooltip="Téléchargements"
            iconStyle={{ height: 22, width: 40 }}
          >
            <DownloadIcon color="white" />
          </IconButton>
        </Badge>
        <Snackbar
          open={highlightDownloadsIcon === 2}
          message="Génération de document terminée"
          autoHideDuration={4000}
        />
      </div>
    );
  }
}

export default connect(
  state => {
    const { userInfo } = getApiState(state);
    const { downloads, highlightDownloadsIcon } = getTopbarState(state);
    return {
      canDownload: userInfo && userInfo.rights.includes('BACKOFFICE_DOWNLOAD'),
      downloads,
      highlightDownloadsIcon,
    };
  },
  dispatch => ({
    updateDownloadsListDispatch: () => dispatch(updateDownloadsList()),
    stopBlinkingNotificationDispatch: () => dispatch(highlightDownloads(0)),
  })
)(Notification);
