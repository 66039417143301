import { useContext } from 'react';

import { SnackBarContext } from './SnackBarContext';

const useSnackbar = () => {
  const setMessage = useContext(SnackBarContext);

  return setMessage;
};

export default useSnackbar;
