import React, { CSSProperties, useState } from 'react';
import { TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import TextAreaWithHints from 'commons/TextAreaWithHints';
import {
  BKG_PINK,
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
  STYLE_TITLE,
  TXT_RED,
} from 'theme';
import { MailTemplateDTO } from '@cvfm-front/tefps-types';
import Separator from 'commons/Separator';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';

import { getUnusedAnchorList } from '../../utils';

import HintListDrawer from './components/HintListDrawer';
import MailEditorTest from './components/MailEditorTest';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_BUTTON_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  margin: 20,
};

const STYLE_REQUIRED_ANCHOR: CSSProperties = {
  borderBottom: `1px solid ${TXT_RED}`,
  color: TXT_RED,
  fontSize: 14,
  paddingBottom: 10,
};

const SEPARATOR_STYLE: CSSProperties = {
  width: 'auto',
  height: 1,
  marginLeft: 20,
  marginRight: 20,
};

type Props = {
  mailTemplate: MailTemplateDTO;
  onContentChange: (newTemplate: MailTemplateDTO) => void;
  onSave?: () => Promise<void>;
  onPreview?: () => void;
  hintList: Array<{
    field: string;
    legend: string | null | undefined;
    required?: boolean;
    testValue?: string;
  }>;
  useSubject?: boolean;
  useSms?: boolean;
  enableTest?: boolean;
};

const MailEditor = ({
  mailTemplate,
  onContentChange,
  onSave,
  onPreview,
  hintList,
  useSms,
  useSubject,
  enableTest,
}: Props): JSX.Element => {
  const [unusedAnchorList, setUnusedAnchorList] = useState<Array<string>>([]);
  const [openTestModal, setOpenTestModal] = useState(false);
  const onChangeMail = (newValue: string) => {
    onContentChange({ ...mailTemplate, mail: newValue });
    setUnusedAnchorList(getUnusedAnchorList(newValue, hintList));
  };

  const getWarningText = () => {
    return _t('feedback.warning.mandatoryTags', {
      unusedAnchorList: unusedAnchorList
        .map(anchor => `{${anchor}}`)
        .toString(),
    });
  };

  function onChangeSubject(_e: never, value: string) {
    onContentChange({ ...mailTemplate, subject: value });
  }

  function onChangeSms(_e: never, value: string) {
    onContentChange({ ...mailTemplate, sms: value });
  }

  return (
    <div>
      {!!unusedAnchorList.length && (
        <div style={STYLE_REQUIRED_ANCHOR}>{getWarningText()}</div>
      )}
      <HintListDrawer
        description={_t('element.hints.description')}
        hintList={hintList}
      />
      <Separator style={SEPARATOR_STYLE} />
      {useSubject && (
        <div style={STYLE_INPUTS_CONTAINER}>
          <TextField
            floatingLabelText={_t('element.subject')}
            value={mailTemplate.subject || ''}
            onChange={onChangeSubject}
            style={STYLE_FIELD}
          />
        </div>
      )}
      <TextAreaWithHints
        hintText={_t('element.textArea.hintText')}
        multiLine
        fullWidth
        rows={6}
        value={mailTemplate.mail || ''}
        onChange={onChangeMail}
        hints={hintList.map(hint => ({
          field: hint.field,
          legend: hint.legend,
        }))}
      />
      {useSms && (
        <div>
          <SeparatorWithTitle
            style={STYLE_TITLE}
            title={_t('element.smsTitle')}
            color={BKG_PINK}
            titleSize={20}
          />
          <div style={STYLE_INPUTS_CONTAINER}>
            <TextField
              hintText={_tg('field.human.sms')}
              multiLine
              fullWidth
              rows={6}
              value={mailTemplate.sms || ''}
              onChange={onChangeSms}
            />
          </div>
        </div>
      )}
      {onSave && (
        <div style={STYLE_BUTTON_ROW}>
          <BoButton
            label={_tg('action.save_2')}
            onClick={onSave}
            primary
            disabled={!!unusedAnchorList.length}
          />
          {mailTemplate.id && onPreview && (
            <BoButton
              label={_t('preview')}
              onClick={onPreview}
              disabled={!!unusedAnchorList.length}
              style={{ marginRight: '10px' }}
            />
          )}
          {enableTest && (
            <BoButton
              label={_t('sendTest')}
              onClick={() => setOpenTestModal(true)}
              style={{ marginRight: '10px' }}
            />
          )}
        </div>
      )}
      {enableTest && (
        <MailEditorTest
          mailTemplateDTO={mailTemplate}
          useSms={!!useSms}
          hintList={hintList}
          open={openTestModal}
          close={() => setOpenTestModal(false)}
        />
      )}
    </div>
  );
};

export default MailEditor;
