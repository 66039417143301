import { apiGet } from 'api/helpers';
import {
  TreatmentAgentCounterDTO,
  VehicleCategory,
} from '@cvfm-front/tefps-types';

const ROOT_STATS_API =
  '/api/proxy/control/api/prv/cities/{cityId}/controls/statistics';

export async function fetchTodayReviewAgentStat(
  agentId: string,
  vehicleType: VehicleCategory
): Promise<TreatmentAgentCounterDTO> {
  return apiGet<TreatmentAgentCounterDTO>(
    `${ROOT_STATS_API}/today_count_by_agent/${agentId}?vehicleType=${vehicleType}`
  );
}
