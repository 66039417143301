import { getAuthLocalStore } from 'Login/auth-storage';

import { apiDelete, apiGet, apiPostUnauthenticated } from '../helpers';

import {
  BackofficeAuthResponseDTO,
  ChartsAuthResponseDTO,
  InternalAgent,
} from './types';

export async function doAuthAndRetrieveToken(
  cityId: string,
  login: string,
  password: string,
  deviceId: string | null | undefined,
  deviceSecret: string | null | undefined
): Promise<BackofficeAuthResponseDTO> {
  const credentials = { password, deviceId, deviceSecret };
  return apiPostUnauthenticated(
    `/api/prv/auth/v1/cities/${cityId}/token?login=${encodeURIComponent(
      login
    )}`,
    credentials
  );
}

export async function getUserInfo(): Promise<InternalAgent> {
  return apiGet<InternalAgent>('/api/proxy/directory/api/oauth2/v1/userinfo');
}

export async function getChartToken(): Promise<ChartsAuthResponseDTO> {
  return apiGet<ChartsAuthResponseDTO>('/api/prv/auth/v1/{cityId}/charts');
}

export async function signOut(): Promise<void> {
  const authLocalStore = getAuthLocalStore();

  if (!authLocalStore.accessToken || !authLocalStore.refreshToken) {
    return;
  }

  await apiDelete(
    `/api/prv/auth/v1?refreshTokenId=${authLocalStore.refreshToken}`
  );
}
