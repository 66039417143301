import React from 'react';

import CheckboxV1 from 'commons/SidebarV2/Components/CheckboxV1';
import Input from 'commons/SidebarV2/Components/Input';
import Dates from 'commons/SidebarV2/Components/Dates';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';
import MultiSearch from 'commons/SidebarV2/Components/MultiSearch';
import Select from 'commons/SidebarV2/Components/Select';
import { FilterType } from 'commons/types/filterbar';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';

const { _tg } = window.loadTranslations(__filename);

const mapFilterToComponent = (
  filter: FilterType,
  onChange: (id: string, value: any) => void,
  facetings: CheckboxFaceting
): JSX.Element => {
  switch (filter.type) {
    case 'INPUT':
      return (
        <Input
          id={filter.id}
          key={filter.id}
          title={filter.label}
          placeholder={filter.hint || ''}
          onChange={onChange}
          value={filter.inputValue || ''}
        />
      );
    case 'MULTISEARCH':
      return (
        <MultiSearch
          id={filter.id}
          key={filter.id}
          title={filter.label}
          placeholder={filter.hint || ''}
          modalTitle={filter.modalTitle || ''}
          modalFloatingLabelText={filter.modalFloatingLabelText || ''}
          onChange={onChange}
          value={filter.multiSearchValue}
        />
      );
    case 'DATETIME':
      return (
        <Dates
          id={filter.id}
          key={filter.id}
          title={filter.label}
          dates={filter.datetimeValue || { from: undefined, to: undefined }}
          onChange={onChange}
        />
      );
    case 'SELECT':
      return (
        <Select
          id={filter.id}
          key={filter.id}
          title={filter.label}
          onChange={onChange}
          selected={filter.selectValue}
          options={filter.selectOptions}
        />
      );
    case 'MULTISELECT':
      return (
        <Select
          id={filter.id}
          key={filter.id}
          title={filter.label}
          onChange={onChange}
          selected={filter.selectValues}
          options={filter.selectOptions}
          multiple
        />
      );
    case 'CHECKBOX':
    case 'CHECKBOX_TO_RANGE':
      return (
        <Checkboxes
          id={filter.id}
          key={filter.id}
          title={filter.label}
          options={filter.checkboxOptions || []}
          filters={filter.checkboxValues || new Set()}
          onChange={onChange}
          faceting={
            facetings && facetings[filter.checkboxFacetName]
              ? facetings[filter.checkboxFacetName]
              : null
          }
        />
      );
    case 'CHECKBOX_TO_BOOLEAN':
      return (
        <CheckboxV1
          id={filter.id}
          key={filter.id}
          title={filter.label}
          filter={filter.checkboxValue}
          options={filter.checkboxOptions}
          onChange={onChange}
        />
      );
    case 'PRICE_RANGE':
      return (
        <PriceRange
          id={filter.id}
          key={filter.id}
          title={filter.label}
          price={filter.priceRangeValue || { from: undefined, to: undefined }}
          onChange={onChange}
        />
      );
    case 'AUTOCOMPLETE':
      return (
        <Autocomplete
          id={filter.id}
          title={filter.label}
          options={filter.selectOptions}
          onChange={onChange}
          onAutocomplete={filter.onAutocomplete}
          search={filter.search}
        />
      );
    default:
      return <div>{_tg('commons.unknownFilter')}</div>;
  }
};

export default mapFilterToComponent;
