import React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { TXT_ERROR_RED } from 'theme';

const { _tg } = window.loadTranslations();

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
};

const ErrorModal = ({ open, onClose, message }: Props) => {
  const handleClose = () => {
    onClose();
  };

  const actions = [
    <BoButton label={_tg('action.close')} secondary onClick={handleClose} />,
  ];

  return (
    <Dialog
      title={_tg('feedback.error.generic')}
      titleStyle={{ color: TXT_ERROR_RED }}
      actions={actions}
      modal
      open={open}
    >
      {message}
    </Dialog>
  );
};

export default ErrorModal;
