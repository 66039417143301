import * as React from 'react';
import Dialog from 'material-ui/Dialog';
import DeleteForever from 'material-ui/svg-icons/action/delete-forever';

import BoButton from 'facade/BoButton';
import { SectionDocumentDTO } from 'api/documentLibrary/types';

import { COLOR_STYLE } from '../../style';
import { IconFromDocumentMimeType } from '../../IconFromDocumentMimeType';

const { _tg } = window.loadTranslations();

type Props = {
  isDeleteModalOpen: boolean;
  documents: SectionDocumentDTO[];
  documentsToRemove: MapInterface<boolean>;
  closeModals: () => any;
  onDocumentRemove: (ev: React.MouseEvent<HTMLElement>) => any;
  onSectionRemove: (ev: React.MouseEvent<HTMLElement>) => any;
  onToggleDocumentremove: (ev: React.MouseEvent<HTMLInputElement>) => void;
  closeOneDocumentSectionModals: (ev: React.MouseEvent<HTMLElement>) => any;
  sectionId: string;
};

export const DeleteModalContainer = ({
  documents,
  documentsToRemove,
  isDeleteModalOpen,
  closeModals,
  onDocumentRemove,
  onToggleDocumentremove,
  onSectionRemove,
  closeOneDocumentSectionModals,
  sectionId,
}: Props): JSX.Element => {
  const oneDocumentSections = ['PRICELIST', 'CVGU'];
  const isADocumentSelected = Object.values(documentsToRemove || {}).some(
    e => e
  );
  return (
    <Dialog
      title={_tg('action.deleteAlternate')}
      actions={[
        <BoButton label={_tg('action.back')} onClick={closeModals} />,
        <BoButton
          label={
            isADocumentSelected
              ? _tg('tefps.documentLibrary.sections.deleteSelection')
              : _tg('tefps.documentLibrary.sections.selectDocuments')
          }
          onClick={
            oneDocumentSections.includes(sectionId)
              ? closeOneDocumentSectionModals
              : onDocumentRemove
          }
          disabled={!isADocumentSelected}
          labelColor={COLOR_STYLE.LABEL_COLOR}
          backgroundColor={COLOR_STYLE.BKG_CYAN}
          style={{ marginLeft: 8 }}
        />,
      ]}
      open={isDeleteModalOpen}
    >
      {/* Display file to delete */}
      {documents.map(doc => {
        const iconColor = documentsToRemove[doc.id]
          ? COLOR_STYLE.BKG_CYAN
          : COLOR_STYLE.TEXT_COLOR_LIGHT;
        return (
          <div
            key={doc.id}
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={onToggleDocumentremove}
            data-document-id={doc.id}
          >
            <IconFromDocumentMimeType type={doc.type} color={iconColor} />
            <span>{doc.name}</span>
            <DeleteForever
              style={{ opacity: documentsToRemove[doc.id] ? 1 : 0.5 }}
              color={iconColor}
            />
          </div>
        );
      })}

      {/* Display remove section button */}
      {documents.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          <BoButton
            label={_tg('tefps.documentLibrary.sections.deleteSection')}
            labelColor={COLOR_STYLE.LABEL_COLOR}
            backgroundColor={COLOR_STYLE.BKG_CYAN}
            onClick={onSectionRemove}
          />
        </div>
      )}
    </Dialog>
  );
};
