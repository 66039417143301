import {
  AvailableModuleConfiguration,
  ModulesConfiguration,
} from 'config/duck';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';

import { getUniverseState, Universe, UniverseState } from './duck';

const STORAGE_UNIVERSE = 'cvfm.universe';

function getUniverse(cityId: string): Universe | null | undefined {
  try {
    const universeStorage = JSON.parse(
      localStorage.getItem(STORAGE_UNIVERSE) || ''
    );
    const universe = universeStorage[cityId];
    if (universe) {
      return universe;
    }
  } catch (err) {
    // NOOP
  }
  return null;
}

export function getActiveUniverse(state: any) {
  const { userInfo } = getApiState(state);
  const { activeUniverse } = getUniverseState(state);

  let universe = null;
  if (
    !userInfo?.hasPvAccess &&
    userInfo?.hasFpsAccess &&
    activeUniverse !== 'fps'
  )
    universe = 'fps';
  if (
    userInfo?.hasPvAccess &&
    !userInfo?.hasFpsAccess &&
    activeUniverse !== 'pv'
  )
    universe = 'pv';

  return activeUniverse || universe;
}

export function setUniverse(universe: Universe, cityId: string) {
  const universeStorage = JSON.parse(
    localStorage.getItem(STORAGE_UNIVERSE) || '{}'
  );
  universeStorage[cityId] = universe;
  localStorage.setItem(STORAGE_UNIVERSE, JSON.stringify(universeStorage));
}

export function clearUniverses() {
  console.log('Clearing universe settings'); // eslint-disable-line no-console
  localStorage.removeItem(STORAGE_UNIVERSE);
}

function getDefaultUniverse(): Universe {
  const { hostname } = window.location;

  if (hostname.includes('tepv')) {
    return 'pv';
  }
  if (hostname.includes('tetao')) {
    return 'tao';
  }
  return 'fps';
}

export function getInitConfig(
  modulesConfiguration: ModulesConfiguration,
  cityId: string,
  userInfo: InternalAgent
): UniverseState {
  const availableUniverses: Array<Universe> = [];

  if (
    modulesConfiguration.pv.enabled &&
    userInfo.rights.includes('UNIVERSE_SWITCH_PV')
  ) {
    availableUniverses.push('pv');
  }

  if (
    modulesConfiguration.tao.enabled &&
    userInfo.rights.includes('UNIVERSE_SWITCH_TAO')
  ) {
    availableUniverses.push('tao');
  }

  const fpsEnabled = Object.keys(modulesConfiguration).some(
    (module: AvailableModuleConfiguration) =>
      module !== 'pv' &&
      module !== 'tao' &&
      (modulesConfiguration[module].enabled ||
        modulesConfiguration[module].displayed)
  );
  if (fpsEnabled && userInfo.rights.includes('UNIVERSE_SWITCH_FPS')) {
    availableUniverses.push('fps');
  }

  switch (availableUniverses.length) {
    case 1:
      return {
        availableUniverses,
        activeUniverse: availableUniverses[0],
      };
    default:
      return {
        availableUniverses,
        activeUniverse: getUniverse(cityId) || getDefaultUniverse(),
      };
  }
}
