import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import { BKG_GREY_LIGHT } from 'theme';
import FlexCenter from 'commons/FlexCenter';

type Props = {
  children?: React.ReactNode;
  style?: Record<string, any>;
};

type BodyProps = {
  children?: React.ReactNode;
  style?: Record<string, any>;
  loading: boolean;
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  padding: 32,
  width: '100%',
  height: '100%',
  flexGrow: 1,
  maxWidth: '90%',
  margin: '0 auto',
  boxSizing: 'border-box',
};

const STYLE_LIST_FLEX: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

const STYLE_TBODY_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  height: '100%',
};

const STYLE_CONTAINER_BOTTOM: CSSProperties = {
  display: 'flex',
  borderTop: `2px solid ${BKG_GREY_LIGHT}`,
  paddingTop: 32,
  boxSizing: 'border-box',
};

export const ListWrapper = ({ children, style }: Props) => (
  <div style={{ ...STYLE_LIST_WRAPPER, ...style }}>
    <div style={STYLE_LIST_FLEX}>{children}</div>
  </div>
);

export const ListBody = ({ children, style, loading }: BodyProps) => {
  if (loading) {
    return (
      <div style={{ ...STYLE_TBODY_WRAPPER, ...style }}>
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      </div>
    );
  }
  return <div style={{ ...STYLE_TBODY_WRAPPER, ...style }}>{children}</div>;
};

export const ListBottom = ({ children, style }: Props) => (
  <div style={{ ...STYLE_CONTAINER_BOTTOM, ...style }}>{children}</div>
);
