import React, { useState } from 'react';
import Snackbar from 'material-ui/Snackbar';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const SnackBarContext = React.createContext<Function>(() => {});

const SnackBarProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { children } = props;
  const [message, setMessage] = useState<string>('');

  function handleClose() {
    setMessage('');
  }

  return (
    <SnackBarContext.Provider value={setMessage}>
      {children}
      <Snackbar
        key={message}
        open={message !== ''}
        message={message}
        autoHideDuration={Math.min(10000, 4000 + 30 * message.length)}
        onRequestClose={() => {
          handleClose();
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '100vw',
        }}
        bodyStyle={{ maxWidth: '90%' }}
      />
    </SnackBarContext.Provider>
  );
};

export { SnackBarContext, SnackBarProvider };
