import React, { CSSProperties } from 'react';

import BoButton from 'facade/BoButton';
import PdfButton from 'commons/PdfButton';

const { _t } = window.loadTranslations(__filename);

const STYLE_BAR: CSSProperties = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
};

type Props = {
  onEditMailClick: () => void;
  openPdfUrl: () => void;
};

const EditMailBar = ({ onEditMailClick, openPdfUrl }: Props) => {
  return (
    <div style={STYLE_BAR}>
      <PdfButton openPdfUrl={openPdfUrl} />
      <BoButton
        label={_t('element.editMail.label')}
        onClick={onEditMailClick}
      />
    </div>
  );
};

export default EditMailBar;
