import { apiDelete, apiGet, apiPut } from 'api/helpers';

import { InfractionLocationDTO } from './types';

const BASE_URL =
  '/api/proxy/pv/api/v1/customers/{cityId}/private/infraction-locations';

export async function getInfractionLocations(): Promise<
  Array<InfractionLocationDTO>
> {
  return apiGet<Array<InfractionLocationDTO>>(BASE_URL);
}

export async function upsertInfractionLocation(
  locationId: string,
  updatedInfractionLocation: InfractionLocationDTO
): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${locationId}`, updatedInfractionLocation);
}

export async function deleteInfractionLocation(
  locationId: string
): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${locationId}`);
}
