import { Moment } from 'moment';

export type JsonDatetime = string | number;

export type ImportResultDTO = {
  success: boolean;
  errors: Array<string>;
};

export type Plate = {
  plate: string;
  plateCountry: string;
  pricingCategory?: string;
};

export type Vehicle = {
  brand?: string;
  model?: string;
  surfaceArea?: number;
  pollutionType?: string;
  vehicleCategory?: string;
};

export type Location = {
  latitude: number;
  longitude: number;
  altitude: number;
  incertitude: number;
};

export type Address = {
  streetNumber: string;
  streetNumberBis?: string;
  streetType: string;
  streetName: string;
  specialPlace?: string;
  postOfficeBoxNumber?: string;
  postalCode: string;
  addressSubRegion: string;
  addressRegion: string;
  addressLocality: string;
  addressCountry: string;
};

export type SimpleAddress = {
  streetName: string;
  streetNumber: string;
  streetNumberBis?: string;
  streetType: string;
  postalCode: string;
  complement?: string;
  locality: string;
};

export type ControlAddressType = Omit<Address, 'streetNumber'> & {
  streetNumber?: string;
};

// conform to the backend media type
export type Media = {
  // Identifiant sur le service de stockage (URN pour S3)
  url: string;
  name?: string;
  dateCreated: string;
  dateRemoved?: string;
};

export type ControlMediaType =
  | 'PLATE'
  | 'CONTEXT'
  | 'CONTEXT_IMPROVED'
  | 'CONTEXT_HIK';

export type Position = 'FRONT' | 'BACK';

export type UploadFile = {
  size: number;
  mime: string;
  urn: string;
  contentUrl: string;
  created: string;
  name: string;
  file?: File;
  uploaded: boolean;
  purged?: boolean;
  mediaType?: ControlMediaType;
};

export type MapClusterDTO = {
  key: string;
  latitude: number;
  longitude: number;
  number: number;
};

export type Coordinates = { latitude: number; longitude: number };

export type PaymentStatus =
  | 'PENDING'
  | 'INCOMPLETE'
  | 'OVERPAID'
  | 'OVERPAID_REDUCED'
  | 'PAID'
  | 'PAID_REDUCED'
  | 'RECOVERED'
  | 'ABANDONED';

export const PENDING_AND_INCOMPLETE_PAYMENT_STATUSES: Array<PaymentStatus> = [
  'PENDING',
  'INCOMPLETE',
];

export type OffenderNotification =
  | 'PENDING'
  | 'IDENTIFIED'
  | 'APA_TO_SEND'
  | 'APA_SENT'
  | 'E_APA_TO_SENT'
  | 'E_APA_SENT'
  | 'NOTIFIED'
  | 'IMPOSSIBLE';

export type FpsType =
  | 'INITIAL'
  | 'TEST'
  | 'PRE_FPS'
  | 'CORRECTION'
  | 'RAPO'
  | 'CCSP'
  | 'CANCEL_ANTAI_CASE';
export type FnmsType =
  | 'INITIAL'
  | 'CORRECTION'
  | 'CANCELLED'
  | 'CANCEL_ANTAI'
  | 'TEST';

export type TicketCreator = 'USER' | 'FPS' | 'POLLUTION';

export type PaymentChannel =
  | 'DGFIP'
  | 'PARKMETER'
  | 'MOBILE'
  | 'DESK'
  | 'INTERNET'
  | 'MAIL'
  | 'VP'
  | 'ANTAI'
  | 'COMMUNITY';

export type PaymentMode =
  | 'CARD'
  | 'CARD_CONTACTLESS'
  | 'CASH'
  | 'CHECK'
  | 'ANTAI'
  | 'RECURRING_DEBIT'
  | 'DIRECT_DEBIT'
  | 'TRANSFERT';

export type PaymentOrigin = {
  key: PaymentChannel;
  label: string;
  paymentModes: Array<PaymentMode>;
};

export type RefundPayment = {
  paymentDatetime: Moment;
  paymentChannel: PaymentChannel;
  paymentMode: PaymentMode;
  comment: string;
};

export type Organization = {
  organizationId: string;
  name: string;
  url: string;
  address: Address;
  shortId: number;
};

export type ControlAgent = {
  agentId: string;
  name: string;
  worksFor?: Organization;
};

export type FnmsPaymentDTO = {
  paymentDatetime: string;
  paymentChannel: PaymentChannel;
  paymentMode: PaymentMode;
  paymentAmount: number;
  agent?: ControlAgent;
};

export type Payment = FnmsPaymentDTO & {
  comment?: string;
  paymentId: string;
  receptionDatetime: string;
  clientAppId?: string;
};

export type Person = {
  gender: 'MALE' | 'FEMALE';
  honorificPrefix: string;
  givenName: string;
  additionalName: string;
  maidenName?: string;
  familyName: string;
  address: Address;
  email: string;
  compagnyName?: string;
};

export type SignificantRight = {
  zoneId: string;
  parkId: string;
  cityId: string;
  ticketId: string;
  fineLegalId: string;
  type: 'FINE' | 'TICKET' | 'CONTRACT' | 'FREE' | 'OTHER';
  rightPrice: number;
  startDatetime: string;
  endDatetime: string;
};

export type Offender = Person | Organization;

export type KeyBucketDTO = {
  key: string;
  value: number;
  additionalLegend?: string;
};

export type SortParameters = {
  sortField: number;
  increasingOrder: boolean;
};

export type Pager = {
  page: number;
  maxRecords: number;
};

export type PatchObject<T> = {
  op: string;
  path: string;
  value: T;
};

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export type ItemIdName = { id: string; name: string };

export type ColumnConfig = { columns: Array<string> };

export type VehicleClass =
  | 'CYCLE'
  | 'CYCLO'
  | 'VP'
  | 'PL'
  | 'TCP'
  | 'TAXI'
  | 'MOTO'
  | 'CTTE'
  | 'PIETON'
  | 'EDPM'
  | 'BATEAU'
  | 'AUTRE';

export type PvAdress = {
  localisation: string;
  inseeCode: string;
  latitude: number;
  longitude: number;
};

export type PvMediaDTO = {
  contentUrl: string;
  url: string;
  name: string;
  dateCreated: string;
  expires: string | undefined;
};
