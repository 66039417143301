import querystring from 'querystring';

import { apiGet } from '../helpers';

import { AuditLogsSearchResultDTO } from './types';

const BASE_URL = 'api/proxy/audit/api/audit-logs/v1';

export async function searchAuditList(
  param: any = {}
): Promise<AuditLogsSearchResultDTO> {
  return apiGet<AuditLogsSearchResultDTO>(
    `${BASE_URL}?cityId={cityId}&${querystring.stringify(param)}`
  );
}
