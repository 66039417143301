import { IParkingMeterPatchObject } from 'commons/ZoningComponents/types';

import { ImportResultDTO, PatchObject } from '../commonTypes';
import {
  apiDelete,
  apiGet,
  apiGetRaw,
  apiPatch,
  apiPostCSV,
  apiPostRaw,
} from '../helpers';
import { ParkingMeterConfiguration } from '../documentLibrary/types';

import { ParkingMeterDTO } from './type';

const BASE_PROXY = '/api/proxy/tv';
const BASE_URL = '/api/cities/{cityId}/parkingMeter';

export async function importParkingMeter(file: File): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_URL}/import`, file);
}

export async function exportParkingMeter(): Promise<Response> {
  return apiPostRaw(`${BASE_URL}/export`, {});
}

export async function updateParkingMeterConfig(
  dto: PatchObject<ParkingMeterConfiguration>[]
): Promise<void> {
  return apiPatch<any>(`${BASE_URL}/updateConfig`, dto);
}

export async function getCityParkingMeters(): Promise<Array<ParkingMeterDTO>> {
  return apiGet<Array<ParkingMeterDTO>>(`${BASE_PROXY}${BASE_URL}/getList`);
}

export async function deleteCityParkingMeters(): Promise<boolean> {
  return apiDelete<boolean>(`${BASE_PROXY}${BASE_URL}`);
}

export async function deleteParkingMeterByNumber(
  number: string
): Promise<boolean> {
  return apiDelete<boolean>(`${BASE_PROXY}${BASE_URL}/${number}`);
}

export function getParkingMetersSummaryPdfUrl(): string {
  return `${BASE_PROXY}${BASE_URL}/summary`;
}

export async function getProofOfPayment(
  ticketId: string,
  proofPlate: string
): Promise<Blob> {
  const data = await apiGetRaw(`${BASE_URL}/proof/${ticketId}/${proofPlate}`);
  return data.blob();
}

export async function updateParkingMeter(
  parkingMeterNumber: string,
  updateConfig: IParkingMeterPatchObject[]
): Promise<void> {
  return apiPatch<any>(
    `${BASE_PROXY}${BASE_URL}/updateConfig/${parkingMeterNumber}`,
    updateConfig
  );
}
