import {
  AgentProfileDTO,
  AgentProfileUpsertDTO,
} from '@cvfm-front/tefps-types';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';

const BASE_URL = '/api/proxy/directory/api/cities/{cityId}/profiles/v1';

export async function listProfiles(
  details = false,
  type: EnumTypeUniverse = 'all',
  showDeleted = false
): Promise<Array<AgentProfileDTO>> {
  return apiGet<Array<AgentProfileDTO>>(
    `${BASE_URL}?details=${details.toString()}&showDeleted=${showDeleted.toString()}&type=${type}`
  );
}

export async function createProfile(
  request: AgentProfileUpsertDTO
): Promise<void> {
  return apiPost<void>(BASE_URL, request);
}

export async function editProfile(
  profileId: string,
  update: AgentProfileUpsertDTO
): Promise<void> {
  // Sets aren't serializable
  return apiPut<void>(`${BASE_URL}/${profileId}`, {
    ...update,
    agentRights: Array.from(update.agentRights),
  });
}

export async function deleteProfile(profileId: string): Promise<void> {
  return apiDelete<void>(`${BASE_URL}/${profileId}`);
}

export async function restoreProfile(profileId: string): Promise<void> {
  return apiPut<void>(`${BASE_URL}/${profileId}/enable`, {});
}
