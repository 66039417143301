import { apiPost } from '../helpers';

import { EmbedDashboardAccess, SupersetDashboardFilters } from './types';

const BASE_BO_URL = '/api/proxy/exporter/api/v0/cities/{cityId}/superset';

export async function loadEmbedDashboardAccess(
  filters: SupersetDashboardFilters
): Promise<EmbedDashboardAccess> {
  return apiPost<EmbedDashboardAccess>(
    `${BASE_BO_URL}/loadEmbedDashboardAccess`,
    filters
  );
}
