import { PatchObject } from 'api/commonTypes';

import { apiPut } from '../helpers';

import { PatchBOLapiReviewConfigurationDTO } from './types';

const BASE_PRV_URL = '/api/prv/v0/cities/lapi_review_configuration/{cityId}';

export async function upsertLapiReviewConfiguration(
  updateConfig: PatchObject<PatchBOLapiReviewConfigurationDTO>
): Promise<void> {
  return apiPut<void>(BASE_PRV_URL, updateConfig);
}
