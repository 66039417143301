import querystring from 'querystring';

import { apiGet, apiPost, apiPostRaw } from 'api/helpers';

import { MifSearchParams } from '../mif/types';

import {
  GpvSearchParams,
  GpvStatsDTO,
  GpvReportDTO,
  MifStatsDTO,
  PvGraphExportData,
  PvReportDTO,
} from './types';

const BASE_PV_URL = '/api/proxy/pv/api/v1/customers/{cityId}/stats';
const BASE_MIF_URL = '/api/proxy/pv/api/v1/customers/{cityId}/private/mifs';
const BASE_GPV_URL = '/api/proxy/pv/api/v1/customers/{cityId}/stats/gpvs';

export async function getStats(filters: MifSearchParams): Promise<MifStatsDTO> {
  return apiGet<MifStatsDTO>(
    `${BASE_PV_URL}?${querystring.stringify(filters)}`
  );
}

export async function getAgentReport(filters: any = {}): Promise<PvReportDTO> {
  return apiPost<PvReportDTO>(`${BASE_PV_URL}/count_by_agent`, filters);
}

export async function getGpvAgentReport(
  filters: any = {}
): Promise<GpvReportDTO> {
  return apiGet<GpvReportDTO>(
    `${BASE_GPV_URL}/count_by_agent?${querystring.stringify(filters)}`
  );
}

export async function getOrganizationReport(
  filters: any = {}
): Promise<PvReportDTO> {
  return apiGet<PvReportDTO>(
    `${BASE_PV_URL}/count_by_organization?${querystring.stringify(filters)}`
  );
}

export async function getGpvOrganizationReport(
  filters: any = {}
): Promise<GpvReportDTO> {
  return apiGet<GpvReportDTO>(
    `${BASE_GPV_URL}/count_by_organization?${querystring.stringify(filters)}`
  );
}

export async function fetchMifStatsExport(
  filters: MifSearchParams,
  filePrefix?: string
): Promise<void> {
  return apiGet<void>(
    `/api/prv/v0/city/{cityId}/mifs/stats/export?${querystring.stringify({
      ...filters,
      filePrefix,
    })}`
  );
}

export async function exportGraph(body: PvGraphExportData[]): Promise<any> {
  return apiPostRaw(`${BASE_MIF_URL}/exportGraph`, body);
}

export async function getGpvStats(
  filters: GpvSearchParams
): Promise<GpvStatsDTO> {
  return apiGet<GpvStatsDTO>(
    `${BASE_GPV_URL}?${querystring.stringify(filters)}`
  );
}

export async function fetchGpvStatsExport(
  filters: GpvSearchParams,
  filePrefix?: string
): Promise<void> {
  return apiGet<void>(
    `/api/prv/v0/city/{cityId}/gpvs/stats/export?${querystring.stringify({
      ...filters,
      filePrefix,
    })}`
  );
}

export async function exportGpvGraph(body: PvGraphExportData[]): Promise<any> {
  return apiPostRaw(`${BASE_GPV_URL}/exportGraph`, body);
}
