const STORAGE_CREDENTIALS = 'cvfm.credentials';

export type Credentials = {
  cityId: string;
  login: string | null | undefined;
  deviceId: string | null | undefined;
  deviceSecret: string | null | undefined;
};

export function getCredentials(): Credentials | null | undefined {
  try {
    const credentials = JSON.parse(
      localStorage.getItem(STORAGE_CREDENTIALS) || ''
    );
    if (credentials.cityId) {
      return credentials;
    }
  } catch (err) {
    // NOOP
  }
  return null;
}

export function setCredentials(credentials: Credentials): void {
  localStorage.setItem(STORAGE_CREDENTIALS, JSON.stringify(credentials));
}

export function clearCredentials(onlyUser = true): void {
  console.log('Clearing credentials'); // eslint-disable-line no-console
  const credentials = getCredentials();
  if (credentials) {
    if (onlyUser) {
      delete credentials.login;
      localStorage.setItem(STORAGE_CREDENTIALS, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(STORAGE_CREDENTIALS);
    }
  }
}
