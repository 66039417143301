import { CSSProperties } from 'react';

import { BKG_CYAN, TEXT_COLOR_LIGHT, TXT_BLACK } from 'theme';

export const STYLE: {
  [k: string]: CSSProperties;
} = {
  BTN_ICON: {
    color: BKG_CYAN,
    cursor: 'pointer',
  },
  CONTAINER: {
    flex: 1,
    display: 'flex',
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60,
    flexDirection: 'column',
  },
  NAME_CONTAINER: {
    display: 'flex',
    flexDirection: 'column',
    color: TEXT_COLOR_LIGHT,
    overflow: 'hidden',
  },
  FILENAME_CONTAINER: {
    paddingTop: 15,
    marginLeft: 8,
    lineHeight: '12px',
    flex: 1,
  },
  DISPLAY_FILE: {
    display: 'flex',
    alignItems: 'center',
    margin: 16,
    padding: '0 8px',
    cursor: 'pointer',
  },
  DISPLAY_FILE_ICON: {
    width: 40,
    height: 50,
    paddingRight: 8,
    color: TEXT_COLOR_LIGHT,
  },
  DISPLAY_FILE_FILENAME: {
    color: TXT_BLACK,
  },
};

export const COLOR_STYLE = {
  // Expose some of the default theme value
  LABEL_COLOR: '#fff',
  BKG_CYAN,
  TEXT_COLOR_LIGHT,
  TXT_BLACK,
};
