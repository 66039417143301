import _set from 'lodash.set';
import React, { CSSProperties } from 'react';

import { createFormStore, FormStorage } from './createStore';
import { FieldsMap, AttachedField, FormValues } from './types';

export type IProps = {
  style?: CSSProperties;
  onSubmit?: (formValue: FormValues) => void;
  children?: any;
};

/**
 * index.ts acts as the provider
 * it exposes all necessary methods to trigger an external submit (validation, its fields, etc...)
 */
class FormContext extends React.Component<IProps> {
  public store = createFormStore();

  public onSubmit = (e: any) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const entries = this.getFormEntries();
    if (this.isValid() && onSubmit && entries) {
      onSubmit(entries);
    }
  };

  public getFields = (): FieldsMap | undefined => {
    return this.store.getState();
  };

  /** getFormEntries: store all the values in an object structure
   * lodash allows us to create nested object by separating field names by a dot
   * ex: name="car.model" or name="car['model']" will create an object `car` with the property `model` in the result
   */
  public getFormEntries = () => {
    const result = {};
    const fields = this.getFields();
    if (fields) {
      fields.forEach((field: AttachedField) => {
        _set(result, field.getName(), field.getValueOnSubmit());
      });
    }
    return result;
  };

  public isValid = () => {
    let hasError = false;
    const fields = this.getFields();
    if (fields) {
      fields.forEach((field: AttachedField) => {
        hasError = field.validate(field.getValue()) || hasError; // we need to compute all fields to set all the errors in the view
      });
    }
    return !hasError;
  };

  public render() {
    const { children, ...otherProps } = this.props;
    return (
      <FormStorage store={this.store}>
        <form {...otherProps} onSubmit={this.onSubmit}>
          {children}
        </form>
      </FormStorage>
    );
  }
}

export default FormContext;
