import querystring from 'querystring';

import { PaymentFiltersParam } from 'Dashboard/fps/Payment/List/types';
import { PatchObject } from 'api/commonTypes';
import { FpsVehicleConsistencyStatus } from '@cvfm-front/tefps-types';

import { apiGet, apiPatch, apiPost, apiDelete } from '../helpers';

import {
  BaseFpsContactDTO,
  FnmsFpsDTO,
  FpsCaseDTO,
  FpsCreation,
  FpsMapSearchRequest,
  FpsMapSearchResultDTO,
  FpsSearchResultDTO,
  FpsUpdateDTO,
  FpsUpdateDTOResponse,
  PaymentSearchResultDTO,
} from './types';

const BASE_PROXY_FPS_CONTACT_URL =
  '/api/proxy/fps/api/v0/cities/{cityId}/fps_contacts';
const BASE_PROXY_FNMS_URL = '/api/proxy/fps/api/fnms/cities/{cityId}/fines/v1';
const BASE_PROXY_FPS_URL = 'api/proxy/fps/api/v0/cities/{cityId}/admin/cases';
const BASE_PROXY_FPS_CONSISTENCY_URL =
  'api/proxy/fps/api/v0/cities/{cityId}/fps_consistency';
const BASE_BO_URL = '/api/prv/v0/city/{cityId}/fps';

export async function fetchFps(fpsId: string): Promise<FpsCaseDTO> {
  return apiGet<FpsCaseDTO>(`${BASE_BO_URL}/${fpsId}`);
}

export async function fetchAllFpsId(
  filterParam: Record<string, any>
): Promise<Array<string>> {
  return apiPost<Array<string>>(`${BASE_PROXY_FPS_URL}/_ids`, filterParam);
}

export async function patchFps(
  fpsId: string,
  body: Array<PatchObject<unknown>>
): Promise<FnmsFpsDTO> {
  return apiPatch<FnmsFpsDTO>(`${BASE_PROXY_FNMS_URL}/${fpsId}`, body);
}

export async function createFps(body: FpsCreation): Promise<FnmsFpsDTO> {
  return apiPost<FnmsFpsDTO>(`${BASE_BO_URL}`, body);
}

export async function updateFps(
  fpsId: string,
  body: FpsUpdateDTO,
  lastAgentShortId?: string
): Promise<FpsUpdateDTOResponse> {
  return apiPost<FpsUpdateDTOResponse>(
    `${BASE_BO_URL}/${fpsId}${
      lastAgentShortId ? `?${querystring.stringify({ lastAgentShortId })}` : ''
    }`,
    body
  );
}

export async function updateFpsConsistencyStatus(
  fpsId: string,
  status: FpsVehicleConsistencyStatus
): Promise<FpsVehicleConsistencyStatus> {
  return apiPost<FpsVehicleConsistencyStatus>(
    `${BASE_PROXY_FPS_CONSISTENCY_URL}/${fpsId}`,
    status
  );
}

export async function getExport(
  filterParam: Record<string, any>,
  exportParam: Record<string, any>
): Promise<any> {
  return apiPost<any>(`${BASE_BO_URL}/_export`, {
    ...filterParam,
    ...exportParam,
  });
}

export function exportPayments(
  filterParam: Record<string, any>,
  exportParam: Record<string, any>
): Promise<any> {
  return apiPost<unknown>(`${BASE_BO_URL}/_payments-export`, {
    ...filterParam,
    ...exportParam,
  });
}

export async function fetchFpsFilteredList(
  filterParam: Record<string, any>
): Promise<FpsSearchResultDTO> {
  return apiPost<FpsSearchResultDTO>(`${BASE_BO_URL}/_search`, filterParam);
}

export async function fetchFpsForMap(
  filterParam: FpsMapSearchRequest
): Promise<FpsMapSearchResultDTO> {
  const filterMerge = {
    ...filterParam,
    boundingBoxNorthEastLatitude: filterParam.boundingBoxNorthEast.latitude,
    boundingBoxNorthEastLongitude: filterParam.boundingBoxNorthEast.longitude,
    boundingSouthWestLatitude: filterParam.boundingSouthWest.latitude,
    boundingSouthWestLongitude: filterParam.boundingSouthWest.longitude,
  };
  const filter = {
    ...filterMerge,
    boundingBoxNorthEast: undefined,
    boundingSouthWest: undefined,
  };
  return apiPost<FpsMapSearchResultDTO>(`${BASE_BO_URL}/_map`, filter);
}

export function getFpsCasePdfUrl(fpsId: string, withPictures: boolean): string {
  return `${BASE_PROXY_FPS_URL}/${fpsId}/pdf/fpsCase?exportWithPictures=${withPictures}`;
}

export function getNoticeUrl(fpsId: string): string {
  return `${BASE_PROXY_FPS_URL}/${fpsId}/pdf/notice`;
}

export function getProofUrl(fpsId: string): string {
  return `${BASE_PROXY_FPS_URL}/${fpsId}/pdf/payments/proof`;
}

export function getCashVoucherUrl(): string {
  return `${BASE_PROXY_FPS_URL}/payments/cash-voucher`;
}

export async function fetchPaymentFilteredList(
  filterParam: PaymentFiltersParam
): Promise<PaymentSearchResultDTO> {
  return apiPost<PaymentSearchResultDTO>(
    `${BASE_BO_URL}/_payments`,
    filterParam
  );
}

export async function addContact(
  contact: BaseFpsContactDTO,
  fpsId: string
): Promise<void> {
  return apiPost<void>(
    `${BASE_PROXY_FPS_CONTACT_URL}/_addContact/${fpsId}`,
    contact
  );
}

export async function deleteContact(contactId: string): Promise<void> {
  const encodedContactId = encodeURIComponent(contactId);
  return apiDelete<void>(
    `${BASE_PROXY_FPS_CONTACT_URL}/_removeContactById/${encodedContactId}`
  );
}
