import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

import { getApiState } from 'api/duck';

import { setUniverse } from './helpers';

export type Universe = 'pv' | 'fps' | 'tao';

export type UniverseState = {
  activeUniverse: Universe | null | undefined;
  availableUniverses: Array<Universe>;
};

type InitUniverseState = {
  type: 'INIT_UNIVERSE';
  active: Universe | null | undefined;
  available: Array<Universe>;
};

type SwitchUniverse = {
  type: 'SWITCH_UNIVERSE';
  active: Universe;
};

type UniverseAction = InitUniverseState | SwitchUniverse;

function initialState(): UniverseState {
  return {
    activeUniverse: null,
    availableUniverses: [],
  };
}

export function initUniverseState(
  universeState: UniverseState
): InitUniverseState {
  return {
    type: 'INIT_UNIVERSE',
    active: universeState.activeUniverse,
    available: universeState.availableUniverses,
  };
}

export function switchUniverse(
  universe: Universe
): ThunkAction<void, any, any, any> {
  return (dispatch: Dispatch<any>, getState: any) => {
    const { cityId } = getApiState(getState());
    if (!cityId) return;

    setUniverse(universe, cityId);
    dispatch({
      type: 'SWITCH_UNIVERSE',
      active: universe,
    });
  };
}

export default function universeReducer(
  state: UniverseState = initialState(),
  action: UniverseAction
): UniverseState {
  switch (action.type) {
    case 'INIT_UNIVERSE':
      return {
        activeUniverse: action.active,
        availableUniverses: action.available,
      };
    case 'SWITCH_UNIVERSE':
      if (!state.availableUniverses.includes(action.active)) return state;
      return {
        ...state,
        activeUniverse: action.active,
      };
    default:
      return state;
  }
}

export function getUniverseState(state: any): UniverseState {
  return state.universe;
}
