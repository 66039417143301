import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ErrorModal from 'commons/ErrorModal/ErrorModal';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import {
  fetchMailTemplate,
  updateMailTemplate,
  openPreviewURL,
} from 'api/mail';
import {
  MailConfigurationDTO,
  MailTemplateDTO,
  MailType,
} from '@cvfm-front/tefps-types';
import { getHintList } from 'commons/Mail/utils';
import SectionTitle from 'commons/SectionTitle';
import { Config, getConfigState } from 'config/duck';

import MailConfiguration from './MailConfiguration/MailConfiguration';
import MailEditor from './MailEditor/MailEditor';

const { _t } = window.loadTranslations(__filename);

type Props = {
  mailType: MailType;
  organizationId?: string;
  resourceId?: string;
  useSubject?: boolean;
  useSms?: boolean;
  config: Config;
  enableTest?: boolean;
  useHeaderFooter?: boolean;
  noPreview?: boolean;
};

const MailTemplateEditor = ({
  mailType,
  organizationId,
  useSms,
  useSubject,
  resourceId,
  config,
  enableTest,
  useHeaderFooter,
  noPreview,
}: Props): JSX.Element => {
  const [mailTemplate, setMailTemplate] = useState<
    MailTemplateDTO | null | undefined
  >(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const setMessage = useSnackbar();

  const initMailContent = async () => {
    const response = await fetchMailTemplate({
      type: mailType,
      organizationId,
      resourceId,
    });
    setMailTemplate(response);
  };

  useEffect(() => {
    void initMailContent();
  }, [mailType, resourceId, organizationId]);

  const onContentChange = (newTemplate: MailTemplateDTO) => {
    setMailTemplate({ ...newTemplate });
  };

  const computeErrorNode = (content: string): Element | null => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      `<html>${content.replace(/&/g, '&amp;')}</html>`,
      'application/xml'
    );
    return doc.querySelector('parsererror');
  };

  const onSave = async () => {
    if (mailTemplate) {
      const errorNode = computeErrorNode(mailTemplate.mail || '');
      if (errorNode) {
        setError(true);
        setErrorMessage(_t('error.parseError'));
        return;
      }
      try {
        await updateMailTemplate(mailTemplate);
        setMessage(_t('feedback.success.modelSaved'));
      } catch (e) {
        setError(true);
        setErrorMessage(e.message);
      }
    }
  };

  const onPreview = () => {
    if (mailTemplate && mailTemplate.id) {
      try {
        openPreviewURL(mailTemplate.id);
      } catch (e) {
        setError(true);
        setErrorMessage(e.message);
      }
    }
  };

  const onConfigurationChange = (
    mailConfiguration: MailConfigurationDTO | null | undefined
  ) => {
    if (mailConfiguration) {
      setMailTemplate({
        ...mailTemplate,
        mailConfiguration,
      } as MailTemplateDTO);
    } else {
      setMailTemplate({
        ...mailTemplate,
        mailConfiguration: null,
      } as MailTemplateDTO);
    }
  };

  const onErrorClose = () => {
    setError(false);
    setErrorMessage('');
  };

  return (
    <>
      <SectionTitle title={_t('element.editMail.title')} />
      {mailTemplate && (
        <>
          <MailEditor
            mailTemplate={mailTemplate}
            onContentChange={onContentChange}
            onSave={onSave}
            onPreview={noPreview ? undefined : onPreview}
            hintList={getHintList(config, mailType) || []}
            useSms={useSms}
            useSubject={useSubject}
            enableTest={enableTest}
          />
          {useHeaderFooter && (
            <>
              <SectionTitle title={_t('element.configureHeaderFooter.title')} />
              <MailConfiguration
                mailConfiguration={
                  mailTemplate ? mailTemplate.mailConfiguration : null
                }
                onSave={onSave}
                onChange={onConfigurationChange}
                organizationId={organizationId}
                editable
                mailType={mailType}
              />
            </>
          )}
        </>
      )}
      <ErrorModal open={error} onClose={onErrorClose} message={errorMessage} />
    </>
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return {
    config,
  };
})(MailTemplateEditor);
