import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  initialFilters as fpsInitialFilters,
  strategyFiltersChanged as setFpsFilters,
} from '../Dashboard/fps/Strategy/duck';
import { MapFilters as FpsMapFilters } from '../Dashboard/fps/Strategy/types';

import MapLinkButton from './MapLinkButton';
import { computeDatesWithPeriod } from './DateFilter';

const { _t } = window.loadTranslations(__filename);

type ReduxDispatchProps = {
  updateFpsFilters: (newFilters: FpsMapFilters) => void;
};

type Props = ReduxDispatchProps & {
  fpsId: string;
};

const FpsMapButton = ({ fpsId, updateFpsFilters }: Props) => {
  const history = useHistory();

  const setFpsFiltersAndRedirect = () => {
    updateFpsFilters({
      ...fpsInitialFilters(),
      dates: computeDatesWithPeriod('-1'),
      fineLegalId: fpsId,
    });
    history.push({
      pathname: '/dashboard/strategy',
    });
  };

  return (
    <MapLinkButton
      label={_t('element.mapButton.label')}
      onClick={setFpsFiltersAndRedirect}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<object>
): ReduxDispatchProps => ({
  updateFpsFilters: (filters: FpsMapFilters) =>
    dispatch(setFpsFilters(filters)),
});

export default connect(null, mapDispatchToProps)(FpsMapButton);
