import { Dispatch } from 'redux';

import {
  clearCredentials,
  setCredentials,
  getCredentials,
} from 'Login/credentials-storage';
import { clearAuthLocalStore } from 'Login/auth-storage';
import { getUserInfo } from 'api/auth';

import { InternalAgent } from './auth/types';

declare let __SERVER_HOSTNAME__: string | null | undefined;

type AbstractApiState = {
  hostname: string;
  scope: Array<string> | null | undefined;
  userInfo: InternalAgent | null | undefined;
  login?: any;
  account?: any;
};

export type InternalApiState = AbstractApiState & {
  cityId: string | null | undefined;
};

// In most component, we are authenticated for sure and the cityId will not be null
export type ApiState = AbstractApiState & {
  cityId: string;
  userInfo: InternalAgent;
};

const LOGIN = 'User/LOGIN';
const USER_INFO = 'User/USER_INFO';
const REFRESHED = 'User/REFRESHED';
export const LOGOUT = 'User/LOGOUT';

type LoginAction = {
  type: typeof LOGIN | typeof USER_INFO | typeof LOGOUT;
  cityId?: string;
  scope?: Array<string>;
  userInfo?: InternalAgent | null | undefined; // added on flow-to-ts switch
};

type RefreshedAction = {
  type: typeof REFRESHED;
  scope?: Array<string>;
  userInfo?: InternalAgent | null | undefined; // added on flow-to-ts switch
};

export type ApiAction = LoginAction | RefreshedAction;

function inferHostname() {
  // In production, the backend has the same adress as the front because it serves it directly
  return location.href.split('#')[0];
}

const initialState: InternalApiState = {
  hostname: __SERVER_HOSTNAME__ || inferHostname(), // eslint-disable-line no-undef
  cityId: null,
  scope: null,
  userInfo: null,
};

export default function apiReducer(
  state: InternalApiState = initialState,
  action: ApiAction
): InternalApiState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        cityId: action.cityId,
        scope: action.scope,
      };
    case USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case REFRESHED:
      return {
        ...state,
        scope: action.scope,
      };
    case LOGOUT:
      return {
        ...state,
        login: null,
        account: null,
        userInfo: null,
      };
    default:
      return state;
  }
}

export function getApiState(state: any): InternalApiState {
  return state.api;
}

export function loginAction(cityId: string, scope: Array<string>) {
  return { type: LOGIN, cityId, scope };
}

export function fetchUserInfoAction(): any {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const userInfo = await getUserInfo();
    dispatch({ type: USER_INFO, userInfo });
  };
}

export function refreshed(scope: Array<string>): RefreshedAction {
  const credentials = getCredentials();
  setCredentials({
    cityId: credentials ? credentials.cityId : '',
    login: credentials?.login,
    deviceId: credentials?.deviceId,
    deviceSecret: credentials?.deviceSecret,
  });
  return { type: REFRESHED, scope };
}

export function logoutAction() {
  clearCredentials();
  clearAuthLocalStore();
  return { type: LOGOUT };
}
