import { MailConfigurationDTO, MailTemplateDTO } from '@cvfm-front/tefps-types';

import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  openNewAuthentifiedTab,
} from '../helpers';

import { MailTemplateQueryDTO } from './type';

const BASE_PROXY_URL = '/api/proxy/documents/api/cities/{cityId}/mail/v0';
const BASE_PROXY_DOCUMENT_URL =
  '/api/proxy/documents/api/cities/{cityId}/documents/v0';

export async function fetchMailTemplate(
  query: MailTemplateQueryDTO
): Promise<MailTemplateDTO> {
  return apiPost<MailTemplateDTO>(`${BASE_PROXY_URL}/mailTemplate`, query);
}

export async function updateMailTemplate(
  mailTemplate: MailTemplateDTO
): Promise<void> {
  return apiPut<void>(`${BASE_PROXY_URL}/mailTemplate`, mailTemplate);
}

export function openPreviewURL(mailTemplateId: string): void {
  openNewAuthentifiedTab(
    `${BASE_PROXY_DOCUMENT_URL}/mailTemplate/${mailTemplateId}/preview`
  );
}

export async function fetchMailConfigurationList(
  organizationId: string | null | undefined
): Promise<Array<MailConfigurationDTO>> {
  return apiGet<Array<MailConfigurationDTO>>(
    `${BASE_PROXY_URL}/mailConfiguration${
      organizationId ? `?organizationId=${organizationId}` : ''
    }`
  );
}

export async function createMailConfiguration(
  name: string,
  organizationId: string | null | undefined
): Promise<MailConfigurationDTO> {
  return apiPost<MailConfigurationDTO>(
    `${BASE_PROXY_URL}/mailConfiguration?name=${name}&&${
      organizationId ? `organizationId=${organizationId}` : ''
    }`,
    undefined
  );
}

export async function updateMailConfiguration(
  mailConfiguration: MailConfigurationDTO
): Promise<MailConfigurationDTO> {
  return apiPut<MailConfigurationDTO>(
    `${BASE_PROXY_URL}/mailConfiguration/${mailConfiguration.id}`,
    mailConfiguration
  );
}

export async function deleteMailConfiguration(
  mailConfigurationId: string
): Promise<void> {
  return apiDelete<void>(
    `${BASE_PROXY_URL}/mailConfiguration/${mailConfigurationId}`
  );
}
